import {Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';

export interface IInitialiseData {
  displayedContentId: string;
}

@injectable()
export class BookViewerDisplayedDataModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.BookViewerBookContent)
    BookContent: BookViewerBookContentModule,
  ) {
    @Module({name: StoreName.BookViewerDisplayedData, store})
    class BookViewerBookContent extends VuexModule {
      public displayedContentId: string = null;
      public isSwitchingContent = false;

      public get content(): ContentFragment {
        return BookContent.contentInfo(this.displayedContentId);
      }

      public get isFirstPage(): boolean {
        return BookContent.firstPageId === this.displayedContentId;
      }

      public get orderNumber(): number {
        if (!this.shouldDisplayOrderNumber) return null;
        return BookContent.orderNumbers[this.content.id];
      }

      public get title(): string {
        return BookContent.title(this.displayedContentId);
      }

      public get shouldDisplayOrderNumber(): boolean {
        return (
          this.content?.data.type === MatterType.Chapter ||
          this.content?.data.type === MatterType.Part
        ) && this.isBodyMatter;
      }

      public get isBodyMatter(): boolean {
        return !!this.content?.path.find((pathFragment) => pathFragment === MatterType.Body);
      }

      @Mutation
      public DISPLAYED_CONTENT_ID(displayedContentId: string): void {
        this.displayedContentId = displayedContentId;
      }

      @Mutation
      public IS_SWITCHING_CONTENT(isSwitchingContent: boolean): void {
        this.isSwitchingContent = isSwitchingContent;
      }
    }

    this.Module = BookViewerBookContent;
  }
}

export type BookViewerDisplayedDataModule = InstanceType<BookViewerDisplayedDataModuleFactory['Module']>;
