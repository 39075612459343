import Module from '@reedsy/quill/core/module';

/*
 * No-op module to override the Quill History module, which adds undo/redo
 * behaviour. We have a book-wide undo/redo stack, which exists outside of Quill, so
 * let's remove Quill's undo/redo logic to avoid conflicts with our own stack.
 */
export default class History extends Module {
  public clear(): void {
    // No op
  }

  public cutoff(): void {
    // No op
  }
}
