<template>
  <VuiSlider
    v-model="value"
    :adsorb="true"
    :marks="true"
    :hide-label="true"
  />
</template>

<script lang="ts">
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class Slider extends ClientSharedVue {
  @Model
  public value: any;
}
</script>
