import IChange from '@reedsy/studio.shared/store/modules/rich-text/i-change';
import Delta from '@reedsy/quill-delta';
import Op from '@reedsy/quill-delta/dist/Op';
import {trackedComments} from '@reedsy/studio.shared/services/track-changes/track-changes-utils';
import hasId from './has-id';
import {clone} from '@reedsy/utils.clone';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import AttributeMap from '@reedsy/quill-delta/dist/AttributeMap';

const {BLOCK, INLINE} = TrackChangesKeys;

export default function resolveComments(changes: IChange[], contents: Op[]): Delta {
  const ops: Op[] = [];
  const changeIds = new Set(changes.map((change) => change.id));
  contents.forEach((op) => {
    if (shouldResolveComment(op, changeIds)) {
      op = clone(op);
      let attributes = op.attributes;
      changeIds.forEach((id) => {
        attributes = AttributeMap.compose(attributes, {
          [INLINE.COMMENT]: {[id]: null},
          [BLOCK.COMMENT]: {[id]: null},
        }, false);
      });

      if (attributes) op.attributes = attributes;
      else delete op.attributes;
    }

    ops.push(op);
  });

  return new Delta(contents).diff(new Delta(ops));
}

function shouldResolveComment(op: Op, changeIds: Set<string>): boolean {
  const comments = trackedComments(op);
  return hasId(comments, changeIds);
}
