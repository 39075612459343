import {CollapsedSelectionTooltip} from '@reedsy/studio.shared/services/quill/theme/collapsed-selection-tooltip';
import {Bounds} from '@reedsy/studio.shared/services/quill/modules/bounds.interface';

export abstract class MentionsTooltip extends CollapsedSelectionTooltip {
  protected abstract get tooltipAnchorBounds(): Bounds;

  public override listen(): void {
    super.listen();

    const resizeObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(this.updateTooltipPosition.bind(this));
    });
    resizeObserver.observe(this.quill.options.bounds || this.quill.root);
  }

  private updateTooltipPosition(): void {
    if (!this.tooltipAnchorBounds) return;

    this.position(this.tooltipAnchorBounds);
  }
}
