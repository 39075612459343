
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {$lazyInjectStore, $lazyInject} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({})
export default class Footer extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  @$lazyInject('Navigation')
  public navigation: INavigation;

  public get writeABookUrl(): string {
    return this.navigation.writeABookUrl;
  }

  public get authorName(): string {
    return this.$bookContent.authorName;
  }
}
