import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import LocalStorageHelper from '@reedsy/studio.shared/services/local-storage/local-storage-helper';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable, named} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';

@injectable()
export class BookViewerReadingLocationModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('LocalStorageHelper')
    localStorageHelper: LocalStorageHelper,

    @$inject('StoreModule')
    @named(StoreName.BookViewerBookContent)
    BookContent: BookViewerBookContentModule,
  ) {
    @Module({name: StoreName.BookViewerReadingLocation, store})
    class BookViewerReadingLocationModule extends VuexModule {
      public readonly NAMESPACE = 'book-viewer-reading-location';
      public lastReadContentId = '';
      /**
       * Scroll position value is from 0 to 1
       */
      public lastReadPosition = 0;

      @Action
      public changeLastReadContentId(lastReadContentId: string): void {
        this.LAST_READ_CONTENT_ID(lastReadContentId);
        localStorageHelper.setItem(this, 'lastReadContentId', this.lastReadContentId);
      }

      @Action
      public changeLastReadPosition(lastReadPosition: number): void {
        this.LAST_READ_POSITION(lastReadPosition);
        localStorageHelper.setItem(this, 'lastReadPosition', this.lastReadPosition);
      }

      @Action
      public loadFromStorage(): void {
        const lastReadContentId = localStorageHelper.getItem(this, 'lastReadContentId');
        const lastReadPosition = localStorageHelper.getItem(this, 'lastReadPosition');

        const contentInfo = BookContent.contentInfo(lastReadContentId);

        if (!contentInfo) return;
        this.LAST_READ_CONTENT_ID(lastReadContentId);
        this.LAST_READ_POSITION(lastReadPosition);
      }

      @Mutation
      private LAST_READ_CONTENT_ID(lastReadContentId: string): void {
        this.lastReadContentId = lastReadContentId;
      }

      @Mutation
      private LAST_READ_POSITION(lastReadPosition: number): void {
        this.lastReadPosition = lastReadPosition;
      }
    }

    this.Module = BookViewerReadingLocationModule;
  }
}

export type BookViewerReadingLocationModule = InstanceType<BookViewerReadingLocationModuleFactory['Module']>;
