import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "feature-summaries" }
const _hoisted_3 = { class: "flex-justified" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DelayedLoadingIndicator = _resolveComponent("DelayedLoadingIndicator")!
  const _component_FeatureSummary = _resolveComponent("FeatureSummary")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (!_ctx.currencies)
    ? (_openBlock(), _createElementBlock("rbe-loading", _hoisted_1, [
        _createVNode(_component_DelayedLoadingIndicator, { delay: 1_000 })
      ]))
    : (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
        key: 1,
        id: _ctx.id,
        title: `Start your free ${_ctx.config.stripe.trialDays}-day trial`,
        class: "subscription-trial-modal"
      }, {
        left: _withCtx(() => [
          _createElementVNode("rbe-trail-info", null, [
            _cache[5] || (_cache[5] = _createElementVNode("p", null, "\n          Unlock Studio by test driving our premium add-ons.\n        ", -1)),
            _cache[6] || (_cache[6] = _createTextVNode()),
            _createElementVNode("section", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSummaries, (summary) => {
                return (_openBlock(), _createBlock(_component_FeatureSummary, {
                  key: summary.product,
                  ref_for: true,
                  ref: summary.product,
                  "model-value": true,
                  summary: summary,
                  "show-trial-price": ""
                }, null, 8, ["summary"]))
              }), 128))
            ]),
            _cache[7] || (_cache[7] = _createTextVNode()),
            _createElementVNode("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("div", null, "\n            TODO: [subscriptions] Add currency selector\n          ", -1)),
              _cache[4] || (_cache[4] = _createTextVNode()),
              _createElementVNode("rbe-trial-actions", null, [
                _createElementVNode("button", {
                  class: "button",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }, "\n              Not yet\n            "),
                _cache[2] || (_cache[2] = _createTextVNode()),
                _createVNode(_component_LoadingButton, {
                  class: "button",
                  type: "button",
                  loading: _ctx.loading,
                  onClick: _ctx.startTrial
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("\n              Start free trial\n            ")
                  ])),
                  _: 1
                }, 8, ["loading", "onClick"])
              ])
            ])
          ])
        ]),
        right: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("\n      TODO: [subscriptions] Add videos\n    ")
        ])),
        _: 1
      }, 8, ["id", "title"]))
}