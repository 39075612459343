import Op from '@reedsy/quill-delta/dist/Op';
import {ITrackedChanges, ITrackedChange} from '@reedsy/studio.shared/services/track-changes/i-tracked-changes';
import AttributeMap from '@reedsy/quill-delta/dist/AttributeMap';
import {ITrackedReformats, ITrackedReformat} from './i-tracked-reformats';
import {TrackChangesKeys, TRACK_CHANGES_KEYS} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import TrackDeletionBlockIndicator from '@reedsy/studio.shared/services/quill/blots/track-changes/track-deletion-block-indicator';
import Delta from '@reedsy/quill-delta';
import {findAttribute, withoutAttributes} from '@reedsy/utils.ot-rich-text';
import {isBlock} from '@reedsy/studio.shared/services/quill/helpers/is-block';

const {CHANGE, BLOCK, INLINE} = TrackChangesKeys;

export function trackedDeletions(op: Op | AttributeMap): ITrackedChanges | ITrackedChange[] {
  return findAttribute(
    op,
    INLINE.DELETION,
    BLOCK.DELETION,
    BLOCK.COLLAPSED_DELETION,
  ) || {};
}

export function trackedInsertions(op: Op | AttributeMap): ITrackedChanges | ITrackedChange[] {
  return findAttribute(op, INLINE.INSERTION, BLOCK.INSERTION) || {};
}

export function trackedReformats(op: Op | AttributeMap): ITrackedReformats | ITrackedReformat[] {
  return findAttribute(op, INLINE.REFORMAT, BLOCK.REFORMAT) || {};
}

export function trackedComments(op: Op | AttributeMap): ITrackedChanges | ITrackedChange[] {
  return findAttribute(op, INLINE.COMMENT, BLOCK.COMMENT) || {};
}

export function withoutTrackAttributes(attributes: AttributeMap): AttributeMap {
  const trackAttributes: string[] = [];
  TRACK_CHANGES_KEYS.forEach((attribute) => {
    trackAttributes.push(attribute);
  });
  return withoutAttributes(attributes, ...trackAttributes);
}

export function isBlockOrCollapsedBlock(op: Op): boolean {
  return isBlock(op) || !!(op.insert as any)[TrackDeletionBlockIndicator.blotName];
}

export function contributorIds(delta: Delta): string[] {
  const ids = new Set<string>();
  delta.ops.forEach((op) => {
    if (!op.attributes) return;
    TRACK_CHANGES_KEYS.forEach((attribute) => {
      const changesById = op.attributes[attribute] as ITrackedChanges;
      if (!changesById) return;
      const changes = Object.values(changesById);
      changes.forEach((change) => {
        if (change && change.userId) ids.add(change.userId);
      });
    });
  });
  return Array.from(ids);
}

export function canonicalChanges<T extends ITrackedChange>(changes: T[] | {[id: string]: T}): {[id: string]: T} {
  if (!Array.isArray(changes)) return changes;
  return changes.reduce((canonical, change) => {
    canonical[change.changeId] = change;
    return canonical;
  }, {} as {[id: string]: T});
}

export function isTracked(op: Op | AttributeMap): boolean {
  op ||= {};
  const attributes = (op.attributes ? op.attributes : op) as AttributeMap;
  let isTracked = false;
  TRACK_CHANGES_KEYS.forEach((attribute) => {
    if (attribute === CHANGE) return;
    isTracked = isTracked || !!attributes[attribute];
  });
  return isTracked;
}

export function nullToFalse(attributes: AttributeMap): AttributeMap {
  return shallowReplace(attributes, null, false);
}

export function falseToNull(attributes: AttributeMap): AttributeMap {
  return shallowReplace(attributes, false, null);
}

function shallowReplace(attributes: AttributeMap, search: any, replacement: any): AttributeMap {
  const copy: AttributeMap = {};
  for (const key in attributes) {
    copy[key] = attributes[key] === search ? replacement : attributes[key];
  }
  return copy;
}
