import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';

@injectable()
export class BookViewerLayoutModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,
  ) {
    @Module({name: StoreName.BookViewerLayout, store})
    class BookViewerLayout extends VuexModule {
      public fontSettingsMenuIsOpen = false;
      public leftSidebarIsOpen = false;
      public loading = true;

      @Action
      public toggleFontSettingsMenu(): void {
        this.FONT_SETTINGS_MENU_IS_OPEN(!this.fontSettingsMenuIsOpen);
      }

      @Action
      public hideFontSettingsMenu(): void {
        this.FONT_SETTINGS_MENU_IS_OPEN(false);
      }

      @Action
      public toggleLeftSidebar(): void {
        this.LEFT_SIDEBAR_IS_OPEN(!this.leftSidebarIsOpen);
      }

      @Action
      public closeLeftSidebar(): void {
        this.LEFT_SIDEBAR_IS_OPEN(false);
      }

      @Mutation
      public LOADING(value: boolean): void {
        this.loading = value;
      }

      @Mutation
      private FONT_SETTINGS_MENU_IS_OPEN(fontSettingsMenuIsOpen: boolean): void {
        this.fontSettingsMenuIsOpen = fontSettingsMenuIsOpen;
      }

      @Mutation
      private LEFT_SIDEBAR_IS_OPEN(leftSidebarIsOpen: boolean): void {
        this.leftSidebarIsOpen = leftSidebarIsOpen;
      }
    }

    this.Module = BookViewerLayout;
  }
}

export type BookViewerLayoutModule = InstanceType<BookViewerLayoutModuleFactory['Module']>;
