import {injectable, named} from 'inversify';
import {BookViewerRouterPlugin} from '@reedsy/studio.viewer/store/book-viewer-router-plugin';
import {$inject} from '@reedsy/studio.shared/types';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {timeout} from '@reedsy/utils.timeout';
import {Viewport} from '@reedsy/studio.shared/utils/viewport';
import {decodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';
import {BookViewerReadingLocationModule} from '@reedsy/studio.viewer/store/modules/reading-location/reading-location';
import {BookViewerRouteName} from '@reedsy/studio.shared/router/route-names/book-viewer-route-name';
import {scrollToPosition} from '@reedsy/studio.viewer/utils/scroll/scroll-to-position';
import {IBookViewerRoute} from '@reedsy/studio.viewer/router/i-book-viewer-route';

@injectable()
export default class ScrollPositionPluginFactory extends BookViewerRouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.BookViewerRichText)
  public readonly _RichText: BookViewerRichTextModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerReadingLocation)
  public readonly _ReadingLocation: BookViewerReadingLocationModule;

  public override async afterEach(to: IBookViewerRoute, from: IBookViewerRoute): Promise<void> {
    const encodedContentId = to.params.contentId;
    if (!encodedContentId) {
      return;
    }

    const hash = to.hash;
    const contentId = decodeObjectId(encodedContentId);
    await this._RichText.waitForContentToLoad(contentId);
    // To make sure the editors are rendered
    await timeout();

    if (hash) {
      await this.scrollToHash(hash);
      return;
    }

    if (this.shouldScrollToLastReadPosition(to, from)) {
      this.scrollContent(this._ReadingLocation.lastReadPosition);
      return;
    }

    this._ReadingLocation.changeLastReadPosition(0);
  }

  private shouldScrollToLastReadPosition(_to: IBookViewerRoute, from: IBookViewerRoute): boolean {
    if (!this._ReadingLocation.lastReadPosition) return false;
    return from.name === BookViewerRouteName.BookViewer || !from.name;
  }

  private async scrollToHash(hash: string): Promise<void> {
    const element = document.querySelector(hash);
    if (element) {
      await Viewport.scrollIntoView(element, {
        behavior: 'auto',
        block: 'center',
      });
    }
  }

  private scrollContent(scrollPosition: number): void {
    const contentScrollDiv = document.getElementById('content-scroll');
    scrollToPosition(contentScrollDiv, scrollPosition);
  }
}
