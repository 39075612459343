import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {TopMatterType} from '@reedsy/reedsy-sharedb/lib/server/book-contents-structure';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {topMatterType} from './top-matter-type';

export function count(bookContent: ContentFragment[], options: ICountOptions): number {
  bookContent = bookContentUntil(bookContent, options.until);

  if (options.types?.length) {
    const types = new Set(options.types);
    bookContent = bookContent.filter((content) => types.has(content.data.type));
  }

  if (options.in) {
    bookContent = bookContent.filter((content) => topMatterType(content) === options.in);
  }

  return bookContent.length;
}

function bookContentUntil(bookContent: ContentFragment[], until: string): ContentFragment[] {
  const filtered: ContentFragment[] = [];
  for (const content of bookContent) {
    if (content.data.enabled !== false) filtered.push(content);
    if (content.id === until) break;
  }

  return filtered;
}

export interface ICountOptions {
  types?: ReadonlyArray<MatterType>;
  in?: TopMatterType;
  until?: string;
}
