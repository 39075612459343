export const THEMES = {
  'theme-reedsy': 'Reedsy',
  'theme-classic': 'Classic',
  'theme-romance': 'Romance',
} as const;
export type Theme = keyof typeof THEMES;
export type ThemeName = typeof THEMES[Theme];

export const FONT_SIZES = {
  'font-size-small': 'Small',
  'font-size-medium': 'Medium',
  'font-size-large': 'Large',
} as const;
export type FontSize = keyof typeof FONT_SIZES;
export type FontSizeName = typeof FONT_SIZES[FontSize];

export const LINE_HEIGHTS = {
  'line-height-slight': 'Slight',
  'line-height-normal': 'Normal',
  'line-height-double': 'Double',
} as const;
export type LineHeight = keyof typeof LINE_HEIGHTS;
export type LineHeightName = typeof LINE_HEIGHTS[LineHeight];

export const TEXT_JUSTIFIED_CLASS = 'text-justified';
export type TextJustified = typeof TEXT_JUSTIFIED_CLASS;
