import {injectable} from 'inversify';
import {ILocalStorage} from './i-local-storage';

@injectable()
export default class LocalStorageHelper {
  private readonly staticNamespaces: string[];

  public constructor(
    rootNamespace: string,
    ...staticNamespaces: string[]
  ) {
    this.staticNamespaces = [rootNamespace, ...staticNamespaces];
  }

  public getItem<T extends ILocalStorage, K extends keyof T, V extends T[K]>({NAMESPACE}: T, property: K & string): V {
    const value = localStorage.getItem(this.getItemKey(NAMESPACE, property));
    return JSON.parse(value) as V;
  }

  public setItem<T extends ILocalStorage, K extends keyof T, V extends T[K]>(
    {NAMESPACE}: T,
    property: K,
    value: V,
  ): void {
    value = value === undefined ? null : value;
    const serialized = JSON.stringify(value);
    localStorage.setItem(this.getItemKey(NAMESPACE, property as string), serialized);
  }

  protected addNamespace(namespace: string): void {
    this.staticNamespaces.push(namespace);
  }

  private getItemKey(namespace: string, property: string): string {
    return [
      ...this.staticNamespaces,
      namespace,
      property,
    ].join('.');
  }
}
