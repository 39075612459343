import {ParentBlot} from 'parchment';
import Container from '@reedsy/quill/blots/container';
import Scroll from '@reedsy/quill/blots/scroll';
import Blot from '@reedsy/studio.shared/services/quill/blots/blot';
import FigureImage from './figure-image';
import FigureCaption from './figure-caption';
import Quill from '@reedsy/quill/core';
import {makeElementDraggable} from '@reedsy/studio.shared/services/quill/helpers/drag-and-drop';
import findQuill from '@reedsy/studio.shared/services/quill/helpers/find-quill';
import ActionBar, {IActionBarOptions} from '@reedsy/studio.shared/services/quill/helpers/action-bar/action-bar';
import TrackChanges from '@reedsy/studio.shared/services/quill/modules/track-changes';

export default class Figure extends Container {
  public static override readonly blotName = 'figure';
  public static override readonly className = 'ql-figure';
  public static override readonly tagName = 'figure';
  public static readonly classes = Object.freeze({
    SELECTED: 'selected',
    INVALID: 'invalid',
    READ_ONLY: 'read-only',
  });

  private readonly quill: Quill;

  public constructor(scroll: Scroll, domNode: HTMLElement) {
    super(scroll, domNode);
    this.quill = findQuill(scroll.domNode);

    if (this.isReadOnly) {
      this.domNode.classList.add(Figure.classes.READ_ONLY);
      return;
    }

    const actionBarOptions: IActionBarOptions = {
      remove: () => this.remove(),
    };

    if (this.quill.getModule('track-changes')) {
      actionBarOptions.comment = () => this.addComment();
    }

    const actionBar = new ActionBar(actionBarOptions);

    makeElementDraggable(this.domNode, this.isTrackedDeletion.bind(this));
    domNode.addEventListener('click', this.handleClick.bind(this));
    domNode.prepend(actionBar.element);
  }

  private get isReadOnly(): boolean {
    return this.quill.options.readOnly;
  }

  public override optimize(context: any): void {
    super.optimize(context);

    // If the optimization has removed this node, no need to
    // do any further work
    if (!this.scroll.domNode.contains(this.domNode)) return;

    this.ensureOnlyOneImageAndOneCaption();
    this.setInvalidClass();
  }

  public override insertBefore(child: Blot, ref: Blot): void {
    if (ref !== this.children.head) return super.insertBefore(child, ref);

    // Something being inserted before the first child doesn't make
    // sense, since the first child must always be an image.
    // So just shunt the insertion before the figure instead. If
    // it's another image, it'll be wrapped in another figure.
    this.scroll.insertBefore(child, this);
  }

  public override checkMerge(): boolean {
    return super.checkMerge() &&
      // If this figure is "complete" (ie has an image and a caption),
      // and the next figure is also complete, then there is no need
      // to merge them, since we'll just have to split them again
      // later (causing an infinite loop).
      this.onlyHasImage(this) &&
      this.onlyHasCaption(this.next);
  }

  public override deleteAt(index: number, length: number): void {
    if (index === 0 && length === 1) {
      // In the special case that we're deleting just the image, we
      // should also interpret this as wanting to remove the entire
      // figure, including the caption. We mark it for deletion later in
      // the optimize cycle to avoid breaking ops that follow this deletion
      const caption = this.children.tail;
      if (caption instanceof FigureCaption) caption.markForDeletion();
    }

    super.deleteAt(index, length);
  }

  public isTrackedDeletion(): boolean {
    const image = this.children.head;
    return image instanceof FigureImage && image.isTrackedDeletion();
  }

  private ensureOnlyOneImageAndOneCaption(): void {
    let newFigure: ParentBlot;
    let index = 0;
    this.children.forEach((child) => {
      if (!newFigure && this.shouldStartNewFigure(child, index++)) {
        newFigure = this.scroll.create(Figure.blotName) as ParentBlot;
        this.scroll.insertBefore(newFigure, this.next);
      }

      if (newFigure) newFigure.appendChild(child);
    });
  }

  private shouldStartNewFigure(child: Blot, index: number): boolean {
    return child instanceof FigureImage && index > 0;
  }

  private onlyHasImage(blot: Blot): boolean {
    return blot instanceof Figure &&
      blot.children.length === 1 &&
      blot.children.head instanceof FigureImage;
  }

  private onlyHasCaption(blot: Blot): boolean {
    return blot instanceof Figure &&
      blot.children.length === 1 &&
      blot.children.head instanceof FigureCaption;
  }

  private setInvalidClass(): void {
    this.domNode.classList.toggle(Figure.classes.INVALID, !this.isValid());
  }

  private handleClick(): void {
    if (this.isTrackedDeletion()) return;
    if (!this.isValid()) return this.remove();
    this.domNode.classList.add(Figure.classes.SELECTED);
    if (this.quill.getSelection() && !this.selectionIsWithinCaption()) this.focusCaption();
  }

  private selectionIsWithinCaption(): boolean {
    const selection = window.getSelection();
    const caption = this.children.tail.domNode;
    return caption.contains(selection.focusNode) &&
      caption.contains(selection.anchorNode);
  }

  private focusCaption(): void {
    const index = this.quill.getIndex(this) + this.length() - 1;
    this.quill.setSelection(index, 0, Quill.sources.SILENT);
  }

  private isValid(): boolean {
    const image = this.children.head;
    if (!image || !(image instanceof FigureImage)) return false;
    return image.isValid();
  }

  private addComment(): void {
    const quill = findQuill(this.domNode);
    const trackChanges = quill.getModule('track-changes') as TrackChanges;
    const range = {index: quill.getIndex(this), length: 1};
    // Actively blur the editor to avoid inactivating the new comment
    // on selection change
    quill.blur();
    trackChanges.addComment(range);
  }
}
