import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "navigation-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconArrowRight = _resolveComponent("VuiIconArrowRight")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_ctx.hasNextContent)
    ? (_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        to: _ctx.nextContentUrl,
        class: "button content-navigation-button button-lg button-round reedsy-accented bordered"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.buttonTitle), 1),
          _cache[0] || (_cache[0] = _createTextVNode()),
          _createVNode(_component_VuiIconArrowRight, { class: "next-content-icon" })
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}