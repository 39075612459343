import Scroll from '@reedsy/quill/blots/scroll';
import {isObjectId} from '@reedsy/utils.object-id';
import UnformattableEmbed from '@reedsy/studio.shared/services/quill/blots/unformattable-embed';

export default class Endnote extends UnformattableEmbed {
  public static override readonly blotName = 'endnote';
  public static override readonly className = 'ql-endnote';
  public static override readonly tagName = 'SUP';

  public static readonly events = Object.freeze({
    ENDNOTE_CLICKED: 'endnote-clicked',
  });

  public static override value(domNode: HTMLElement): string {
    return domNode.dataset[Endnote.blotName];
  }

  public override scroll: Scroll;

  public constructor(scroll: Scroll, domNode: HTMLElement, value: string) {
    super(scroll, domNode);
    domNode.dataset[Endnote.blotName] = value;
    domNode.addEventListener('click', this.handleClick.bind(this));
  }

  public handleClick(): void {
    this.scroll.emitter.emit(Endnote.events.ENDNOTE_CLICKED, this.value().endnote);
  }

  public override optimize(context: any): void {
    super.optimize(context);
    const node = this.domNode as HTMLElement;
    const endnoteId = node.dataset[Endnote.blotName];
    if (!isObjectId(endnoteId)) this.remove();
  }
}
