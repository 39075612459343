import Delta from '@reedsy/quill-delta';
import Op from '@reedsy/quill-delta/dist/Op';
import {isBlockOrCollapsedBlock} from '@reedsy/studio.shared/services/track-changes/track-changes-utils';

export default class DeltaDocument {
  public readonly lines: IDeltaDocumentLine[];
  private readonly delta: Delta;

  public constructor(delta: Delta) {
    this.delta = new Delta(delta);
    this.lines = this.parseLines();
  }

  public getLineAt(index: number): IDeltaDocumentLine {
    for (let lineIndex = 0; lineIndex < this.lines.length; lineIndex++) {
      const line = this.lines[lineIndex];
      if (index < line.end) return line;
    }
  }

  public slice(start: number, end: number): Delta {
    return this.delta.slice(start, end);
  }

  private parseLines(): IDeltaDocumentLine[] {
    let index = 0;
    const lines: IDeltaDocumentLine[] = [];

    this.deltaLines().forEach((line) => {
      const endIndex = index + this.length(line);
      lines.push({
        start: index,
        end: endIndex,
        ops: line.slice(0, line.length - 1),
        lineOp: line[line.length - 1],
        previous: null,
        next: null,
      });

      index = endIndex;
    });

    for (let i = 0; i < lines.length; i++) {
      lines[i].previous = lines[i - 1];
      lines[i].next = lines[i + 1];
    }

    return lines;
  }

  private length(ops: Op[]): number {
    return ops.reduce((length, op) => length + Op.length(op), 0);
  }

  private deltaLines(): Op[][] {
    const lines: Op[][] = [];

    this.delta.eachLine((line, attributes) => {
      let currentLine: Op[] = [];

      line.ops.forEach((op) => {
        if (!isBlockOrCollapsedBlock(op)) return currentLine.push(op);
        currentLine.push(op);
        lines.push(currentLine);
        currentLine = [];
      });

      const lineOp: Op = {insert: '\n'};
      if (Object.keys(attributes).length) lineOp.attributes = attributes;
      currentLine.push(lineOp);
      lines.push(currentLine);
    });

    return lines;
  }
}

export interface IDeltaDocumentLine {
  start: number;
  end: number;
  ops: Op[];
  lineOp: Op;
  previous: IDeltaDocumentLine;
  next: IDeltaDocumentLine;
}
