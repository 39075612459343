import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "trial-price-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconTick = _resolveComponent("VuiIconTick")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isProduct ? 'button' : 'div'), {
    class: _normalizeClass(["feature-summary reedsy-accented", {
      product: _ctx.isProduct,
      'vui-tab-option': _ctx.isProduct, // Reuse some styling but without the full component
      active: _ctx.isProduct && _ctx.selected,
    }]),
    onClick: _ctx.toggleSelected
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", null, _toDisplayString(_ctx.summary.title), 1),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.features, (feature) => {
          return (_openBlock(), _createElementBlock("li", { key: feature }, _toDisplayString(feature), 1))
        }), 128))
      ]),
      _cache[1] || (_cache[1] = _createTextVNode()),
      (_ctx.showTrialPrice)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "\n      Free for " + _toDisplayString(_ctx.config.stripe.trialDays) + " days, then TODO: [subscriptions] price\n    ", 1))
        : (_ctx.isProduct)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: _normalizeClass(["button button-full reedsy-accented", {
        bordered: !_ctx.selected,
      }])
            }, _toDisplayString(_ctx.selected ? 'Add-on selected' : 'Select add-on'), 3))
          : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createTextVNode()),
      (_ctx.isProduct)
        ? (_openBlock(), _createBlock(_component_VuiIconTick, {
            key: 2,
            class: "icon-tick reedsy-accented plain"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "onClick"]))
}