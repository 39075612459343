
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import {BookViewerTableOfContentsModule} from '@reedsy/studio.viewer/store/modules/table-of-contents/table-of-contents';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@Component({})
export default class ContentNavigationButton extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerTableOfContents)
  public $tableOfContents: BookViewerTableOfContentsModule;

  @$lazyInjectStore(SharedStoreName.Browser)
  public $browser: SharedBrowserModule;

  public get contentId(): string {
    return this.$displayedData.displayedContentId;
  }

  public get hasNextContent(): boolean {
    return this.$tableOfContents.hasContentAfter(this.contentId);
  }

  public get nextContent(): IContentFragment {
    return this.$tableOfContents.nextContentFragment(this.contentId);
  }

  public get nextContentUrl(): string {
    return this.$tableOfContents.matterUrl(
      this.nextContent.id,
    );
  }

  public get buttonTitle(): string {
    return this.$browser.screen.greaterThanOrEqual.md ?
      'Next chapter' :
      'Next';
  }
}
