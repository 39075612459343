import Blot from '@reedsy/studio.shared/services/quill/blots/blot';
import Scroll from '@reedsy/quill/blots/scroll';
import BlotPlaceholder from './blot-placeholder';
import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';

export default class DraggedBlot {
  public static readonly classes = Object.freeze({
    DRAGGING: 'dragging',
  });

  private readonly quill: Quill;
  private readonly blot: Blot | null;
  private readonly scroll: Scroll;

  private placeholder: BlotPlaceholder;

  public constructor(quill: Quill, blot: Blot | null) {
    this.quill = quill;
    this.scroll = quill.scroll;
    this.blot = blot;
  }

  public startDrag(): void {
    this.scroll.enable(false);
    this.setDraggingClass(true);
    const blotType = this.blot?.statics.blotName;
    this.placeholder = new BlotPlaceholder(this.quill, blotType);
  }

  public range(): Range {
    return this.placeholder.range();
  }

  public isAlreadyInDocument(): boolean {
    return !!this.blot;
  }

  public repositionPlaceholder(event: DragEvent): void {
    this.placeholder.moveBefore(event);
    this.placeholder.element.style.display = this.placeholderIsAdjacentToDraggedBlot() ? 'none' : 'flex';
  }

  public abortDrag(): void {
    this.scroll.enable(true);
    this.setDraggingClass(false);
    if (this.placeholder) this.placeholder.remove();
  }

  public replacePlaceholderWithBlot(): void {
    this.setDraggingClass(false);
    if (this.blot && this.placeholder.attached()) {
      this.scroll.domNode.insertBefore(this.blot.domNode, this.placeholder.element);
    }
    this.placeholder.remove();
    this.placeholder = null;
  }

  private setDraggingClass(isDragging: boolean): void {
    if (!this.blot) return;
    const domNode = this.blot.domNode as HTMLElement;
    domNode.classList.toggle(DraggedBlot.classes.DRAGGING, isDragging);
  }

  private placeholderIsAdjacentToDraggedBlot(): boolean {
    return this.blot && this.placeholder &&
      (this.blot.domNode.nextSibling === this.placeholder.element ||
      this.blot.domNode.previousSibling === this.placeholder.element);
  }
}
