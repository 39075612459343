import {BookOwnerModuleFactory} from './modules/book-owner';
import {BookViewerBookContentModuleFactory} from './modules/book-content/book-viewer-book-content';
import {BookViewerSettingsModuleFactory} from './modules/book-viewer-settings/book-viewer-settings';
import {BookViewerDisplayedDataModuleFactory} from './modules/displayed-data/book-viewer-displayed-data';
import {BookViewerLayoutModuleFactory} from './modules/layout/book-viewer-layout';
import {BookViewerModalsModuleFactory} from './modules/modals';
import {BookViewerReadingLocationModuleFactory} from './modules/reading-location/reading-location';
import {BookViewerRichTextFactory} from './modules/rich-text/book-viewer-rich-text';
import {BookViewerShareableUrlDetailsModuleFactory} from './modules/shareable-url-details/shareable-url-details';
import {BookViewerTableOfContentsModuleFactory} from './modules/table-of-contents/table-of-contents';
import StoreName from './store-name';
import {SHARED_STORE_FACTORY_MAPPING} from '@reedsy/studio.shared/store/store-mapping';
import {Constructor} from '@reedsy/utils.types';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';

export const BOOK_VIEWER_STORE_FACTORY_MAPPING = {
  ...SHARED_STORE_FACTORY_MAPPING,
  [StoreName.BookViewerSettings]: BookViewerSettingsModuleFactory,
  [StoreName.BookViewerLayout]: BookViewerLayoutModuleFactory,
  [StoreName.BookViewerModals]: BookViewerModalsModuleFactory,
  [StoreName.BookViewerSharableUrlDetails]: BookViewerShareableUrlDetailsModuleFactory,
  [StoreName.BookViewerBookContent]: BookViewerBookContentModuleFactory,
  [StoreName.BookViewerTableOfContents]: BookViewerTableOfContentsModuleFactory,
  [StoreName.BookViewerRichText]: BookViewerRichTextFactory,
  [StoreName.BookViewerBookOwner]: BookOwnerModuleFactory,
  [StoreName.BookViewerDisplayedData]: BookViewerDisplayedDataModuleFactory,
  [StoreName.BookViewerReadingLocation]: BookViewerReadingLocationModuleFactory,
} as const satisfies Record<string, Constructor<IModuleFactory>>;

type BookViewerStoreFactoryMapping = typeof BOOK_VIEWER_STORE_FACTORY_MAPPING;

export type IBookViewerStoreFactoryMapping = {
  [K in keyof BookViewerStoreFactoryMapping]: InstanceType<InstanceType<BookViewerStoreFactoryMapping[K]>['Module']>;
};

type IBookViewerStoreMapping = IBookViewerStoreFactoryMapping;

export default IBookViewerStoreMapping;
