import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseLeftSidebarButton = _resolveComponent("CloseLeftSidebarButton")!
  const _component_TableOfContents = _resolveComponent("TableOfContents")!
  const _component_LeftSideBarBase = _resolveComponent("LeftSideBarBase")!

  return (_openBlock(), _createBlock(_component_LeftSideBarBase, {
    open: _ctx.leftSidebarIsActive,
    title: "Contents",
    "with-overlay": "",
    class: "book-viewer-left-sidebar",
    onHide: _ctx.toggleLeftSidebar
  }, {
    "header-after-content": _withCtx(() => [
      _createVNode(_component_CloseLeftSidebarButton)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_TableOfContents)
    ]),
    _: 1
  }, 8, ["open", "onHide"]))
}