import SceneBreakPlaceholder from '@reedsy/studio.shared/services/quill/modules/drag-and-drop/placeholders/scene-break-placeholder';
import Quill from '@reedsy/quill';
import Module from '@reedsy/quill/core/module';
import DragAndDrop from '@reedsy/studio.shared/services/quill/modules/drag-and-drop';
import SceneBreak from '@reedsy/studio.shared/services/quill/blots/scene-break';
import {Registry} from 'parchment';

export default class SceneBreaks extends Module {
  public static registerFormats(registry: Registry): void {
    registry.register(SceneBreak);
  }

  public constructor(quill: Quill, options: any) {
    super(quill, options);
    SceneBreaks.registerFormats(quill.options.registry);

    DragAndDrop.registerModule(quill, {
      placeholderTemplates: {
        [SceneBreak.blotName]: SceneBreakPlaceholder,
      },
    });
  }
}
