import {Range} from '@reedsy/quill/core/selection';
import {deepEqual} from '@reedsy/utils.deep-equal';

export default function splitOverlappingRanges(ranges: Range[]): Range[] {
  const highlightsAtIndex: Record<number, Set<number>> = {};
  for (let rangeIndex = 0; rangeIndex < ranges.length; rangeIndex++) {
    const range = ranges[rangeIndex];
    for (let i = 0; i < range.length; i++) {
      const index = range.index + i;
      highlightsAtIndex[index] ||= new Set();
      highlightsAtIndex[index].add(rangeIndex);
    }
  }

  const splitRanges: Range[] = [];

  let currentRange: Range;
  let previousHighlight: {index: number; ranges: Set<number>};
  Object.keys(highlightsAtIndex).forEach((key) => {
    const i = parseInt(key);
    if (previousHighlight && !deepEqual(highlightsAtIndex[i], previousHighlight.ranges)) {
      splitRanges.push(currentRange);
      currentRange = null;
    }
    if (!currentRange) {
      currentRange = {index: i, length: 0};
    }
    currentRange.length += 1;
    previousHighlight = {index: i, ranges: highlightsAtIndex[i]};
  });

  if (currentRange) {
    splitRanges.push(currentRange);
  }

  return splitRanges;
}
