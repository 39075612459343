import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable, named} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import {IRichText} from '@reedsy/reedsy-sharedb/lib/common/rich-text/rich-text';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {BookViewerShareableUrlDetailsModule} from '@reedsy/studio.viewer/store/modules/shareable-url-details/shareable-url-details';
import {opsToString} from '@reedsy/studio.isomorphic/utils/rich-text/ops-to-string';
import {IEndnoteInfo} from '@reedsy/studio.isomorphic/controllers/api/reader/book/i-get-endnotes-response';
import {BookViewerTableOfContentsModule} from '@reedsy/studio.viewer/store/modules/table-of-contents/table-of-contents';

interface IAddRichText {
  richTextId: string;
  richText: IRichText;
}

interface IAddLoadingContentPromise {
  contentId: string;
  loadingPromise: Promise<void>;
}

interface IAddLoadingEndnotesPromise {
  richTextId: string;
  loadingPromise: Promise<void>;
}

interface ILoadRichTextRequest {
  contentId: string;
  richTextId: string;
  loadEndnotes: boolean;
}

@injectable()
export class BookViewerRichTextFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.BookViewerSharableUrlDetails)
    SharableUrlDetails: BookViewerShareableUrlDetailsModule,

    @$inject('StoreModule')
    @named(StoreName.BookViewerTableOfContents)
    TableOfContents: BookViewerTableOfContentsModule,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: StoreName.BookViewerRichText, store})
    class BookViewerRichText extends VuexModule {
      public richTexts: {[richTextId: string]: IRichText} = {};
      public endnotes: {[endnoteId: string]: IEndnoteInfo} = {};
      public endnotesNumber: {[endnoteId: string]: number} = {};

      private loadingContentPromise: {[contentId: string]: Promise<void>} = {};
      private loadingEndnotesPromise: {[richTextId: string]: Promise<void>} = {};

      public get isEmpty() {
        return (richTextId: string) => {
          const richText = this.richTexts[richTextId];
          if (!richText) return true;

          const plainText = opsToString(richText.ops).trim();

          return !plainText;
        };
      }

      public get isEndnoteLoading() {
        return (endnoteId: string) => {
          return !(endnoteId in this.endnotes);
        };
      }

      @Action
      public async loadRichText({contentId, richTextId, loadEndnotes}: ILoadRichTextRequest): Promise<IRichText> {
        // This shouldn't be awaited as the main point is to load
        // the content rich text to be displayed for user as fast as possible
        if (loadEndnotes) {
          this.loadEndnotes({contentId, richTextId});
        }

        if (!(contentId in this.loadingContentPromise)) {
          await this.loadContentReferences(contentId);
        } else {
          await this.loadingContentPromise[contentId];
        }

        return this.richTexts[richTextId];
      }

      @Action
      public async loadContentReferences(contentId: string): Promise<void> {
        if (!(contentId in this.loadingContentPromise)) {
          this.ADD_LOADING_CONTENT_PROMISE({
            contentId,
            loadingPromise: this._loadContentReferences(contentId),
          });
        }

        await this.loadingContentPromise[contentId];
      }

      @Action
      public async waitForContentToLoad(contentId: string): Promise<void> {
        if (!(contentId in this.loadingContentPromise)) {
          throw new Error('The content is not loading');
        }

        await this.loadingContentPromise[contentId];
      }

      @Action
      private async _loadContentReferences(contentId: string): Promise<void> {
        const response = await api.getReaderRichTextReferences(
          SharableUrlDetails.shortId,
          contentId,
        );

        Object.keys(response.richTexts).forEach((richTextId) => {
          const richText = response.richTexts[richTextId];
          this.ADD_RICH_TEXT({
            richTextId,
            richText,
          });
        });
      }

      @Action
      private async loadEndnotes({contentId, richTextId}: {contentId: string; richTextId: string}): Promise<void> {
        if (!(richTextId in this.loadingEndnotesPromise)) {
          this.ADD_LOADING_ENDNOTE_PROMISE({
            richTextId,
            loadingPromise: this._loadEndnotes({contentId, richTextId}),
          });
        }

        await this.loadingContentPromise[contentId];
      }

      @Action
      private async _loadEndnotes({contentId, richTextId}: {contentId: string; richTextId: string}): Promise<void> {
        const response = await api.getReaderRichTextEndnotes(
          SharableUrlDetails.shortId,
          contentId,
          richTextId,
        );

        response.endnotes.forEach((endnoteInfo) => this.ADD_ENDNOTE(endnoteInfo));
      }

      @Mutation
      private ADD_RICH_TEXT({richTextId, richText}: IAddRichText): void {
        this.richTexts[richTextId] = richText;
      }

      @Mutation
      private ADD_ENDNOTE(endnoteInfo: IEndnoteInfo): void {
        this.endnotes[endnoteInfo.id] = endnoteInfo;
        this.richTexts[endnoteInfo.id] = endnoteInfo.richText;
        this.endnotesNumber[endnoteInfo.id] = endnoteInfo.endnoteNumber;
      }

      @Mutation
      private ADD_LOADING_CONTENT_PROMISE({contentId, loadingPromise}: IAddLoadingContentPromise): void {
        this.loadingContentPromise[contentId] = loadingPromise;
      }

      @Mutation
      private ADD_LOADING_ENDNOTE_PROMISE({richTextId, loadingPromise}: IAddLoadingEndnotesPromise): void {
        this.loadingEndnotesPromise[richTextId] = loadingPromise;
      }
    }
    this.Module = BookViewerRichText;
  }
}

export type BookViewerRichTextModule = InstanceType<BookViewerRichTextFactory['Module']>;
