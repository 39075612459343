import {Registry} from 'parchment';
import Quill from '@reedsy/quill';
import {Range} from '@reedsy/quill/core/selection';
import Figure, {FigureImage, FigureCaption} from '@reedsy/studio.shared/services/quill/blots/figure';
import EventListeners from '@reedsy/studio.shared/services/quill/helpers/event-listeners';
import ReedsyClipboard from './clipboard';
import IFigure from './uploader/i-figure';
import Module from '@reedsy/quill/core/module';
import DragAndDrop from './drag-and-drop';
import ReedsyUploader from './uploader';

export default class Figures extends Module {
  public static registerFormats(registry: Registry): void {
    [Figure, FigureImage, FigureCaption].forEach((format) => registry.register(format));
  }

  public constructor(quill: Quill, options?: any) {
    super(quill, options);
    Figures.registerFormats(quill.options.registry);

    const uploader = this.quill.getModule('uploader') as ReedsyUploader;
    uploader.options.enabled = true;
    DragAndDrop.registerModule(quill);

    this.quill.on(Quill.events.SELECTION_CHANGE, this.updateFigureSelections.bind(this));

    new EventListeners(quill, {
      [ReedsyClipboard.events.AFTER_PASTE]: this.uploadPastedImages,
    }).bindListeners(this).useOnOff().startListening();
  }

  private updateFigureSelections(range: Range): void {
    const leaf = range && this.quill.getLeaf(range.index)[0];
    const figures = Array.from(document.getElementsByClassName(Figure.className));
    figures.forEach((figure) => {
      const isSelected = leaf && figure.contains(leaf.domNode);
      figure.classList.toggle(Figure.classes.SELECTED, isSelected);
    });
  }

  private uploadPastedImages(): void {
    const selector = `.${Figure.className}.${Figure.classes.INVALID} ${FigureImage.tagName}`;
    Array.from(this.quill.root.querySelectorAll(selector))
      .map((domNode) => this.quill.options.registry.find(domNode) as FigureImage)
      .forEach((image) => this.uploadPastedImage(image));
  }

  private uploadPastedImage(blot: FigureImage): void {
    const range = {index: this.quill.getIndex(blot), length: 0};
    const upload: IFigure = {
      url: (blot.domNode as HTMLImageElement).src,
    };

    if (blot.next instanceof FigureCaption) {
      const caption = blot.next;
      const index = this.quill.getIndex(caption);
      const length = caption.length();
      upload.caption = this.quill.getContents(index, length);
    }

    const uploader = this.quill.getModule('uploader') as ReedsyUploader;
    uploader.upload(range, [upload]);
    // Remove the original <figure> with the external URL. If we
    // do this too early, the mutation is swallowed by the placeholder's
    // takeRecords() call
    setTimeout(() => blot.parent.remove());
  }
}
