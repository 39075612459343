import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section-title" }
const _hoisted_2 = {
  key: 0,
  class: "order-number-wrapper"
}
const _hoisted_3 = { class: "chapter-number order-number" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "chapter-separator-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.shouldDisplayOrderNumber)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, "\n         " + _toDisplayString(_ctx.orderLiteral) + " \n      ", 1)
        ]))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("p", {
      class: _normalizeClass(["title-text", {
        'hidden-chapter-title': !_ctx.shouldShowTitle
      }])
    }, [
      (!_ctx.shouldShowTitle)
        ? (_openBlock(), _createElementBlock("br", _hoisted_4))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
    ], 2),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (_ctx.shouldShowSeparator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.separatorComponent), { class: "chapter-separator" }))
        ]))
      : _createCommentVNode("", true)
  ]))
}