import AttributeMap from '@reedsy/quill-delta/dist/AttributeMap';
import TrackAttributor from './track-attributor';
import {dig} from '@reedsy/utils.dig';
import {ITrackedReformat, ITrackedReformats} from '@reedsy/studio.shared/services/track-changes/i-tracked-reformats';
import {isEmpty} from '@reedsy/utils.object';

const PREVIOUS_ATTRIBUTE = 'track-reformat-previous';
const FORMATS_ATTRIBUTE = 'track-reformat-formats';

export default class TrackReformatAttributor extends TrackAttributor<ITrackedReformat> {
  public value(node: HTMLElement): any {
    const changes = this.getUserChangeIds(node, this.keyName);
    if (!changes) return null;
    const previous = this.getAttributeAsObject(node, PREVIOUS_ATTRIBUTE);
    const formats = this.getAttributeAsObject(node, FORMATS_ATTRIBUTE);

    const trackedReformats = changes.reduce((reformats, change) => {
      reformats[change.changeId] = {...change};
      if (!isEmpty(previous || {})) reformats[change.changeId].previous = previous;
      if (!isEmpty(formats[change.changeId] || {})) reformats[change.changeId].formats = formats[change.changeId];
      if (this.isNoOp(reformats[change.changeId])) delete reformats[change.changeId];
      return reformats;
    }, {} as ITrackedReformats);

    return Object.keys(trackedReformats).length ? trackedReformats : null;
  }

  protected override setChangeMeta(node: HTMLElement, values: ITrackedReformat[]): void {
    this.setFormats(node, values);
    this.setPrevious(node, values);
  }

  private setFormats(node: HTMLElement, values: ITrackedReformat[]): void {
    const formatsById: {[changeId: string]: AttributeMap} = {};
    values.forEach((change) => formatsById[change.changeId] = change.formats);
    const value = Object.keys(formatsById).length ? formatsById : null;
    this.setAttribute(node, FORMATS_ATTRIBUTE, value);
  }

  private setPrevious(node: HTMLElement, values: ITrackedReformat[]): void {
    if (values.length && node.hasAttribute(PREVIOUS_ATTRIBUTE)) return;
    const previous = dig(values, 0, 'previous');
    this.setAttribute(node, PREVIOUS_ATTRIBUTE, previous);
  }

  private isNoOp(reformat: ITrackedReformat): boolean {
    return !reformat.formats && !reformat.previous;
  }
}
