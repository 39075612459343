import {StyleAttributor, Scope} from 'parchment';

class UnderlineStyleAttributor extends StyleAttributor {
  public override value(node: HTMLElement): any {
    const value = super.value(node);
    return value.includes('underline');
  }
}

const underlineStyleAttributor = new UnderlineStyleAttributor('underline', 'text-decoration', {
  scope: Scope.INLINE_ATTRIBUTE,
});

export default underlineStyleAttributor;
