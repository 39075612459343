// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/404.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-view[data-v-37b41d0c]{-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) bottom center no-repeat;background-size:160% auto;display:-webkit-box;display:-ms-flexbox;display:flex;height:calc(var(--one-vh, 1vh)*100);padding:0 2rem}@media(min-width: 1200px){.not-found-view[data-v-37b41d0c]{background-size:100% auto;max-width:75rem;margin:0 auto}}@media(min-width: 1200px){.not-found-view .vui-wrapper-sm[data-v-37b41d0c]{margin-bottom:3rem}}.not-found-view .four-oh-four[data-v-37b41d0c]{color:var(--reedsy-form-info-textColor);margin-bottom:1rem;font-weight:400;font-size:4rem}`, "",{"version":3,"sources":["webpack://./src/client/shared/components/not-found/not-found-partial.vue","webpack://./node_modules/@reedsy/vui/dist/sass/_mixins/_breakpoints.scss","webpack://./src/client/shared/style/variables/sizes.scss"],"names":[],"mappings":"AAeA,iCACE,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,0EAAA,CACA,yBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,mCAAA,CACA,cAAA,CCqCA,0BD3CF,iCASI,yBAAA,CACA,eEmBY,CFlBZ,aAAA,CAAA,CCgCF,0BD7BA,iDAEI,kBAAA,CAAA,CAIJ,+CACE,uCAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
