import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import IconEllipsis from './ellipsis';

export default class SpellingTooltip extends JsxComponent {
  public render(): HTMLElement {
    return (
      <div>
        <span class="ql-tooltip-arrow"/>
        <button class="button button-md reedsy-accented plain suggestion-button" />
        <button
          class="button button-round button-xs options-button reedsy-accented neutral"
          title="Open Spellcheck panel"
        >
          <IconEllipsis />
        </button>
      </div>
    );
  }
}
