import Quill from '@reedsy/quill';
import Module from '@reedsy/quill/core/module';
import Highlighter, {ACTIVE_CLASS} from '@reedsy/studio.shared/services/quill/helpers/highlighter/highlighter';

const HIGHLIGHT_CLASS = 'ql-search';

export default class Search extends Module {
  public static readonly CLASSES = {
    HIGHLIGHT: HIGHLIGHT_CLASS,
    ACTIVE: ACTIVE_CLASS,
  };

  public highlighter: Highlighter;

  public constructor(quill: Quill, options?: any) {
    super(quill, options);
    this.highlighter = new Highlighter(quill, HIGHLIGHT_CLASS);
  }
}
