import EventListeners from '@reedsy/studio.shared/services/quill/helpers/event-listeners';
import {getDraggedElementId, ATTRIBUTE_DRAG_ID} from '@reedsy/studio.shared/services/quill/helpers/drag-and-drop';
import DraggedBlot from './dragged-blot';
import Quill from '@reedsy/quill';
import {dig} from '@reedsy/utils.dig';
import IFigure from '@reedsy/studio.shared/services/quill/modules/uploader/i-figure';
import IDragAndDropOptions from './i-drag-and-drop-options';
import Module from '@reedsy/quill/core/module';
import {Browser} from '@reedsy/studio.shared/utils/browser';
import ReedsyUploader from '@reedsy/studio.shared/services/quill/modules/uploader';

export default class DragAndDrop extends Module {
  public static registerModule(quill: Quill, options: IDragAndDropOptions = {}): void {
    const registeredModule = quill.getModule('drag-and-drop') as DragAndDrop;
    if (registeredModule) {
      registeredModule.options.placeholderTemplates = {
        ...registeredModule.options.placeholderTemplates,
        ...options.placeholderTemplates,
      };
      return;
    }
    (quill.options.modules as any)['drag-and-drop'] = options;
    quill.theme.addModule('drag-and-drop');
  }

  public override options: IDragAndDropOptions;

  private draggedBlot: DraggedBlot = null;

  private dragEnterCount = 0;

  public constructor(quill: Quill, options?: IDragAndDropOptions) {
    super(quill, options);

    new EventListeners(this.quill.root, {
      dragenter: this.handleDragEnter,
      dragstart: this.startDrag,
      dragover: this.handleDragOver,
      drop: this.handleDrop,
      dragend: this.handleDragEnd,
      dragleave: this.checkDragOutsideEditor,
    }).bindListeners(this).startListening();
  }

  private handleDragEnter(event: DragEvent): void {
    this.dragEnterCount++;
    this.startDrag(event);
  }

  private startDrag(event: DragEvent): void {
    if (this.draggedBlot || !this.quill.isEnabled()) return;
    this.collapseSelection();
    const draggedElementId = getDraggedElementId(event);

    const element = this.quill.root.querySelector(
      `[${ATTRIBUTE_DRAG_ID}="${draggedElementId}"]`,
    );
    const blot = this.quill.options.registry.find(element);
    this.draggedBlot = new DraggedBlot(this.quill, blot);
    this.draggedBlot.startDrag();
  }

  private handleDragEnd(): void {
    this.dragEnterCount = 0;
    if (this.draggedBlot) {
      this.draggedBlot.abortDrag();
      this.draggedBlot = null;
    }
  }

  private handleDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.draggedBlot) {
      this.draggedBlot.repositionPlaceholder(event);
    }
  }

  private handleDrop(event: DragEvent): void {
    event.preventDefault();
    if (!this.draggedBlot.isAlreadyInDocument()) {
      const filesOrUrls = this.hasFiles(event) ? this.files(event) : this.imageUrls(event);
      const range = this.draggedBlot.range();
      if (filesOrUrls.length) {
        const uploader = this.quill.getModule('uploader') as ReedsyUploader;
        uploader.upload(range, filesOrUrls);
      }
    }
    this.draggedBlot.replacePlaceholderWithBlot();
    this.handleDragEnd();
  }

  private checkDragOutsideEditor(event: DragEvent): void {
    this.dragEnterCount--;

    // Due to a bug Safari always returns null for event.relatedTarget https://bugs.webkit.org/show_bug.cgi?id=66547
    // Universal solution does not work in Firefox as changing innerHTML keeps triggering dragenter
    if (Browser.isDesktopSafari()) {
      if (this.dragEnterCount === 0) {
        this.handleDragEnd();
      }
    } else if (!this.quill.root.contains(event.relatedTarget as Node)) {
      this.handleDragEnd();
    }
  }

  private hasFiles(event: DragEvent): boolean {
    return !!dig(event, 'dataTransfer', 'files', 'length');
  }

  private files(event: DragEvent): File[] {
    return Array.from(event.dataTransfer.files);
  }

  private imageUrls(event: DragEvent): IFigure[] {
    const html = event.dataTransfer.getData('text/html') || '';
    const div = document.createElement('div');
    div.innerHTML = html;
    const images = div.querySelectorAll('img');
    return Array.from(images).map((image) => ({url: image.src}));
  }

  // Prevents the dragged blot from being highlighted by Quill's selection
  private collapseSelection(): void {
    const selection = this.quill.getSelection();
    if (selection && selection.length) this.quill.setSelection(selection.index, Quill.sources.SILENT);
  }
}
