import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {interfaces} from 'inversify';
import {$getDecorators} from '@reedsy/utils.inversify';
import {BookViewerLocalStorageHelper} from './utils/book-viewer-local-storage-helper';
import {ReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import StoreWrapper from '@reedsy/studio.shared/store/store-wrapper';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {VuexModule} from '@reedsy/vuex-module-decorators';
import {Store} from 'vuex';
import IModal from '@reedsy/studio.shared/store/modules/modals/i-modal';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

export type BookViewerBindings = {
  Api: IApi;
  BookViewerStorePlugins: IStorePluginFactory;
  LocalStorageHelper: BookViewerLocalStorageHelper;
  LoggerFactory: LoggerFactory;
  ModalsData: Record<string, IModal>;
  Router: ReedsyRouter;
  Store: Store<any>;
  StoreWrapper: StoreWrapper;
  StoreModule: VuexModule;
  StoreModuleFactory: IModuleFactory;
  Navigation: INavigation;
};

export type BookViewerBinding = keyof BookViewerBindings;

export function getBinding<K extends BookViewerBinding, T extends BookViewerBindings[K]>(
  serviceIdentifier: K,
  container: interfaces.Container,
): T {
  return container.get(serviceIdentifier);
}

export const {$inject} = $getDecorators<BookViewerBindings>();
