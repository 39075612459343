import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';
import {injectable} from 'inversify';
import {IBookViewerRoute} from '@reedsy/studio.viewer/router/i-book-viewer-route';
import {BookViewerRouteName} from '@reedsy/studio.shared/router/route-names/book-viewer-route-name';

// TODO: Extract this router to shared
@injectable()
export class BookViewerRouterPlugin extends RouterPlugin<IBookViewerRoute> {
  protected override shouldApplyBefore(to: IBookViewerRoute, _from: IBookViewerRoute): boolean {
    return to.name !== BookViewerRouteName.NotFound;
  }

  protected override shouldApplyAfter(_to: IBookViewerRoute, _from: IBookViewerRoute): boolean {
    return true;
  }
}
