
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import {BookViewerLayoutModule} from '@reedsy/studio.viewer/store/modules/layout/book-viewer-layout';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class CloseLeftSidebarButton extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerLayout)
  public $layout: BookViewerLayoutModule;

  public closeLeftSidebar(): void {
    this.$layout.closeLeftSidebar();
  }
}
