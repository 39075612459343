import {VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {ITableOfContents, ITableOfContentsEntry} from './i-table-of-contents';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {bookContentStructure, TopMatterType} from '@reedsy/reedsy-sharedb/lib/server/book-contents-structure';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {Matter} from '@reedsy/reedsy-sharedb/lib/utils/book-content/matter.interface';
import {buildFragmentUrl} from '@reedsy/studio.viewer/utils/build-fragment-url';
import {topMatterTitles} from '@reedsy/studio.isomorphic/utils/book-content/top-matter-titles';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable, named} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {BookViewerShareableUrlDetailsModule} from '@reedsy/studio.viewer/store/modules/shareable-url-details/shareable-url-details';
import {OmittedMatterTypesInBookViewer} from '@reedsy/studio.isomorphic/utils/book-content/omitted-matter-types-in-book-viewer';
import {getContentFragmentAfter} from './get-content-fragment-after';
import {getContentFragmentBefore} from './get-content-fragment-before';

@injectable()
export class BookViewerTableOfContentsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.BookViewerBookContent)
    BookContent: BookViewerBookContentModule,

    @$inject('StoreModule')
    @named(StoreName.BookViewerSharableUrlDetails)
    ShareableUrlDetails: BookViewerShareableUrlDetailsModule,
  ) {
    @Module({name: StoreName.BookViewerTableOfContents, store})
    class BookViewerTableOfContents extends VuexModule {
      public get tableOfContents(): ITableOfContents {
        const entries: ITableOfContentsEntry[] = [
          this.topMatter(MatterType.FrontMatter),
          this.topMatter(MatterType.Body),
          this.topMatter(MatterType.BackMatter),
        ].filter((topEntry) => topEntry.children.length);

        return {
          entries,
        };
      }

      public get matterUrl() {
        return (fragmentId: string, hash?: string): string => {
          const fragment = BookContent.contentInfo(fragmentId);
          return buildFragmentUrl({
            content: fragment,
            sharableUrlShortId: ShareableUrlDetails.shortId,
            title: BookContent.title(fragment.id),
            hash,
          });
        };
      }

      public get nextContentFragment() {
        return (currentContentId: string): ContentFragment => getContentFragmentAfter(
          BookContent.flat,
          currentContentId,
        );
      }

      public get hasContentAfter() {
        return (currentContentId: string): boolean => {
          return !!this.nextContentFragment(currentContentId);
        };
      }

      public get previousContentFragment() {
        return (currentContentId: string): ContentFragment => getContentFragmentBefore(
          BookContent.flat,
          currentContentId,
        );
      }

      public get hasContentBefore() {
        return (currentContentId: string): boolean => {
          return !!this.previousContentFragment(currentContentId);
        };
      }

      private get doesMatterTypeExistsInBookContent() {
        return (matterType: MatterType): boolean => {
          return !!BookContent.flat
            .find((fragment) => fragment.data.type === matterType);
        };
      }

      private get matterChildren() {
        return (matter: Matter): ITableOfContentsEntry[] => {
          const children: ITableOfContentsEntry[] = [];

          if (!matter.contents) return children;

          for (const currentMatter of matter.contents) {
            children.push(this.entryMatter(currentMatter));
          }
          return children;
        };
      }

      private get topMatter() {
        return (topMatterType: TopMatterType): ITableOfContentsEntry => {
          return {
            matter: null,
            title: topMatterTitles[topMatterType],
            id: topMatterType,
            children: this.topMatterEntries(topMatterType),
            orderNumber: null,
          };
        };
      }

      private get entryMatter() {
        return (matter: Matter): ITableOfContentsEntry => {
          return {
            matter,
            title: BookContent.title(matter.id),
            id: matter.id,
            children: this.matterChildren(matter),
            url: this.matterUrl(matter.id),
            orderNumber: BookContent.orderNumbers[matter.id],
          };
        };
      }

      private get topMatterEntries() {
        return (topMatterType: TopMatterType): ITableOfContentsEntry[] => {
          if (!BookContent.data) return [];
          const bookContents = BookContent.data.contents;
          const topMatterContents: Matter[] = bookContents[topMatterType]?.contents || [];
          const entries: ITableOfContentsEntry[] = [];

          bookContentStructure.order[topMatterType]
            .filter(this.doesMatterTypeExistsInBookContent)
            .filter((matterType) => !OmittedMatterTypesInBookViewer[matterType])
            .forEach((matterType) => {
              const matter: Matter = (bookContents as any)[matterType];

              if (matterType === topMatterType) {
                topMatterContents.forEach((topMatter) => {
                  entries.push(this.entryMatter(topMatter));
                });
              } else {
                entries.push(this.entryMatter(matter));
              }
            });

          return entries;
        };
      }
    }

    this.Module = BookViewerTableOfContents;
  }
}

export type BookViewerTableOfContentsModule = InstanceType<BookViewerTableOfContentsModuleFactory['Module']>;
