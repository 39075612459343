<template>
  <div class="page-container theme-body">
    <TitlePageHeader
      v-if="isFirstPage"
      class="title-page-header"
    />
    <div class="page-container-content">
      <slot />
    </div>
    <div class="navigation-buttons">
      <ContentNavigationButton />
    </div>
  </div>
</template>

<script lang="ts">
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import ContentNavigationButton from '@reedsy/studio.viewer/components/content-navigation-button/content-navigation-button.vue';
import TitlePageHeader from '@reedsy/studio.viewer/components/title-page-header/title-page-header.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';

@Component({
  components: {
    ContentNavigationButton,
    TitlePageHeader,
  },
})
export default class PageContainer extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  public get isFirstPage(): boolean {
    return this.$displayedData.isFirstPage;
  }
}
</script>

<style lang="scss">
.page-container {
  .rich-text-editor {
    // TODO - not sure where this inherits from
    // seems to be using default ql-editor class
    // from default quill lib
    .ql-editor {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  padding: $editor-space-top - $book-viewer-top-bar-height
    var(--main-view-horizontal-margin)
    $view-space-bottom
    var(--main-view-horizontal-margin);

  @include screen-more-than(xs) {
    max-width: $view-max-width;
  }

  .title-page-header {
    margin-top: $editor-row-gap;
  }

  .page-container-content {
    min-height: safe-vh(40);
  }

  .navigation-buttons {
    width: 100%;
    margin-top: $editor-row-gap;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
