import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rich-text-reader editor-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultEmptyReaderPlaceholder = _resolveComponent("DefaultEmptyReaderPlaceholder")!
  const _component_RichTextReaderImpl = _resolveComponent("RichTextReaderImpl")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_RichTextReaderImpl, _mergeProps({
      key: _ctx.richTextId,
      ref: "editor",
      class: "rich-text-editor"
    }, _ctx.$props), {
      "empty-placeholder": _withCtx(() => [
        _renderSlot(_ctx.$slots, "empty-placeholder", {}, () => [
          _createVNode(_component_DefaultEmptyReaderPlaceholder, { "empty-text": _ctx.emptyDefaultPlaceholderText }, null, 8, ["empty-text"])
        ])
      ]),
      loader: _withCtx(() => [
        _renderSlot(_ctx.$slots, "loader")
      ]),
      _: 3
    }, 16))
  ]))
}