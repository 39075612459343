import Styles from '.';

export default class TrackChangesStyles {
  private readonly styles = new Styles();
  private readonly userColors: {[userId: string]: string} = {};

  public addUser(userId: string, color: string): void {
    if (this.userColors[userId]) return;
    this.userColors[userId] = color;
    this.addColorRules(userId, color);
  }

  public remove(): void {
    this.styles.remove();
  }

  private addColorRules(userId: string, color: string): void {
    const delimitedUser = `:${userId}:`;
    this.styles.addStyle([
      `[track-insertion*="${delimitedUser}"]`,
      `[track-reformat*="${delimitedUser}"]`,
      `.ql-scene-break[track-insertion-block*="${delimitedUser}"]::after`,
      `.ql-scene-break[track-deletion-block*="${delimitedUser}"]::after`,
      `[track-insertion*="${delimitedUser}"] .ql-link-element`,
      `[track-reformat*="${delimitedUser}"] .ql-link-element`,
      `[track-insertion-block*=":${delimitedUser}:"] + li .ql-ui:first-child::before`,
    ].join(', '), {color: `${color} !important`});

    this.styles.addStyle([
      `[track-insertion*="${delimitedUser}"] .ql-soft-break::before`,
      `[track-deletion*="${delimitedUser}"] .ql-soft-break::before`,
      `[track-insertion-block*="${delimitedUser}"] .track-indicator::before`,
      `[track-deletion-block*="${delimitedUser}"]:not([track-insertion-block]) .track-indicator::before`,
      `[track-deletion-block-collapsed*="${delimitedUser}"] span::before`,
      `[track-reformat-block*="${delimitedUser}"]::before`,
      `[track-reformat-block*="${delimitedUser}"]::after`,
    ].join(', '), {background: color});

    this.styles.addCustomProperty([
      `[track-deletion*="${delimitedUser}"]`,
      `[track-deletion-block*="${delimitedUser}"][track-insertion-block] .track-indicator`,
    ].join(', '), 'tracked-deletion-strikethrough-color', color);

    this.styles.addStyle(`.active-change-${userId}`, {borderColor: color});
  }
}
