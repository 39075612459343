<template>
  <span class="vui-icon">
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/Font</title>
      <g
        id="icon-fill"
        stroke="none"
        stroke-width="1"
        fill="#000000"
        fill-rule="evenodd"
      >
        <path
          d="M12.114,3.2 C12.394,3.2 12.534,3.354 12.604,3.55 L12.604,3.55 L15.978,12.58 C15.992,12.622 15.992,12.678 15.992,12.706 C15.992,12.874 15.866,13 15.712,13 L15.712,13 L14.494,13 C14.228,13 14.116,12.832 14.088,12.734 L14.088,12.734 L13.43,11.026 L9.272,11.026 L8.614,12.734 C8.572,12.832 8.46,13 8.208,13 L8.208,13 L6.99,13 C6.836,13 6.696,12.874 6.696,12.706 C6.696,12.678 6.71,12.622 6.724,12.58 L6.724,12.58 L10.098,3.55 C10.154,3.354 10.308,3.2 10.588,3.2 L10.588,3.2 Z M3.249,6.35 C3.401,6.35 3.477,6.4165 3.5245,6.54 L3.5245,6.54 L5.928,12.7055 C5.9375,12.734 5.947,12.791 5.947,12.81 C5.947,12.9145 5.8615,13 5.757,13 L5.757,13 L5.567,13 C5.4435,13 5.377,12.905 5.358,12.8575 L5.358,12.8575 L4.769,11.3375 L1.406,11.3375 L0.817,12.8575 C0.798,12.905 0.7315,13 0.608,13 L0.608,13 L0.418,13 C0.3135,13 0.228,12.9145 0.228,12.81 C0.228,12.791 0.2375,12.734 0.247,12.7055 L0.247,12.7055 L2.6505,6.54 C2.698,6.4165 2.774,6.35 2.926,6.35 L2.926,6.35 Z M3.0875,7.0245 L1.634,10.7675 L4.541,10.7675 L3.0875,7.0245 Z M11.344,5.09 L9.762,9.416 L12.94,9.416 L11.344,5.09 Z"
        ></path>
      </g>
    </svg>
  </span>
</template>
