import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import romanNumerals from '@reedsy/studio.shared/filters/roman-numerals';

export function getOrderNumberLiteral(matterType: MatterType, orderNumber: number): string {
  if (matterType === MatterType.Part) {
    return romanNumerals(orderNumber);
  }

  return `${orderNumber}`;
}
