<template>
  <div class="section-title">
    <div
      v-if="shouldDisplayOrderNumber"
      class="order-number-wrapper"
    >
      <p
        class="chapter-number order-number"
      >
        &nbsp;{{ orderLiteral }}&nbsp;
      </p>
    </div>

    <p
      class="title-text"
      :class="{
        'hidden-chapter-title': !shouldShowTitle
      }"
    >
      <!-- NEED to add this as otherwise the height is not being taken -->
      <br v-if="!shouldShowTitle">
      {{ title }}
    </p>

    <div
      v-if="shouldShowSeparator"
      class="chapter-separator-wrapper"
    >
      <component
        :is="separatorComponent"
        class="chapter-separator"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {getOrderNumberLiteral} from '@reedsy/studio.viewer/utils/get-order-number-literal';
import {Theme} from '@reedsy/studio.shared/models/settings';
import {
  SeparatorComponents,
  SeparatorComponentsMap,
} from '@reedsy/studio.viewer/components/chapter-separators/separators';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';

@Component({
  components: {
    SkeletonLoader,
    ...SeparatorComponents,
  },
})
export default class SectionTitle extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerSettings)
  public $settings: BookViewerSettingsModule;

  public get content(): ContentFragment {
    return this.$displayedData.content;
  }

  public get orderNumber(): number {
    return this.$displayedData.orderNumber;
  }

  public get shouldDisplayOrderNumber(): boolean {
    return this.$displayedData.shouldDisplayOrderNumber;
  }

  public get orderLiteral(): string {
    return getOrderNumberLiteral(this.content.data.type, this.orderNumber);
  }

  public get title(): string {
    return this.$displayedData.title;
  }

  public get shouldShowTitle(): boolean {
    return !!this.title;
  }

  public get shouldShowSeparator(): boolean {
    return this.$displayedData.isBodyMatter && !!this.separatorComponent && this.isChapter;
  }

  public get separatorComponent(): string {
    return SeparatorComponentsMap[this.theme];
  }

  public get theme(): Theme {
    return this.$settings.theme;
  }

  public get isChapter(): boolean {
    return this.content.data.type === MatterType.Chapter;
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  padding-bottom: $editor-row-gap;

  .order-number {
    font-size: $font-size-chapter-title;
    line-height: $line-height-chapter-title;
    color: var(--reedsy-form-info-textColor);
    text-align: center;
  }

  .chapter-separator-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $space-xxl;
    margin-bottom: $space-xl;
  }

  .title-text {
    color: var(--reedsy-plain-onDefault);
    width: 100%;
    text-align: center;
    font-size: $font-size-chapter-title;
    line-height: $line-height-chapter-title;
    margin-top: $editor-row-gap;
    word-break: break-word;

    :deep(.ql-editor) {
      font-size: $font-size-xxxl;
      font-style: normal;
      text-align: center;
    }

    &.hidden-chapter-title {
      visibility: hidden;
    }
  }

  .order-number-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .order-number-skeleton-loader {
      $order-number-skeleton-loader-size: 3rem;

      height: $order-number-skeleton-loader-size;
      width: $order-number-skeleton-loader-size * 2;
    }
  }
}
</style>
