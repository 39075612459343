import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {FontSize, FONT_SIZES, LineHeight, LINE_HEIGHTS, Theme, THEMES} from '@reedsy/studio.shared/models/settings';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {IBookViewerSettings} from './i-book-viewer-settings';
import LocalStorageHelper from '@reedsy/studio.shared/services/local-storage/local-storage-helper';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import {AppTheme} from '@reedsy/studio.shared/store/modules/user-settings/app-theme';
import {isOsDarkMode} from '@reedsy/studio.shared/utils/is-os-dark-mode';

@injectable()
export class BookViewerSettingsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('LocalStorageHelper')
    localStorageHelper: LocalStorageHelper,
  ) {
    @Module({name: StoreName.BookViewerSettings, store})
    class BookViewerSettings extends VuexModule implements IBookViewerSettings {
      public readonly NAMESPACE = 'book-viewer-settings';
      public theme: Theme = 'theme-reedsy';
      public fontSize: FontSize = 'font-size-medium';
      public lineHeight: LineHeight = 'line-height-normal';
      public isTextJustified = false;
      public hasSelectedDarkMode: boolean | null = null;

      public get rootThemeClass(): AppTheme {
        if (this.hasSelectedDarkMode === null) return null;
        return this.hasSelectedDarkMode ? AppTheme.Dark : AppTheme.Light;
      }

      public get appTheme(): AppTheme {
        if (this.rootThemeClass) return this.rootThemeClass;
        return isOsDarkMode.value ? AppTheme.Dark : AppTheme.Light;
      }

      @Action
      public toggleDarkMode(): void {
        this.HAS_SELECTED_DARK_MODE(this.appTheme !== AppTheme.Dark);
        localStorageHelper.setItem(this, 'hasSelectedDarkMode', this.hasSelectedDarkMode);
      }

      @Action
      public toggleJustifyText(newValue?: boolean): void {
        newValue = newValue === undefined ? !this.isTextJustified : newValue;
        this.IS_TEXT_JUSTIFIED(newValue);
        localStorageHelper.setItem(this, 'isTextJustified', this.isTextJustified);
      }

      @Action
      public changeTheme(theme: Theme): void {
        this.THEME(theme);
        localStorageHelper.setItem(this, 'theme', this.theme);
      }

      @Action
      public changeFontSize(fontSize: FontSize): void {
        this.FONT_SIZE(fontSize);
        localStorageHelper.setItem(this, 'fontSize', this.fontSize);
      }

      @Action
      public changeLineHeight(lineHeight: LineHeight): void {
        this.LINE_HEIGHT(lineHeight);
        localStorageHelper.setItem(this, 'lineHeight', this.lineHeight);
      }

      @Action
      public loadSettingsFromStorage(): void {
        const savedTheme = localStorageHelper.getItem(this, 'theme');
        const savedFontSize = localStorageHelper.getItem(this, 'fontSize');
        const savedLineHeight = localStorageHelper.getItem(this, 'lineHeight');
        const savedHasSelectedDarkMode = localStorageHelper.getItem(this, 'hasSelectedDarkMode');
        const isTextJustified = localStorageHelper.getItem(this, 'isTextJustified');

        if (THEMES[savedTheme]) this.THEME(savedTheme as Theme);
        if (FONT_SIZES[savedFontSize]) this.FONT_SIZE(savedFontSize as FontSize);
        if (LINE_HEIGHTS[savedLineHeight]) this.LINE_HEIGHT(savedLineHeight as LineHeight);

        this.HAS_SELECTED_DARK_MODE(savedHasSelectedDarkMode);
        this.IS_TEXT_JUSTIFIED(!!isTextJustified);
      }

      @Mutation
      private HAS_SELECTED_DARK_MODE(hasSelectedDarkMode: boolean | null): void {
        this.hasSelectedDarkMode = hasSelectedDarkMode;
      }

      @Mutation
      private THEME(theme: Theme): void {
        this.theme = theme;
      }

      @Mutation
      private FONT_SIZE(fontSize: FontSize): void {
        this.fontSize = fontSize;
      }

      @Mutation
      private LINE_HEIGHT(lineHeight: LineHeight): void {
        this.lineHeight = lineHeight;
      }

      @Mutation
      private IS_TEXT_JUSTIFIED(isTextJustified: boolean): void {
        this.isTextJustified = isTextJustified;
      }
    }

    this.Module = BookViewerSettings;
  }
}

export type BookViewerSettingsModule = InstanceType<BookViewerSettingsModuleFactory['Module']>;
