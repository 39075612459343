<template>
  <PageContainer>
    <div class="epigraph">
      <RichTextReader
        :rich-text-id="contentRichTextId"
        empty-default-placeholder-text="Epigraph page is still a work in progress!"
        class="epigraph-content"
      >
        <template #loader>
          <div class="content-loader">
            <SkeletonLoader
              v-for="i in 3"
              :key="i"
              class="line-skeleton-loader"
            />
          </div>
        </template>
      </RichTextReader>
      <RichTextReader
        v-if="hasContent"
        :rich-text-id="attributionRichTextId"
        class="align-right epigraph-attribution"
      >
        <template #empty-placeholder>
          <div />
        </template>
        <template #loader>
          <div class="attribute-loader">
            <SkeletonLoader class="name-skeleton-loader" />
          </div>
        </template>
      </RichTextReader>
    </div>
  </PageContainer>
</template>

<script lang="ts">
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import PageContainer from '@reedsy/studio.viewer/components/page-container/page-container.vue';
import {IEpigraph} from '@reedsy/reedsy-sharedb/lib/common/book-content/epigraph';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import SectionTitle from '@reedsy/studio.viewer/components/section-title/section-title.vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

@Component({
  components: {
    PageContainer,
    RichTextReader,
    SectionTitle,
    SkeletonLoader,
  },
})
export default class Epigraph extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerRichText)
  public $richText: BookViewerRichTextModule;

  public get epigraph(): IEpigraph {
    return this.$displayedData.content.data as IEpigraph;
  }

  public get contentRichTextId(): string {
    return this.epigraph.references.content.id;
  }

  public get attributionRichTextId(): string {
    return this.epigraph.references.attribution.id;
  }

  public get hasContent(): boolean {
    return !this.$richText.isEmpty(this.contentRichTextId);
  }
}
</script>

<style lang="scss" scoped>
.epigraph {
  .epigraph-page-title {
    margin-bottom: $space-xxxl
  }

  .content-loader {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;

    .line-skeleton-loader{
      margin-bottom: $space-md;

      &:last-child {
        width: 75%;
      }
    }
  }

  .attribute-loader {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: column;

    .name-skeleton-loader {
      margin-left: auto;
      width: 30%;
    }
  }
}
</style>
