import {BookViewerRouterPlugin} from '@reedsy/studio.viewer/store/book-viewer-router-plugin';
import {$inject} from '@reedsy/studio.viewer/types';
import {injectable, named} from 'inversify';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {IBookViewerRoute} from '@reedsy/studio.viewer/router/i-book-viewer-route';
import {decodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';
import {getBrowserTitle} from '@reedsy/studio.shared/utils/browser-title/get-browser-title';

@injectable()
export default class BrowserTitlePluginFactory extends BookViewerRouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.BookViewerBookContent)
  public _$bookContent: BookViewerBookContentModule;

  public override afterEach(to: IBookViewerRoute): void {
    const bookTitle = this._$bookContent.bookTitle;
    const contentId = decodeObjectId(to.params.contentId);
    const contentTitle = this._$bookContent.title(contentId);
    document.title = getBrowserTitle({
      bookTitle,
      contentTitle,
    });
  }
}
