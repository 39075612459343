import RestrictedFormatBlock from '@reedsy/studio.shared/services/quill/blots/restricted-format-block';
import {ALWAYS_ALLOWED_INLINES} from './always-allowed-inlines';
import MentionBlot from '@reedsy/studio.shared/services/quill/blots/mentions';

export default class ReedsyHeader extends RestrictedFormatBlock {
  public static override blotName = 'header';
  public static override tagName = ['H1', 'H2', 'H3'];
  public static override readonly allowedChildren = [...ALWAYS_ALLOWED_INLINES, MentionBlot];

  public static readonly MIN_LEVEL = 1;
  public static readonly MAX_LEVEL = ReedsyHeader.tagName.length;

  public static override formats(domNode: HTMLElement): any {
    const number = this.tagName.indexOf(domNode.tagName) + 1;
    return (number >= this.MIN_LEVEL && number <= this.MAX_LEVEL) ? number : null;
  }
}
