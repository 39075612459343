import {injectable, named} from 'inversify';
import {BookViewerRouterPlugin} from '@reedsy/studio.viewer/store/book-viewer-router-plugin';
import {$inject} from '@reedsy/studio.viewer/types';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerShareableUrlDetailsModule} from '@reedsy/studio.viewer/store/modules/shareable-url-details/shareable-url-details';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {BookViewerLayoutModule} from '@reedsy/studio.viewer/store/modules/layout/book-viewer-layout';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';
import {BookOwnerModule} from '@reedsy/studio.viewer/store/modules/book-owner';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {BookViewerReadingLocationModule} from '@reedsy/studio.viewer/store/modules/reading-location/reading-location';
import {IBookViewerRoute} from '@reedsy/studio.viewer/router/i-book-viewer-route';

@injectable()
export default class LoadPluginFactory extends BookViewerRouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.BookViewerSharableUrlDetails)
  public readonly _ShareableUrlDetails: BookViewerShareableUrlDetailsModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerBookOwner)
  public readonly _BookOwner: BookOwnerModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerBookContent)
  public readonly _BookContent: BookViewerBookContentModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerLayout)
  public readonly _Layout: BookViewerLayoutModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerSettings)
  public readonly _BookViewerSettings: BookViewerSettingsModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerReadingLocation)
  public readonly _ReadingLocation: BookViewerReadingLocationModule;

  @$inject('Api')
  public readonly _api: IApi;

  public override async beforeEach(to: IBookViewerRoute): Promise<void> {
    const sharableUrlShortId = to.params.shareableUrlShortId;

    await this.loadContent(sharableUrlShortId);
    this._Layout.LOADING(false);
  }

  private async loadContent(sharableUrlShortId: string): Promise<void> {
    await Promise.all([
      this._ShareableUrlDetails.initialise(sharableUrlShortId),
      this._BookContent.initialise(sharableUrlShortId),
    ]);

    await this._BookViewerSettings.loadSettingsFromStorage();
    await this._ReadingLocation.loadFromStorage();
    await this.loadBookOwner();
  }

  private async loadBookOwner(): Promise<void> {
    if (this._BookOwner.loaded || !this._BookContent.ownerId) return;

    const owner = await this._api.getReaderBookOwner(
      this._ShareableUrlDetails.shortId,
    );
    this._BookOwner.DATA(owner);
  }
}
