import {Theme} from '@reedsy/studio.shared/models/settings';
import RomanceSeparator from './romance-separator.vue';

export const SeparatorComponentsMap: Partial<Record<Theme, keyof typeof SeparatorComponents>> = {
  ['theme-romance']: 'RomanceSeparator',
};

export const SeparatorComponents = {
  RomanceSeparator,
};
