import {TopMatterType, bookContentStructure} from '@reedsy/reedsy-sharedb/lib/server/book-contents-structure';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {deepFreeze} from '@reedsy/utils.object';

const _REVERSE_MAP = {} as Record<MatterType, TopMatterType>;
bookContentStructure.order.front_matter.forEach((matterType) => _REVERSE_MAP[matterType] = MatterType.FrontMatter);
bookContentStructure.order.body.forEach((matterType) => _REVERSE_MAP[matterType] = MatterType.Body);
bookContentStructure.order.back_matter.forEach((matterType) => _REVERSE_MAP[matterType] = MatterType.BackMatter);
const REVERSE_MAP = deepFreeze(_REVERSE_MAP);

export function topMatterType(content: ContentFragment): TopMatterType {
  if (!content) return null;
  if (content.data.type === MatterType.Chapter || content.data.type === MatterType.Part) {
    return content.path[1] as TopMatterType;
  }

  return REVERSE_MAP[content.data.type] || null;
}
