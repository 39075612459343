
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import Expander from '@reedsy/studio.shared/components/expander.vue';
import {ITableOfContentsEntry} from '@reedsy/studio.viewer/store/modules/table-of-contents/i-table-of-contents';
import {getOrderNumberLiteral} from '@reedsy/studio.viewer/utils/get-order-number-literal';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerLayoutModule} from '@reedsy/studio.viewer/store/modules/layout/book-viewer-layout';

@Component({
  components: {
    Expander,
  },
})
export default class TOCContentFragmentTitle extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerLayout)
  public $layout: BookViewerLayoutModule;

  @Prop({type: Object, required: true})
  public tocEntry: ITableOfContentsEntry;

  @Prop({type: Boolean, required: true})
  public isCollapsible: boolean;

  @Model
  public expanded: boolean;

  @Prop({type: Number, required: true})
  public nestLevel: number;

  public get isTopMatter(): boolean {
    return this.nestLevel === 0;
  }

  public get isPart(): boolean {
    return this.tocEntry.matter?.type === MatterType.Part;
  }

  public get isChapter(): boolean {
    return this.tocEntry.matter.type === MatterType.Chapter;
  }

  public get title(): string {
    return this.tocEntry.title || 'Untitled chapter';
  }

  public get orderNumberLiteral(): string {
    if (!this.tocEntry.orderNumber) return '';
    return getOrderNumberLiteral(this.tocEntry.matter.type, this.tocEntry.orderNumber);
  }

  public closeLeftSidebar(): void {
    this.$layout.closeLeftSidebar();
  }
}
