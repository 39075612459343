import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import SoftBreakBlot from '@reedsy/studio.shared/services/quill/blots/soft-break-blot';
import Delta from '@reedsy/quill-delta';
import {KeyboardThis} from './keyboard.interface';

export default function(this: KeyboardThis, range: Range): boolean {
  if (!this.quill.options.registry.query(SoftBreakBlot.blotName)) return true;

  const delta = new Delta()
    .retain(range.index)
    .delete(range.length)
    .insert({[SoftBreakBlot.blotName]: true});
  this.quill.updateContents(delta, Quill.sources.USER);
  this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
}
