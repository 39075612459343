import {Scope} from 'parchment';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {FigureImage} from '@reedsy/studio.shared/services/quill/blots/figure';
import TrackInsertAttributor from './track-insert-attributor';

const {BLOCK} = TrackChangesKeys;

export const TrackCommentBlock = new TrackInsertAttributor(
  BLOCK.COMMENT,
  BLOCK.COMMENT,
  {scope: Scope.BLOCK_ATTRIBUTE, allowedTags: [FigureImage.tagName]},
);
