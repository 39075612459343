
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerQuill, {IBookViewerQuillConfig} from '@reedsy/studio.viewer/services/quill';
import {PropType} from 'vue';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';

export const DEFAULT_PLACEHOLDER = 'This chapter is still a work in progress';

@Component({})
export default class Reader extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  @Prop({type: Object as PropType<IBookViewerQuillConfig>, required: false})
  public options: IBookViewerQuillConfig;

  public quill: BookViewerQuill;

  public mounted(): void {
    const container = this.$refs.quill as HTMLElement;
    const options: IBookViewerQuillConfig = {
      theme: 'reedsy',
      placeholder: DEFAULT_PLACEHOLDER,
      readOnly: true,
      bounds: this.$el as HTMLElement,
      modules: {
        figures: true,
        endnotes: {
          getContents: () => null,
          create: () => {/* no op */},
        },
        'scene-breaks': true,
        links: {storeLinksEnabled: true},
        toolbar: false,
      },
      metadata: {},
      ...this.options,
    };

    options.metadata.bookId = this.$bookContent.bookId;
    options.metadata.legacyBookId = this.$bookContent.legacyBookId;
    this.quill = new BookViewerQuill(container, options);
    this.$el.querySelector('.ql-container').classList.add('ql-reedsy');
  }
}
