
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import Quill, {IBookViewerQuillConfig} from '@reedsy/studio.viewer/services/quill';
import Reader from '@reedsy/studio.viewer/components/reader/reader.vue';
import {PropType} from 'vue';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import {Logger} from '@reedsy/reedsy-logger-js';
import BookViewerEndnotesMixin from './mixins/endnotes';
import SetQuillMixin from './mixins/set-quill';
import ParagraphLoader from './paragraph-loader.vue';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

@Component({
  components: {
    Reader,
    ParagraphLoader,
  },
})
export default class RichTextReaderImpl extends mixins(
  SetQuillMixin,
  BookViewerEndnotesMixin,
) {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerRichText)
  public override $richText: BookViewerRichTextModule;

  @Prop({type: String, required: true})
  public richTextId: string;

  @Prop({type: Boolean, default: true})
  public loadEndnotes: boolean;

  @Prop({type: String, required: false, default: 'Reader'})
  public editor: string;

  @Prop({type: Object as PropType<IBookViewerQuillConfig>, required: false})
  public options: IBookViewerQuillConfig;

  public ready = false;

  public override quill: Quill;
  protected logger: Logger;

  public get isRichTextEmpty(): boolean {
    return this.$richText.isEmpty(this.richTextId);
  }

  public override async mounted(): Promise<void> {
    try {
      const richText = await this.$richText.loadRichText({
        contentId: this.$displayedData.displayedContentId,
        richTextId: this.richTextId,
        loadEndnotes: this.loadEndnotes,
      });
      this.quill.setContents(richText.ops);
    } catch {
      Notify.error({message: 'There was some problem loading the content', persistent: true});
    }
    this.ready = true;
  }

  public created(): void {
    this.logger = loggerFactory.create('RichTextReaderImpl');
  }
}
