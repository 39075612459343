import {Component, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import Endnote from '@reedsy/studio.shared/services/quill/formats/endnote';
import {endnoteHashFromId} from '@reedsy/studio.shared/utils/endnote-hash';
import Endnotes from '@reedsy/studio.shared/services/quill/modules/endnotes';
import Quill from '@reedsy/studio.shared/services/quill';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

export const DEFAULT_ENDNOTE_PLACEHOLDER = '*';

@Component({})
export default class EndnotesMixin extends ClientSharedVue {
  public quill: Quill;
  public endnoteIds: string[] = [];

  public get endnoteStartNumber(): number {
    return null;
  }

  public get shouldUsePlaceholder(): boolean {
    return false;
  }

  public get placeholder(): string {
    return DEFAULT_ENDNOTE_PLACEHOLDER;
  }

  private get endnotesModule(): Endnotes {
    return this.quill.getModule('endnotes');
  }

  public mounted(): void {
    this.quill.once(Quill.events.TEXT_CHANGE, this.initializeEndnotes);
  }

  @Watch('shouldUsePlaceholder')
  public watchShouldUsePlaceholder(): void {
    this.endnotesModule.shouldUsePlaceholder = this.shouldUsePlaceholder;
    this.endnotesModule.update();
  }

  @Watch('placeholder')
  public watchPlaceholder(): void {
    this.endnotesModule.placeholder = this.placeholder;
    this.endnotesModule.update();
  }

  @Watch('endnoteStartNumber')
  public watchEndnoteStartNumber(): void {
    this.endnotesModule.startNumber = this.endnoteStartNumber;
    this.endnotesModule.update();
  }

  private initializeEndnotes(): void {
    if (!this.endnotesModule) return;
    this.quill.emitter.on(
      Endnote.events.ENDNOTE_CLICKED,
      (id: string) => this.$router.push({
        path: this.$route.path,
        hash: endnoteHashFromId(id),
      }),
    );
    this.endnoteIds = this.endnotesModule.list;

    this.endnotesModule.shouldUsePlaceholder = this.shouldUsePlaceholder;
    this.endnotesModule.placeholder = this.placeholder;
    this.endnotesModule.startNumber = this.endnoteStartNumber;
    this.endnotesModule.update();
  }
}
