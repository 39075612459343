import {LinkType} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/link-type.interface';

export interface IStoreLinkConfig {
  key: Exclude<LinkType, LinkType.Regular>;
  label: string;
  placeholder?: string;
}

const storeLinkConfig: IStoreLinkConfig[] = [
  {
    key: LinkType.Pdf,
    label: 'PDF',
    placeholder: 'Digital PDF',
  },
  {
    key: LinkType.IBooks,
    label: 'Apple',
    placeholder: 'Apple Books',
  },
  {
    key: LinkType.Kindle,
    label: 'Kindle',
  },
  {
    key: LinkType.Kobo,
    label: 'Kobo',
  },
  {
    key: LinkType.BNPress,
    label: 'B&N Press',
  },
  {
    key: LinkType.Play,
    label: 'Play',
    placeholder: 'Google Play',
  },
];

export default storeLinkConfig;
