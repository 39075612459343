<template>
  <div
    class="expander"
    :class="{collapsed: !modelValue}"
  >
    <button @click="toggle">
      <div
        v-if="loading"
        class="spinner-wrapper"
      >
        <VuiLoadingIndicator class="sm" />
      </div>
      <VuiIconTriangle :class="iconClass" />
    </button>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class Expander extends ClientSharedVue {
  @Prop({type: Boolean, required: true})
  public override modelValue: boolean;

  @Prop({type: String, required: false})
  public iconClass: string;

  @Prop({type: Boolean, required: false})
  public loading: boolean;

  public toggle(): void {
    if (this.loading) return;
    this.update();
  }

  @Emit('update:modelValue')
  private update(): boolean {
    return !this.modelValue;
  }
}
</script>

<style lang="scss" scoped>
.expander {
  button {
    display: flex;
    margin: -$space-sm;
    padding: $space-sm;
    transition: transform $transition-time-fast;
    transform: rotate(180deg);
  }

  &.collapsed {
    button {
      transform: rotate(90deg);
    }
  }

  .spinner-wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;

    & + * {
      visibility: hidden;
    }
  }
}
</style>
