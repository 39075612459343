import Delta from '@reedsy/quill-delta';
import listTrackedChanges from '@reedsy/studio.shared/store/modules/rich-text/list-tracked-changes';
import acceptAll from '@reedsy/studio.shared/services/track-changes/change-applier/accept-all';
import resolveComments from '@reedsy/studio.shared/services/track-changes/change-applier/resolve-comments';
import {combine} from '@reedsy/utils.ot-rich-text';
import {splitOpsOnNewlines} from '@reedsy/utils.ot-rich-text';
import {isComment} from '@reedsy/studio.shared/services/track-changes/is-comment';

export default function trackedChangeMatcher(node: HTMLElement, delta: Delta): Delta {
  delta = new Delta(splitOpsOnNewlines(delta.ops));
  const changes = listTrackedChanges(delta.ops)
    .filter((change) => !isComment(change.changes));
  // The change appliers need a base document to check ops against.
  // The paste can be considered a standalone document on its own,
  // so let's just use the delta itself as the base document.
  const contents = delta.ops;
  const accept = acceptAll(changes, contents);
  const resolve = resolveComments(changes, contents);
  return delta.compose(combine(accept, resolve));
}
