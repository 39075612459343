<template>
  <div class="book-contents">
    <TOCContentFragment
      v-for="tocEntry in tableOfContents.entries"
      :key="tocEntry.id"
      :toc-entry="tocEntry"
      :nest-level="0"
    />
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import TOCContentFragment from './toc-content-fragment.vue';
import {ITableOfContents} from '@reedsy/studio.viewer/store/modules/table-of-contents/i-table-of-contents';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerTableOfContentsModule} from '@reedsy/studio.viewer/store/modules/table-of-contents/table-of-contents';

@Component({
  components: {
    TOCContentFragment,
  },
})
export default class TableOfContents extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerTableOfContents)
  public $tableOfContents: BookViewerTableOfContentsModule;

  public get tableOfContents(): ITableOfContents {
    return this.$tableOfContents.tableOfContents;
  }
}
</script>

<style lang="scss" scoped>
.orientation-landscape-left {
  .book-contents {
    margin-left: env(safe-area-inset-left);
  }
}
</style>
