import {Scope} from 'parchment';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import TrackInsertAttributor from './track-insert-attributor';

const {INLINE} = TrackChangesKeys;

export const TrackDeletionInline = new TrackInsertAttributor(
  INLINE.DELETION,
  INLINE.DELETION,
  {scope: Scope.INLINE_ATTRIBUTE},
);
