<template>
  <div class="title-page-header">
    <p class="author-name theme-body">
      {{ authorName }}
    </p>
    <p
      v-if="bookTitle"
      class="book-title theme-header"
    >
      {{ bookTitle }}
    </p>
    <p
      v-if="bookSubtitle"
      class="book-subtitle theme-subheader"
    >
      {{ bookSubtitle }}
    </p>
    <div class="divider" />
  </div>
</template>

<script lang="ts">
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({
  components: {},
})
export default class TitlePageHeader extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  public get authorName(): string {
    return this.$bookContent.authorName;
  }

  public get bookTitle(): string {
    return this.$bookContent.bookTitle;
  }

  public get bookSubtitle(): string {
    return this.$bookContent.bookSubtitle;
  }
}
</script>

<style lang="scss" scoped>
.title-page-header {
  p {
    text-align: center;
  }

  .book-title {
    margin-top: $space-md;
    font-size: $font-size-xxxl;
  }

  .book-subtitle {
    margin-top: $space-md;
  }

  .divider {
    border-top: $border;
    width: 100%;
    margin: $space-xxxl 0;
  }
}
</style>
