
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import SubscriptionBaseModal from './subscription-base.vue';
import AddOnToggle from './add-on-toggle.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {DisposableFunction, finallyCall} from '@reedsy/studio.shared/utils/finally-call/finally-call';
import {config} from '@reedsy/studio.shared/config';
import {IPriceCurrencyOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-currency-options-response';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';
import {FEATURE_SUMMARIES} from './features/feature-summaries';
import FeatureSummary from './features/feature-summary.vue';

@Component({
  components: {
    AddOnToggle,
    DelayedLoadingIndicator,
    LoadingButton,
    SubscriptionBaseModal,
    FeatureSummary,
  },
})
export default class SubscriptionTrial extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public readonly config = config;
  public readonly cancelable = true;

  public readonly availableSummaries = FEATURE_SUMMARIES.filter((summary) => summary.product);

  public loading = false;
  public currencies: IPriceCurrencyOptionsResponse = null;

  public async created(): Promise<void> {
    this.currencies = await this.$subscription.fetchPrice();
  }

  public async startTrial(): Promise<void> {
    using doneLoading = this.startLoading();
    await this.$subscription.startTrial();
    doneLoading();
    this.close();
    this.$modals.open('SubscriptionTrialStarted');
  }

  private startLoading(): DisposableFunction<() => void> {
    this.loading = true;
    return finallyCall(() => this.loading = false);
  }
}
