import {timeout} from '@reedsy/utils.timeout';

export class Viewport {
  public static async scrollIntoView(element: Element, options?: ScrollIntoViewOptions): Promise<void> {
    const reference = document.body;
    if (!element || !reference) return;
    await stableBounds(element);
    if (!isInViewport(element, reference) || isObscured(element)) element.scrollIntoView(options);
  }
}

function isInViewport(element: Element, reference: Element): boolean {
  const elementBounds = element.getBoundingClientRect();
  const referenceBounds = reference.getBoundingClientRect();
  return elementBounds.bottom <= referenceBounds.bottom && elementBounds.top >= referenceBounds.top;
}

function isObscured(element: Element): boolean {
  // Get all rectangles and check the first one to cover eg multi-line <span>
  const bounds = element.getClientRects();
  if (!bounds.length) return false;
  const x = bounds[0].left + 0.5 * bounds[0].width;
  // TODO: This function works incorrectly with the big blocks, which are bigger than the visible area.
  // It checks the point in the middle of the block. But if the block is much higher than visibility area,
  // it will be obscured all the time and tend to unnecessary scrolling
  const y = bounds[0].top + 0.5 * bounds[0].height;
  const topElement = document.elementFromPoint(x, y);
  return !element.contains(topElement);
}

async function stableBounds(element: Element): Promise<void> {
  let oldBounds = element.getBoundingClientRect();
  for (let i = 0; i < 10; i++) {
    await timeout();
    const newBounds = element.getBoundingClientRect();
    if (newBounds.left !== oldBounds.left || newBounds.top !== oldBounds.top) i = 0;
    oldBounds = newBounds;
  }
}
