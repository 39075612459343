import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_ContentPane = _resolveComponent("ContentPane")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("fieldset", {
      disabled: _ctx.readOnlyMode || null,
      class: _normalizeClass(["layout-left", {
        'layout-left-open': _ctx.open,
      }])
    }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.withOverlay && _ctx.open)
            ? (_openBlock(), _createBlock(_component_Overlay, {
                key: 0,
                class: "sidebar-overlay",
                onClick: _ctx.emitHide
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _renderSlot(_ctx.$slots, "before-content", {}, undefined, true),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _renderSlot(_ctx.$slots, "content-pane", {}, () => [
        _createVNode(_component_ContentPane, { title: _ctx.title }, {
          title: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ]),
          "header-after-content": _withCtx(() => [
            _renderSlot(_ctx.$slots, "header-after-content", {}, undefined, true)
          ]),
          header: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ]),
          content: _withCtx(() => [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ]),
          footer: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["title"])
      ], true)
    ], 10, _hoisted_1),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "after-content", {}, undefined, true)
  ]))
}