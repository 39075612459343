import {Range} from '@reedsy/quill/core/selection';
import {LinkTooltipMode} from '@reedsy/studio.shared/services/quill/modules/links';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';
import Link from '@reedsy/studio.shared/services/quill/modules/links/base-link';
import {KeyboardContext, KeyboardThis} from './keyboard.interface';
import Links from '@reedsy/studio.shared/services/quill/modules/links';

export default function(this: KeyboardThis, range: Range, {format}: KeyboardContext): void {
  if (!range.length || !this.quill.options.registry.query(Link.blotName)) return;
  const links = format.link as unknown[] | ILinks;
  const linksModule = this.quill.getModule('links') as Links;
  if (!links || Array.isArray(links)) return linksModule.openLinkEditor();
  const mode = links.regularUrl ? LinkTooltipMode.Link : LinkTooltipMode.StoreLink;
  linksModule.openLinkEditor(mode, {links});
}
