import Block from '@reedsy/quill/blots/block';

const WANTS_DELETION = 'wants-deletion';

export default class FigureCaption extends Block {
  public static override readonly blotName = 'caption';
  public static override readonly tagName = 'figcaption';

  public static override formats(domNode: HTMLElement): boolean {
    this.updateEmptyClass(domNode);
    return true;
  }

  public static updateEmptyClass(domNode: HTMLElement): void {
    const hasContent = domNode.textContent.trim();
    domNode.classList.toggle('empty', !hasContent);
  }

  public override optimize(context: any): void {
    super.optimize(context);
    if (this.domNode.hasAttribute(WANTS_DELETION)) this.remove();
  }

  public markForDeletion(): void {
    this.domNode.setAttribute(WANTS_DELETION, 'true');
  }
}
