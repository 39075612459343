
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {ITableOfContentsEntry} from '@reedsy/studio.viewer/store/modules/table-of-contents/i-table-of-contents';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import TOCContentFragmentTitle from './toc-content-fragment-title.vue';
import {decodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';
import {PropType} from 'vue';

@Component({
  components: {
    ExpandTransition,
    TOCContentFragmentTitle,
  },
})
export default class TOCContentFragment extends BookViewerVue {
  @Prop({type: Object as PropType<ITableOfContentsEntry>, required: true})
  public tocEntry: ITableOfContentsEntry;

  @Prop({type: Number, required: true})
  public nestLevel: number;

  public expanded = true;

  public get children(): ITableOfContentsEntry[] {
    return this.tocEntry.children;
  }

  public get isCollapsible(): boolean {
    return this.hasChildren;
  }

  public get hasChildren(): boolean {
    return !!this.tocEntry.children?.length;
  }

  public get isActive(): boolean {
    if (!this.$route.params.contentId) return false;

    const contentId = decodeObjectId(this.$route.params.contentId);
    return contentId === this.tocEntry.id;
  }
}
