
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import SectionTitle from '@reedsy/studio.viewer/components/section-title/section-title.vue';
import {IChapter} from '@reedsy/reedsy-sharedb/lib/common/book-content/chapter';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import ContentNavigationButton from '@reedsy/studio.viewer/components/content-navigation-button/content-navigation-button.vue';
import PageContainer from '@reedsy/studio.viewer/components/page-container/page-container.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';

@Component({
  components: {
    RichTextReader,
    SectionTitle,
    ContentNavigationButton,
    PageContainer,
  },
})
export default class Chapter extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  public get contentInfo(): IContentFragment<IChapter> {
    return this.$displayedData.content as IContentFragment<IChapter>;
  }

  public get richTextId(): string {
    return this.contentInfo.data.references.content.id;
  }
}
