import {BubbleTooltip} from '@reedsy/quill/themes/bubble';

/**
 * This file is a bit of a hack that lets us inherit from the BubbleTooltip class,
 * but without calling its constructor, which registers a listener whose behaviour
 * we actively don't want.
 *
 * These other solutions cannot be used:
 * - Overriding the listener method, because it's an arrow function defined in
 *   the constructor
 * - Using Emitter.prependListener, because it's not implemented
 *
 * This file has to be a vanilla JS file because the TS compiler will not allow
 * us to have a constructor without a super() call.
 */
export default class BubbleTooltipNoHide extends BubbleTooltip {
  constructor(quill, boundsContainer) {
    const tooltip = Object.create(new.target.prototype);
    tooltip.quill = quill;
    tooltip.boundsContainer = boundsContainer || document.body;
    tooltip.root = quill.addContainer('ql-tooltip');
    tooltip.root.innerHTML = tooltip.constructor.TEMPLATE;
    quill.root.addEventListener('scroll', () => {
      tooltip.root.style.marginTop = `${-1 * quill.root.scrollTop}px`;
    });
    tooltip.hide();

    // Dummy element isn't used by the Reedsy theme, but is expected by some
    // BubbleTheme methods
    tooltip.textbox = document.createElement('input');

    return tooltip;
  }
}
