import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "also-by" }
const _hoisted_2 = {
  key: 0,
  class: "author-intro"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_DefaultEmptyReaderPlaceholder = _resolveComponent("DefaultEmptyReaderPlaceholder")!
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_AlsoByBook = _resolveComponent("AlsoByBook")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SectionTitle, { class: "page-title" }),
        _cache[1] || (_cache[1] = _createTextVNode()),
        (_ctx.isPageEmpty)
          ? (_openBlock(), _createBlock(_component_DefaultEmptyReaderPlaceholder, {
              key: 0,
              "empty-text": "This page is still a work in progress!"
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.hasIntro)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_RichTextReader, {
                      "rich-text-id": _ctx.introRichTextId,
                      "load-endnotes": false
                    }, null, 8, ["rich-text-id"])
                  ]))
                : _createCommentVNode("", true),
              _cache[0] || (_cache[0] = _createTextVNode()),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.books, (book, index) => {
                return (_openBlock(), _createBlock(_component_AlsoByBook, {
                  key: index,
                  book: book,
                  class: "also-by-book"
                }, null, 8, ["book"]))
              }), 128))
            ], 64))
      ])
    ]),
    _: 1
  }))
}