import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import TrackChanges from '@reedsy/studio.shared/services/quill/modules/track-changes';
import {KeyboardThis} from '@reedsy/studio.shared/services/quill/modules/keyboard/keyboard.interface';

// Don't allow re-deleting deleted images. When tracking changes, Quill
// tries to insert a paragraph deletion that we don't need, which breaks
// tracking.
export default function deletedImageHandlerFactory(isDelete: boolean) {
  return function deletedImageHandler(this: KeyboardThis, range: Range, context: any): boolean {
    if (!trackChangesEnabled(this.quill)) return true;

    const selection = this.quill.getSelection();
    let lines = [];
    if (selection.length) {
      lines = this.quill.getLines(selection);
    } else if (context.offset === 0) {
      const line = isDelete ? context.line : context.line.prev;
      lines.push(line);
    }

    return !lines.some((line) => line && line.isTrackedDeletion && line.isTrackedDeletion());
  };
}

function trackChangesEnabled(quill: Quill): boolean {
  const trackChanges = quill.getModule('track-changes') as TrackChanges;
  return trackChanges && trackChanges.enabled;
}
