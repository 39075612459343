import Quill from '@reedsy/quill';
import Module from '@reedsy/quill/core/module';
import {Registry} from 'parchment';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';
import BaseLink from './base-link';
import LinkWithStoreMode from './link-with-store-mode';
import LinkTooltip from './link-tooltip';
import RegularLink from './regular-link';

export interface ILinkTooltipOptions {
  links?: ILinks;
  editing?: boolean;
  keepInitialValue?: boolean;
}

export enum LinkTooltipMode {
  Link = 'link',
  StoreLink = 'store-link',
}

interface IToolbarOptions {
  storeLinksEnabled?: boolean;
}

export default class Links extends Module {
  public static registerFormats(registry: Registry, options: IToolbarOptions = {}): void {
    registry.register(options.storeLinksEnabled ? LinkWithStoreMode : RegularLink);
  }

  public override options: IToolbarOptions;

  private tooltip: LinkTooltip;

  public constructor(quill: Quill, options: IToolbarOptions) {
    super(quill, options);
    Links.registerFormats(quill.options.registry, options);

    this.tooltip = new LinkTooltip(
      quill,
      quill.root,
    );

    this.quill.root.addEventListener('click', (event) => {
      const targetBlot = this.quill.options.registry.find(event.target as Node);

      if (!(targetBlot instanceof BaseLink)) return;

      if (quill.options.readOnly && targetBlot.mode === LinkTooltipMode.StoreLink) event.preventDefault();
      this.openTooltipForLink(targetBlot);
    });
  }

  public get storeLinksEnabled(): boolean {
    return !!this.options.storeLinksEnabled;
  }

  public openLinkEditor(mode: LinkTooltipMode = LinkTooltipMode.Link, options: ILinkTooltipOptions = {}): void {
    this.tooltip.edit(mode, options);
  }

  private openTooltipForLink<T extends BaseLink>(linkBlot: T): void {
    const index = this.quill.getIndex(linkBlot);

    if (this.quill.options.readOnly) {
      // This is a fix for the Safari where the link
      // cannot be focused by click
      linkBlot.domNode.focus();

      if (linkBlot.mode === LinkTooltipMode.Link) return;
    }

    this.quill.setSelection(index, linkBlot.length(), Quill.sources.USER);

    this.tooltip.edit(
      linkBlot.mode,
      {
        links: linkBlot.links,
        editing: true,
      },
    );
  }
}
