import {Attributor, BlotConstructor, Registry, Scope} from 'parchment';

import Break from '@reedsy/quill/blots/break';
import Container from '@reedsy/quill/blots/container';
import Cursor from '@reedsy/quill/blots/cursor';
import Inline from '@reedsy/quill/blots/inline';

import './blots/block';
import Callout from './blots/callout';
import IndentedBlockBlot from './blots/indented-block-blot';
import IndentedBlockquote from './blots/indented-blockquote-blot';
import Text from '@reedsy/quill/blots/text';
import ReedsyScroll from './blots/scroll';

import Bold from '@reedsy/quill/formats/bold';
import CodeBlock from './formats/code-block';
import {CodeBlockContainer} from '@reedsy/quill/formats/code';
import Italic from '@reedsy/quill/formats/italic';
import ReedsyListItem, {ReedsyListContainer} from './formats/list';
import Script from '@reedsy/quill/formats/script';
import Strike from '@reedsy/quill/formats/strike';
import Underline from '@reedsy/quill/formats/underline';

import ReedsyAlign from './formats/align';
import ReedsyHeader from './formats/header';
import TrackChange from './blots/track-changes/track-change';
import SoftBreakBlot from './blots/soft-break-blot';
import HighlightBlot from './blots/highlight';
import {objectKeys} from '@reedsy/utils.object';
import {has} from '@reedsy/utils.set';

export const BLOTS = Object.freeze([
  Break,
  Container,
  Cursor,
  HighlightBlot,
  Inline,
  IndentedBlockBlot,
  ReedsyScroll,
  SoftBreakBlot,
  Text,
] as const);

// Track change must be the highest blot so that all changes are
// correctly grouped under one span
Inline.order.push(TrackChange.blotName);
CodeBlockContainer.allowedChildren.push(CodeBlock);

// Insert highlights right above links to prevent a bug with Safari
// See: https://github.com/reedsy/reedsy-editor/issues/2658
const linkIndex = Inline.order.indexOf('link');
Inline.order.splice(linkIndex + 1, 0, HighlightBlot.blotName);

const FORMATS = Object.freeze({
  align: [ReedsyAlign],
  bold: [Bold],
  blockquote: [IndentedBlockquote],
  callout: [Callout],
  'code-block': [CodeBlock, CodeBlockContainer],
  clean: [],
  italic: [Italic],
  header: [ReedsyHeader],
  list: [ReedsyListContainer, ReedsyListItem],
  script: [Script],
  strike: [Strike],
  underline: [Underline],
} as const);

export type AllowedBlot = typeof BLOTS[number];
export type AllowedFormat = keyof typeof FORMATS;

export class ReedsyRegistry extends Registry {
  public override query(query: string | Node | Scope, scope: Scope = Scope.ANY): Attributor | BlotConstructor | null {
    if (!query) return null;
    return super.query(query, scope);
  }

  public withFormats(allowedFormats: ReadonlyArray<AllowedFormat>): this {
    const allowed = new Set(allowedFormats || objectKeys(FORMATS));

    for (const formatName in FORMATS) {
      if (has(allowed, formatName)) {
        const formatClasses = FORMATS[formatName];
        formatClasses.forEach((formatClass) => this.register(formatClass));
      }
    }

    return this;
  }

  public withBlots(allowedBlots: ReadonlyArray<AllowedBlot>): this {
    const allowed = new Set(allowedBlots || BLOTS);

    BLOTS.filter((blot) => has(allowed, blot))
      .forEach((blot) => this.register(blot));

    return this;
  }
}
