import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';

export default class Tooltip extends JsxComponent {
  public render(): HTMLElement {
    return (
      <div>
        <span class="ql-tooltip-arrow"/>
        <div class="ql-mention-list" />
      </div>
    );
  }
}
