import {Action, Mutation, VuexModule} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {IShareableUrlWithBuiltUrl} from '@reedsy/studio.isomorphic/controllers/api/v1/books/shareable-urls/i-shareable-url-with-built-url';
import {memoize} from '@reedsy/utils.decorator';
import {addDays} from '@reedsy/utils.date';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import IApi from '@reedsy/studio.shared/services/api/i-api';

@injectable()
export class BookViewerShareableUrlDetailsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: StoreName.BookViewerSharableUrlDetails, store})
    class ShareableUrlDetailsModule extends VuexModule {
      public shareableUrlDetails: IShareableUrlWithBuiltUrl = null;

      public get shortId(): string {
        return this.shareableUrlDetails?.shortId;
      }

      public get bookId(): string {
        return this.shareableUrlDetails?.bookId;
      }

      public get isExpired(): boolean {
        const expiryDate = this.shareableUrlDetails?.expiryDate;

        if (!expiryDate) {
          return false;
        }

        return expiryDate < new Date();
      }

      public get isSoonToBeExpired(): boolean {
        const expiryDate = this.shareableUrlDetails?.expiryDate;

        if (!expiryDate || this.isExpired) {
          return false;
        }
        return addDays(expiryDate, -1) < new Date();
      }

      @Action
      @memoize
      public async initialise(sharableUrlShortId: string): Promise<void> {
        const sharableUrlDetails = await api.getShareableUrlDetails(sharableUrlShortId);
        this.SHAREABLE_URL_DETAILS(sharableUrlDetails);
      }

      @Mutation
      private SHAREABLE_URL_DETAILS(shareableUrlDetails: IShareableUrlWithBuiltUrl): void {
        this.shareableUrlDetails = shareableUrlDetails;
      }
    }

    this.Module = ShareableUrlDetailsModule;
  }
}

export type BookViewerShareableUrlDetailsModule = InstanceType<BookViewerShareableUrlDetailsModuleFactory['Module']>;
