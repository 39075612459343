import {Attributor} from 'parchment';
import {TRACK_CHANGES_KEYS} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {ITrackedChange} from '@reedsy/studio.shared/services/track-changes/i-tracked-changes';
import UserChangeId from '@reedsy/studio.shared/services/quill/helpers/track-changes/user-change-id';
import AttributeMap from '@reedsy/quill-delta/dist/AttributeMap';

const CHANGE_IDS_ATTRIBUTE = 'change-ids';

export default abstract class TrackAttributor<T extends ITrackedChange> extends Attributor {
  public abstract override value(node: HTMLElement): any;
  protected abstract setChangeMeta(node: HTMLElement, values: T[]): void;

  public static readonly CHANGE_IDS_ATTRIBUTE = CHANGE_IDS_ATTRIBUTE;

  public constructor(attrName: string, keyName: string, options?: any) {
    super(attrName, keyName, options);
  }

  public override add(node: HTMLElement, values: any): boolean {
    if (!this.canAdd(node, values) || !values || !Object.values(values).length) return false;
    this.setValues(node, values);
    return true;
  }

  public override remove(node: HTMLElement): void {
    this.setValues(node, null);
  }

  protected setAttribute(node: HTMLElement, attribute: string, value: any): void {
    if (!value) return node.removeAttribute(attribute);
    if (typeof value !== 'string') value = JSON.stringify(value);
    node.setAttribute(attribute, value);
  }

  protected getAttributeAsObject(node: HTMLElement, attribute: string): any {
    return JSON.parse(node.getAttribute(attribute));
  }

  protected getUserChangeIds(node: HTMLElement, attribute: string): ITrackedChange[] {
    if (!node || !node.getAttribute(attribute)) return null;
    return node.getAttribute(attribute)
      .split(/\s+/)
      .map((value) => UserChangeId.parse(value).toPlainObject());
  }

  private setValues(node: HTMLElement, values: T[]): void {
    values = this.composeValues(node, values);
    this.setUserChangeIds(node, values);
    this.setChangeIds(node);
    this.setChangeMeta(node, values);
  }

  private setUserChangeIds(node: HTMLElement, values: T[]): void {
    const attributeValue = values
      .filter(Boolean)
      .map((change) => new UserChangeId(change.userId, change.changeId, change.pending).toString())
      .join(' ');

    this.setAttribute(node, this.keyName, attributeValue);
  }

  private setChangeIds(node: HTMLElement): void {
    const ids = new Set();

    TRACK_CHANGES_KEYS.forEach((attribute) => {
      const values = this.getUserChangeIds(node, attribute) || [];
      Object.values(values).forEach((id) => id && ids.add(id.changeId));
    });

    const changeIds = Array.from(ids).join(' ');
    this.setAttribute(node, CHANGE_IDS_ATTRIBUTE, changeIds);
  }

  private composeValues(node: HTMLElement, values: Record<string, T[]> | T[]): T[] {
    // If we have legacy values, we can't compose, so just return the new values
    if (Array.isArray(values)) return values;
    if (values === null) return [];
    const composed = AttributeMap.compose(this.value(node), values, false) as Record<string, T>;
    return composed ? Object.values(composed).filter(Boolean) : [];
  }
}
