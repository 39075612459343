<template>
  <div
    class="table-of-contents-content-fragment"
    :class="{active: isActive}"
  >
    <TOCContentFragmentTitle
      v-model="expanded"
      :is-collapsible="isCollapsible"
      :nest-level="nestLevel"
      :toc-entry="tocEntry"
    />

    <ExpandTransition>
      <div
        v-if="isCollapsible && expanded"
      >
        <TOCContentFragment
          v-for="tocEntry in children"
          :key="tocEntry.id"
          :toc-entry="tocEntry"
          :nest-level="nestLevel + 1"
        />
      </div>
    </ExpandTransition>
  </div>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {ITableOfContentsEntry} from '@reedsy/studio.viewer/store/modules/table-of-contents/i-table-of-contents';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import TOCContentFragmentTitle from './toc-content-fragment-title.vue';
import {decodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';
import {PropType} from 'vue';

@Component({
  components: {
    ExpandTransition,
    TOCContentFragmentTitle,
  },
})
export default class TOCContentFragment extends BookViewerVue {
  @Prop({type: Object as PropType<ITableOfContentsEntry>, required: true})
  public tocEntry: ITableOfContentsEntry;

  @Prop({type: Number, required: true})
  public nestLevel: number;

  public expanded = true;

  public get children(): ITableOfContentsEntry[] {
    return this.tocEntry.children;
  }

  public get isCollapsible(): boolean {
    return this.hasChildren;
  }

  public get hasChildren(): boolean {
    return !!this.tocEntry.children?.length;
  }

  public get isActive(): boolean {
    if (!this.$route.params.contentId) return false;

    const contentId = decodeObjectId(this.$route.params.contentId);
    return contentId === this.tocEntry.id;
  }
}
</script>

<style lang="scss" scoped>
.table-of-contents-content-fragment {
  $active-border-width: $space-xs;

  position: relative;

  &.active::before {
    content: '';
    display: block;
    height: $left-sidebar-content-item-height;
    width: $active-border-width ;
    background-color: var(--studio-leftSidebar-activeElementColor);
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
