
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import Footer from './footer/footer.vue';
import Header from './header/header.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class ContentPane extends ClientSharedVue {
  @Prop({type: String})
  public title: string;
}
