
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import {config} from '@reedsy/studio.shared/config';

@Component({})
export default class FeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Boolean, default: false})
  public showTrialPrice: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  public readonly config = config;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }

  public toggleSelected(): void {
    if (!this.isProduct) return;
    this.selected = !this.selected;
  }
}
