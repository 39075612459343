const ID_DELIMITER = ':';

export default class UserChangeId implements IUserChangeId {
  public static parse(s: string): UserChangeId {
    const ids = s.split(ID_DELIMITER);
    return new this(ids[1], ids[2], ids[3]);
  }

  public constructor(
    public readonly userId: string,
    public readonly changeId: string,
    public readonly pending?: string,
  ) {}

  public toString(): string {
    if (!this.userId || !this.changeId) return '';
    let s = `${ID_DELIMITER}${this.userId}${ID_DELIMITER}${this.changeId}`;
    if (this.pending) s += `${ID_DELIMITER}${this.pending}`;
    return s;
  }

  public toPlainObject(): IUserChangeId {
    if (!this.userId || !this.changeId) return null;
    const obj: IUserChangeId = {
      userId: this.userId,
      changeId: this.changeId,
    };
    if (this.pending) obj.pending = this.pending;
    return obj;
  }
}

export interface IUserChangeId {
  userId: string;
  changeId: string;
  pending?: string;
}
