import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';
import storeLinkConfig, {IStoreLinkConfig} from './store-link-config';

export default class StoreLinkTemplate extends JsxComponent {
  public override $props: {
    isEditing: boolean;
    links: ILinks;
    readOnly: boolean;
  };

  public render(): HTMLElement {
    return (
      <div class="store-link-editor">
        {storeLinkConfig.map((link) => this.displayedLink(link))}
      </div>
    );
  }

  private displayedLink(link: IStoreLinkConfig): HTMLElement {
    if (this.$props.readOnly && !this.$props.links[link.key]) return;

    const linkContents = this.$props.isEditing ?
      <input
        name={link.key}
        is="url-input"
        placeholder={`${link.placeholder || link.label} URL`}
        value={this.$props.links[link.key]}
      /> :
      <a
        class={`${link.key} ellipsis`}
        target="_blank"
        href={this.$props.links[link.key]}
        title={this.$props.links[link.key]}
      >
        {this.$props.links[link.key]}
      </a>;

    return (
      <div class="link-row">
        <label for={link.key}>
          {link.label}
        </label>
        {linkContents}
      </div>
    );
  }
}
