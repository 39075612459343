
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import {PropType} from 'vue';
import {IAlsoByBook} from '@reedsy/reedsy-sharedb/lib/common/book-content/also-by-book';
import {opsToString} from '@reedsy/studio.isomorphic/utils/rich-text/ops-to-string';

@Component({
  components: {
    RichTextReader,
  },
})
export default class AlsoByBook extends BookViewerVue {
  @Prop({type: Object as PropType<IAlsoByBook>, required: true})
  public book: IAlsoByBook;

  public get title(): string {
    return opsToString(this.book.title.ops).trim();
  }

  public get blurbRichTextId(): string {
    return this.book.blurbId;
  }

  public get links(): string[] {
    return Object.values(this.book.storeLinks)
      .filter((link) => !!link.trim());
  }

  public get hasLinks(): boolean {
    return !!this.links.length;
  }

  public get hasCover(): boolean {
    return !!this.book.cover;
  }
}
