import {AppTheme} from '@reedsy/studio.shared/store/modules/user-settings/app-theme';
import {AppThemeBasePluginFactory} from '@reedsy/studio.shared/store/plugins/app-theme/app-theme-base';
import {$inject} from '@reedsy/studio.viewer/types';
import {injectable, named} from 'inversify';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';

@injectable()
export class AppThemePluginFactory extends AppThemeBasePluginFactory {
  @$inject('StoreModule')
  @named(StoreName.BookViewerSettings)
  public $settings: BookViewerSettingsModule;

  protected override get rootThemeClass(): AppTheme {
    return this.$settings.rootThemeClass;
  }
}
