import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import EndnotesMixin from '@reedsy/studio.shared/mixins/endnotes';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

@Component({})
export default class BookViewerEndnotesMixin extends mixins(
  EndnotesMixin,
  BookViewerVue,
) {
  @$lazyInjectStore(StoreName.BookViewerRichText)
  public $richText: BookViewerRichTextModule;

  public override get endnoteStartNumber(): number {
    const firstEndnoteId = this.endnoteIds[0];
    if (!firstEndnoteId) return 1;
    return this.$richText.endnotesNumber[firstEndnoteId];
  }

  public override get shouldUsePlaceholder(): boolean {
    const firstEndnoteId = this.endnoteIds[0];
    if (!firstEndnoteId) return true;

    return !this.$richText.endnotesNumber[firstEndnoteId];
  }
}
