import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "feature-summaries" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeriodToggle = _resolveComponent("PeriodToggle")!
  const _component_FeatureSummary = _resolveComponent("FeatureSummary")!
  const _component_AnnualSaving = _resolveComponent("AnnualSaving")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
    id: _ctx.id,
    title: _ctx.title,
    class: "subscription-features-modal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-subscription-upgrade", null, [
        _createElementVNode("section", null, [
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "\n          Unlock Studio with powerful add-ons.\n        ", -1)),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createVNode(_component_PeriodToggle, {
            modelValue: _ctx.billAnnually,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billAnnually) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _cache[5] || (_cache[5] = _createTextVNode()),
        _createElementVNode("section", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries, (summary) => {
            return (_openBlock(), _createBlock(_component_FeatureSummary, {
              key: summary.product,
              ref_for: true,
              ref: summary.product,
              modelValue: _ctx.selectedProducts[summary.product],
              "onUpdate:modelValue": ($event: any) => ((_ctx.selectedProducts[summary.product]) = $event),
              summary: summary
            }, null, 8, ["modelValue", "onUpdate:modelValue", "summary"]))
          }), 128))
        ]),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createElementVNode("section", null, [
          _createElementVNode("span", null, [
            _createVNode(_component_AnnualSaving),
            _cache[3] || (_cache[3] = _createTextVNode("\n          when you purchase multiple add-ons\n        "))
          ]),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _createElementVNode("button", {
            ref: "continue",
            class: "button reedsy-accented",
            type: "button",
            disabled: !_ctx.hasSelectedAnything
          }, "\n          Continue\n        ", 8, _hoisted_2)
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "title"]))
}