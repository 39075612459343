import Inline from '@reedsy/quill/blots/inline';
import Script from '@reedsy/quill/formats/script';

/**
 * This is a special blot that is only ever represented in the DOM, and
 * never in the Delta. It's used for transient highlights, like search
 * highlighting.
 */
export default class HighlightBlot extends Inline {
  public static override tagName = 'MARK';
  public static override blotName = 'highlight';

  public static override create(value: any): HTMLElement {
    const node = super.create(value) as HTMLElement;
    node.classList.add(value.nodeClass);
    return node;
  }

  public static override formats(_node: Node): any {
    // Prevent this blot from showing up in the contents Delta
    return null;
  }

  public override unwrap(force?: boolean): void {
    // Prevent Quill from removing the blot, but allow us to manually override
    if (force || this.isInsideScript()) super.unwrap();
  }

  // Changing to/from super/subscript changes spelling/search results,
  // So allow unwrapping in this case
  private isInsideScript(): boolean {
    let node = this.parent;
    while (node) {
      if (node instanceof Script) return true;
      node = node.parent;
    }
    return false;
  }
}
