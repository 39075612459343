import ComplexInlineBlot from './complex-inline-blot';

export const IGNORE_SPELLING_WORD_ATTRIBUTE = 'ignore-spelling-word';
export default class IgnoreSpelling extends ComplexInlineBlot {
  public static override readonly blotName = 'ignore-spelling';
  public static override readonly className = 'ql-ignore-spelling';

  public static override create(value: IIgnoreSpellingValue): HTMLElement {
    const node: any = super.create(value) as HTMLElement;
    node.setAttribute(this.blotName, Object.keys(value.userIds).join(' '));
    node.setAttribute(IGNORE_SPELLING_WORD_ATTRIBUTE, value.ignoredWord);
    return node;
  }

  public static override formats(node: HTMLElement): IIgnoreSpellingValue {
    const ignoredAttribute = node.getAttribute(this.blotName);
    if (!ignoredAttribute) return null;
    const userIds = ignoredAttribute.split(' ');
    const ids: Record<string, boolean> = {};
    userIds.forEach((id) => ids[id] = true);
    return {
      userIds: ids,
      ignoredWord: node.getAttribute(IGNORE_SPELLING_WORD_ATTRIBUTE),
    };
  }
}

export interface IIgnoreSpellingValue {
  userIds: Record<string, boolean>;
  ignoredWord: string;
}
