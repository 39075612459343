
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {getOrderNumberLiteral} from '@reedsy/studio.viewer/utils/get-order-number-literal';
import {Theme} from '@reedsy/studio.shared/models/settings';
import {
  SeparatorComponents,
  SeparatorComponentsMap,
} from '@reedsy/studio.viewer/components/chapter-separators/separators';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';

@Component({
  components: {
    SkeletonLoader,
    ...SeparatorComponents,
  },
})
export default class SectionTitle extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerSettings)
  public $settings: BookViewerSettingsModule;

  public get content(): ContentFragment {
    return this.$displayedData.content;
  }

  public get orderNumber(): number {
    return this.$displayedData.orderNumber;
  }

  public get shouldDisplayOrderNumber(): boolean {
    return this.$displayedData.shouldDisplayOrderNumber;
  }

  public get orderLiteral(): string {
    return getOrderNumberLiteral(this.content.data.type, this.orderNumber);
  }

  public get title(): string {
    return this.$displayedData.title;
  }

  public get shouldShowTitle(): boolean {
    return !!this.title;
  }

  public get shouldShowSeparator(): boolean {
    return this.$displayedData.isBodyMatter && !!this.separatorComponent && this.isChapter;
  }

  public get separatorComponent(): string {
    return SeparatorComponentsMap[this.theme];
  }

  public get theme(): Theme {
    return this.$settings.theme;
  }

  public get isChapter(): boolean {
    return this.content.data.type === MatterType.Chapter;
  }
}
