
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ScreenEdgeArrow from '@reedsy/studio.shared/components/screen-edge-arrow/screen-edge-arrow.vue';

@Component({
  components: {
    ScreenEdgeArrow,
  },
})
export default class PullLoader extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public down: boolean;

  @Prop({type: Number, required: true})
  public percentage: number;

  @Prop({type: String, default: ''})
  public text: string;
}
