export interface IGetBrowserTitleRequest {
  bookTitle: string;
  contentTitle: string;
}

export function getBrowserTitle({
  bookTitle,
  contentTitle,
}: IGetBrowserTitleRequest): string {
  return [contentTitle, bookTitle].filter(Boolean).join(' - ');
}
