import {Container} from 'inversify';
import {BookViewerBindings, getBinding} from './types';
import {PLUGINS} from './store/plugins';
import BookViewerApi from './services/api';
import IBookViewerStoreMapping, {BOOK_VIEWER_STORE_FACTORY_MAPPING} from './store/book-viewer-store-mapping';
import {bindBookViewerConstants} from './bind-book-viewer-constants';
import {BookViewerLocalStorageHelper} from './utils/book-viewer-local-storage-helper';
import {bindModules} from '@reedsy/studio.shared/store/bind-modules';
import {TypedContainer} from '@reedsy/utils.inversify';
import {$getLazyDecorators} from '@reedsy/utils.inversify';
import {TypedDecorator} from '@reedsy/utils.types';
import Navigation from '@reedsy/studio.shared/services/navigation';

export const bookViewerContainer: TypedContainer<BookViewerBindings> = new Container();

PLUGINS.forEach((plugin) => {
  bookViewerContainer.bind('BookViewerStorePlugins')
    .to(plugin);
});

bindBookViewerConstants(bookViewerContainer);

bookViewerContainer.bind('Store')
  .toDynamicValue(({container}) => {
    const wrapper = getBinding('StoreWrapper', container);
    return wrapper.store;
  });

// It needs to be a constant value as the parent has different
// number of constructor args
bookViewerContainer
  .bind('LocalStorageHelper')
  .toConstantValue(new BookViewerLocalStorageHelper());

bookViewerContainer
  .bind('Api')
  .to(BookViewerApi);
bookViewerContainer
  .bind('Navigation')
  .to(Navigation);

bindModules(bookViewerContainer, BOOK_VIEWER_STORE_FACTORY_MAPPING);

export const {$lazyInject, $lazyInjectNamed} = $getLazyDecorators(bookViewerContainer);
export function $lazyInjectStore<K extends keyof IBookViewerStoreMapping>(
  key: K,
): TypedDecorator<IBookViewerStoreMapping[K]> {
  return $lazyInjectNamed('StoreModule', key);
}
