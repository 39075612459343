import SoftBreakBlot from './blots/soft-break-blot';
import Figures from './modules/figures';
import Links from './modules/links';
import SceneBreaks from './modules/scene-breaks';
import TrackChanges from './modules/track-changes';
import {AllowedBlot, AllowedFormat, BLOTS, ReedsyRegistry} from './registry';

const PLAIN_TEXT_BLOTS = Object.freeze(BLOTS.filter((blot) => blot !== SoftBreakBlot));

export class RegistryBuilder {
  private allowedBlots: ReadonlyArray<AllowedBlot> = null;
  private allowedFormats: ReadonlyArray<AllowedFormat> = null;

  public withBlots(allowedBlots: ReadonlyArray<AllowedBlot>): this {
    this.allowedBlots = allowedBlots;
    return this;
  }

  public withFormats(allowedFormats: ReadonlyArray<AllowedFormat>): this {
    this.allowedFormats = allowedFormats;
    return this;
  }

  public default(): ReedsyRegistry {
    return this.build();
  }

  public inline(): ReedsyRegistry {
    return this
      .withFormats([
        'bold',
        'clean',
        'italic',
        'script',
        'strike',
        'underline',
      ])
      .build();
  }

  public plainText(): ReedsyRegistry {
    return this
      .withFormats([])
      .withBlots(PLAIN_TEXT_BLOTS)
      .build();
  }

  public all(): ReedsyRegistry {
    const registry = this.default();
    Figures.registerFormats(registry);
    SceneBreaks.registerFormats(registry);
    TrackChanges.registerFormats(registry);
    Links.registerFormats(registry);
    return registry;
  }

  public build(): ReedsyRegistry {
    return new ReedsyRegistry()
      .withBlots(this.allowedBlots)
      .withFormats(this.allowedFormats);
  }
}
