import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import Delta from '@reedsy/quill-delta';
import {EmitterSource} from '@reedsy/quill/core/emitter';
import {type as richText} from '@reedsy/rich-text';

export default class LiveRange {
  private readonly quill: Quill;
  private range: Range;
  private changeHandler: (delta: Delta, oldContents: Delta, source: EmitterSource) => void;

  public constructor(quill: Quill, range: Range) {
    this.quill = quill;
    this.range = range;
    this.changeHandler = this.transformRange.bind(this);

    this.quill.on(Quill.events.TEXT_CHANGE, this.changeHandler);
  }

  public get(): Range {
    this.quill.off(Quill.events.TEXT_CHANGE, this.changeHandler);
    return this.range ? {...this.range} : null;
  }

  private transformRange(delta: Delta, oldContents: Delta, source: EmitterSource): void {
    this.range = richText.transformPresence(this.range, delta, source === Quill.sources.USER);
  }
}
