import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {TitleGenerator} from '@reedsy/studio.isomorphic/utils/book-content/title-generator';

export function getBookFirstPageId(flatBookContent: ContentFragment[]): string {
  let firstPageId = getFirstChapterOrPartWithTitle(flatBookContent);

  if (!firstPageId) {
    firstPageId = getFirstChapterOrPart(flatBookContent);
  }

  if (!firstPageId) {
    firstPageId = getFirstMatterThatIsNotCopyrights(flatBookContent);
  }

  if (!firstPageId) {
    firstPageId = getCopyrights(flatBookContent);
  }

  return firstPageId || null;
}

function getFirstChapterOrPartWithTitle(flatBookContent: ContentFragment[]): string {
  const titleGenerator = new TitleGenerator(flatBookContent, '');
  return flatBookContent.find((fragment) => {
    return (
      (
        fragment.data.type === MatterType.Part ||
        fragment.data.type === MatterType.Chapter
      ) &&
      titleGenerator.title(fragment)
    );
  })?.id;
}

function getFirstChapterOrPart(flatBookContent: ContentFragment[]): string {
  return flatBookContent.find((fragment) => (
    (
      fragment.data.type === MatterType.Part ||
      fragment.data.type === MatterType.Chapter
    )
  ))?.id;
}

function getFirstMatterThatIsNotCopyrights(flatBookContent: ContentFragment[]): string {
  return flatBookContent.find(
    (fragment) => fragment.data.type !== MatterType.Copyright,
  )?.id;
}

function getCopyrights(flatBookContent: ContentFragment[]): string {
  return flatBookContent.find(
    (fragment) => fragment.data.type === MatterType.Copyright,
  )?.id;
}
