import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-settings-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiDropdown = _resolveComponent("VuiDropdown")!
  const _component_SettingsField = _resolveComponent("SettingsField")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_VuiToggle = _resolveComponent("VuiToggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SettingsField, { label: "Theme" }, {
      default: _withCtx(() => [
        _createVNode(_component_VuiDropdown, {
          modelValue: _ctx.themeOption,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.themeOption) = $event)),
          class: "sm theme-option-dropdown",
          options: _ctx.dropdownThemeOptions,
          "avoid-auto-positioning": "",
          underlined: ""
        }, null, 8, ["modelValue", "options"])
      ]),
      _: 1
    }),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createVNode(_component_SettingsField, { label: "Font size" }, {
      default: _withCtx(() => [
        _createVNode(_component_Slider, {
          modelValue: _ctx.fontSize,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fontSize) = $event)),
          data: _ctx.fontSizeData,
          class: "font-size-slider"
        }, null, 8, ["modelValue", "data"])
      ]),
      _: 1
    }),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createVNode(_component_SettingsField, { label: "Line height" }, {
      default: _withCtx(() => [
        _createVNode(_component_Slider, {
          modelValue: _ctx.lineHeight,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lineHeight) = $event)),
          data: _ctx.lineHeightData,
          class: "line-height-slider"
        }, null, 8, ["modelValue", "data"])
      ]),
      _: 1
    }),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createVNode(_component_SettingsField, { label: "Justify text" }, {
      default: _withCtx(() => [
        _createVNode(_component_VuiToggle, {
          modelValue: _ctx.isTextJustified,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isTextJustified) = $event)),
          title: "Justify text",
          class: "justify-text-toggle label-left spread"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}