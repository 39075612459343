
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import {bookContentStructure} from '@reedsy/reedsy-sharedb/lib/server/book-contents-structure';
import {collaboratorCredit} from '@reedsy/reedsy-sharedb/lib/common/book-content/collaborator-credit';
import SectionTitle from '@reedsy/studio.viewer/components/section-title/section-title.vue';
import PageContainer from '@reedsy/studio.viewer/components/page-container/page-container.vue';
import editions from '@reedsy/studio.shared/utils/book-content/editions';
import {ICopyright} from '@reedsy/reedsy-sharedb/lib/common/book-content/copyright';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

type ICollaborators = ICopyright['settings']['collaborators'];

@Component({
  components: {
    RichTextReader,
    SectionTitle,
    PageContainer,
  },
})
export default class Copyright extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  @$lazyInjectStore(StoreName.BookViewerRichText)
  public $richText: BookViewerRichTextModule;

  public get copyright(): ICopyright {
    return this.$bookContent.data.contents.copyright;
  }

  public get customClauseRichTextId(): string {
    return this.copyright.references.customClause.id;
  }

  public get isCustomClauseEmpty(): boolean {
    return this.$richText.isEmpty(this.customClauseRichTextId);
  }

  public get isCustomClauseEnabled(): boolean {
    return this.copyright.settings.enabledClauses.customClause;
  }

  public get publisherName(): string {
    return this.copyright.settings.publisher.name;
  }

  public get publicationYear(): string {
    return this.copyright.settings.publicationYear;
  }

  public get publisherLogoUrl(): string {
    return this.copyright.settings.publisher.logo;
  }

  public get hasPublisherLogo(): boolean {
    return !!this.publisherLogoUrl;
  }

  public get copyrightClauses(): any[] {
    return bookContentStructure.copyrightClauses
      .filter((clause) => (this.copyright.settings.enabledClauses as any)[clause.key])
      .map((clause: any) => ({
        key: clause.key,
        content: clause.content.replace(/\\@author\\/g, this.authorName),
      }));
  }

  public get authorName(): string {
    return this.$bookContent.authorName;
  }

  public get editionsTexts(): Record<number, string> {
    const editionsTexts: Record<number, string> = {};
    editions.forEach((editionDetails) => {
      editionsTexts[Number(editionDetails.value)] = editionDetails.text;
    });

    return editionsTexts;
  }

  public get currentEditionText(): string {
    return this.editionsTexts[this.copyright.settings.edition];
  }

  public get collaboratorsCredits(): string[] {
    return this.collaborators
      .map(collaboratorCredit);
  }

  public get hasCollaborators(): boolean {
    return !!this.collaborators.length;
  }

  public get collaborators(): ICollaborators {
    return this.copyright
      .settings
      .collaborators || [];
  }
}
