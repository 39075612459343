import {BlockEmbed} from '@reedsy/quill/blots/block';
import Scroll from '@reedsy/quill/blots/scroll';
import ActionBar from '@reedsy/studio.shared/services/quill/helpers/action-bar/action-bar';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import Quill from '@reedsy/quill/core';
import {makeElementDraggable} from '@reedsy/studio.shared/services/quill/helpers/drag-and-drop';
import findQuill from '@reedsy/studio.shared/services/quill/helpers/find-quill';

const {BLOCK} = TrackChangesKeys;

export default class SceneBreak extends BlockEmbed {
  public static override readonly blotName = 'scene-break';
  public static override readonly className = 'ql-scene-break';
  public static override readonly tagName = 'DIV';

  public static readonly classes = Object.freeze({
    SELECTED: 'selected',
    READ_ONLY: 'read-only',
  });

  public override domNode: HTMLElement;
  private readonly quill: Quill;
  private readonly isReadOnly: boolean;

  public constructor(scroll: Scroll, domNode: HTMLElement) {
    super(scroll, domNode);
    this.quill = findQuill(scroll.domNode);
    this.isReadOnly = this.quill.options.readOnly;

    if (this.isReadOnly) {
      this.domNode.classList.add(SceneBreak.classes.READ_ONLY);
      return;
    }

    const actionBar = new ActionBar({
      remove: () => this.remove(),
    });
    makeElementDraggable(this.domNode, this.isTrackedDeletion.bind(this));
    domNode.addEventListener('click', this.handleClick.bind(this));
    domNode.prepend(actionBar.element);
    domNode.draggable = true;
    domNode.contentEditable = 'false';
  }

  private get isSelected(): boolean {
    return this.domNode.classList.contains(SceneBreak.classes.SELECTED);
  }

  public isTrackedDeletion(): boolean {
    return !!this.attributes.values()[BLOCK.DELETION];
  }

  private handleClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.isTrackedDeletion()) return;
    if (!this.isSelected) window.addEventListener('click', () => this.select(false), {once: true});
    this.select(!this.isSelected);
  }

  private select(selected: boolean): void {
    this.domNode.classList.toggle(SceneBreak.classes.SELECTED, selected);
  }
}
