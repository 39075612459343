import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "title-page-header" }
const _hoisted_2 = { class: "author-name theme-body" }
const _hoisted_3 = {
  key: 0,
  class: "book-title theme-header"
}
const _hoisted_4 = {
  key: 1,
  class: "book-subtitle theme-subheader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.authorName), 1),
    _cache[0] || (_cache[0] = _createTextVNode()),
    (_ctx.bookTitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.bookTitle), 1))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (_ctx.bookSubtitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.bookSubtitle), 1))
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "divider" }, null, -1))
  ]))
}