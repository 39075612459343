export default class EventListeners {
  private isListening = false;
  private start = 'addEventListener';
  private stop = 'removeEventListener';

  public constructor(
    private readonly target: any,
    private readonly listeners: {[event: string]: any},
  ) {}

  public bindListeners(thisReference: any): this {
    Object.keys(this.listeners).forEach((event) => this.listeners[event] = this.listeners[event].bind(thisReference));
    return this;
  }

  public startListening(): void {
    if (this.isListening) return;
    this.isListening = true;
    Object.keys(this.listeners).forEach((event) => this.target[this.start](event, this.listeners[event]));
  }

  public stopListening(): void {
    this.isListening = false;
    Object.keys(this.listeners).forEach((event) => this.target[this.stop](event, this.listeners[event]));
  }

  public useOnOff(): this {
    this.start = 'on';
    this.stop = 'off';
    return this;
  }
}
