import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import ComplexInlineBlot from '@reedsy/studio.shared/services/quill/blots/complex-inline-blot';

const {CHANGE, INLINE} = TrackChangesKeys;
const BREAK_CLASS = 'track-block-break';

export default class TrackChange extends ComplexInlineBlot {
  public static override readonly blotName = CHANGE;
  public static override readonly className = 'ql-track-change';
  public static readonly breakClass = BREAK_CLASS;

  public static override create(value: any): HTMLElement {
    const node = super.create(value) as HTMLElement;
    node.setAttribute(CHANGE, value);
    return node;
  }

  public static override formats(domNode: HTMLElement): boolean {
    return domNode.getAttribute(CHANGE) === 'true';
  }

  // Override the default insertAt behaviour with the same behaviour as
  // Parchment.Text.insertAt. We want to copy this behaviour, because
  // sometimes embeds are inserted in the middle of track changes, where
  // they're not wanted. In these cases, we should split the TrackChanges
  // blot around the embed.
  public override insertAt(index: number, value: string, def?: any): void {
    const blot = def == null ?
      this.scroll.create('text', value) :
      this.scroll.create(value, def);
    const ref = this.split(index);
    this.parent.insertBefore(blot, ref);
  }

  public override optimize(context: any): void {
    super.optimize(context);
    this.addBreakToParent();
  }

  // If we've deleted inline content, we need to add a span with a no-break space
  // so that - if the user hides deletions - the empty line has some content in it
  // for the cursor to target (like how a new Block will contain a Break).
  private addBreakToParent(): void {
    this.moveBreakToBeginningOfParent();
    if (!this.shouldAddBreak()) return;

    const el = document.createElement('span');
    el.classList.add(BREAK_CLASS);
    el.innerHTML = '&#65279;';
    el.contentEditable = 'false';
    this.parent.domNode.prepend(el);
  }

  private moveBreakToBeginningOfParent(): void {
    const br = this.parentBreak();
    if (br && br.previousSibling) this.parent.domNode.prepend(br);
  }

  private shouldAddBreak(): boolean {
    return this.domNode.hasAttribute(INLINE.DELETION) && !this.parentBreak();
  }

  private parentBreak(): HTMLElement {
    return this.parent.domNode.querySelector(`.${BREAK_CLASS}`);
  }
}
