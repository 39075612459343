import {Scope} from 'parchment';
import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import Toolbar from '@reedsy/quill/modules/toolbar';
import toolbarContainer, {CYCLING_CLASS, HEADER_VALUES, ALIGN_VALUES, SCRIPT_VALUES, QUOTE_FORMATS} from './toolbar-container';
import TrackChanges from '@reedsy/studio.shared/services/quill/modules/track-changes';
import ReedsyAlign, {ALLOWED_BLOTS} from '@reedsy/studio.shared/services/quill/formats/align';
import Link from '@reedsy/studio.shared/services/quill/modules/links/base-link';
import Links from '@reedsy/studio.shared/services/quill/modules/links';

const ALLOWED_ALIGN_FORMATS = Object.freeze([
  ReedsyAlign.attrName,
  ...ALLOWED_BLOTS.map((blot) => blot.blotName),
]);

export default class ReedsyToolbar extends Toolbar {
  public static readonly classes = Object.freeze({
    ACTIVE: 'ql-active',
    HIDE_FORMAT: 'hide-format',
    NO_BLOCK: 'no-block',
    NO_INLINE: 'no-inline',
  });

  public constructor(quill: Quill, options: any) {
    options.container = toolbarContainer();
    quill.container.parentNode.insertBefore(options.container, quill.container);

    super(quill, {
      ...options,
      handlers: {
        ...options.handlers,
        clean: () => this.handleParagraphButton.call(this),
        header: () => this.updateHeader.call(this),
        align: () => this.updateAlign.call(this),
        script: () => this.updateScript.call(this),
        italic: (value: boolean) => this.updateItalics.call(this, value),
        'track-comment': () => this.addComment.call(this),
        link: (value: boolean) => this.updateLink.call(this, value),
        blockquote: () => this.updateQuote.call(this),
        callout: () => this.updateQuote.call(this),
      },
    });
  }

  public override update(range: Range): void {
    if (!range) return;
    super.update(range);
    this.updateCleanButton();
    this.updateItalicButton(range);
    this.updateCyclingButtons();
    this.setBlockFormatVisibility(range);
    this.disableUnsupportedInlineFormats(range);
    this.updateUnderlineButton(range);
  }

  private clearBlockFormatting(options: IClearBlockFormattingOptions = {}): void {
    const ignoredFormats = new Set(options.ignore);
    // FIXME: https://github.com/quilljs/quill/pull/3731
    const formats = this.quill.getFormat(this.quill.getSelection(true));
    Object.keys(formats).forEach((name) => {
      if (ignoredFormats.has(name)) return;
      if (this.quill.scroll.query(name, Scope.BLOCK)) {
        this.quill.format(name, false, Quill.sources.USER);
      }
    });
  }

  private handleParagraphButton(): void {
    this.clearBlockFormatting();
  }

  private updateHeader(): void {
    this.updateCyclingFormat('header', HEADER_VALUES);
  }

  private updateAlign(): void {
    this.clearBlockFormatting({ignore: ALLOWED_ALIGN_FORMATS});
    this.updateCyclingFormat('align', ALIGN_VALUES);
  }

  private updateScript(): void {
    this.updateCyclingFormat('script', SCRIPT_VALUES);
  }

  private updateItalics(value: boolean): void {
    const range = this.quill.getSelection();
    if (this.isItalicByDefault(range)) value = !value;
    this.quill.format('italic', value, Quill.sources.USER);
  }

  private updateLink(value: boolean): void {
    if (!value) this.quill.format('link', false, Quill.sources.USER);
    else {
      const links = this.quill.getModule('links') as Links;
      links.openLinkEditor();
    };
  }

  private updateQuote(): void {
    const formats = this.selectionFormats();
    this.clearBlockFormatting();
    let newFormat = QUOTE_FORMATS[0];
    QUOTE_FORMATS.forEach((format, index) => {
      if (formats[format]) newFormat = QUOTE_FORMATS[index + 1];
    });
    if (newFormat) this.quill.format(newFormat, true, Quill.sources.USER);
  }

  private addComment(): void {
    const trackChanges = this.quill.getModule('track-changes') as TrackChanges;
    trackChanges.addComment();
  }

  private updateCyclingFormat(format: string, values: any[]): void {
    values = values.slice();
    if (!!values[0]) values.unshift(false);
    const formats = this.selectionFormats();
    const currentValue = formats[format];
    let newValueIndex = 1;
    if (currentValue) {
      const currentValueIndex = values.indexOf(currentValue);
      newValueIndex = currentValueIndex + 1;
    }
    const newValue = values[newValueIndex];
    this.quill.format(format, newValue, Quill.sources.USER);
  }

  private updateCyclingButtons(): void {
    const cyclingButtons = this.container.getElementsByClassName(CYCLING_CLASS.BUTTON);
    Array.from(cyclingButtons).forEach((cycle: HTMLElement) => this.updateCyclingButton(cycle));
  }

  private updateCyclingButton(cycle: HTMLElement): void {
    const buttons = cycle.querySelectorAll('button');
    const indicators = cycle.getElementsByClassName(CYCLING_CLASS.INDICATOR);
    let hasActiveButton = false;
    Array.from(buttons).forEach((button: HTMLElement, index: number) => {
      const isActive = button.classList.contains(ReedsyToolbar.classes.ACTIVE);
      button.style.display = isActive ? 'block' : 'none';
      hasActiveButton = hasActiveButton || isActive;
      indicators[index].classList.toggle('active', isActive);
    });
    if (!hasActiveButton) (buttons[0] as HTMLElement).style.display = 'block';
  }

  private updateCleanButton(): void {
    const activeBlockButtons = this.container.querySelectorAll(
      '.ql-formats-block button.ql-active:not(.ql-clean):not(.ql-align)',
    );

    const isClean = !activeBlockButtons.length;
    const cleanButton = this.container.querySelector('.ql-clean');
    cleanButton.classList.toggle(ReedsyToolbar.classes.ACTIVE, isClean);
  }

  private updateItalicButton(range: Range): void {
    if (!this.isItalicByDefault(range)) return;
    const italicButton = this.container.querySelector('.ql-italic');
    italicButton.classList.toggle(ReedsyToolbar.classes.ACTIVE);
  }

  private updateUnderlineButton(range: Range): void {
    const formats = this.rangeFormats(range);
    const linkSelected = formats.has(Link.blotName);
    const underlineButton = this.container.querySelector('.ql-underline') as HTMLButtonElement;
    underlineButton.disabled = underlineButton.disabled || linkSelected;
    if (linkSelected) underlineButton.classList.remove(ReedsyToolbar.classes.ACTIVE);
  }

  private disableUnsupportedInlineFormats(range: Range): void {
    this.enableInlineFormats();
    this.hideButtonsIfMissingModule('track-comment', 'track-changes');
    this.hideButtonsIfMissingModule('link', 'links');
    const formatNames = this.rangeFormats(range);
    formatNames.forEach((format) => {
      const blot = this.quill.scroll.query(format) as any;
      const allowedFormats: any[] = blot && blot.allowedChildren;
      this.disableInlineFormats(allowedFormats);
    });
    this.container.parentElement.classList.toggle(ReedsyToolbar.classes.NO_INLINE, this.allInlineButtonsAreDisabled());
  }

  private enableInlineFormats(): void {
    this.inlineButtons().forEach((button: HTMLButtonElement) => button.disabled = false);
  }

  private disableInlineFormats(allowedFormats: any[]): void {
    this.inlineButtons().forEach((button: HTMLButtonElement) => {
      const format = this.buttonFormat(button);
      if (!this.isAllowed(format, allowedFormats)) button.disabled = true;
    });
  }

  private allInlineButtonsAreDisabled(): boolean {
    return this.inlineButtons().every((button) => button.disabled || button.classList.contains('ql-track-comment'));
  }

  private inlineButtons(): HTMLButtonElement[] {
    const buttons = this.container.querySelectorAll('.ql-formats-inline button');
    return Array.from(buttons) as HTMLButtonElement[];
  }

  private blockButtons(): HTMLButtonElement[] {
    const buttons = this.container.querySelectorAll('.ql-formats-block button');
    return Array.from(buttons) as HTMLButtonElement[];
  }

  private buttonFormat(button: HTMLElement): any {
    let format: any;
    button.classList.forEach((className) => {
      if (format) return;
      const formatName = className.split('ql-')[1];
      format = this.quill.scroll.query(formatName);
    });
    return format;
  }

  private isAllowed(format: any, allowedFormats: any[]): boolean {
    return !allowedFormats ||
      !allowedFormats.length ||
      allowedFormats.some((allowedFormat) =>
        format === allowedFormat ||
        (format && format.prototype instanceof allowedFormat));
  }

  private isItalicByDefault(range: Range): boolean {
    return this.hasFormat(range, 'blockquote') ||
      this.hasFormat(range, 'caption');
  }

  private hasFormat(range: Range, format: string): boolean {
    const formats = this.quill.getFormat(range);
    return Object.keys(formats).some((f) => f === format);
  }

  private setBlockFormatVisibility(range: Range): void {
    const blockButtons = this.blockButtons();
    const isCaption = this.rangeFormats(range).has('caption');
    const blockFormatsDisabled = isCaption || blockButtons
      .every((button) => !this.buttonFormat(button) || button.classList.contains('ql-track-comment'));
    this.container.parentElement.classList.toggle(ReedsyToolbar.classes.NO_BLOCK, blockFormatsDisabled);
  }

  private selectionFormats(): any {
    const range = this.quill.getSelection();
    return this.quill.getFormat(range);
  }

  private rangeFormats(range: Range): Set<string> {
    const formats = this.quill.getFormat(range);
    const formatNames = Object.keys(formats);
    return new Set(formatNames);
  }

  private hideButtonsIfMissingModule(format: string, moduleName: string): void {
    const buttons = this.container.querySelectorAll(`.ql-${format}`) as NodeListOf<HTMLButtonElement>;
    for (const button of buttons) {
      button.classList.toggle(ReedsyToolbar.classes.HIDE_FORMAT, !this.quill.getModule(moduleName));
    }
  }
}

interface IClearBlockFormattingOptions {
  ignore?: readonly string[];
}
