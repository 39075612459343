
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';

@Component({})
export default class SettingsField extends BookViewerVue {
  @Prop({type: Boolean, default: false})
  public column: boolean;

  @Prop({type: String, required: true})
  public label: string;
}
