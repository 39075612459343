import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {isReadableContentFragment} from './is-readable-content-fragment';

export function getContentFragmentAfter(
  flatBookContent: IContentFragment[],
  currentContentId: string,
): IContentFragment {
  let foundCurrentContent = !currentContentId;

  for (const contentFragment of flatBookContent) {
    if (foundCurrentContent && isReadableContentFragment(contentFragment)) {
      return contentFragment;
    }

    foundCurrentContent ||= contentFragment.id === currentContentId;
  }
  return null;
}
