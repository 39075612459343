import {injectable} from 'inversify';
import {BookViewerRouterPlugin} from '@reedsy/studio.viewer/store/book-viewer-router-plugin';
import {bookViewerContainer} from '@reedsy/studio.viewer/inversify.config';
import {IBookViewerRoute} from '@reedsy/studio.viewer/router/i-book-viewer-route';

@injectable()
export default class InitLocalStorageHelperPluginFactory extends BookViewerRouterPlugin {
  public override async beforeEach(to: IBookViewerRoute): Promise<void> {
    const localStorageHelper = bookViewerContainer.get('LocalStorageHelper');

    if (localStorageHelper.isInitialised) return;

    localStorageHelper.initialise(to.params.shareableUrlShortId);
  }
}
