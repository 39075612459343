import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "endnote-pane-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "endnote-pane"
}
const _hoisted_3 = {
  id: "endnote-pane-content",
  class: "endnote-pane-content"
}
const _hoisted_4 = { class: "endnote-controls" }
const _hoisted_5 = { class: "endnote-number" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "close-button"
}
const _hoisted_8 = { class: "close-icon" }
const _hoisted_9 = {
  key: 1,
  class: "close-button save"
}
const _hoisted_10 = { class: "close-icon" }
const _hoisted_11 = {
  id: "endnote-container",
  class: "endnote-container theme-body editor-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_VuiIconCross = _resolveComponent("VuiIconCross")!
  const _component_VuiIconTick = _resolveComponent("VuiIconTick")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock(_component_Overlay, {
              key: 0,
              class: "overlay",
              onClick: _ctx.close
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createVNode(_Transition, { name: "endnote-pane" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.endnoteNumber)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.endnoteNumber) + ".\n              ", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _cache[3] || (_cache[3] = _createTextVNode()),
                  _createElementVNode("button", {
                    ref: "closeButton",
                    class: "close-endnote-pane-button reedsy-accented",
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                  }, [
                    (_ctx.readOnly)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _cache[1] || (_cache[1] = _createTextVNode("\n                Close\n                ")),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_VuiIconCross, { class: "icon-sm" })
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _cache[2] || (_cache[2] = _createTextVNode("\n                Save\n                ")),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_VuiIconTick, { class: "icon-sm" })
                          ])
                        ]))
                  ], 512)
                ]),
                _cache[4] || (_cache[4] = _createTextVNode()),
                _createElementVNode("div", _hoisted_11, [
                  _renderSlot(_ctx.$slots, "endnote-editor", {}, undefined, true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}