
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import SectionTitle from '@reedsy/studio.viewer/components/section-title/section-title.vue';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import AlsoByBook from './also-by-book.vue';
import {IAlsoBy} from '@reedsy/reedsy-sharedb/lib/common/book-content/also-by';
import PageContainer from '@reedsy/studio.viewer/components/page-container/page-container.vue';
import DefaultEmptyReaderPlaceholder from '@reedsy/studio.viewer/components/reader/default-empty-reader-placeholder.vue';
import {IAlsoByBook} from '@reedsy/reedsy-sharedb/lib/common/book-content/also-by-book';
import {opsToString} from '@reedsy/studio.isomorphic/utils/rich-text/ops-to-string';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

@Component({
  components: {
    SectionTitle,
    RichTextReader,
    AlsoByBook,
    PageContainer,
    DefaultEmptyReaderPlaceholder,
  },
})
export default class AlsoBy extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerRichText)
  public $richText: BookViewerRichTextModule;

  public get alsoBy(): IAlsoBy {
    return this.$displayedData.content.data as IAlsoBy;
  }

  public get introRichTextId(): string {
    return this.alsoBy.references.intro.id;
  }

  public get hasIntro(): boolean {
    return !this.$richText.isEmpty(this.introRichTextId);
  }

  public get books(): IAlsoByBook[] {
    return this.alsoBy.books
      .filter((book) => !this.isBookEmpty(book));
  }

  public get isPageEmpty(): boolean {
    return !this.hasIntro && !this.books.length;
  }

  public get isBookEmpty() {
    return (book: IAlsoByBook): boolean => {
      const links = Object.values(book.storeLinks)
        .filter((link) => !!link.trim());
      const title = opsToString(book.title.ops).trim();

      const isEmptyBlurb = !book.blurbId || this.$richText.isEmpty(book.blurbId);

      return !book.cover && !links.length && !title && isEmptyBlurb;
    };
  }
}
