
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import RichTextReaderImpl from './rich-text-reader-impl.vue';
import {PropType} from 'vue';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import Quill, {IBookViewerQuillConfig} from '@reedsy/studio.viewer/services/quill';
import DefaultEmptyReaderPlaceholder from '@reedsy/studio.viewer/components/reader/default-empty-reader-placeholder.vue';

/**
 * This is a wrapper component that ensures that the actual RichTextReaderImpl
 * component is always invoked with a key binding so that it re-renders when
 * its ID changes
 */
@Component({
  components: {
    RichTextReaderImpl,
    DefaultEmptyReaderPlaceholder,
  },
})
export default class RichTextReader extends BookViewerVue {
  @Prop({type: String, required: true})
  public richTextId: string;

  @Prop({type: Boolean, default: true})
  public loadEndnotes: boolean;

  @Prop({type: String, required: false, default: 'Reader'})
  public editor: string;

  @Prop({type: String})
  public emptyDefaultPlaceholderText: string;

  @Prop({type: Object as PropType<IBookViewerQuillConfig>, required: false})
  public options: IBookViewerQuillConfig;

  public get quill(): Quill {
    return (this.$refs.editor as any).quill;
  }
}
