
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    Overlay,
  },
})
export default class EndnotePaneWrapper extends ClientSharedVue {
  @Prop({type: Number})
  public endnoteNumber: number;

  @Prop({type: Boolean, required: true})
  public show: boolean;

  @Prop({type: Boolean, default: false})
  public readOnly: boolean;

  public close(): void {
    this.$router.push({hash: ''});
  }
}
