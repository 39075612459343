import Scroll from '@reedsy/quill/blots/scroll';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';
import ComplexInlineBlot from '@reedsy/studio.shared/services/quill/blots/complex-inline-blot';
import {LinkTooltipMode} from '@reedsy/studio.shared/services/quill/modules/links';
import {VALID_LINK_PROTOCOLS} from './valid-protocols';

export default abstract class BaseLink extends ComplexInlineBlot {
  public abstract get links(): ILinks;
  public abstract get mode(): LinkTooltipMode;

  public static override readonly blotName = 'link';
  public static override readonly tagName = 'A';
  public static override readonly className = 'ql-link-element';

  public static override create(value: any): HTMLLinkElement {
    const node = super.create(value) as HTMLLinkElement;
    node.rel = 'nofollow';
    return node;
  }

  protected static sanitize(url: string): string {
    if (!url) return '';
    url = url.trim();
    const [protocol] = url.split(':');
    if (!VALID_LINK_PROTOCOLS.has(protocol.toLowerCase())) return '';
    return url;
  }

  public override readonly domNode: HTMLLinkElement;

  public constructor(scroll: Scroll, domNode: HTMLElement) {
    super(scroll, domNode);
    domNode.setAttribute('target', '_blank');
    domNode.classList.add('vui-no-animation');
  }
}
