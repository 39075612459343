<template>
  <rbe-loading v-if="!currencies">
    <DelayedLoadingIndicator :delay="1_000" />
  </rbe-loading>
  <SubscriptionBaseModal
    v-else
    :id="id"
    :title="`Start your free ${config.stripe.trialDays}-day trial`"
    class="subscription-trial-modal"
  >
    <template #left>
      <rbe-trail-info>
        <p>
          Unlock Studio by test driving our premium add-ons.
        </p>

        <section class="feature-summaries">
          <FeatureSummary
            v-for="summary in availableSummaries"
            :key="summary.product"
            :ref="summary.product"
            :model-value="true"
            :summary="summary"
            show-trial-price
          />
        </section>

        <div class="flex-justified">
          <div>
            TODO: [subscriptions] Add currency selector
          </div>

          <rbe-trial-actions>
            <button
              class="button"
              type="button"
              @click="close"
            >
              Not yet
            </button>

            <LoadingButton
              class="button"
              type="button"
              :loading="loading"
              @click="startTrial"
            >
              Start free trial
            </LoadingButton>
          </rbe-trial-actions>
        </div>
      </rbe-trail-info>
    </template>

    <template #right>
      TODO: [subscriptions] Add videos
    </template>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import SubscriptionBaseModal from './subscription-base.vue';
import AddOnToggle from './add-on-toggle.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {DisposableFunction, finallyCall} from '@reedsy/studio.shared/utils/finally-call/finally-call';
import {config} from '@reedsy/studio.shared/config';
import {IPriceCurrencyOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-currency-options-response';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';
import {FEATURE_SUMMARIES} from './features/feature-summaries';
import FeatureSummary from './features/feature-summary.vue';

@Component({
  components: {
    AddOnToggle,
    DelayedLoadingIndicator,
    LoadingButton,
    SubscriptionBaseModal,
    FeatureSummary,
  },
})
export default class SubscriptionTrial extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public readonly config = config;
  public readonly cancelable = true;

  public readonly availableSummaries = FEATURE_SUMMARIES.filter((summary) => summary.product);

  public loading = false;
  public currencies: IPriceCurrencyOptionsResponse = null;

  public async created(): Promise<void> {
    this.currencies = await this.$subscription.fetchPrice();
  }

  public async startTrial(): Promise<void> {
    using doneLoading = this.startLoading();
    await this.$subscription.startTrial();
    doneLoading();
    this.close();
    this.$modals.open('SubscriptionTrialStarted');
  }

  private startLoading(): DisposableFunction<() => void> {
    this.loading = true;
    return finallyCall(() => this.loading = false);
  }
}
</script>

<style lang="scss" scoped>
.subscription-trial-modal {
  rbe-trail-info {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
  }

  .feature-summaries {
    display: flex;
    flex-wrap: wrap;
    gap: $space-base;
    align-items: stretch;

    @include screen-less-than(lg) {
      flex-direction: column;
    }

    & > * {
      flex: 1 0 0;
    }
  }

  // TODO: Get mobile/tablet designs
  @include screen-less-than(md) {
    li {
      &.active {
        --item-background: transparent;
      }

      button,
      :deep(label) {
        cursor: default;
      }
    }
  }

  rbe-trial-actions {
    display: flex;
    gap: $space-sm;
  }
}
</style>
