<template>
  <SubscriptionBaseModal
    :id="id"
    :title="title"
    class="subscription-features-modal"
  >
    <rbe-subscription-upgrade>
      <section>
        <p>
          Unlock Studio with powerful add-ons.
        </p>
        <PeriodToggle v-model="billAnnually" />
      </section>

      <section class="feature-summaries">
        <FeatureSummary
          v-for="summary in summaries"
          :key="summary.product"
          :ref="summary.product"
          v-model="selectedProducts[summary.product]"
          :summary="summary"
        />
      </section>

      <section>
        <span>
          <AnnualSaving />
          when you purchase multiple add-ons
        </span>

        <!-- TODO: [subscriptions] add pricing -->
        <!-- TODO: [subscriptions] hook up Continue button -->
        <button
          ref="continue"
          class="button reedsy-accented"
          type="button"
          :disabled="!hasSelectedAnything"
        >
          Continue
        </button>
      </section>
    </rbe-subscription-upgrade>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import PeriodToggle from './period-toggle.vue';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {PropType} from 'vue';
import {ISubscriptionFeaturesModalArgs} from './subscription-features-modal-args.interface';
import {getProducts} from '@reedsy/utils.subscription';
import {FEATURE_SUMMARIES} from './feature-summaries';

@Component({
  components: {
    AnnualSaving,
    FeatureSummary,
    PeriodToggle,
    SubscriptionBaseModal,
  },
})
export default class SubscriptionFeatures extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionFeaturesModalArgs>, default: {}})
  public context: ISubscriptionFeaturesModalArgs;

  public readonly cancelable = true;
  public billAnnually = false;
  public title = 'Ready to upgrade?';

  public readonly summaries = FEATURE_SUMMARIES;

  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>> = {};

  public get hasSelectedAnything(): boolean {
    return Object.values(this.selectedProducts).some(Boolean);
  }

  public created(): void {
    const feature = this.context.feature;
    if (!feature) return;
    this.title = 'Upgrade to access this feature!';
    for (const product of getProducts(feature)) this.selectedProducts[product] = true;
  }
}
</script>

<style lang="scss" scoped>
.subscription-features-modal {
  rbe-subscription-upgrade {
    display: flex;
    flex-direction: column;
    gap: $space-base;
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-of-type {
      margin-top: $space-sm;
    }
  }

  .feature-summaries {
    flex-wrap: wrap;
    gap: $space-base;
    align-items: stretch;

    @include screen-less-than(lg) {
      flex-direction: column;
    }

    & > * {
      flex: 1 0 0;
    }
  }
}
</style>
