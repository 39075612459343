import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import TrackChanges from '@reedsy/studio.shared/services/quill/modules/track-changes';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {dig} from '@reedsy/utils.dig';
import {KeyboardThis} from '@reedsy/studio.shared/services/quill/modules/keyboard/keyboard.interface';

const {BLOCK, INLINE} = TrackChangesKeys;

export function hiddenChangesRight(this: KeyboardThis, range: Range, context: any): boolean {
  if (!changesAreHidden(context)) return true;
  const index = indexAfterSkippingHidden(this.quill, range, +1);
  this.quill.setSelection(index, Quill.sources.USER);
}

export function hiddenChangesLeft(this: KeyboardThis, range: Range, context: any): boolean {
  if (!changesAreHidden(context)) return true;
  const index = indexAfterSkippingHidden(this.quill, range, -1);
  this.quill.setSelection(index, Quill.sources.USER);
}

export function hiddenChangesDelete(this: KeyboardThis, range: Range, context: any): boolean {
  if (!changesAreHidden(context)) return true;

  const index = indexAfterSkippingHidden(this.quill, range, +1);
  if (hasNonBreakingWhitespaceSelected(this.quill) || index > range.index + 1) {
    this.quill.deleteText(index - 1, 1, Quill.sources.USER);
    this.quill.setSelection(index - 1, Quill.sources.USER);
    return;
  }

  return true;
}

export function hiddenChangesBackspace(this: KeyboardThis, range: Range, context: any): boolean {
  if (!changesAreHidden(context) || !range.index) return true;

  const previousContent = this.quill.getContents(range.index - 1, 1).ops[0];
  const previousFormat = dig(previousContent, 'attributes') || {};
  if (!isDeletion(previousFormat)) return true;
  let index = indexAfterSkippingHidden(this.quill, range, -1);
  // Increment because we want to backspace, so need to move forward
  index++;

  this.quill.setSelection(index);
  range.index = index;
  if (index <= 0) return;

  const newPreviousContent = this.quill.getContents(index - 1, 1).ops[0];
  // If we're at the start of a block, let's re-dispatch the event so that Quill can
  // handle the backspace appropriately at the new selection position (eg handling
  // list formatting).
  // If it's not the start of a block, just return true so that we can defer to the
  // browser's default behaviour.
  if (newPreviousContent.insert !== '\n') return true;
  this.quill.root.dispatchEvent(new KeyboardEvent('keydown', {key: 'Backspace'}));
}

function indexAfterSkippingHidden(quill: Quill, range: Range, direction: number): number {
  let index = range.index;
  let insideDeletion = true;
  while (insideDeletion && index >= 0 && index < quill.getLength()) {
    index += direction;
    const formatIndex = direction === 1 ? index - 1 : index;
    const content = quill.getContents(formatIndex, 1).ops[0];
    const format = dig(content, 'attributes') || {};
    insideDeletion = isDeletion(format);
  }
  return index;
}

function changesAreHidden(context: any): boolean {
  return context.line.domNode.matches(`.${TrackChanges.HIDE_DELETIONS_CLASS} *`);
}

function isDeletion(format: Record<string, any>): boolean {
  return format[INLINE.DELETION] || format[BLOCK.COLLAPSED_DELETION];
}

function hasNonBreakingWhitespaceSelected(quill: Quill): boolean {
  const nativeRange = quill.selection.getNativeRange();
  return /^\uFEFF$/.test(nativeRange.end.node.textContent);
}
