export enum MentionEvent {
  SuggestionClick = 'suggestion-click',
  SuggestionMouseEnter = 'suggestion-mouse-enter',
  MentionTitleUpdate = 'mention-title-update',
  MentionPinClick = 'mention-pin-click',
  MentionReady = 'mention-ready',
  MentionStateUpdate = 'mention-state-update',
  MentionDeletedState = 'mention-deleted-state',
  MentionClick = 'mention-click',
}
