// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakpoint-sm": `600px`,
	"breakpoint-md": `950px`,
	"breakpoint-lg": `1200px`,
	"transition-fast": `.15s`,
	"transition-base": `.3s`,
	"transition-slow": `.6s`,
	"rem": `16px`,
	"size-widget-height": `480`,
	"size-widget-headerHeight": `3rem`,
	"size-checkIn-minHeight": `13.8rem`,
	"space-xxxs": `.0625rem`,
	"space-xxs": `.125rem`,
	"space-xs": `.25rem`,
	"space-sm": `.5rem`,
	"space-md": `.75rem`,
	"space-base": `1rem`,
	"space-lg": `1.25rem`,
	"space-xl": `1.5rem`,
	"space-xxl": `1.75rem`,
	"space-xxxl": `2rem`,
	"borderRadius-xs": `2px`,
	"borderRadius-sm": `4px`,
	"borderRadius-base": `6px`,
	"borderRadius-lg": `8px`,
	"borderRadius-xl": `16px`,
	"borderRadius-xxl": `32px`,
	"userColorPoolSize": `7`
};
export default ___CSS_LOADER_EXPORT___;
