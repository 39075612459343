import {VuexModule, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {Store} from 'vuex';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {injectable} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {$inject} from '@reedsy/studio.viewer/types';

@injectable()
export class BookOwnerModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,
  ) {
    @Module({name: StoreName.BookViewerBookOwner, store})
    class BookOwner extends VuexModule {
      private data: IUserInfo = null;

      public get loaded(): boolean {
        return !!this.data;
      }

      public get name(): string {
        return this.data?.name;
      }

      @Mutation
      public DATA(data: IUserInfo): void {
        this.data = data;
      }
    }

    this.Module = BookOwner;
  }
}

export type BookOwnerModule = InstanceType<BookOwnerModuleFactory['Module']>;
