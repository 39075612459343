import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import UserChangeId from '@reedsy/studio.shared/services/quill/helpers/track-changes/user-change-id';
import {ITrackedChanges, ITrackedChange} from '@reedsy/studio.shared/services/track-changes/i-tracked-changes';
import TrackAttributor from './attributors/track-attributor';
import ComplexInlineBlot from '@reedsy/studio.shared/services/quill/blots/complex-inline-blot';
import SESSION_ID from '@reedsy/studio.shared/services/sharedb/session-id';

const {INLINE} = TrackChangesKeys;

// This has to be its own blot rather than an attributor, so that we can have
// two different backgrounds when we have a deletion and a comment
export default class TrackComment extends ComplexInlineBlot {
  public static override readonly blotName = INLINE.COMMENT;
  public static override readonly className = 'ql-track-comment';
  public static readonly activeClass = 'active-comment';
  public static PENDING_ATTRIBUTE = 'pending';

  public static override create(values: ITrackedChanges | ITrackedChange[]): HTMLElement {
    const node = super.create(values) as HTMLElement;
    values = Object.values(values).filter(Boolean);
    const ids = values.map((value) => new UserChangeId(value.userId, value.changeId, value.pending).toString());
    node.setAttribute(this.blotName, ids.join(' '));
    const changeIds = values.map((value) => value.changeId);
    node.setAttribute(TrackAttributor.CHANGE_IDS_ATTRIBUTE, changeIds.join(' '));
    if (this.isPending(values)) node.setAttribute(this.PENDING_ATTRIBUTE, 'true');
    return node;
  }

  public static override formats(domNode: HTMLElement): ITrackedChanges {
    const values = domNode.getAttribute(this.blotName) || '';
    const comments = values.split(/\s+/)
      .filter(Boolean)
      .reduce((byId, value) => {
        const comment = UserChangeId.parse(value).toPlainObject();
        byId[comment.changeId] = comment;
        return byId;
      }, {} as ITrackedChanges);
    return Object.keys(comments).length ? comments : null;
  }

  private static isPending(values: ITrackedChange[]): boolean {
    return values.every((value) => value.pending && value.pending !== SESSION_ID);
  }
}
