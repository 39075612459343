import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "top-loader",
  class: "pull-to-top-loader pull-to-loader"
}
const _hoisted_2 = {
  ref: "bottom-loader",
  class: "pull-to-bottom-loader pull-to-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PullLoader = _resolveComponent("PullLoader")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "scroll-container",
    class: "pull-to",
    onScrollPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleScroll && _ctx.handleScroll(...args))),
    onTouchstartPassive: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
    onTouchmovePassive: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleTouchMove && _ctx.handleTouchMove(...args))),
    onTouchendPassive: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args)))
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "top-loader", { activatePercentage: _ctx.activatePercentage }, () => [
        _createVNode(_component_PullLoader, { percentage: _ctx.activatePercentage }, null, 8, ["percentage"])
      ], true)
    ], 512), [
      [_vShow, _ctx.topShown]
    ]),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "bottom-loader", { activatePercentage: _ctx.activatePercentage }, () => [
        _createVNode(_component_PullLoader, {
          percentage: _ctx.activatePercentage,
          down: ""
        }, null, 8, ["percentage"])
      ], true)
    ], 512), [
      [_vShow, _ctx.bottomShown]
    ])
  ], 544))
}