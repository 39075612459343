import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/inlined/chapter-in-progress.svg'


const _hoisted_1 = { class: "default-empty-reader-placeholder" }
const _hoisted_2 = { class: "default-empty-reader-placeholder-text theme-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "default-empty-reader-placeholder-image",
      src: _imports_0
    }, null, -1)),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "empty-placeholder-text", {}, () => [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.emptyText), 1)
    ])
  ]))
}