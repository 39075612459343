import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import {LinkTooltipMode} from '.';

export default class LinkActions extends JsxComponent {
  public override $props: {
    mode?: LinkTooltipMode;
    isEditing: boolean;
  };

  public render(): HTMLElement {
    return (
      <div class="link-action-buttons">
        {this.$props.mode &&
          <button
            type="button"
            class="link-toggle"
          >
            {this.$props.mode === LinkTooltipMode.Link ? 'Store Link' : 'Regular Link'}
          </button>
        }
        {this.$props.isEditing ?
          [
            <button
              type="button"
              class="link-cancel"
            >
              cancel
            </button>,
            <button
              type="submit"
              class="link-save"
            >
              save
            </button>,
          ] :
          [
            <button
              type="button"
              class="link-edit"
            >
              edit
            </button>,
            <button
              type="button"
              class="link-delete"
            >
              delete
            </button>,
          ]
        }
      </div>
    );
  }
}
