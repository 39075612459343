import Delta from '@reedsy/quill-delta';
import {hasAttributes, isNoOp, simplify} from '@reedsy/utils.ot-rich-text';
import DeltaDocument, {IDeltaDocumentLine} from '@reedsy/studio.shared/services/track-changes/delta-document';
import Op from '@reedsy/quill-delta/dist/Op';
import CodeBlock from '@reedsy/quill/formats/code';
import {SmartQuotes, TextSanitizer, Ligatures} from '@reedsy/utils.string';
import {clone} from '@reedsy/utils.clone';
import {Range} from '@reedsy/quill/core/selection';
import {last} from '@reedsy/utils.iterable';

export default function smartTextOp(contents: Delta, range?: Range): Delta {
  const doc = new DeltaDocument(contents);
  const updated: Op[] = [];
  const targetStart = range ? range.index : 0;
  const targetEnd = range ? range.index + range.length : contents.length();

  doc.lines.forEach((line) => {
    const skipLine = line.lineOp.insert !== '\n' ||
      line.start > targetEnd ||
      line.end < targetStart;

    if (skipLine) return addLine(updated, line);

    let cursor = line.start;
    line.ops.forEach((op, index) => {
      const original = opString(op);
      if (!original) {
        cursor += Op.length(op);
        return updated.push(op);
      }
      const previous = opString(line.ops[index - 1]);
      const next = opString(line.ops[index + 1]);

      const textCursor = range && targetEnd - cursor;
      let newValue = shouldAddSmartQuotes(line.lineOp) ?
        addSmartText(previous, original, next, textCursor) :
        removeSmartText(original);

      newValue = TextSanitizer.sanitize(newValue);

      if (newValue !== original) {
        op = clone(op);
        op.insert = newValue;
      }

      updated.push(op);
      cursor += Op.length(op);
    });

    updated.push(line.lineOp);
  });

  let updatedContents = new Delta(simplify(updated));
  if (isFragment(contents)) updatedContents = removeLastCharacter(updatedContents);
  const delta = contents.diff(updatedContents);

  return isNoOp(delta) ? null : delta;
}

function addSmartText(previous: string, original: string, next: string, cursor: number): string {
  cursor += previous.length;
  const updated = SmartQuotes.apply(previous + original + next, cursor)
    .substring(previous.length, previous.length + original.length);
  return Ligatures.apply(updated);
}

function removeSmartText(original: string): string {
  const updated = SmartQuotes.remove(original);
  return Ligatures.remove(updated);
}

function addLine(ops: Op[], line: IDeltaDocumentLine): void {
  line.ops.forEach((op) => ops.push(op));
  ops.push(line.lineOp);
}

function shouldAddSmartQuotes(lineOp: Op): boolean {
  return !hasAttributes(lineOp, CodeBlock.blotName);
}

function opString(op: Op): string {
  if (!op) return '';
  return typeof op.insert === 'string' ? op.insert : '';
}

function isFragment(contents: Delta): boolean {
  const lastOp = last(contents.ops);
  const string = opString(lastOp);
  return !string.endsWith('\n');
}

function removeLastCharacter(contents: Delta): Delta {
  const length = contents.length();
  if (!length) return contents;
  return contents.compose(new Delta().retain(contents.length() - 1).delete(1));
}
