import {InlineBlot} from 'parchment';
import Embed from '@reedsy/quill/blots/embed';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {TrackDeletionBlockIndicatorAttributor} from './attributors/track-deletion-block-indicator';
import AttributeMap from '@reedsy/quill-delta/dist/AttributeMap';

const {BLOCK} = TrackChangesKeys;

export default class TrackDeletionBlockIndicator extends Embed {
  public static override readonly blotName = 'track-deletion-block-indicator';
  public static override readonly className = 'ql-track-deletion-block';
  public static override readonly tagName = 'SPAN';

  public override domNode: HTMLElement;

  public override format(name: string, value: any): void {
    // The only valid attribute that we can apply to this embed is the collapsed
    // deletion information. We manually invoke the attributor, because if we let
    // Quill handle it, the attributor will insist on wrapping the embed in an
    // inline blot, which splits the information across multiple levels of the DOM.
    if (name !== BLOCK.COLLAPSED_DELETION) return;

    TrackDeletionBlockIndicatorAttributor.add(this.domNode, value);
  }

  public override optimize(context: any): void {
    const parent: any = this.scroll.find(this.domNode.parentElement);
    if (parent instanceof InlineBlot) {
      const formats = parent.formats();
      for (const format in formats) {
        if (format !== BLOCK.COLLAPSED_DELETION) parent.format(format, null);
      }
    }

    super.optimize(context);
  }

  public override formats(): AttributeMap {
    const value = TrackDeletionBlockIndicatorAttributor.value(this.domNode);
    return value && {[BLOCK.COLLAPSED_DELETION]: value};
  }
}
