import {injectable, named} from 'inversify';
import BaseCloseModalsPluginFactory from '@reedsy/studio.shared/store/plugins/close-modals/close-modals';
import {$inject} from '@reedsy/studio.viewer/types';
import BookViewerStoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerModalsModule} from '@reedsy/studio.viewer/store/modules/modals';

@injectable()
export default class CloseModalsPluginFactory extends BaseCloseModalsPluginFactory {
  @$inject('StoreModule')
  @named(BookViewerStoreName.BookViewerModals)
  public override $modals: BookViewerModalsModule;
}
