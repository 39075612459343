import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "rich-text-editor-impl" }
const _hoisted_2 = {
  key: 1,
  ref: "loader",
  class: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParagraphLoader = _resolveComponent("ParagraphLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.ready && _ctx.isRichTextEmpty)
      ? _renderSlot(_ctx.$slots, "empty-placeholder", { key: 0 })
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editor), _mergeProps({ ref: "editor" }, _ctx.$props), null, 16)), [
      [_vShow, _ctx.ready && !_ctx.isRichTextEmpty]
    ]),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (!_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "loader", {}, () => [
            _createVNode(_component_ParagraphLoader)
          ])
        ], 512))
      : _createCommentVNode("", true)
  ]))
}