import Scroll from '@reedsy/quill/blots/scroll';
import UnformattableEmbed from '@reedsy/studio.shared/services/quill/blots/unformattable-embed';
import {MentionEvent} from '@reedsy/studio.shared/services/quill/modules/mentions/events';

export const MENTION_ID = 'id';

export default class MentionBlot extends UnformattableEmbed {
  public static override blotName = 'mention';
  public static override tagName = 'span';
  public static override className = 'ql-mention';

  public static override create(id: string): HTMLElement {
    const node = super.create(id) as HTMLElement;
    node.dataset[MENTION_ID] = id;

    return node;
  }

  public static override value(domNode: HTMLElement): string {
    return domNode.dataset[MENTION_ID];
  }

  public constructor(scroll: Scroll, domNode: HTMLElement) {
    super(scroll, domNode);

    this.titleUpdateHandler = this.titleUpdateHandler.bind(this);
    this.deletedStateHandler = this.deletedStateHandler.bind(this);

    domNode.addEventListener('click', (event) => {
      if (domNode.classList.contains('deleted')) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      scroll.emitter.emit(MentionEvent.MentionClick, this);
    });

    // Have to wait until the mention is added to the DOM
    setTimeout(() => {
      scroll.emitter.on(MentionEvent.MentionTitleUpdate, this.titleUpdateHandler);
      scroll.emitter.on(MentionEvent.MentionDeletedState, this.deletedStateHandler);
      scroll.emitter.emit(MentionEvent.MentionReady, this.id);
    });
  }

  public get id(): string {
    return this.domNode.dataset[MENTION_ID];
  }

  public titleUpdateHandler({id, title}: ITitleUpdate): void {
    if (!this.scroll.domNode.contains(this.domNode)) {
      this.scroll.emitter.off(MentionEvent.MentionTitleUpdate, this.titleUpdateHandler);
    }

    if (id !== this.id) return;

    const mentionText = `@${title}`;
    if (this.text === mentionText) return;
    this.text = mentionText;
  }

  public deletedStateHandler({id, deleted}: IStateUpdate): void {
    if (!this.scroll.domNode.contains(this.domNode)) {
      this.scroll.emitter.off(MentionEvent.MentionStateUpdate, this.deletedStateHandler);
    }

    if (id !== this.id) return;

    this.domNode.classList.toggle('deleted', deleted);
  }
}

interface ITitleUpdate {
  id: string;
  title: string;
}

interface IStateUpdate {
  id: string;
  deleted: boolean;
}
