import CodeBlock, {CodeBlockContainer} from '@reedsy/quill/formats/code';
import RestrictedFormatBlock from '@reedsy/studio.shared/services/quill/blots/restricted-format-block';
import {ALWAYS_ALLOWED_INLINES} from './always-allowed-inlines';

export default class ReedsyCodeBlock extends RestrictedFormatBlock {
  public static override readonly blotName = CodeBlock.blotName;
  public static override readonly className = CodeBlock.className;
  public static override readonly tagName = CodeBlock.tagName;
  public static override readonly requiredContainer = CodeBlockContainer;
  public static override readonly allowedChildren = [...ALWAYS_ALLOWED_INLINES];
}
