import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {NodeType} from '@reedsy/reedsy-sharedb/lib/common/plan-content/node-type';

const CHAPTER_PATH = 'c';
const NOT_NAVIGABLE: string = null;
const MANUSCRIPT_MAP = {
  [MatterType.FrontMatter]: NOT_NAVIGABLE,
  [MatterType.Body]: NOT_NAVIGABLE,
  [MatterType.BackMatter]: NOT_NAVIGABLE,

  [MatterType.AboutTheAuthor]: 'a',
  [MatterType.Acknowledgement]: CHAPTER_PATH,
  [MatterType.Afterword]: CHAPTER_PATH,
  [MatterType.AlsoBy]: 'b',
  [MatterType.Chapter]: CHAPTER_PATH,
  [MatterType.Conclusion]: CHAPTER_PATH,
  [MatterType.Copyright]: 'r',
  [MatterType.Dedication]: 'd',
  [MatterType.Epigraph]: 'e',
  [MatterType.Epilogue]: CHAPTER_PATH,
  [MatterType.Foreword]: CHAPTER_PATH,
  [MatterType.Introduction]: CHAPTER_PATH,
  [MatterType.Notes]: 'n',
  [MatterType.Part]: 'p',
  [MatterType.Preface]: CHAPTER_PATH,
  [MatterType.Prologue]: CHAPTER_PATH,
  [MatterType.TableOfContents]: 't',
} as const satisfies Record<MatterType, string>;

const PLAN_MAP = {
  [NodeType.Asset]: 'a',
  [NodeType.Board]: 'b',
} as const satisfies Record<NodeType, string>;

const MAP = Object.freeze({...MANUSCRIPT_MAP, ...PLAN_MAP});

export default function pathFromType(type: keyof typeof MAP): string {
  return MAP[type];
}
