import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';

export function isReadableContentFragment(contentFragment: IContentFragment): boolean {
  if (!contentFragment) return false;
  if (!('references' in contentFragment.data)) {
    return false;
  }
  if (!('enabled' in contentFragment.data)) return true;
  return contentFragment.data.enabled;
}
