
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import PeriodToggle from './period-toggle.vue';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {PropType} from 'vue';
import {ISubscriptionFeaturesModalArgs} from './subscription-features-modal-args.interface';
import {getProducts} from '@reedsy/utils.subscription';
import {FEATURE_SUMMARIES} from './feature-summaries';

@Component({
  components: {
    AnnualSaving,
    FeatureSummary,
    PeriodToggle,
    SubscriptionBaseModal,
  },
})
export default class SubscriptionFeatures extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionFeaturesModalArgs>, default: {}})
  public context: ISubscriptionFeaturesModalArgs;

  public readonly cancelable = true;
  public billAnnually = false;
  public title = 'Ready to upgrade?';

  public readonly summaries = FEATURE_SUMMARIES;

  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>> = {};

  public get hasSelectedAnything(): boolean {
    return Object.values(this.selectedProducts).some(Boolean);
  }

  public created(): void {
    const feature = this.context.feature;
    if (!feature) return;
    this.title = 'Upgrade to access this feature!';
    for (const product of getProducts(feature)) this.selectedProducts[product] = true;
  }
}
