import Op from '@reedsy/quill-delta/dist/Op';
import {TrackChangesKeys, TRACK_CHANGES_KEYS} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {ITrackedChange} from '@reedsy/studio.shared/services/track-changes/i-tracked-changes';
import {clone} from '@reedsy/utils.clone';
import {ITrackedReformat} from '@reedsy/studio.shared/services/track-changes/i-tracked-reformats';
import AttributeMap from '@reedsy/quill-delta/dist/AttributeMap';
import {trackedDeletions, withoutTrackAttributes} from '@reedsy/studio.shared/services/track-changes/track-changes-utils';
import {dig} from '@reedsy/utils.dig';
import {isBlock} from '@reedsy/studio.shared/services/quill/helpers/is-block';
import IChangeEntry from './i-change-entry';
import IChange from './i-change';
import {positiveLength, splitOpsOnNewlines} from '@reedsy/utils.ot-rich-text';
import SESSION_ID from '@reedsy/studio.shared/services/sharedb/session-id';
import {forEachOp} from '@reedsy/studio.shared/utils/rich-text/for-each-op';

const {CHANGE, BLOCK} = TrackChangesKeys;

export default function listTrackedChanges(ops: Op[]): IChange[] {
  const changesById = new Map<string, IChange>();
  ops = splitOpsOnNewlines(ops);

  let lastBlockIndex = 0;
  forEachOp(ops, (op, {cursor}) => {
    const attributes = op.attributes || {};
    TRACK_CHANGES_KEYS.forEach((attribute) => {
      if (attribute === CHANGE || !attributes[attribute]) return;
      const changes: ITrackedChange[] = Object.values(attributes[attribute]);
      changes.forEach((change) => addChange(changesById, op, attribute, change, cursor, lastBlockIndex));
    });

    if (isBlock(op)) lastBlockIndex = cursor + positiveLength(op);
  });

  return Array.from(changesById.values());
}

function addChange(
  changesById: Map<string, IChange>,
  op: Op,
  changeType: string,
  change: ITrackedChange | ITrackedReformat,
  cursor: number,
  lastBlockIndex: number,
): void {
  const changeId = change && change.changeId;
  if (!changeId) return;
  if ('pending' in change && change.pending !== SESSION_ID) return;

  if (!changesById.has(change.changeId)) {
    changesById.set(changeId, {
      id: changeId,
      userId: change.userId,
      start: cursor,
      end: null,
      changes: {},
    });
    if ('pending' in change) changesById.get(changeId).pending = change.pending;
  }

  const combinedChange = changesById.get(changeId);
  const end = cursor + Op.length(op);
  combinedChange.end = end;

  const entry: IChangeEntry = {
    id: changeId,
    start: cursor,
    end: end,
    op: clone(op),
  };

  if ('formats' in change) entry.formats = change.formats;
  if ('previous' in change) entry.previous = change.previous;

  if (isBlockChange(changeType)) {
    entry.op.attributes = blockAttributes(op, changeType);
    if (entry.formats) combinedChange.blockStart = lastBlockIndex;
  }
  entry.op.attributes = withoutTrackAttributes(entry.op.attributes);
  if (!Object.keys(entry.op.attributes).length) delete entry.op.attributes;
  combinedChange.changes[changeType] = combinedChange.changes[changeType] || [];
  combinedChange.changes[changeType].push(entry);
}

function blockAttributes(op: Op, changeType: string): AttributeMap {
  const deletions = Object.values(trackedDeletions(op));
  const attributes = changeType === BLOCK.COLLAPSED_DELETION ?
    dig(deletions, 0, 'attributes') :
    op.attributes;

  return Object.assign({}, attributes);
}

function isBlockChange(changeType: string): boolean {
  return Object.keys(BLOCK)
    .some(
      (key: keyof typeof BLOCK) => BLOCK[key] === changeType,
    );
}
