import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { ref: "emptyPlaceholder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_SectionTitle, { class: "part-title" }),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createVNode(_component_RichTextReader, {
        id: "part-reader",
        "rich-text-id": _ctx.richTextId
      }, {
        "empty-placeholder": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, null, 512)
        ]),
        _: 1
      }, 8, ["rich-text-id"])
    ]),
    _: 1
  }))
}