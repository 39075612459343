import {Scope} from 'parchment';
import TrackReformatAttributor from './track-reformat-attributor';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';

const {BLOCK} = TrackChangesKeys;

export const TrackReformatBlock = new TrackReformatAttributor(
  BLOCK.REFORMAT,
  BLOCK.REFORMAT,
  {scope: Scope.BLOCK_ATTRIBUTE},
);
