
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {IAboutTheAuthor} from '@reedsy/reedsy-sharedb/lib/common/book-content/about-the-author';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import SectionTitle from '@reedsy/studio.viewer/components/section-title/section-title.vue';
import PageContainer from '@reedsy/studio.viewer/components/page-container/page-container.vue';
import DefaultEmptyReaderPlaceholder from '@reedsy/studio.viewer/components/reader/default-empty-reader-placeholder.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

interface ILinkInfo {
  url: string;
  icon: string;
}

@Component({
  components: {
    SectionTitle,
    RichTextReader,
    PageContainer,
    DefaultEmptyReaderPlaceholder,
  },
})
export default class AboutTheAuthor extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerRichText)
  public $richText: BookViewerRichTextModule;

  public get aboutTheAuthor(): IAboutTheAuthor {
    return this.$displayedData.content.data as IAboutTheAuthor;
  }

  public get authorPhotoUrl(): string {
    return this.aboutTheAuthor.settings.image;
  }

  public get bioRichTextId(): string {
    return this.aboutTheAuthor.references.bio.id;
  }

  public get hasBio(): boolean {
    return !this.$richText.isEmpty(this.bioRichTextId);
  }

  public get hasAnyUrl(): boolean {
    return !!this.links.length;
  }

  public get links(): ILinkInfo[] {
    const links: ILinkInfo[] = [];
    const urls = this.aboutTheAuthor.settings.url;

    if (urls.website) {
      links.push({
        url: urls.website,
        icon: 'VuiIconGlobe',
      });
    }

    if (urls.twitter) {
      links.push({
        url: urls.twitter,
        icon: 'VuiIconTwitter',
      });
    }

    if (urls.facebook) {
      links.push({
        url: urls.facebook,
        icon: 'VuiIconFacebook',
      });
    }

    if (urls.other.length) {
      urls.other
        .filter((otherLink) => !!otherLink)
        .forEach((otherLink) => {
          links.push({
            url: otherLink,
            icon: 'VuiIconLink',
          });
        });
    }

    return links;
  }

  public get newsletterUrl(): string {
    return this.aboutTheAuthor.settings.url.newsletter;
  }

  public get isPageEmpty(): boolean {
    return !this.authorPhotoUrl && !this.hasBio && !this.hasAnyUrl;
  }
}
