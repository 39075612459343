import {ParentBlot} from 'parchment';
import ListItem from '@reedsy/studio.shared/services/quill/formats/list';
import TrackAttributor from './track-attributor';
import IndentedBlockBlot from '@reedsy/studio.shared/services/quill/blots/indented-block-blot';
import IndentedBlockquote from '@reedsy/studio.shared/services/quill/blots/indented-blockquote-blot';
import ReedsyHeader from '@reedsy/studio.shared/services/quill/formats/header';
import TrackInsertAttributor from './track-insert-attributor';
import Callout from '@reedsy/studio.shared/services/quill/blots/callout';
import {blotTags} from '@reedsy/studio.shared/services/quill/helpers/blotTags';

const BLOCKS_WITH_INDICATORS = Object.freeze(new Set(blotTags(
  Callout,
  IndentedBlockBlot,
  IndentedBlockquote,
  ListItem,
  ReedsyHeader,
)));

const INDICATOR_WRAPPER_CLASS = 'track-indicator-wrapper';

export default class TrackBlockInsertAttributor extends TrackInsertAttributor {
  public static INDICATOR_WRAPPER_CLASS = INDICATOR_WRAPPER_CLASS;

  public static createIndicator(indicatorClass: string): HTMLElement {
    const indicatorWrapper = document.createElement('span');
    indicatorWrapper.classList.add(ParentBlot.uiClass, INDICATOR_WRAPPER_CLASS, indicatorClass);
    indicatorWrapper.setAttribute('contenteditable', 'false');

    // Nest the indicator so that the before and after pseudo-elements of
    // the "active" change highlighter don't conflict
    const indicator = document.createElement('span');
    indicator.classList.add('track-indicator');
    indicatorWrapper.appendChild(indicator);

    return indicatorWrapper;
  }

  private indicatorClass: string;

  public constructor(attrName: string, keyName: string, options?: any) {
    super(attrName, keyName, options);
    this.indicatorClass = options.indicatorClass;
  }

  public override add(node: HTMLElement, values: any): boolean {
    super.add(node, values);
    this.addIndicatorToNodeAndSiblings(node);
    return true;
  }

  public override remove(node: HTMLElement): void {
    super.remove(node);
    this.updateIndicatorChangeIds(node);

    // When merging nodes, we need to make sure the neighbouring node
    // has been updated
    this.addIndicator(node.nextElementSibling);
    this.addIndicator(node.previousElementSibling);
  }

  public override value(node: HTMLElement): any {
    this.addIndicatorToNodeAndSiblings(node);
    return super.value(node);
  }

  private addIndicatorToNodeAndSiblings(node: HTMLElement): void {
    this.addIndicator(node);
    this.addIndicator(node.nextElementSibling);
    this.addIndicator(node.previousElementSibling);
  }

  private addIndicator(node: Element): void {
    if (!this.shouldAddIndicator(node)) return;

    const indicator = TrackBlockInsertAttributor.createIndicator(this.indicatorClass);
    node.appendChild(indicator);

    this.updateIndicatorChangeIds(node);
  }

  private shouldAddIndicator(node: Element): boolean {
    return node &&
      BLOCKS_WITH_INDICATORS.has(node.nodeName.toUpperCase()) &&
      node.hasAttribute(this.keyName) &&
      !this.indicator(node);
  }

  // Add the change IDs so that we can highlight them when showing the
  // "active" change
  private updateIndicatorChangeIds(node: Element): void {
    const changeIds = node.getAttribute(TrackAttributor.CHANGE_IDS_ATTRIBUTE);

    const indicator = this.indicator(node);
    if (!indicator) return;

    if (changeIds) indicator.setAttribute(TrackAttributor.CHANGE_IDS_ATTRIBUTE, changeIds);
    else indicator.remove();
  }

  private indicator(node: Element): HTMLElement {
    return node.querySelector(`.${INDICATOR_WRAPPER_CLASS}`);
  }
}
