import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import LinkHeader from './link-header';
import LinkActions from './link-actions';
import {LinkTooltipMode} from '.';
import StoreLinkTemplate from './store-link-template';
import RegularLinkTemplate from './regular-link-template';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';

export default class LinkWithStoreModeTooltip extends JsxComponent {
  public override $props: {
    mode: LinkTooltipMode;
    links: ILinks;
    isEditing: boolean;
    readOnly: boolean;
  };

  public render(): HTMLElement {
    return (
      <form novalidate>
        <div class="head">
          <LinkHeader
            title={this.$props.mode === LinkTooltipMode.StoreLink ? 'Store link' : 'Link'}
          />
          {
            !this.$props.readOnly &&
            <LinkActions
              isEditing={this.$props.isEditing}
              mode={this.$props.mode}
            />
          }
        </div>
        {
          this.$props.mode === LinkTooltipMode.StoreLink ?
            <StoreLinkTemplate
              isEditing={this.$props.isEditing}
              links={this.$props.links}
              readOnly={this.$props.readOnly}
            /> :
            <RegularLinkTemplate
              isEditing={this.$props.isEditing}
              links={this.$props.links}
            />
        }
      </form>
    );
  }
}
