import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import VideoIcon from './video-icon';

export default class VideosPlaceholder extends JsxComponent {
  public render(): HTMLElement {
    return (
      <rbe-video-placeholder>
        <rbe-divider-line class="file-progress-indicator" />

        <rbe-icon-wrapper class="file-progress-icon-container">
          <VideoIcon class="file-progress-icon" />
        </rbe-icon-wrapper>
      </rbe-video-placeholder>
    );
  }
}
