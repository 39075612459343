import {LinkTooltipMode} from '@reedsy/studio.shared/services/quill/modules/links';
import {LinkType} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/link-type.interface';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';
import BaseLink from './base-link';

export default class RegularLink extends BaseLink {
  public static override create(value: any): HTMLLinkElement {
    const node = super.create(value);
    const link = typeof value === 'string' ? value : value[LinkType.Regular];
    node.href = this.sanitize(link) || '';
    return node;
  }

  public static override formats(node: HTMLLinkElement): ILinks {
    const link = node.getAttribute('href') || '';
    const formats = {[LinkType.Regular]: link};

    return link ? formats : null;
  }

  public get mode(): LinkTooltipMode {
    return LinkTooltipMode.Link;
  }

  public get links(): ILinks {
    return this.formats().link;
  }
}
