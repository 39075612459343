import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import Delta from '@reedsy/quill-delta';
import HighlightBlot from '@reedsy/studio.shared/services/quill/blots/highlight';
import {deepEqual} from '@reedsy/utils.deep-equal';
import {Viewport} from '@reedsy/studio.shared/utils/viewport';
import {dig} from '@reedsy/utils.dig';
import Blot from '@reedsy/studio.shared/services/quill/blots/blot';
import {ParentBlot} from 'parchment';
import Block from '@reedsy/quill/blots/block';
import {ObjectId} from '@reedsy/utils.object-id';
import splitOverlappingRanges from './split-overlapping-ranges';

export const ACTIVE_CLASS = 'ql-active';

export default class Highlighter {
  public quill: Quill;
  public enabled = true;

  private highlightClass: string;
  private activeClass: string = ACTIVE_CLASS;

  public constructor(quill: Quill, highlightClass: string) {
    this.highlightClass = highlightClass;
    this.quill = quill;
  }

  public highlight(ranges: Range[], root: Blot = this.quill.scroll, rootRange?: Range): void {
    if (!this.enabled) ranges = [];
    const selection = this.quill.getSelection();
    const lengthsByIndex = this.lengthsByIndex(ranges);
    this.removeUnwantedHighlights(lengthsByIndex, root, rootRange);
    const delta = this.newHighlightDelta(lengthsByIndex);
    this.quill.updateContents(delta);
    this.restoreSelection(selection);
  }

  public highlightBatch(requests: IHighlightRequest[]): void {
    if (!requests.length) return;
    if (this.quill.selection.composing) {
      // If we're composing, it's not safe to mutate the DOM around our IME.
      // Let's try to defer highlighting until after composition.
      return this.highlightBatchLater(requests);
    }
    const selection = this.quill.getSelection();
    const allLengthsByIndex: {[index: number]: number} = {};
    for (const {ranges, root, rootRange} of requests) {
      const lengthsByIndex = this.lengthsByIndex(ranges);
      this.removeUnwantedHighlights(lengthsByIndex, root, rootRange);
      Object.assign(allLengthsByIndex, lengthsByIndex);
    }
    const delta = this.newHighlightDelta(allLengthsByIndex);
    this.quill.updateContents(delta);
    this.restoreSelection(selection);
  }

  public setActive(range: Range): void {
    const activeHighlights = this.highlightsAtRange(range);
    let firstActiveHighlight: Element;
    this.quill.root.querySelectorAll(`.${this.highlightClass}.${this.activeClass}`).forEach((highlight) => {
      if (activeHighlights.has(highlight)) return;
      highlight.classList.remove(this.activeClass);
    });
    activeHighlights.forEach((activeHighlight) => {
      if (activeHighlight.classList.contains(this.activeClass)) return;
      firstActiveHighlight ||= activeHighlight;
      activeHighlight.classList.add(this.activeClass);
    });

    Viewport.scrollIntoView(firstActiveHighlight, {block: 'center'});
  }

  private lengthsByIndex(ranges: Range[]): {[index: number]: number} {
    const splitRanges = splitOverlappingRanges(ranges);
    return splitRanges.reduce((byIndex, range) => {
      byIndex[range.index] = range.length;
      return byIndex;
    }, {} as {[index: number]: number});
  }

  private removeUnwantedHighlights(
    lengthsByIndex: Record<number, number>,
    blot: Blot,
    rootRange?: Range,
    index?: number,
  ): number {
    if (typeof index !== 'number') index = this.quill.getIndex(blot);

    const children: Blot[] = [];
    if (blot instanceof ParentBlot) {
      let child = blot.children.head;
      while (child) {
        children.push(child);
        child = child.next;
      }
    }

    if (this.isHighlight(blot) && this.isInsideRootRange(index, rootRange)) {
      if (blot.length() === lengthsByIndex[index]) delete lengthsByIndex[index];
      else blot.unwrap(true);
    }

    children.forEach((child) => {
      index = this.removeUnwantedHighlights(lengthsByIndex, child, rootRange, index);
    });

    if (blot instanceof ParentBlot) return (blot instanceof Block) ? index + 1 : index;
    else return index + blot.length();
  }

  private newHighlightDelta(lengthsByIndex: Record<number, number>): Delta {
    let cursor = 0;
    const delta = new Delta();
    Object.keys(lengthsByIndex).forEach((index: any) => {
      const range = {index: +index, length: lengthsByIndex[index]};
      const newCursor = range.index;
      delta.retain(newCursor - cursor);
      delta.retain(range.length, {
        highlight: {
          nodeClass: this.highlightClass,
          // The only use of this id is to keep DOM nodes separated
          id: new ObjectId().toHexString(),
        },
      });
      cursor = newCursor + range.length;
    });
    return delta;
  }

  private restoreSelection(selection: Range): void {
    if (deepEqual(selection, this.quill.getSelection())) return;
    // Need to make sure that the DOM is up-to-date before setting the selection
    this.quill.update();
    this.quill.setSelection(selection);
  }

  private highlightsAtRange(range: Range): Set<Element> {
    const nodes: Set<Element> = new Set();
    if (!range) return nodes;
    let leafsLength = 0;
    while (leafsLength < range.length) {
      const [leaf] = this.quill.getLeaf(range.index + leafsLength + 1);
      if (!leaf) return new Set();
      const node = dig(leaf, 'domNode');
      const element = node instanceof Element ? node : node.parentElement;
      const highlight = element.closest(`.${this.highlightClass}`);
      if (highlight) nodes.add(highlight);
      leafsLength += leaf.length();
    }
    return nodes;
  }

  private isInsideRootRange(index: number, range: Range): boolean {
    if (!range) return true;
    return index >= range.index && index < range.index + range.length;
  }

  private isHighlight(blot: Blot): blot is HighlightBlot {
    return blot instanceof HighlightBlot &&
      blot.domNode.classList.contains(this.highlightClass);
  }

  private highlightBatchLater(requests: IHighlightRequest[]): void {
    requests = requests.slice();

    // Try to trigger the highlight again on selectionchange: there's a
    // chance composition has ended. If that's not the case, we'll just
    // trigger another call to highlightBatchLater() and try again
    document.addEventListener('selectionchange', () => {
      this.highlightBatch(requests);
    }, {once: true});

    // Check for changes to the document. If the document changes before
    // we can flush our highlights, let's just discard them. We don't
    // know if they're still valid, and a text-update should trigger
    // another fresh batch of highlights anyway.
    const clearRequests = (type: string): void => {
      if (type !== Quill.events.TEXT_CHANGE) return;
      this.quill.off(Quill.events.EDITOR_CHANGE, clearRequests);
      requests.length = 0;
    };
    this.quill.on(Quill.events.EDITOR_CHANGE, clearRequests);
  }
}

export interface IHighlightRequest {
  ranges: Range[];
  root: Blot;
  rootRange: Range;
}
