import {dig} from '@reedsy/utils.dig';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {IBookContentsStructure, bookContentStructure} from '@reedsy/reedsy-sharedb/lib/server/book-contents-structure';
import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {opsToString} from '../rich-text/ops-to-string';
import {findChapterNumber} from './find-chapter-number';
import {findContentInfo} from './find-content-info';
import {findPartNumber} from './find-part-number';
import {ICopyright} from '@reedsy/reedsy-sharedb/lib/common/book-content/copyright';
import numberConverter from 'number-to-words';
import capitalize from 'capitalize';

export class TitleGenerator {
  public constructor(
    private readonly flatBookContent: IContentFragment[],
    private readonly ownerName: string,
  ) { }

  public get authorName(): string {
    const copyrightFragment = this.flatBookContent.find((fragment) => {
      return fragment.data.type === MatterType.Copyright;
    }) as IContentFragment<ICopyright>;

    const penName = dig(
      copyrightFragment,
      'data',
      'settings',
      'penName',
    )?.trim();

    return penName || this.ownerName;
  }

  public titleById(contentId: string): string {
    const contentFragment = findContentInfo(this.flatBookContent, contentId);
    return this.title(contentFragment);
  }

  public title(content: ContentFragment): string {
    if (!content?.data?.title) return this.defaultTitle(content);

    const title = opsToString(content.data.title.ops);
    return title.trim() || this.defaultTitle(content);
  }

  public defaultTitleById(contentId: string): string {
    const contentFragment = findContentInfo(this.flatBookContent, contentId);
    return this.defaultTitle(contentFragment);
  }

  public defaultTitle(content: ContentFragment): string {
    if (!content) return '';
    const matterType = content.data.type;

    if (this.hasFixedTitle(matterType)) {
      const title = bookContentStructure.titles[matterType];
      return title.replace(/\\@author\\/g, this.authorName);
    }

    if (matterType === MatterType.Chapter) return this.chapterTitle(content.id);
    if (matterType === MatterType.Part) return this.partTitle(content.id);
    return '';
  }

  private chapterTitle(contentId: string): string {
    const chapterNumber = findChapterNumber(this.flatBookContent, contentId);
    return chapterNumber ? `Chapter ${chapterNumber}` : '';
  }

  private partTitle(contentId: string): string {
    const partNumber = findPartNumber(this.flatBookContent, contentId);
    const spelledIndex = capitalize.words(numberConverter.toWords(partNumber));
    return `Part ${spelledIndex}`;
  }

  private hasFixedTitle(matterType: string | MatterType): matterType is keyof IBookContentsStructure['titles'] {
    return matterType in bookContentStructure.titles;
  }
}
