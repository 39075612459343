import {deepFreeze} from '@reedsy/utils.object';
import {parseUrl} from '@reedsy/utils.url';
import {URL} from 'whatwg-url';

const ALLOWED_PROTOCOLS = deepFreeze(new Set(['http:', 'https:']));
const LONG_HOSTNAMES = deepFreeze(new Set(['youtube.com', 'www.youtube.com', 'm.youtube.com']));
const SHORT_HOSTNAMES = deepFreeze(new Set(['youtu.be', 'www.youtu.be']));

const ALL_HOSTNAMES = deepFreeze(new Set([...LONG_HOSTNAMES, ...SHORT_HOSTNAMES]));

export function youtubeLinkTransformer(string: string): string {
  string = ensureProtocol(string);

  const url = parseUrl(string);

  if (!isValidYoutubeUrl(url)) return null;

  const youtubeId = getYoutubeVideoId(url) || '';

  const timestamp = url.searchParams.get('t') ? `?start=${url.searchParams.get('t')}` : '';

  // Even though it is not guaranteed we assume the YouTube ID is always 11 characters
  // as it is the safest way of testing the validity of a video
  // https://developers.google.com/youtube/iframe_api_reference#Events
  return youtubeId.length === 11 ? `https://www.youtube.com/embed/${youtubeId}${timestamp}` : null;
}

function ensureProtocol(string: string): string {
  for (const hostname of ALL_HOSTNAMES) {
    if (string.startsWith(hostname)) return `https://${string}`;
  }
  return string;
}

function getYoutubeVideoId(url: URL): string {
  if (url.pathname === '/watch') return url.searchParams.get('v');
  else if (url.pathname.split('/')[1] === 'shorts') return url.pathname.split('/')[2];
  else if (SHORT_HOSTNAMES.has(url.hostname)) return url.pathname.split('/')[1];
}

function isValidYoutubeUrl(url: URL): boolean {
  return (
    url &&
    ALL_HOSTNAMES.has(url.hostname) &&
    ALLOWED_PROTOCOLS.has(url.protocol)
  );
}
