import {injectable, named} from 'inversify';
import {BookViewerRouterPlugin} from '@reedsy/studio.viewer/store/book-viewer-router-plugin';
import {$inject} from '@reedsy/studio.viewer/types';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {BookViewerRouteName} from '@reedsy/studio.shared/router/route-names/book-viewer-route-name';
import {buildFragmentUrl} from '@reedsy/studio.viewer/utils/build-fragment-url';
import {BookViewerShareableUrlDetailsModule} from '@reedsy/studio.viewer/store/modules/shareable-url-details/shareable-url-details';
import {BookViewerReadingLocationModule} from '@reedsy/studio.viewer/store/modules/reading-location/reading-location';
import {decodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';
import {IBookViewerRoute} from '@reedsy/studio.viewer/router/i-book-viewer-route';
import {NavigationGuardReturn} from 'vue-router';

@injectable()
export default class RedirectToFirstPagePluginFactory extends BookViewerRouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.BookViewerBookContent)
  public readonly _BookContent: BookViewerBookContentModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerSharableUrlDetails)
  public readonly _ShareableUrlDetails: BookViewerShareableUrlDetailsModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerReadingLocation)
  public readonly _ReadingLocation: BookViewerReadingLocationModule;

  protected override async beforeEach(to: IBookViewerRoute): Promise<NavigationGuardReturn> {
    if (to.name !== BookViewerRouteName.BookViewer) return;

    if (this._ReadingLocation.lastReadContentId) {
      return this.buildContentUrl(this._ReadingLocation.lastReadContentId);
    }

    return this.buildContentUrl(this._BookContent.firstPageId);
  }

  protected override afterEach(to: IBookViewerRoute): void {
    const contentId: string = decodeObjectId(to.params.contentId);
    if (!contentId) return;

    this._ReadingLocation.changeLastReadContentId(contentId);
  }

  private buildContentUrl(contentId: string): string {
    const pageFragment = this._BookContent.contentInfo(contentId);
    return buildFragmentUrl({
      content: pageFragment,
      sharableUrlShortId: this._ShareableUrlDetails.shortId,
      title: this._BookContent.title(pageFragment.id),
    });
  }
}
