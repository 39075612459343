import {ParentBlot} from 'parchment';
import Block from '@reedsy/quill/blots/block';
import Break from '@reedsy/quill/blots/break';
import SoftBreakBlot from './soft-break-blot';
import {ALIGN_VALUES} from '@reedsy/studio.shared/services/quill/modules/toolbar/toolbar-container';
import Cursor from '@reedsy/quill/blots/cursor';
import {dig} from '@reedsy/utils.dig';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';

const {INLINE} = TrackChangesKeys;

export default class IndentedBlockBlot extends Block {
  public static readonly classes = Object.freeze({
    INDENTED: 'indented',
  });

  public override optimize(context: any): void {
    this.updateIndentation();
    super.optimize(context);

    // We may go from empty line to having content (or vice versa) in this paragraph,
    // so let's make sure we update the next paragraph if needed.
    if (this.next instanceof IndentedBlockBlot) this.next.updateIndentation();
  }

  public updateIndentation(): void {
    this.domNode.classList.toggle(IndentedBlockBlot.classes.INDENTED, this.shouldIndent());
  }

  private shouldIndent(): boolean {
    return this.prev &&
      this.prev.statics.blotName === this.statics.blotName &&
      !this.followsEmptyLine() &&
      !this.isAligned();
  }

  private followsEmptyLine(): boolean {
    return this.followsBreak() ||
      this.followsBlockWithOnlyCursor() ||
      this.followsParagraphWithOnlyWhitespace() ||
      this.followsParagraphWithAllTrackedDeletions();
  }

  private followsBreak(): boolean {
    return this.followsBrElement() || this.followsSoftBreak();
  }

  private followsBrElement(): boolean {
    const prev = this.prev as ParentBlot;
    const node = dig(prev, 'children', 'tail', 'domNode') as HTMLElement;
    return dig(node, 'tagName') === Break.tagName;
  }

  private followsSoftBreak(): boolean {
    const prev = this.prev as ParentBlot;
    return prev.children.tail instanceof SoftBreakBlot;
  }

  private isAligned(): boolean {
    const align = this.formats().align;
    // Ignore the first align value, which is unaligned
    return ALIGN_VALUES.indexOf(align) > 0;
  }

  private followsBlockWithOnlyCursor(): boolean {
    const prev = this.prev as ParentBlot;
    return prev.children.length === 1 &&
      prev.children.head instanceof Cursor &&
      prev.children.head.textNode.data === Cursor.CONTENTS;
  }

  private followsParagraphWithOnlyWhitespace(): boolean {
    const prev = this.prev;
    return prev instanceof IndentedBlockBlot &&
      !!prev.domNode.innerText.match(/^\s+$/);
  }

  private followsParagraphWithAllTrackedDeletions(): boolean {
    const prev = this.prev as ParentBlot;
    let child = prev.children.head;
    while (child) {
      const node = child.domNode;
      const isTrackedDeletion = (
        node instanceof HTMLElement &&
        node.hasAttribute(INLINE.DELETION)
      );
      if (!isTrackedDeletion) return false;
      child = child.next;
    }
    return true;
  }
}
