import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_VuiIconTriangle = _resolveComponent("VuiIconTriangle")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["expander", {collapsed: !_ctx.modelValue}])
  }, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_VuiLoadingIndicator, { class: "sm" })
          ]))
        : _createCommentVNode("", true),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createVNode(_component_VuiIconTriangle, {
        class: _normalizeClass(_ctx.iconClass)
      }, null, 8, ["class"])
    ])
  ], 2))
}