import BrowserTitlePluginFactory from './browser-title';
import CurrentContentPluginFactory from './current-content-plugin';
import InitLocalStorageHelperPluginFactory from './init-local-storage-helper';
import LoadPluginFactory from './load';
import RedirectToFirstPagePluginFactory from './redirect-to-first-page';
import ScrollPositionPluginFactory from './scroll-position-plugin';
import ScreenSizePluginFactory from '@reedsy/studio.shared/store/plugins/browser/screen-size';
import OrientationPluginFactory from '@reedsy/studio.shared/store/plugins/browser/orientation';
import CloseModalsPluginFactory from './close-modals';
import {AppThemePluginFactory} from './app-theme';

export const PLUGINS = Object.freeze([
  CloseModalsPluginFactory,
  InitLocalStorageHelperPluginFactory,
  LoadPluginFactory,
  CurrentContentPluginFactory,
  RedirectToFirstPagePluginFactory,
  BrowserTitlePluginFactory,
  ScrollPositionPluginFactory,
  ScreenSizePluginFactory,
  OrientationPluginFactory,
  AppThemePluginFactory,
]);
