<template>
  <button
    type="button"
    class="option-button button-md reedsy-accented translucent"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';

@Component({})
export default class OptionButton extends BookViewerVue {}
</script>

<style lang="scss" scoped>
.option-button {
  color: var(--reedsy-plain-onDefault);
  background-color: unset;

  @include screen-less-than(md) {
    padding: $space-md;
  }

  &.active {
    background-color: var(--local-color-muted);
    box-shadow: $button-on-active-shadow;
  }
}
</style>

<style lang="scss">
.option-button .vui-icon {
  @include size($book-viewer-top-bar-icon-md);

  svg {
    pointer-events: none;
  }
}
</style>
