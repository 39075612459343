import IChange from '@reedsy/studio.shared/store/modules/rich-text/i-change';
import Op from '@reedsy/quill-delta/dist/Op';
import Delta from '@reedsy/quill-delta';
import {trackedDeletions, isTracked} from '@reedsy/studio.shared/services/track-changes/track-changes-utils';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import AttributeMap from '@reedsy/quill-delta/dist/AttributeMap';
import {clone} from '@reedsy/utils.clone';
import hasId from './has-id';

const {CHANGE, BLOCK, INLINE} = TrackChangesKeys;

export default function acceptAll(changes: IChange[], contents: Op[]): Delta {
  const ops: Op[] = [];
  const changeIds = new Set(changes.map((change) => change.id));
  contents.forEach((op) => {
    if (shouldAcceptDeletion(op, changeIds)) return;
    if (op.attributes) {
      op = clone(op);
      let attributes = op.attributes;
      changeIds.forEach((id) => {
        attributes = AttributeMap.compose(attributes, {
          [INLINE.INSERTION]: {[id]: null},
          [BLOCK.INSERTION]: {[id]: null},
          [INLINE.REFORMAT]: {[id]: null},
          [BLOCK.REFORMAT]: {[id]: null},
        }, false);
      });

      if (!isTracked(attributes)) {
        attributes = AttributeMap.compose(attributes, {[CHANGE]: null}, false);
      }

      if (attributes) op.attributes = attributes;
      else delete op.attributes;
    }

    ops.push(op);
  });

  return new Delta(contents).diff(new Delta(ops));
}

function shouldAcceptDeletion(op: Op, changeIds: Set<string>): boolean {
  const deletions = trackedDeletions(op);
  return hasId(deletions, changeIds);
}
