import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_SectionTitle, { class: "chapter-title" }),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createVNode(_component_RichTextReader, {
        id: "chapter-reader",
        "rich-text-id": _ctx.richTextId
      }, null, 8, ["rich-text-id"])
    ]),
    _: 1
  }))
}