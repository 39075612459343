import {BlotConstructor} from 'parchment';

export function blotTags(...blots: BlotConstructor[]): string[] {
  const tags: string[] = [];
  blots.forEach((blot) => {
    if (typeof blot.tagName === 'string') tags.push(blot.tagName.toUpperCase());
    else tags.push(...blot.tagName.map((tag) => tag.toUpperCase()));
  });
  return tags;
}
