import StoreWrapper from '@reedsy/studio.shared/store/store-wrapper';
import {BookViewerBindings} from './types';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import router from './router';
import {TypedContainer} from '@reedsy/utils.inversify';

export function bindBookViewerConstants(container: TypedContainer<BookViewerBindings>, options: IOptions = {}): void {
  const method = options.rebind ? 'rebind' : 'bind';

  container[method]('StoreWrapper')
    .to(StoreWrapper)
    .inSingletonScope();

  container[method]('LoggerFactory')
    .toConstantValue(loggerFactory);

  container[method]('Router')
    .toConstantValue(router);

  container[method]('ModalsData')
    .toConstantValue({});
}

interface IOptions {
  rebind?: boolean;
}
