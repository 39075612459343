import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TOCContentFragmentTitle = _resolveComponent("TOCContentFragmentTitle")!
  const _component_TOCContentFragment = _resolveComponent("TOCContentFragment")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["table-of-contents-content-fragment", {active: _ctx.isActive}])
  }, [
    _createVNode(_component_TOCContentFragmentTitle, {
      modelValue: _ctx.expanded,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expanded) = $event)),
      "is-collapsible": _ctx.isCollapsible,
      "nest-level": _ctx.nestLevel,
      "toc-entry": _ctx.tocEntry
    }, null, 8, ["modelValue", "is-collapsible", "nest-level", "toc-entry"]),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createVNode(_component_ExpandTransition, null, {
      default: _withCtx(() => [
        (_ctx.isCollapsible && _ctx.expanded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (tocEntry) => {
                return (_openBlock(), _createBlock(_component_TOCContentFragment, {
                  key: tocEntry.id,
                  "toc-entry": tocEntry,
                  "nest-level": _ctx.nestLevel + 1
                }, null, 8, ["toc-entry", "nest-level"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}