import {Op} from '@reedsy/quill-delta';
// eslint-disable-next-line no-restricted-imports
import {opsToPlainText as _opsToPlainText, IPlainText} from '@reedsy/utils.ot-rich-text';
import {SmartQuotes} from '@reedsy/utils.string';

export function opsToPlainText(ops: ReadonlyArray<Op>): IPlainText[][] {
  const plainTexts = _opsToPlainText(ops);
  for (const group of plainTexts) {
    for (const plainText of group) {
      plainText.text = SmartQuotes.remove(plainText.text);
    }
  }
  return plainTexts;
}
