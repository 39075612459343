import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';

export default class SceneBreakPlaceholder extends JsxComponent {
  public render(): HTMLElement {
    return (
      <div>
        <div class="ql-scene-break" />
      </div>
    );
  }
}
