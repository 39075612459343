import alignCenter from '@reedsy/studio.assets/icons/align-center.svg?raw';
import alignLeft from '@reedsy/studio.assets/icons/align-left.svg?raw';
import alignRight from '@reedsy/studio.assets/icons/align-right.svg?raw';
import blockquote from '@reedsy/studio.assets/icons/blockquote.svg?raw';
import bold from '@reedsy/studio.assets/icons/bold.svg?raw';
import callout from '@reedsy/studio.assets/icons/callout.svg?raw';
import codeBlock from '@reedsy/studio.assets/icons/code-block.svg?raw';
import comment from '@reedsy/studio.assets/icons/comment.svg?raw';
import h1 from '@reedsy/studio.assets/icons/h1.svg?raw';
import h2 from '@reedsy/studio.assets/icons/h2.svg?raw';
import h3 from '@reedsy/studio.assets/icons/h3.svg?raw';
import link from '@reedsy/studio.assets/icons/link.svg?raw';
import italic from '@reedsy/studio.assets/icons/italic.svg?raw';
import listBullet from '@reedsy/studio.assets/icons/list-bullet.svg?raw';
import listNumbered from '@reedsy/studio.assets/icons/list-numbered.svg?raw';
import paragraph from '@reedsy/studio.assets/icons/paragraph.svg?raw';
import strike from '@reedsy/studio.assets/icons/strike.svg?raw';
import subscript from '@reedsy/studio.assets/icons/subscript.svg?raw';
import superscript from '@reedsy/studio.assets/icons/superscript.svg?raw';
import underline from '@reedsy/studio.assets/icons/underline.svg?raw';

export default {
  align: {
    '': alignLeft,
    center: alignCenter,
    right: alignRight,
  },
  blockquote,
  bold,
  callout,
  clean: paragraph,
  'code-block': codeBlock,
  header: {
    1: h1,
    2: h2,
    3: h3,
  },
  italic,
  link,
  list: {
    bullet: listBullet,
    ordered: listNumbered,
  },
  script: {
    sub: subscript,
    super: superscript,
  },
  strike,
  'track-comment': comment,
  underline,
};
