import {Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {memoize} from '@reedsy/utils.decorator';
import {IReaderBookInfo} from '@reedsy/studio.isomorphic/controllers/api/reader/book/reader-book-info';
import {BookContentDataModule} from '@reedsy/studio.shared/store/modules/book-content/data';
import {TitleGenerator} from '@reedsy/studio.isomorphic/utils/book-content/title-generator';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import {BookOwnerModule} from '@reedsy/studio.viewer/store/modules/book-owner';
import {getBookFirstPageId} from '@reedsy/studio.viewer/utils/get-book-first-page-id';

@injectable()
export class BookViewerBookContentModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,

    @$inject('StoreModule')
    @named(StoreName.BookViewerBookOwner)
    BookOwner: BookOwnerModule,
  ) {
    @Module({name: StoreName.BookViewerBookContent, store})
    class BookViewerBookContent extends BookContentDataModule {
      public orderNumbers: {[contentId: string]: number} = {};
      public bookInfo: IReaderBookInfo = null;

      public get ownerId(): string {
        return this.bookInfo?.ownerId || null;
      }

      public get bookTitle(): string {
        return this.bookInfo?.title || '';
      }

      public get bookSubtitle(): string {
        return this.bookInfo?.subtitle || '';
      }

      public get bookId(): string {
        return this.bookInfo?._id || null;
      }

      public get legacyBookId(): string {
        return this.bookInfo?.legacyMarketplaceId || null;
      }

      public get firstPageId(): string {
        return getBookFirstPageId(this.flat);
      }

      public get title() {
        return (id: string) => {
          return this.titleGenerator.titleById(id);
        };
      }

      public get authorName(): string {
        return this.titleGenerator.authorName;
      }

      private get titleGenerator(): TitleGenerator {
        return new TitleGenerator(this.flat, BookOwner.name);
      }

      @Action
      @memoize
      public async initialise(sharableUrlShortId: string): Promise<void> {
        const bookInfo = await api.getReaderBook(sharableUrlShortId);
        this.SET_ORDER_NUMBERS(bookInfo.orderNumbers);
        this.BOOK_INFO(bookInfo.info);
        this.DATA({id: this.bookInfo._id, data: bookInfo.bookContents});
      }

      @Mutation
      private BOOK_INFO(bookInfo: IReaderBookInfo): void {
        this.bookInfo = bookInfo;
      }

      @Mutation
      private SET_ORDER_NUMBERS(orderNumbers: Record<string, number>): void {
        this.orderNumbers = orderNumbers;
      }
    }

    this.Module = BookViewerBookContent;
  }
}

export type BookViewerBookContentModule = InstanceType<BookViewerBookContentModuleFactory['Module']>;
