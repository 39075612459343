import ListItem, {ListContainer} from '@reedsy/quill/formats/list';

const ALLOWED_FORMATS = new Set([
  'ordered',
  'bullet',
]);

export default class ReedsyListItem extends ListItem {
  public override optimize(context: any): void {
    const formats = this.formats();
    if (!ALLOWED_FORMATS.has(formats.list)) this.unwrap();
    super.optimize(context);
  }
}

export class ReedsyListContainer extends ListContainer {
  public static override allowedChildren = [ReedsyListItem];
}
