import {BlockEmbed} from '@reedsy/quill/blots/block';
import Scroll from '@reedsy/quill/blots/scroll';
import {LegacyImageHelper} from '@reedsy/utils.legacy-image-url';
import {config} from '@reedsy/studio.shared/config';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {isBookImageUrl} from '@reedsy/studio.shared/services/quill/helpers/is-book-image-url';
import findQuill from '@reedsy/studio.shared/services/quill/helpers/find-quill';
import {dig} from '@reedsy/utils.dig';
import {isBoardsTutorialImageUrl} from '@reedsy/studio.shared/services/quill/helpers/is-boards-tutorial-image-url';
import {last} from '@reedsy/utils.iterable';
import {IReedsyOptionsMetadata} from '@reedsy/studio.shared/services/quill/i-reedsy-options-metadata';

const {BLOCK} = TrackChangesKeys;

export default class FigureImage extends BlockEmbed {
  public static override readonly blotName = 'image-block';
  public static override readonly tagName = 'IMG';

  public static override create(value: string): HTMLElement {
    const domNode = super.create(value) as HTMLElement;
    domNode.setAttribute('src', value);
    return domNode;
  }

  public static override value(domNode: HTMLElement): string {
    return domNode.getAttribute('src');
  }

  private readonly legacyImageHelper: LegacyImageHelper;

  public constructor(scroll: Scroll, domNode: HTMLElement) {
    super(scroll, domNode);
    this.legacyImageHelper = new LegacyImageHelper(config.cdn.host);
    this.domNode.draggable = true;
  }

  public override value(): {[blotName: string]: string} {
    let value = FigureImage.value(this.domNode as HTMLElement);
    // We have to exclude the urls for images which are used in the boards tutorial
    // to make them available on all environments.
    if (!value.includes('reedsy.com/boards-tutorial/')) {
      value = this.legacyImageHelper.cdnUrl(value) || '';
    }

    return {
      [FigureImage.blotName]: value,
    };
  }

  public isValid(): boolean {
    const url = this.value()[FigureImage.blotName];

    const quill = findQuill(this.domNode);
    const metadata = (quill.options as any).metadata as IReedsyOptionsMetadata;
    const bookId = dig(metadata, 'bookId');
    const legacyBookId = dig(metadata, 'legacyBookId');

    if (isBoardsTutorialImageUrl(url)) return true;
    // We need to check if the image is coming from other book url
    // and if it does we need to reupload it
    if ((bookId || legacyBookId) && !isBookImageUrl({bookId, url, legacyBookId})) return false;
    if (this.isMissingFileExtension()) return false;
    return !!url;
  }

  public isTrackedDeletion(): boolean {
    return !!this.attributes.values()[BLOCK.DELETION];
  }

  private isMissingFileExtension(): boolean {
    const url = this.value()[FigureImage.blotName];
    const filename = last(url.split('/'));
    return !filename.includes('.');
  }
}
