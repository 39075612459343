import {Op} from '@reedsy/quill-delta';
// eslint-disable-next-line no-restricted-imports
import {opsToString as _opsToString, IOpsToStringOptions as IOpsToStringOptionsBase} from '@reedsy/utils.ot-rich-text';
import {SmartQuotes} from '@reedsy/utils.string';

export interface IOpsToStringOptions extends IOpsToStringOptionsBase {
  keepSmartQuotes?: boolean;
}

export function opsToString(ops: ReadonlyArray<Op>, options: IOpsToStringOptions = {}): string {
  const s = _opsToString(ops, options);
  return options.keepSmartQuotes ? s : SmartQuotes.remove(s);
}
