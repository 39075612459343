<template>
  <PageContainer>
    <div class="dedication">
      <RichTextReader
        :rich-text-id="richTextId"
        empty-default-placeholder-text="Dedication page is still a work in progress!"
        class="align-center italic"
      />
    </div>
  </PageContainer>
</template>

<script lang="ts">
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import {IDedication} from '@reedsy/reedsy-sharedb/lib/common/book-content/dedication';
import PageContainer from '@reedsy/studio.viewer/components/page-container/page-container.vue';
import SectionTitle from '@reedsy/studio.viewer/components/section-title/section-title.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';

@Component({
  components: {
    RichTextReader,
    PageContainer,
    SectionTitle,
  },
})
export default class Dedication extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  public get dedication(): IDedication {
    return this.$displayedData.content.data as IDedication;
  }

  public get richTextId(): string {
    return this.dedication.references.content.id;
  }
}
</script>
