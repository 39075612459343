import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "wrapper",
  class: "content-pane scrollbar-alt"
}
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createVNode(_component_Header, {
        ref: "header",
        title: _ctx.title
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true),
          _cache[0] || (_cache[0] = _createTextVNode()),
          _renderSlot(_ctx.$slots, "header-after-content", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["title"])
    ], true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createVNode(_component_Footer, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ]),
      _: 3
    })
  ], 512))
}