import BubbleTooltip from './bubble-tooltip-no-constructor';
import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import {Bounds} from '@reedsy/studio.shared/services/quill/modules/bounds.interface';

const TOOLTIP_INTERSECTION_OFFSET = '-8px';
export default class ReedsyTooltip extends BubbleTooltip {
  public static readonly classes = Object.freeze({
    EDITING: 'ql-editing',
    FLIP: 'ql-flip',
    HIDDEN: 'ql-tooltip-hidden',
    READONLY: 'ql-readonly',
  });

  private readonly positionedAncestor: HTMLElement;
  private readonly arrow: HTMLElement;

  public constructor(quill: Quill, bounds: HTMLElement) {
    super(quill, bounds);
    this.quill.container.classList.remove('ql-bubble');
    this.quill.container.classList.add('ql-reedsy');
    this.arrow = this.root.querySelector('.ql-tooltip-arrow');
    const toolbarParent: string | HTMLElement = (quill.options as any).toolbarParent;
    if (toolbarParent) {
      const parent = typeof toolbarParent === 'string' ?
        document.querySelector(toolbarParent) :
        toolbarParent;
      parent.appendChild(this.root);
    }
    this.positionedAncestor = this.findPositionedAncestor();
    this.listen();
  }

  public override show(): void {
    this.root.classList.remove(ReedsyTooltip.classes.EDITING);
    this.root.classList.remove(ReedsyTooltip.classes.HIDDEN);
    this.changeTooltipFocusability(true);
  }

  public override hide(): void {
    this.root.classList.add(ReedsyTooltip.classes.HIDDEN);
    this.changeTooltipFocusability(false);
  }

  public override listen(): void {
    this.quill.on(Quill.events.EDITOR_CHANGE, this.handleSelectionChange.bind(this));

    // If the nearest positioned ancestor isn't the Quill container, then we need
    // to keep an eye on whether the toolbar has scrolled out of its container
    if (this.positionedAncestor !== this.quill.root.parentElement) {
      const options: IntersectionObserverInit = {
        root: this.positionedAncestor,
        threshold: 1,
        rootMargin: TOOLTIP_INTERSECTION_OFFSET,
      };
      new IntersectionObserver((entries) => {
        if (!entries[0].isIntersecting) this.hide();
      }, options).observe(this.arrow);
    }
  }

  public override position(reference: Bounds): number {
    reference = this.positionReferenceRect(reference);
    const shift = super.position(reference);
    this.unflip(reference);
    return shift;
  }

  protected positionReferenceRect(reference: Bounds): Bounds {
    if (this.positionedAncestor === this.quill.root.parentElement) return reference;

    // If the nearest positioned ancestor isn't the Quill container, then we will
    // need to readjust our absolute positioning accordingly
    const quillBounds = this.quill.root.getBoundingClientRect();
    const datumBounds = this.positionedAncestor.getBoundingClientRect();
    const topOffset = quillBounds.top - datumBounds.top;
    const leftOffset = quillBounds.left - datumBounds.left;

    reference.left += leftOffset;
    reference.right += leftOffset;
    reference.top += topOffset;
    reference.bottom += topOffset;

    return reference;
  }

  protected unflip(reference: Bounds): void {
    // Sometimes the super.position can apply a flip that forces the tooltip out
    // the top of the viewport (eg if our Quill instance is near the top of the view).
    // Let's check for this case, and undo it.
    const rootBounds = this.root.getBoundingClientRect();
    if (this.root.classList.contains(ReedsyTooltip.classes.FLIP) && rootBounds.top < 0) {
      this.root.classList.remove(ReedsyTooltip.classes.FLIP);
      this.root.style.top = `${reference.bottom + this.quill.root.scrollTop}px`;
    }
  }

  protected handleSelectionChange(eventType: string, range: Range, oldRange: Range, source: string): void {
    if (eventType === Quill.events.TEXT_CHANGE) return this.reposition();
    if (range != null && range.length > 0) {
      if (source === Quill.sources.USER) {
        this.show();
        this.setInitialPosition(range);
      } else {
        this.reposition();
      }
    } else if (this.quill.hasFocus()) {
      this.hide();
    }
  }

  protected reposition(): void {
    const range = this.quill.selection.savedRange;
    if (!range.length) return;
    const bounds = this.quill.getBounds(range);
    this.position(bounds);
  }

  // Modified from Quill's BubbleTheme constructor
  private setInitialPosition(range: Range): void {
    const lines = this.quill.getLines(range.index, range.length);
    if (lines.length === 1) {
      this.position(this.quill.getBounds(range));
    } else {
      const lastLine = lines[lines.length - 1];
      const index = this.quill.getIndex(lastLine);
      const length = Math.min(
        lastLine.length() - 1,
        range.index + range.length - index,
      );
      const indexBounds = this.quill.getBounds(index, length);
      this.position(indexBounds);
    }
  }

  private findPositionedAncestor(): HTMLElement {
    let element = this.root.parentElement;
    while (element) {
      if (window.getComputedStyle(element).position !== 'static') return element;
      element = element.parentElement;
    }
  }

  private changeTooltipFocusability(isFocusable: boolean): void {
    this.root.querySelectorAll('button, a, input').forEach(
      (element) => (element as HTMLElement).tabIndex = isFocusable ? 0 : -1,
    );
  }
}
