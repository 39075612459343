
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import SunIcon from '@reedsy/studio.shared/components/icons/icon-sun.vue';
import MoonIcon from '@reedsy/studio.shared/components/icons/icon-moon.vue';
import FontIcon from '@reedsy/studio.shared/components/icons/icon-font.vue';
import OptionButton from './option-button.vue';
import DrawerPopover from '@reedsy/studio.viewer/components/drawer-popover/drawer-popover.vue';
import FontSettingsMenu from '@reedsy/studio.viewer/components/font-settings-menu/font-settings-menu.vue';
import time from '@reedsy/studio.shared/filters/time';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerLayoutModule} from '@reedsy/studio.viewer/store/modules/layout/book-viewer-layout';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';
import {BookViewerShareableUrlDetailsModule} from '@reedsy/studio.viewer/store/modules/shareable-url-details/shareable-url-details';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {AppTheme} from '@reedsy/studio.shared/store/modules/user-settings/app-theme';

@Component({
  components: {
    SunIcon,
    MoonIcon,
    FontIcon,
    OptionButton,
    FontSettingsMenu,
    DrawerPopover,
  },
})
export default class TopBar extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerLayout)
  public $layout: BookViewerLayoutModule;

  @$lazyInjectStore(StoreName.BookViewerSettings)
  public $settings: BookViewerSettingsModule;

  @$lazyInjectStore(StoreName.BookViewerSharableUrlDetails)
  public $shareableUrlDetails: BookViewerShareableUrlDetailsModule;

  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  public get isDarkMode(): boolean {
    return this.$settings.appTheme === AppTheme.Dark;
  }

  public get fontSettingsMenuIsOpen(): boolean {
    return this.$layout.fontSettingsMenuIsOpen;
  }

  public get alertBarIsOpen(): boolean {
    return this.$shareableUrlDetails.isSoonToBeExpired;
  }

  public get sharableUrlExpiryDate(): Date {
    return this.$shareableUrlDetails.shareableUrlDetails.expiryDate;
  }

  public get displayedExpiryDate(): string {
    return time(this.sharableUrlExpiryDate);
  }

  public get bookTitle(): string {
    return this.$bookContent.bookTitle;
  }

  public toggleLeftSidebar(): void {
    this.$layout.toggleLeftSidebar();
  }

  public toggleDarkMode(): void {
    this.$settings.toggleDarkMode();
  }

  public async toggleFontSettingsMenu(): Promise<void> {
    this.$layout.toggleFontSettingsMenu();
  }

  public async hideFontSettingsMenu(): Promise<void> {
    this.$layout.hideFontSettingsMenu();
  }

  public tooltipOptions(content: string): any {
    return {
      content,
      distance: 15,
    };
  }
}
