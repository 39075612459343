import {StyleAttributor} from 'parchment';
import Delta from '@reedsy/quill-delta';
import boldStyleAttributor from './bold-style-attributor';
import italicStyleAttributor from './italic-style-attributor';
import strikeStyleAttributor from './strike-style-attributor';
import underlineStyleAttributor from './underline-style-attributor';

const attributors = Object.freeze([
  boldStyleAttributor,
  italicStyleAttributor,
  strikeStyleAttributor,
  underlineStyleAttributor,
]);

export default function styleMatcher(node: HTMLElement, delta: Delta): Delta {
  const styles = new Set(StyleAttributor.keys(node));

  attributors.forEach((attributor) => {
    if (!styles.has(attributor.keyName)) return;
    const value = attributor.value(node);
    delta.forEach((op) => {
      const attributes = op.attributes || {};
      if (attributor.attrName in attributes) return;
      attributes[attributor.attrName] = value;
      op.attributes = attributes;
    });
  });

  return delta;
}
