import {bookContentStructure} from '@reedsy/reedsy-sharedb/lib/server/book-contents-structure';
import {IDropdownOption} from '@reedsy/studio.shared/components/forms/dropdown/i-option';
import {deepFreeze} from '@reedsy/utils.object';

const editions: ReadonlyArray<IDropdownOption> = deepFreeze(
  bookContentStructure.editions.map((text, index) => ({
    value: `${index + 1}`,
    text,
  })),
);

export default editions;
