import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Expander = _resolveComponent("Expander")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["section-control", {
      [`section-control-nest-level-${_ctx.nestLevel}`]: true,
      'top-matter': _ctx.isTopMatter,
      'part-fragment': _ctx.isPart,
    }])
  }, [
    (_ctx.isCollapsible)
      ? (_openBlock(), _createBlock(_component_Expander, {
          key: 0,
          modelValue: _ctx.expanded,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expanded) = $event)),
          "icon-class": "icon-xs expander"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tocEntry.url ? 'RouterLink' : 'p'), {
      to: _ctx.tocEntry.url,
      class: _normalizeClass(["title ellipsis content-title", {'has-children': _ctx.isPart}]),
      onClick: _ctx.closeLeftSidebar
    }, {
      default: _withCtx(() => [
        (_ctx.orderNumberLiteral)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["order-number", {
          'part-number': _ctx.isPart,
          'chapter-number': _ctx.isChapter,
        }])
            }, _toDisplayString(_ctx.orderNumberLiteral), 3))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["to", "class", "onClick"]))
  ], 2))
}