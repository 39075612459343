import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import BookViewerQuill from '@reedsy/studio.viewer/services/quill';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class SetQuillMixin extends BookViewerVue {
  public quill: BookViewerQuill;

  public mounted(): void {
    this.quill = (this.$refs.editor as any).quill;
  }
}
