import {createReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import {config} from '@reedsy/studio.shared/config';
import pathFromType from '@reedsy/studio.shared/utils/path-from-type';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {RouteRecordRaw, createWebHashHistory, createWebHistory} from 'vue-router';
import {BookViewerRouteName} from '@reedsy/studio.shared/router/route-names/book-viewer-route-name';

// If we import these components directly, then we trigger a large dependency cascade,
// which delays the availability of the router to the Vuex store plugins (which get
// initialised virtually immediately in the Inversify DI). Instead, we use the lazy
// loading approach, which short-circuits this dependency cascade.
const components = {
  BookViewerApp: () => import('../BookViewerApp.vue'),
  BookViewer: () => import('../views/BookViewer.vue'),
  NotFound: () => import('../views/NotFound.vue'),
  Chapter: () => import('../views/Chapter.vue'),
  Part: () => import('../views/Part.vue'),
  AboutTheAuthor: () => import('../views/AboutTheAuthor.vue'),
  AlsoBy: () => import('../views/also-by/AlsoBy.vue'),
  Copyright: () => import('../views/Copyright.vue'),
  Dedication: () => import('../views/Dedication.vue'),
  Epigraph: () => import('../views/Epigraph.vue'),
};

const component = (name: keyof typeof components): any => {
  if (config.env.test) return () => import('../../../shared/test/fixtures/test-component.vue');
  return components[name];
};

const bookRoutes: RouteRecordRaw[] = [
  {
    name: BookViewerRouteName.Chapter,
    path: `${pathFromType(MatterType.Chapter)}/:contentId`,
    component: component('Chapter'),
  },
  {
    name: BookViewerRouteName.AboutTheAuthor,
    path: `${pathFromType(MatterType.AboutTheAuthor)}/:contentId`,
    component: component('AboutTheAuthor'),
  },
  {
    name: BookViewerRouteName.AlsoBy,
    path: `${pathFromType(MatterType.AlsoBy)}/:contentId`,
    component: component('AlsoBy'),
  },
  {
    name: BookViewerRouteName.Copyright,
    path: `${pathFromType(MatterType.Copyright)}/:contentId`,
    component: component('Copyright'),
  },
  {
    name: BookViewerRouteName.Dedication,
    path: `${pathFromType(MatterType.Dedication)}/:contentId`,
    component: component('Dedication'),
  },
  {
    name: BookViewerRouteName.Epigraph,
    path: `${pathFromType(MatterType.Epigraph)}/:contentId`,
    component: component('Epigraph'),
  },
  {
    name: BookViewerRouteName.Part,
    path: `${pathFromType(MatterType.Part)}/:contentId`,
    component: component('Part'),
  },
];

bookRoutes.forEach((route) => route.alias = `${route.path}/:titleSlug`);

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: BookViewerRouteName.App,
    component: component('BookViewerApp'),
    redirect: {
      name: BookViewerRouteName.NotFound,
    },
    children: [
      {
        path: 'not-found',
        name: BookViewerRouteName.NotFound,
        component: component('NotFound'),
      },
      {
        path: ':shareableUrlShortId',
        name: BookViewerRouteName.BookViewer,
        component: component('BookViewer'),
        children: bookRoutes,
      },
    ],
  },
];

const router = createReedsyRouter({
  history: config.env.test ? createWebHashHistory() : createWebHistory(),
  routes,
});

export default router;
