<template>
  <div class="default-empty-reader-placeholder">
    <img
      class="default-empty-reader-placeholder-image"
      src="@/assets/inlined/chapter-in-progress.svg"
    >
    <slot name="empty-placeholder-text">
      <p class="default-empty-reader-placeholder-text theme-body">
        {{ emptyText }}
      </p>
    </slot>
  </div>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';

@Component({})
export default class DefaultEmptyReaderPlaceholder extends BookViewerVue {
  @Prop({type: String, default: 'This chapter is still a work in progress!'})
  public emptyText: string;
}
</script>

<style lang="scss">
.default-empty-reader-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: $editor-row-gap;
  width: 100%;

  .default-empty-reader-placeholder-image {
    width: 100%;
    height: auto;
  }

  .default-empty-reader-placeholder-text {
    margin: 0;
    font-style: italic;
  }
}
</style>
