import ReedsyTooltip from '@reedsy/studio.shared/services/quill/theme/reedsy-tooltip';
import {Range} from '@reedsy/quill/core/selection';
import {EmitterSource} from '@reedsy/quill/core/emitter';
import Quill from '@reedsy/quill/core';

export class CollapsedSelectionTooltip extends ReedsyTooltip {
  protected override handleSelectionChange(
    eventType: string,
    range: Range,
    oldRange: Range,
    source: EmitterSource,
  ): void {
    if (source !== Quill.sources.USER) return;
    if (range === null || range.length > 0) {
      return this.hide();
    }
  }
}
