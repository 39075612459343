import {encodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';
import pathFromType from '@reedsy/studio.shared/utils/path-from-type';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import slug from '@reedsy/studio.shared/router/helpers/slug';

export interface IBuildFragmentUrlOptions {
  content: ContentFragment;
  sharableUrlShortId: string;
  title?: string;
  hash?: string;
}

export function buildFragmentUrl({
  content,
  sharableUrlShortId,
  title,
  hash,
}: IBuildFragmentUrlOptions): string {
  if (!content) return null;
  const type = pathFromType(content.data.type);
  if (!type) return null;
  const id = encodeObjectId(content.id);
  title = slug(title || '');
  hash = hash ? `#${encodeURIComponent(hash)}` : '';
  return `/${sharableUrlShortId}/${type}/${id}/${title}${hash}`;
}
