import Quill from '@reedsy/quill/core';
import IChangeRange from './i-change-range';
import combineRanges from './combine-ranges';
import Blot from '@reedsy/studio.shared/services/quill/blots/blot';
import Endnote from '@reedsy/studio.shared/services/quill/formats/endnote';
import TrackDeletionBlockIndicator from '@reedsy/studio.shared/services/quill/blots/track-changes/track-deletion-block-indicator';
import {TrackChangesKeys, TRACK_CHANGES_KEYS} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import UserChangeId from '@reedsy/studio.shared/services/quill/helpers/track-changes/user-change-id';
import {FigureImage} from '@reedsy/studio.shared/services/quill/blots/figure';
import SceneBreak from '@reedsy/studio.shared/services/quill/blots/scene-break';
import IndentedBlockBlot from '@reedsy/studio.shared/services/quill/blots/indented-block-blot';
import Block from '@reedsy/quill/blots/block';
import TrackComment from '@reedsy/studio.shared/services/quill/blots/track-changes/track-comment';
import TrackChanges from '@reedsy/studio.shared/services/quill/modules/track-changes';
import {Bounds} from '@reedsy/studio.shared/services/quill/modules/bounds.interface';

const {BLOCK, INLINE} = TrackChangesKeys;
const ACTIVE_CHANGE_CONTAINER_CLASS = 'active-change-container';
const ACTIVE_CHANGE_CLASS = 'active-change';
const LINE_TOLERANCE_PX = 7;
const COLLAPSED_DELETION_WIDTH_PX = 15;
const MAX_ACTIVE_CHANGE_NODES = 50;

export default class ChangeHighlighter {
  public static readonly classes = Object.freeze({
    ACTIVE_CHANGE: ACTIVE_CHANGE_CLASS,
  });

  private readonly quill: Quill;
  private readonly container: HTMLElement;

  private activeChangeId: string;
  private userId: string;

  public constructor(quill: Quill) {
    this.quill = quill;
    this.container = quill.addContainer(ACTIVE_CHANGE_CONTAINER_CLASS);

    new ResizeObserver(this.redraw.bind(this)).observe(quill.root);
    quill.on(Quill.events.TEXT_CHANGE, this.redraw.bind(this));
    const editor = quill.container.querySelector('.ql-editor');
    // Handle scroll for endnotes
    editor.addEventListener('scroll', this.redraw.bind(this));
  }

  public select(changeId: string): void {
    this.activeChangeId = changeId || null;
    this.redraw();
  }

  public redraw(): void {
    window.requestAnimationFrame(() => {
      this.removeHighlight();
      if (!this.activeChangeId) return;
      const nodes = this.changeNodes();
      // Ignore large, broken changes (eg find/replace), which are expensive to highlight
      if (nodes.length > MAX_ACTIVE_CHANGE_NODES) return;
      if (this.activeChangeIsComment()) return this.highlightActiveComment(nodes);
      this.userId = this.userIdFromNodes(nodes);
      const ranges = this.activeChangeRanges(nodes);
      ranges.forEach(this.highlightRange.bind(this));
    });
  }

  private removeHighlight(): void {
    while (this.container.lastChild) this.container.lastChild.remove();
    const comments = this.quill.root.querySelectorAll(`.${TrackComment.activeClass}`);
    comments.forEach((comment) => comment.classList.remove(TrackComment.activeClass));
    const active = this.quill.root.querySelectorAll(`.${ACTIVE_CHANGE_CLASS}`);
    active.forEach((node) => node.classList.remove(ACTIVE_CHANGE_CLASS));
  }

  private highlightActiveComment(nodes: HTMLElement[]): void {
    nodes.forEach((node) => node.classList.add(TrackComment.activeClass));
  }

  private activeChangeRanges(nodes: HTMLElement[]): IChangeRange[] {
    const ranges = Array.from(nodes)
      .map((node) => this.quill.options.registry.find(node))
      .filter(Boolean)
      .map((blot) => {
        let start: number;
        let end: number;
        const isBlockInsertion = blot instanceof Block &&
          blot.domNode.matches(`[${BLOCK.INSERTION}*="${this.activeChangeId}"]`);

        if (isBlockInsertion) {
          end = this.quill.getIndex(blot) + blot.length();
          start = end - 1;
        } else {
          start = this.quill.getIndex(blot);
          end = start + blot.length();
        }
        return {start, end};
      });

    return combineRanges(ranges);
  }

  private changeNodes(): HTMLElement[] {
    const nodes = Array.from(this.quill.root.querySelectorAll(`[change-ids~="${this.activeChangeId}"]`));
    nodes.forEach((node) => node.classList.add(ACTIVE_CHANGE_CLASS));

    return nodes as HTMLElement[];
  }

  private highlightRange({start, end}: IChangeRange): void {
    const startLeaf = this.startLeaf(start);
    const endLeaf = this.endLeaf(end);
    const rectangles = this.highlightRectangles(startLeaf, endLeaf);
    // We sometimes get no rectangles if the DOM has been updated immediately prior
    // to attempting highlight (eg tracking an inserted endnote)
    if (!rectangles.length) return;
    const startRectangle = rectangles[0];
    const endRectangle = rectangles[rectangles.length - 1];

    // Check for the extreme points, in case the first element has a strange height,
    // such as a sub- or superscript
    const top = rectangles.reduce((value, rectangle) => Math.min(value, rectangle.top), Number.MAX_SAFE_INTEGER);
    const bottom = rectangles.reduce((value, rectangle) => Math.max(value, rectangle.bottom), Number.MIN_SAFE_INTEGER);

    const startPoint = {x: startRectangle.left, y: top};
    const endPoint = {x: endRectangle.right, y: bottom};
    const sameLine = this.areOnTheSameLine(startRectangle, endRectangle);

    // The collapsed deletion indicator isn't a real Quill citizen, so we have to
    // manually bump the width.
    if (endLeaf[0] instanceof TrackDeletionBlockIndicator && !this.deletionsAreHidden()) {
      endPoint.x += COLLAPSED_DELETION_WIDTH_PX;
    }

    this.drawTopLine(startPoint, endPoint, sameLine, this.padding(startLeaf[0].domNode, 'right'));
    this.drawBottomLine(startPoint, endPoint, sameLine, this.padding(endLeaf[0].domNode, 'left'));
  }

  private startLeaf(index: number): [Blot, number] {
    const leaf = this.quill.getLeaf(index);
    if (leaf[0] instanceof FigureImage) return [leaf[0].parent, 1];
    return leaf;
  }

  private endLeaf(index: number): [Blot, number] {
    if ((this.isBlock(index) && !this.isParagraphInsertion(index)) || index === this.quill.getLength()) index--;
    const leaf = this.quill.getLeaf(index);
    // Endnote offsets are off by 1, probably because of the use of a UI element
    if (leaf[0] instanceof Endnote) leaf[1]++;
    return leaf;
  }

  private highlightRectangles(startLeaf: [Blot, number], endLeaf: [Blot, number]): Bounds[] {
    if (startLeaf[0] instanceof SceneBreak) return [startLeaf[0].domNode.getBoundingClientRect()];
    const range = this.leafHighlightRange(startLeaf, endLeaf);
    return Array.from(range.getClientRects())
      // Filter out hidden deletion rectangles, which get moved off the screen
      .filter((rectangle) => rectangle.x >= 0 && rectangle.x <= window.innerWidth);
  }

  private leafHighlightRange(startLeaf: [Blot, number], endLeaf: [Blot, number]): Range {
    const range = document.createRange();
    range.setStart(startLeaf[0].domNode, startLeaf[1]);
    range.setEnd(endLeaf[0].domNode, endLeaf[1]);
    return range;
  }

  // Use a tolerance to detect if two rectangles are approximately on the
  // same line to allow for elements like superscripts
  private areOnTheSameLine(rectangle1: Bounds, rectangle2: Bounds): boolean {
    return Math.abs(rectangle1.top - rectangle2.top) < LINE_TOLERANCE_PX;
  }

  private userIdFromNodes(nodes: HTMLElement[]): string {
    let userId: string = null;
    for (const node of nodes) userId = userId || this.userIdFromNode(node);
    return userId;
  }

  private userIdFromNode(node: HTMLElement): string {
    let id = '';
    TRACK_CHANGES_KEYS.forEach((attribute) => {
      const value = node.getAttribute(attribute);
      if (!value) return;
      id = id || value
        .split(/\s+/g)
        .map((id) => UserChangeId.parse(id))
        .filter((id) => id.changeId === this.activeChangeId)
        .map((id) => id.userId)[0];
    });
    return id;
  }

  private drawTopLine(start: IPoint, end: IPoint, sameLine: boolean, padding: number): void {
    const container = this.quill.container.getBoundingClientRect();

    const line = document.createElement('span');
    line.classList.add('active-change-top', `active-change-${this.userId}`);

    const width = sameLine ? end.x - start.x : container.right - start.x;
    padding = sameLine ? 0 : padding;

    line.style.left = `${start.x - container.left}px`;
    line.style.top = `${start.y - container.top}px`;
    line.style.width = `${width - padding}px`;
    // Use line tolerance as an arbitrary height that's not too big
    line.style.height = `${LINE_TOLERANCE_PX}px`;

    this.container.appendChild(line);
  }

  private drawBottomLine(start: IPoint, end: IPoint, sameLine: boolean, padding: number): void {
    const container = this.quill.container.getBoundingClientRect();

    const line = document.createElement('span');
    line.classList.add('active-change-bottom', `active-change-${this.userId}`);

    const left = sameLine ? start.x - container.left : 0;
    // Use line tolerance as an arbitrary height that's not too big
    const height = LINE_TOLERANCE_PX;
    padding = sameLine ? 0 : padding;

    line.style.left = `${left + padding}px`;
    line.style.top = `${end.y - container.top - height}px`;
    line.style.width = `${end.x - left - container.left - padding}px`;
    line.style.height = `${height}px`;

    this.container.appendChild(line);
  }

  private isBlock(index: number): boolean {
    const leaf = this.quill.getLeaf(index);
    const offset = leaf[1];
    return offset === 0;
  }

  private isParagraphInsertion(index: number): boolean {
    const [block] = this.quill.getLine(index - 1);
    return block instanceof IndentedBlockBlot && !!block.formats()[BLOCK.INSERTION];
  }

  private activeChangeIsComment(): boolean {
    return [INLINE.COMMENT, BLOCK.COMMENT].some((attribute) => {
      return this.quill.root.querySelector(`[${attribute}*="${this.activeChangeId}"]`);
    });
  }

  private padding(node: Node, side: 'right' | 'left'): number {
    let padding = 0;
    let element = node.parentElement;
    const paddingProp = side === 'right' ? 'paddingRight' : 'paddingLeft';
    const marginProp = side === 'right' ? 'marginRight' : 'marginLeft';
    while (element) {
      const style = getComputedStyle(element);
      padding += +style[paddingProp].split('px')[0];
      padding += +style[marginProp].split('px')[0];
      element = element.parentElement;
      if (element === this.quill.root.parentElement) element = null;
    }
    return padding;
  }

  private deletionsAreHidden(): boolean {
    const trackChanges = this.quill.getModule('track-changes') as TrackChanges;
    return trackChanges.deletionsAreHidden;
  }
}

interface IPoint {
  x: number; y: number;
}
