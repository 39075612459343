import {dig} from '@reedsy/utils.dig';
import Delta from '@reedsy/quill-delta';
import Op from '@reedsy/quill-delta/dist/Op';
import {opsToPlainText} from '@reedsy/studio.isomorphic/utils/rich-text/ops-to-plain-text';
import DeltaDocument, {IDeltaDocumentLine} from '@reedsy/studio.shared/services/track-changes/delta-document';

const BULLETS = Object.freeze([
  '\u00b7', // Middle dot
  '\u2022', // Bullet
]);

const BULLET_LIST = new RegExp(`^(\\s*[${BULLETS.join('')}]+\\s*)`);
const NUMBERED_LIST = new RegExp('^(\\s*(\\d+)\\.\\s*)');

export function addLists(delta: Delta): Delta {
  const ops: Op[] = [];
  new DeltaDocument(delta).lines.forEach((line) => {
    if (hasBlockFormatting(line.lineOp)) return addLine(line, ops);

    const plainText = dig(opsToPlainText(line.ops), 0, 0);
    if (dig(plainText, 'index') !== 0) return addLine(line, ops);
    const text = plainText.text;

    addBulletList(line, text);
    addNumberedList(line, text);
    addLine(line, ops);
  });

  if (!endsWithNewline(delta)) stripTrailingNewline(ops);
  return new Delta(ops);
}

function addBulletList(line: IDeltaDocumentLine, plainText: string): void {
  const match = plainText.match(BULLET_LIST);
  if (!match) return;
  const start = match[1].length;
  addList(line, start, 'bullet');
}

function addNumberedList(line: IDeltaDocumentLine, plainText: string): void {
  const currentNumber = listNumber(line);
  if (!currentNumber) return;

  if (currentNumber === 1) {
    const nextNumber = listNumber(line.next);
    if (nextNumber !== 2) return;
  } else {
    const previousNumber = previousNumberedListItems(line);
    if (previousNumber !== currentNumber - 1) return;
  }

  const match = plainText.match(NUMBERED_LIST);
  const start = match[1].length;
  addList(line, start, 'ordered');
}

function listNumber(line: IDeltaDocumentLine): number {
  if (!line) return null;
  const plainTexts = opsToPlainText(line.ops);
  const plainText = dig(plainTexts, 0, 0, 'text');
  if (!plainText) return null;
  const match = plainText.match(NUMBERED_LIST);
  return match && +match[2];
}

function previousNumberedListItems(currentLine: IDeltaDocumentLine): number {
  let count = 0;
  let line = currentLine.previous;
  while (line) {
    const attributes = line.lineOp.attributes || {};
    if (attributes.list === 'ordered') count++;
    else break;
    line = line.previous;
  }

  return count;
}

function addList(line: IDeltaDocumentLine, start: number, listType: 'bullet' | 'ordered'): void {
  const delta = new Delta(line.ops).slice(start);
  line.ops = delta.ops;
  line.lineOp.attributes = {list: listType};
}

function hasBlockFormatting(op: Op): boolean {
  const attributes = dig(op, 'attributes') || {};
  return !!Object.keys(attributes).length;
}

function addLine(line: IDeltaDocumentLine, ops: Op[]): void {
  line.ops.forEach((op) => ops.push(op));
  ops.push(line.lineOp);
}

function endsWithNewline(delta: Delta): boolean {
  const ops = delta.ops;
  const lastOp = ops[ops.length - 1];
  return lastOp && typeof lastOp.insert === 'string' && lastOp.insert.endsWith('\n');
}

function stripTrailingNewline(ops: Op[]): void {
  const lastOp = ops[ops.length - 1];
  if (!lastOp || typeof lastOp.insert !== 'string' || hasBlockFormatting(lastOp)) return;
  ops.pop();
}
