import Block from '@reedsy/quill/blots/block';
import TrackBlockAttributor from './track-changes/attributors/track-block-insert-attributor';

const superOptimize = Block.prototype.optimize;

/**
 * Here we need to hijack the optimize to make sure our track block indicators
 * are always at the end of the paragraph. We need to do this after Block.optimize
 * is called, otherwise we enter an infinite loop of Quill adding and removing
 * the default <br> element to the line.
 */
Block.prototype.optimize = function optimize(context: any): void {
  superOptimize.call(this, context);
  const trackIndicator = this.domNode.querySelector(`.${TrackBlockAttributor.INDICATOR_WRAPPER_CLASS}`);
  if (trackIndicator && trackIndicator.nextSibling) this.domNode.appendChild(trackIndicator);
};
