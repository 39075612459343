import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';

export default class LinkHeader extends JsxComponent {
  public override $props: {
    title: string;
  };

  public render(): HTMLElement {
    return (
      <div class="link-header">
        {this.$props.title}
      </div>
    );
  }
}
