import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "endnote-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_EndnotePaneWrapper = _resolveComponent("EndnotePaneWrapper")!

  return (_openBlock(), _createBlock(_component_EndnotePaneWrapper, {
    "endnote-number": _ctx.endnoteNumber,
    show: _ctx.isOpen,
    "read-only": "",
    class: "endnotes-wrapper"
  }, {
    "endnote-editor": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (index) => {
              return _createVNode(_component_SkeletonLoader, {
                key: index,
                class: "paragraph-skeleton"
              })
            }), 64))
          : (_openBlock(), _createBlock(_component_RichTextReader, {
              key: 1,
              id: "endnote-editor",
              ref: "editor",
              "rich-text-id": _ctx.endnoteId,
              options: _ctx.quillOptions,
              "load-endnotes": false
            }, {
              "empty-placeholder": _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("p", { class: "empty-endnote-text theme-body" }, "\n              This endnote is still a work in progress\n            ", -1)
              ])),
              _: 1
            }, 8, ["rich-text-id", "options"]))
      ])
    ]),
    _: 1
  }, 8, ["endnote-number", "show"]))
}