import timeZone from '@reedsy/studio.shared/utils/date/time-zone';
import {objectKeys} from '@reedsy/utils.object';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {tz} from 'moment-timezone';

const CURRENCY_USERS = {
  [SupportedCurrency.Cad]: new Set([
    ...tz.zonesForCountry('CA'),
  ]),
  // https://www.worlddata.info/currencies/gbp-sterling-pound.php
  [SupportedCurrency.Gpb]: new Set([
    ...tz.zonesForCountry('GB'),
    ...tz.zonesForCountry('GG'),
    ...tz.zonesForCountry('GI'),
    ...tz.zonesForCountry('GS'),
    ...tz.zonesForCountry('IM'),
    ...tz.zonesForCountry('IO'),
    ...tz.zonesForCountry('JE'),
    ...tz.zonesForCountry('SH'),
  ]),
  // https://www.worlddata.info/currencies/eur-euro.php
  [SupportedCurrency.Eur]: new Set([
    ...Intl.supportedValuesOf('timeZone').filter((zone) => zone.startsWith('Europe/')),
    ...tz.zonesForCountry('BL'),
    ...tz.zonesForCountry('CY'),
    ...tz.zonesForCountry('GF'),
    ...tz.zonesForCountry('GP'),
    ...tz.zonesForCountry('MF'),
    ...tz.zonesForCountry('MQ'),
    ...tz.zonesForCountry('PM'),
    ...tz.zonesForCountry('RE'),
    ...tz.zonesForCountry('YT'),
  ]),
  [SupportedCurrency.Usd]: new Set(), // default
} as const satisfies Record<SupportedCurrency, ReadonlySet<string>>;

export function localCurrency(zone = timeZone()): SupportedCurrency {
  for (const currency of objectKeys(CURRENCY_USERS)) {
    if (CURRENCY_USERS[currency].has(zone)) return currency;
  }
  return SupportedCurrency.Usd;
}
