import LinkTooltipTemplate from './link-template';
import Quill from '@reedsy/quill/core';
import ReedsyTooltip from '@reedsy/studio.shared/services/quill/theme/reedsy-tooltip';
import Links, {ILinkTooltipOptions, LinkTooltipMode} from '.';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';
import {Range} from '@reedsy/quill/core/selection';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import Highlighter from '@reedsy/studio.shared/services/quill/helpers/highlighter/highlighter';
import {LinkType} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/link-type.interface';
import ReedsyTheme from '@reedsy/studio.shared/services/quill/theme/reedsy-theme';
import RegularLinkTooltip from './regular-link-tooltip';
import LinkWithStoreModeTooltip from './link-with-store-mode-tooltip';

const TOOLTIP_CLASS = 'ql-tooltip-links';
export default class LinkTooltip extends ReedsyTooltip {
  public static override TEMPLATE = new LinkTooltipTemplate().render().innerHTML;
  public static TOOLTIP_CLASS = TOOLTIP_CLASS;

  private initialValue: ILinks;
  private highlighter: Highlighter;
  private hasPendingLink = false;

  public constructor(
    quill: Quill,
    bounds: HTMLElement,
  ) {
    super(quill, bounds);
    this.root.classList.add(TOOLTIP_CLASS);
    this.highlighter = new Highlighter(quill, 'ql-pending-link');
  }

  private get isHidden(): boolean {
    return this.root.classList.contains(ReedsyTooltip.classes.HIDDEN);
  }

  public override edit(mode: LinkTooltipMode, options: ILinkTooltipOptions): void {
    if (!options.keepInitialValue) this.initialValue = options.links;

    this.renderTooltip(mode, !options.editing, options.links || {});
    super.edit(mode);
    this.show();
    this.reposition();

    const range = this.quill.getSelection();
    this.addPendingLinkFormat(range);
  }

  public override listen(): void {
    super.listen();

    new ResizeObserver(() => {
      window.requestAnimationFrame(() => this.reposition());
    }).observe(this.quill.root);

    const listener = (event: MouseEvent): void => {
      if (!document.body.contains(this.quill.root)) {
        document.body.removeEventListener('click', listener);
        return;
      }

      if (
        !event.composedPath().includes(this.quill.container) &&
        !event.composedPath().includes(this.root) &&
        !this.quill.hasFocus()
      ) {
        this.hide();
      }
    };
    document.body.addEventListener('click', listener);
  }

  public override hide(): void {
    super.hide();
    this.clearPendingLinkFormat();
  }

  protected override handleSelectionChange(eventType: string, range: Range, oldRange: Range, source: string): void {
    if (this.isHidden) return;

    if (!this.quill.isEnabled()) return this.hide();
    super.handleSelectionChange(eventType, range, oldRange, source);
  }

  private updateLinks(links: ILinks): void {
    this.restoreFocus();
    this.quill.format('link', links, Quill.sources.USER);
    const {index} = this.quill.getSelection();
    this.quill.setSelection(index);
  }

  private removeLinks(): void {
    this.restoreFocus();
    this.quill.format('link', false, Quill.sources.USER);
    this.closeLinkEditor();
  }

  private initialMode(): LinkTooltipMode {
    return this.initialValue.regularUrl ? LinkTooltipMode.Link : LinkTooltipMode.StoreLink;
  }

  private mode(): LinkTooltipMode {
    return this.root.dataset.mode as LinkTooltipMode;
  }

  private toggleMode(): void {
    const targetMode = this.mode() === LinkTooltipMode.Link ? LinkTooltipMode.StoreLink : LinkTooltipMode.Link;
    this.edit(targetMode, {
      links: this.initialValue,
      editing: false,
      keepInitialValue: true,
    });
  }

  private toggleEdit(editing: boolean): void {
    this.edit(this.initialMode(), {
      links: this.initialValue,
      editing,
    });
  }

  private addPendingLinkFormat(range: Range): void {
    if (!range) return;
    this.hasPendingLink = true;
    this.highlighter.highlight([range]);
  }

  private clearPendingLinkFormat(): void {
    if (!this.hasPendingLink) return;
    this.hasPendingLink = false;
    this.highlighter.highlight([]);
  }

  private closeLinkEditor(): void {
    this.hide();
    this.root.dataset.mode = '';
  }

  private validateLinksForm(event: SubmitEvent): void {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    if (!form.checkValidity()) return;

    const linkInputs = Object.fromEntries(new FormData(form));
    const links = {[LinkType.Regular]: '', ...linkInputs} as ILinks;
    this.updateLinks(links);
  }

  private openToolbar(): void {
    const theme = this.quill.theme as ReedsyTheme;
    if (theme.tooltip) {
      theme.tooltip.show();
    }
  }

  private checkForCancel(event: KeyboardEvent): void {
    switch (event.key) {
      case Key.Escape:
        this.closeLinkEditor();
        this.openToolbar();
        return event.preventDefault();
    }
  }

  private renderTooltip(mode: LinkTooltipMode, isEditing: boolean, links: ILinks): void {
    const theme = this.quill.theme as ReedsyTheme;
    if (theme.tooltip) theme.tooltip.hide();

    const tooltipOptions = {links, isEditing, readOnly: this.quill.options.readOnly};
    const linksModule = this.quill.getModule('links') as Links;
    const linkTooltip = linksModule.storeLinksEnabled ?
      new LinkWithStoreModeTooltip({...tooltipOptions, mode}) :
      new RegularLinkTooltip(tooltipOptions);

    this.root.querySelector('.ql-link-editor').replaceChildren(linkTooltip.render());

    const linksForm = this.root.querySelector('form');
    linksForm.addEventListener('submit', (event) => this.validateLinksForm(event));
    linksForm.addEventListener('keydown', (event) => this.checkForCancel(event));
    this.root.querySelector('.link-toggle')?.addEventListener('click', () => this.toggleMode());
    this.root.querySelector('.link-cancel')?.addEventListener('click', (event) => {
      event.stopPropagation();
      if (!this.initialValue) {
        this.closeLinkEditor();
        this.openToolbar();
        return;
      }
      this.toggleEdit(true);
    });
    this.root.querySelector('.link-edit')?.addEventListener('click', () => this.toggleEdit(false));
    this.root.querySelector('.link-delete')?.addEventListener('click', (event) => {
      event.stopPropagation();
      this.removeLinks();
      this.openToolbar();
    });

    if (!isEditing) return;
    setTimeout(() => {
      const input = this.root.querySelector('input') as HTMLInputElement;
      input.select();
    });
  }
}
