<template>
  <rbe-pull-to-loader
    class="pull-loader"
    :class="{down}"
  >
    <ScreenEdgeArrow
      v-if="down"
      class="down"
    />
    <rbe-pull-loader-content>
      <slot>
        <VuiProgressCircular
          ref="pullProgress"
          :percentage="percentage"
          class="pull-progress"
        />
        <p class="pull-loader-content-text">
          {{ text }}
        </p>
      </slot>
    </rbe-pull-loader-content>
    <ScreenEdgeArrow
      v-if="!down"
      class="up"
    />
  </rbe-pull-to-loader>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ScreenEdgeArrow from '@reedsy/studio.shared/components/screen-edge-arrow/screen-edge-arrow.vue';

@Component({
  components: {
    ScreenEdgeArrow,
  },
})
export default class PullLoader extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public down: boolean;

  @Prop({type: Number, required: true})
  public percentage: number;

  @Prop({type: String, default: ''})
  public text: string;
}
</script>

<style lang="scss" scoped>
$background-color: var(--reedsy-plain-emphasis);

rbe-pull-to-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: $pull-loader-height;
  z-index: $z-index-pull-to-loader;
  color: var(--reedsy-plain-onEmphasis);;

  rbe-screen-edge-arrow {
    height: $pull-loader-arrow-height;
    position: relative;
    z-index: $z-index-pull-to-loader;

    :deep(.arrow-background) {
      color: $background-color;
    }
  }

  rbe-pull-loader-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: $pull-loader-height - $pull-loader-arrow-height;
    justify-content: center;
    align-items: center;
    background-color: $background-color;
    z-index: $z-index-pull-to-loader;
    box-shadow: $box-shadow-base;

    &:deep(.pull-progress) {
      .progress-circle {
        @include transition(stroke-dashoffset, 0.01s);
      }
    }

    p {
      padding-top: $space-md;

      @include font-family($controls, bold);
    }
  }

  &.down {
    rbe-screen-edge-arrow {
      z-index: $z-index-pull-to-loader + 1;
    }

    rbe-pull-loader-content {
      box-shadow: $box-shadow-up-base;
    }
  }
}
</style>
