export const HEADER_VALUES = [1, 2, 3];
export const ALIGN_VALUES = ['', 'center', 'right'];
export const SCRIPT_VALUES = ['super', 'sub'];
export const QUOTE_FORMATS = ['blockquote', 'callout'];

const BLOCK_FORMATS: IToolbarRow = Object.freeze({
  cssClass: 'ql-formats-block',
  formats: Object.freeze([
    'clean',
    {header: HEADER_VALUES},
    {align: ALIGN_VALUES},
    {list: 'ordered'},
    {list: 'bullet'},
    QUOTE_FORMATS,
    'code-block',
    'track-comment',
  ]),
});

export const INLINE_FORMATS: IToolbarRow = Object.freeze({
  cssClass: 'ql-formats-inline',
  formats: Object.freeze([
    'bold',
    'italic',
    'underline',
    'strike',
    {script: SCRIPT_VALUES},
    'link',
    'track-comment',
  ]),
});

export const CYCLING_CLASS = Object.freeze({
  BUTTON: 'cycling-button',
  INDICATORS: 'cycling-indicators',
  INDICATOR: 'indicator',
});

export default function toolbarContainer(): HTMLElement {
  const container = document.createElement('div');
  addControls(container);
  return container;
}

function addControls(container: HTMLElement): void {
  [BLOCK_FORMATS, INLINE_FORMATS].forEach((row) => {
    const group = document.createElement('span');
    group.classList.add('ql-formats');
    group.classList.add(row.cssClass);
    row.formats.forEach((control) => addControl(group, control));
    container.appendChild(group);
  });
}

function addControl(container: HTMLElement, control: FormatDefinition): void {
  if (typeof control === 'string' || Array.isArray(control)) {
    addButton(container, control);
  } else {
    const [format, value] = Object.entries(control)[0];
    addButton(container, format, value);
  }
}

function addButton(container: HTMLElement, format: string | string[], value?: any): void {
  if (Array.isArray(format)) return addMultiFormatCyclingButton(container, format);
  if (Array.isArray(value)) return addSingleFormatCyclingButton(container, format, value);
  const input = document.createElement('button');
  input.setAttribute('type', 'button');
  input.classList.add(`ql-${format}`);
  if (value != null && !Array.isArray(value)) input.value = value;
  container.appendChild(input);
}

function addMultiFormatCyclingButton(container: HTMLElement, formats: string[]): void {
  const pairs: FormatValuePair[] = formats.map((format) => [format, true]);
  addCyclingButton(container, pairs);
}

function addSingleFormatCyclingButton(container: HTMLElement, format: string, values: any[]): void {
  const pairs: FormatValuePair[] = values.map((value) => [format, value]);
  const cyclingButton = addCyclingButton(container, pairs);
  cyclingButton.classList.add(`ql-${format}`);
}

function addCyclingButton(container: HTMLElement, formats: FormatValuePair[]): HTMLElement {
  const wrapper = document.createElement('div');
  wrapper.classList.add(CYCLING_CLASS.BUTTON);
  const indicators = document.createElement('div');
  indicators.classList.add(CYCLING_CLASS.INDICATORS);
  formats.forEach(([format, value]: any) => {
    addButton(wrapper, format, value);
    const indicator = document.createElement('span');
    indicator.classList.add(CYCLING_CLASS.INDICATOR);
    indicators.appendChild(indicator);
  });
  wrapper.appendChild(indicators);
  container.appendChild(wrapper);
  return wrapper;
}

interface IFormatGroup {
  [format: string]: string | any[];
}
type FormatValuePair = [string, any];
type FormatDefinition = (string | string[] | IFormatGroup);
interface IToolbarRow {
  cssClass: string; formats: readonly FormatDefinition[];
}
