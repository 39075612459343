import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {IBookViewerRoute} from './router/i-book-viewer-route';

/**
 * Simple helper class that adds our prototype modifications for type safety
 */
@Component({})
export default class BookViewerVue extends ClientSharedVue {
  public override $route: IBookViewerRoute;
}
