import {StyleAttributor, Scope} from 'parchment';

class StrikeStyleAttributor extends StyleAttributor {
  public override value(node: HTMLElement): any {
    const value = super.value(node);
    return value.includes('line-through');
  }
}

const strikeStyleAttributor = new StrikeStyleAttributor('strike', 'text-decoration', {
  scope: Scope.INLINE_ATTRIBUTE,
});

export default strikeStyleAttributor;
