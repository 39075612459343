<template>
  <div class="header">
    <h3 v-if="title">
      {{ title }}
    </h3>
    <slot
      v-else
      name="title"
    />
    <slot />
  </div>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class Header extends ClientSharedVue {
  @Prop({type: String})
  public title: string;
}
</script>

<style lang="scss" scoped>
$left-sidebar-header-height: 3rem;

.header {
  background-color: var(--studio-leftSidebar-headingBackground);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $left-sidebar-header-height;
  padding: 0 $space-base 0 $space-md;
  position: sticky;
  top: 0;
  z-index: $z-index-absolute-blocks;
}
</style>
