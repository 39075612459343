import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {findContentInfo} from './find-content-info';

export function findPartNumber(
  flatBookContent: IContentFragment[],
  id: string,
): number {
  const content = findContentInfo(flatBookContent, id);
  if (!content || content.data.type !== MatterType.Part) return null;
  return +content.path[content.path.length - 1] + 1;
}
