
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import TOCContentFragment from './toc-content-fragment.vue';
import {ITableOfContents} from '@reedsy/studio.viewer/store/modules/table-of-contents/i-table-of-contents';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerTableOfContentsModule} from '@reedsy/studio.viewer/store/modules/table-of-contents/table-of-contents';

@Component({
  components: {
    TOCContentFragment,
  },
})
export default class TableOfContents extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerTableOfContents)
  public $tableOfContents: BookViewerTableOfContentsModule;

  public get tableOfContents(): ITableOfContents {
    return this.$tableOfContents.tableOfContents;
  }
}
