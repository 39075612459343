export default class Styles {
  private readonly element: HTMLStyleElement;
  private readonly styles: IStyle[] = [];

  public constructor(id?: string) {
    this.element = document.createElement('style');
    this.element.type = 'text/css';
    if (id) this.element.id = id;
    document.head.appendChild(this.element);
  }

  public addStyle(selector: string, styles: StyleDeclaration): void {
    this.styles.push({selector, styles});
    this.updateStyles();
  }

  public addCustomProperty(selector: string, name: string, value: string): void {
    this.styles.push({selector, styles: {[`--${name}`]: value}});
    this.updateStyles();
  }

  public remove(): void {
    this.element.remove();
  }

  private updateStyles(): void {
    this.element.innerHTML = this.styles
      .map((style) => {
        const styleCss = Object.keys(style.styles)
          .map((prop: any) => `${this.styleKeyToCss(prop)}:${style.styles[prop]}`)
          .join(';');

        return `${style.selector} { ${styleCss} }`;
      })
      .join('\n');
  }

  private styleKeyToCss(key: string): string {
    return key
      .toString()
      .replace(/([A-Z])/g, '-$1')
      .toLowerCase();
  }
}

export interface IStyle {
  selector: string;
  styles: StyleDeclaration;
}

type StyleDeclaration = Partial<Record<keyof CSSStyleDeclaration, string>>;
