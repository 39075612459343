import Keyboard from '@reedsy/quill/modules/keyboard';
import {Range} from '@reedsy/quill/core/selection';
import clearTracking from './track-changes/clear-tracking';
import softBreak from './soft-break';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import {hiddenChangesLeft, hiddenChangesRight, hiddenChangesDelete, hiddenChangesBackspace} from './track-changes/hidden-changes';
import deletedImageHandlerFactory from './track-changes/deleted-image';
import handleBackspace from './handle-backspace';
import link from './link';
import {KeyboardBinding, KeyboardContext, KeyboardHandler, KeyboardOptions} from './keyboard.interface';
import {alignHandler} from './align';
import {handleEnter} from './handle-enter';

export default class ReedsyKeyboard extends Keyboard {
  public override handleBackspace(range: Range, context: KeyboardContext): void {
    handleBackspace(this.quill, range, context);
  }

  public override handleEnter(range: Range): void {
    handleEnter(this.quill, range);
  }

  public prependBinding(keyBinding: KeyboardBinding, context?: KeyboardOptions, handler?: KeyboardHandler): void {
    this.addBinding(keyBinding, context, handler);
    const keys = Array.isArray(keyBinding.key) ? keyBinding.key : [keyBinding.key];
    keys.forEach((key) => {
      this.bindings[key].unshift(this.bindings[key].pop());
    });
  }

  protected override shouldHandleKeydown(event: KeyboardEvent): boolean {
    if (event.defaultPrevented) return false;

    // Android is basically always composing, which stops us from backspacing
    // a bullet point at the start of the document
    if (event.isComposing) {
      const range = this.quill.getSelection();
      if (!range) return false;
      const [, offset] = this.quill.getLine(range.index);
      return event.key === Key.Backspace && offset === 0;
    }

    return true;
  }
}

const REEDSY_BINDINGS: Record<string, KeyboardBinding> = {
  alignCenter: {
    key: 'e',
    shortKey: true,
    shiftKey: true,
    handler: alignHandler('center'),
  },
  alignLeft: {
    key: 'l',
    shortKey: true,
    shiftKey: true,
    handler: alignHandler(false),
  },
  alignRight: {
    key: 'r',
    shortKey: true,
    shiftKey: true,
    handler: alignHandler('right'),
  },
  clearTrackingOnEnter: {
    key: Key.Enter,
    handler: clearTracking,
  },
  'soft break': {
    key: Key.Enter,
    shiftKey: true,
    handler: softBreak,
  },
  link: {
    key: 'k',
    shortKey: true,
    handler: link,
  },
  hiddenChangesLeft: {
    key: Key.ArrowLeft,
    collapsed: true,
    handler: hiddenChangesLeft,
  },
  hiddenChangesRight: {
    key: Key.ArrowRight,
    collapsed: true,
    handler: hiddenChangesRight,
  },
  hiddenChangesDelete: {
    key: Key.Delete,
    collapsed: true,
    handler: hiddenChangesDelete,
  },
  hiddenChangesBackspace: {
    key: Key.Backspace,
    collapsed: true,
    handler: hiddenChangesBackspace,
  },
  deletedImageBackspace: {
    key: Key.Backspace,
    handler: deletedImageHandlerFactory(false),
  },
  deletedImageDelete: {
    key: Key.Delete,
    handler: deletedImageHandlerFactory(true),
  },
};

const QUILL_DEFAULTS = Keyboard.DEFAULTS as KeyboardOptions;

const QUILL_OVERRIDES: Record<string, KeyboardBinding> = {
  'list autofill': {
    ...QUILL_DEFAULTS.bindings['list autofill'] as KeyboardBinding,
    // Override the default matcher to ignore checkboxes
    prefix: /^\s*?(\d+\.|-|\*)$/,
  },
  tab: {
    key: Key.Tab,
    handler: ignoreKey,
  },
};

ReedsyKeyboard.DEFAULTS.bindings = Object.assign(
  {},
  REEDSY_BINDINGS, // Call our custom bindings first
  QUILL_DEFAULTS.bindings, // Then call the built-in bindings
  QUILL_OVERRIDES, // Override Quill behaviour with our own bindings of the same name
);

export function ignoreKey(): boolean {
  return true;
}

export function blockKey(): boolean {
  return false;
}
