import TooltipTemplate from './tooltip';
import Quill from '@reedsy/quill/core';
import {ISuggestion} from '@reedsy/studio.shared/services/quill/modules/mentions';
import MentionOption from '@reedsy/studio.shared/services/quill/modules/mentions/suggestions-tooltip/mention-option';
import {MentionsTooltip} from '@reedsy/studio.shared/services/quill/modules/mentions/mentions-tooltip';
import {Bounds} from '@reedsy/studio.shared/services/quill/modules/bounds.interface';
import {
  SuggestionTooltipClass,
} from '@reedsy/studio.shared/services/quill/modules/mentions/suggestions-tooltip/suggestion-tooltip-class';

export default class SuggestionsTooltip extends MentionsTooltip {
  public static override TEMPLATE = new TooltipTemplate().render().innerHTML;
  public static TOOLTIP_CLASS = SuggestionTooltipClass.Base;

  public readonly mentionsList: HTMLElement;

  public constructor(
    quill: Quill,
    bounds: HTMLElement,
  ) {
    super(quill, bounds);
    this.root.classList.add(SuggestionTooltipClass.Base);

    this.mentionsList = this.root.querySelector('.ql-mention-list');
  }

  protected get tooltipAnchorBounds(): Bounds {
    const range = this.quill.getSelection();
    if (!range) return;
    return this.quill.getBounds(range.index);
  }

  public showTooltip(options: ISuggestion[]): void {
    this.setOptions(options);
    this.show();
    this.position(this.tooltipAnchorBounds);
  }

  private setOptions(options: ISuggestion[]): void {
    this.clearOptions();

    options.forEach((option) => {
      const node = new MentionOption({...option, quill: this.quill}).render();
      this.mentionsList.appendChild(node);
    });
  }

  private clearOptions(): void {
    this.mentionsList.innerHTML = '';
  }
}
