
import {Component, Ref} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {timeout} from '@reedsy/utils.timeout';

export const LEFT_SIDEBAR_FOOTER_HEIGHT = '--left-sidebar-footer-height';

@Component({})
export default class Footer extends ClientSharedVue {
  @Ref
  private readonly footer: HTMLElement;

  private observer: ResizeObserver;

  public mounted(): void {
    this.updateCSSProperty();
    this.observer = new ResizeObserver(async () => {
      await timeout(); // Avoid loop by waiting for render
      this.updateCSSProperty();
    });
    this.observer.observe(this.footer);
  }

  public unmounted(): void {
    this.observer.disconnect();
  }

  private async updateCSSProperty(): Promise<void> {
    const height = this.footer?.getBoundingClientRect().height;
    if (!height) return;
    document.documentElement.style.setProperty(LEFT_SIDEBAR_FOOTER_HEIGHT, `${height}px`);
  }
}
