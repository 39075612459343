
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import PageContainer from '@reedsy/studio.viewer/components/page-container/page-container.vue';
import {IEpigraph} from '@reedsy/reedsy-sharedb/lib/common/book-content/epigraph';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import SectionTitle from '@reedsy/studio.viewer/components/section-title/section-title.vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';

@Component({
  components: {
    PageContainer,
    RichTextReader,
    SectionTitle,
    SkeletonLoader,
  },
})
export default class Epigraph extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerRichText)
  public $richText: BookViewerRichTextModule;

  public get epigraph(): IEpigraph {
    return this.$displayedData.content.data as IEpigraph;
  }

  public get contentRichTextId(): string {
    return this.epigraph.references.content.id;
  }

  public get attributionRichTextId(): string {
    return this.epigraph.references.attribution.id;
  }

  public get hasContent(): boolean {
    return !this.$richText.isEmpty(this.contentRichTextId);
  }
}
