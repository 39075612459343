import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import Quill from '@reedsy/quill/core';
import {MentionEvent} from '@reedsy/studio.shared/services/quill/modules/mentions/events';
import IconPin from './icons/pin';

export default class PinButton extends JsxComponent {
  public override $props: {
    id: string;
    quill: Quill;
    isPinned: boolean;
  };

  public onClick = (): void => {
    this.$props.quill.emitter.emit(MentionEvent.MentionPinClick, this.$props.id);
  };

  public render(): HTMLElement {
    const {isPinned} = this.$props;

    return (
      <button
        onClick={() => this.onClick()}
        class={{
          button: true,
          'pin-button': true,
          active: isPinned,
        }}
        type="button"
      >
        {isPinned ? 'Unpin' : 'Pin' }
        <IconPin />
      </button>
    );
  }
}
