import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "book-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TOCContentFragment = _resolveComponent("TOCContentFragment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableOfContents.entries, (tocEntry) => {
      return (_openBlock(), _createBlock(_component_TOCContentFragment, {
        key: tocEntry.id,
        "toc-entry": tocEntry,
        "nest-level": 0
      }, null, 8, ["toc-entry"]))
    }), 128))
  ]))
}