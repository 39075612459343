import {StyleAttributor, Scope} from 'parchment';

class ItalicStyleAttributor extends StyleAttributor {
  public override value(node: HTMLElement): any {
    const value = super.value(node);
    return value === 'italic';
  }
}

const italicStyleAttributor = new ItalicStyleAttributor('italic', 'font-style', {
  scope: Scope.INLINE_ATTRIBUTE,
});

export default italicStyleAttributor;
