import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_EndnotePane = _resolveComponent("EndnotePane")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["book-viewer-content", _ctx.bookViewerSettingsClasses])
      }, [
        (_openBlock(), _createBlock(_component_RouterView, {
          key: _ctx.$route.path
        })),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createVNode(_component_EndnotePane)
      ], 2)
    ]),
    _: 1
  }))
}