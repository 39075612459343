import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconCross = _resolveComponent("VuiIconCross")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: "close-sidebar-button button-icon button-sm reedsy-accented translucent",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeLeftSidebar && _ctx.closeLeftSidebar(...args)))
  }, [
    _createVNode(_component_VuiIconCross)
  ]))
}