import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-container theme-body" }
const _hoisted_2 = { class: "page-container-content" }
const _hoisted_3 = { class: "navigation-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitlePageHeader = _resolveComponent("TitlePageHeader")!
  const _component_ContentNavigationButton = _resolveComponent("ContentNavigationButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFirstPage)
      ? (_openBlock(), _createBlock(_component_TitlePageHeader, {
          key: 0,
          class: "title-page-header"
        }))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ContentNavigationButton)
    ])
  ]))
}