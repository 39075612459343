export interface IIsBookImageUrlArgs {
  bookId: string;
  url: string;
  legacyBookId: string;
}

export function isBookImageUrl({bookId, url, legacyBookId}: IIsBookImageUrlArgs): boolean {
  // matches /books/<BOOK ID>/images/
  const bookUrlRegex = new RegExp(`\/books\/(${bookId}|${legacyBookId})\/images\/`);
  return !!url.match(bookUrlRegex);
}
