<template>
  <div
    class="settings-field"
    :class="{
      'inline-settings-field': !column,
      'column-settings-field': column,
    }"
  >
    <label>{{ label }}</label>
    <div class="settings-field-value">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';

@Component({})
export default class SettingsField extends BookViewerVue {
  @Prop({type: Boolean, default: false})
  public column: boolean;

  @Prop({type: String, required: true})
  public label: string;
}
</script>

<style lang="scss" scoped>
.settings-field {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $space-md $space-sm;
  width: 100%;

  &.column-settings-field {
    label {
      padding-bottom: $space-md;
    }
  }

  &.inline-settings-field {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label {
      min-width: 5rem;
    }

    .settings-field-value {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  label {
    @include font-family($controls, bold);

    font-size: $font-size-sm;
    padding-right: $space-lg;
  }

  :deep(.vue-slider) {
    margin: 0;

    // Vue-slider contains specific logic with setting width in styles
    // Need to override it
    min-width: 8rem !important;
    width: 100% !important;
  }

  :deep(.vui-dropdown) {
    width: 100%;
  }
}
</style>
