import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import ImageFile from './image-file';

export default class ImagePlaceholder extends JsxComponent {
  public render(): HTMLElement {
    return (
      <div>
        <div class="file-progress-indicator">
          <span
            class="file-progress-indicator-bar progress"
            style="width: 0%"
          />
        </div>

        <div class="file-progress-icon-container">
          <ImageFile class="file-progress-icon" />
        </div>
      </div>
    );
  }
}
