import Quill from '@reedsy/quill';
import {Range} from '@reedsy/quill/core/selection';
import Delta from '@reedsy/quill-delta';

// This is a drastically simplified version of Quill's handler, which no longer
// attempts to break block formatting on Enter: that's handled by our own
// op transformer
export function handleEnter(quill: Quill, range: Range): void {
  const delta = new Delta()
    .retain(range.index)
    .delete(range.length)
    .insert('\n');

  quill.updateContents(delta, Quill.sources.USER);
  quill.setSelection(range.index + 1, Quill.sources.SILENT);
  quill.focus();
}
