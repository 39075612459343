import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import LinkHeader from './link-header';
import LinkActions from './link-actions';
import RegularLinkTemplate from './regular-link-template';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';

export default class RegularLinkTooltip extends JsxComponent {
  public override $props: {
    links: ILinks;
    isEditing: boolean;
    readOnly: boolean;
  };

  public render(): HTMLElement {
    return (
      <form novalidate>
        <div class="head">
          <LinkHeader
            title="Link"
          />
          {
            !this.$props.readOnly && <LinkActions isEditing={this.$props.isEditing} />
          }
        </div>
        <RegularLinkTemplate
          isEditing={this.$props.isEditing}
          links={this.$props.links}
        />
      </form>
    );
  }
}
