import icons from './icons';

export default class ActionBar {
  public readonly element: HTMLElement;

  public constructor(options: IActionBarOptions) {
    this.element = document.createElement('div');
    this.element.classList.add('ql-action-bar');
    this.element.contentEditable = 'false';

    this.addButton('bin', options.remove);
    this.addButton('comment', options.comment);
  }

  private addButton(name: keyof typeof icons, action: () => void): void {
    if (!action) return;
    const button = document.createElement('button');
    button.classList.add('ql-action-button', name);
    const wrapper = document.createElement('span');
    wrapper.classList.add('vui-icon');
    wrapper.innerHTML = icons[name];
    button.appendChild(wrapper);
    button.onclick = action;
    this.element.appendChild(button);
  }
}

export interface IActionBarOptions {
  remove?: () => void;
  comment?: () => void;
}
