import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "epigraph" }
const _hoisted_2 = { class: "content-loader" }
const _hoisted_3 = { class: "attribute-loader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_RichTextReader, {
          "rich-text-id": _ctx.contentRichTextId,
          "empty-default-placeholder-text": "Epigraph page is still a work in progress!",
          class: "epigraph-content"
        }, {
          loader: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
                return _createVNode(_component_SkeletonLoader, {
                  key: i,
                  class: "line-skeleton-loader"
                })
              }), 64))
            ])
          ]),
          _: 1
        }, 8, ["rich-text-id"]),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (_ctx.hasContent)
          ? (_openBlock(), _createBlock(_component_RichTextReader, {
              key: 0,
              "rich-text-id": _ctx.attributionRichTextId,
              class: "align-right epigraph-attribution"
            }, {
              "empty-placeholder": _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", null, null, -1)
              ])),
              loader: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_SkeletonLoader, { class: "name-skeleton-loader" })
                ])
              ]),
              _: 1
            }, 8, ["rich-text-id"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}