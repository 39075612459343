<template>
  <div>
    <fieldset
      :disabled="readOnlyMode || null"
      class="layout-left"
      :class="{
        'layout-left-open': open,
      }"
    >
      <transition name="fade">
        <Overlay
          v-if="withOverlay && open"
          class="sidebar-overlay"
          @click="emitHide"
        />
      </transition>

      <slot name="before-content" />

      <slot name="content-pane">
        <ContentPane
          :title="title"
        >
          <template #title>
            <slot name="title" />
          </template>

          <template #header-after-content>
            <slot name="header-after-content" />
          </template>

          <template #header>
            <slot name="header" />
          </template>

          <template #content>
            <slot name="content" />
          </template>

          <template #footer>
            <slot name="footer" />
          </template>
        </ContentPane>
      </slot>
    </fieldset>

    <slot name="after-content" />
  </div>
</template>

<script lang="ts">
import {Component, Prop, Emit} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import ContentPane from './content-pane/content-pane.vue';

@Component({
  components: {
    Overlay,
    ContentPane,
  },
})
export default class LeftSidebarBase extends ClientSharedVue {
  @Prop({type: String, default: ''})
  public title: string;

  @Prop({type: Boolean, default: false})
  public readOnlyMode: boolean;

  @Prop({type: Boolean, default: false})
  public withOverlay: boolean;

  @Prop({type: Boolean, default: false})
  public open: boolean;

  @Emit('hide')
  public emitHide(): boolean {
    return true;
  }
}
</script>

<style lang="scss">
.shared-left-sidebar .vui-toggle.borderless {
  .toggle {
    background-color: var(--studio-leftSidebar-toggleBackground);
    border-color: var(--studio-leftSidebar-toggleBackground);

    &::before {
      background-color: var(--studio-leftSidebar-toggleHandle);
    }
  }

  &.checked .toggle {
    background-color: var(--studio-leftSidebar-toggleBackground-checked);
    border-color: var(--studio-leftSidebar-toggleBackground-checked);
  }
}
</style>

<style lang="scss" scoped>
.orientation-landscape-left {
  .layout-left {
    left: calc((-1 * var(--left-sidebar-width)) - env(safe-area-inset-left));
    width: calc(var(--left-sidebar-width) + env(safe-area-inset-left));
  }
}

.layout-left {
  color: var(--studio-leftSidebar-textColor);
  bottom: 0;
  flex: 0 0 var(--left-sidebar-width);

  // 1.5rem hack is needed only because of Safari has strange bug with overflow
  left: calc((-1 * var(--left-sidebar-width)) - 1.5rem);
  position: fixed;
  top: 0;
  width: var(--left-sidebar-width);
  z-index: $z-index-left-sidebar;

  @include transition((top, left, box-shadow));

  &.layout-left-open {
    left: 0;

    @include screen-more-than(xs) {
      .document-history-wrapper.active & {
        top: 0;
        left: 0;
      }
    }

    @include screen-less-than(sm) {
      box-shadow: $left-sidebar-shadow;
    }
  }

  .overlay {
    z-index: -1; // Position behind mobile menu
    right: calc(-1 * (var(--right-sidebar-offset) + var(--action-panel-offset))); // cover right sidebar
  }
}
</style>
