import uslug from 'uslug';
import diacritics from 'diacritics';

export default function slug(s: string): string {
  if (!s) return '';

  s = uslug(diacritics.remove(s));
  s = s.replace(/[^A-Za-z0-9\-]/g, '');
  return s.substring(0, 50);
}
