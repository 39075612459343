
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import TopBar from '@reedsy/studio.viewer/components/top-bar/top-bar.vue';
import LeftSideBar from '@reedsy/studio.viewer/components/left-sidebar/left-sidebar.vue';
import Footer from '@reedsy/studio.viewer/components/footer/footer.vue';
import {getScrollPosition} from '@reedsy/studio.viewer/utils/scroll/get-scroll-position';
import PullTo from '@reedsy/studio.viewer/components/pull-to/pull-to.vue';
import PullLoader from '@reedsy/studio.viewer/components/pull-to/pull-loader.vue';
import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {BOTTOM_PAGE_HASH} from '@reedsy/studio.viewer/utils/scroll-hashes';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerShareableUrlDetailsModule} from '@reedsy/studio.viewer/store/modules/shareable-url-details/shareable-url-details';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import {BookViewerTableOfContentsModule} from '@reedsy/studio.viewer/store/modules/table-of-contents/table-of-contents';
import {BookViewerReadingLocationModule} from '@reedsy/studio.viewer/store/modules/reading-location/reading-location';

@Component({
  components: {
    TopBar,
    LeftSideBar,
    Footer,
    PullTo,
    PullLoader,
  },
})
export default class MainLayout extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerSharableUrlDetails)
  public $shareableUrlDetails: BookViewerShareableUrlDetailsModule;

  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  @$lazyInjectStore(StoreName.BookViewerTableOfContents)
  public $tableOfContents: BookViewerTableOfContentsModule;

  @$lazyInjectStore(StoreName.BookViewerReadingLocation)
  public $readingLocation: BookViewerReadingLocationModule;

  public get bottomDivId(): string {
    return BOTTOM_PAGE_HASH;
  }

  public get alertBarIsOpen(): boolean {
    return this.$shareableUrlDetails.isSoonToBeExpired;
  }

  public get currentContentId(): string {
    return this.$displayedData.content.id;
  }

  public get hasNextPage(): boolean {
    return this.$tableOfContents.hasContentAfter(this.currentContentId);
  }

  public get nextContent(): IContentFragment {
    return this.$tableOfContents.nextContentFragment(this.currentContentId);
  }

  public get hasPreviousPage(): boolean {
    return this.$tableOfContents.hasContentBefore(this.currentContentId);
  }

  public get previousContent(): IContentFragment {
    return this.$tableOfContents.previousContentFragment(this.currentContentId);
  }

  public get nextContentUrl(): string {
    return this.$tableOfContents.matterUrl(
      this.nextContent.id,
    );
  }

  public get previousContentUrl(): string {
    return this.$tableOfContents.matterUrl(
      this.previousContent.id,
      BOTTOM_PAGE_HASH,
    );
  }

  public get topLoaderText() {
    return (activatePercentage: number): string => {
      return activatePercentage < 100 ?
        'Swipe down to go to the previous page' :
        'Release to go to previous page';
    };
  }

  public get bottomLoaderText() {
    return (activatePercentage: number): string => {
      return activatePercentage < 100 ?
        'Swipe up to go to the next page' :
        'Release to go to next page';
    };
  }

  public updateScrollLocation(event: MouseEvent): void {
    const scrollPosition = getScrollPosition(event.target as HTMLElement);
    this.$readingLocation.changeLastReadPosition(scrollPosition);
  }

  public goToNextPage(): void {
    if (this.hasNextPage) {
      this.$router.push(this.nextContentUrl);
    }
  }

  public goToPreviousPage(): void {
    if (this.hasPreviousPage) {
      this.$router.push(this.previousContentUrl);
    }
  }
}
