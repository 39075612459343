import BubbleTheme from '@reedsy/quill/themes/bubble';
import icons from './icons';
import type Theme from '@reedsy/quill/core/theme';
import ReedsyTooltip from './reedsy-tooltip';

type ThemeOptions = ConstructorParameters<typeof Theme>[1];
interface IReedsyThemeOptions extends ThemeOptions {
  bounds: HTMLElement;
}

export default class ReedsyTheme extends BubbleTheme {
  public override tooltip: ReedsyTooltip;
  protected override options: IReedsyThemeOptions;

  public override extendToolbar(toolbar: any): void {
    this.tooltip = new ReedsyTooltip(this.quill, this.options.bounds);
    this.tooltip.root.appendChild(toolbar.container);
    this.buildButtons(toolbar.container.querySelectorAll('button'), icons);
  }
}
