<template>
  <NotFoundPartial>
    <template #home-link>
      <a
        :href="reedsyUrl"
        class="text-link"
      >Return to Reedsy</a>
    </template>
  </NotFoundPartial>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import NotFoundPartial from '@reedsy/studio.shared/components/not-found/not-found-partial.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {$lazyInject} from '@reedsy/studio.viewer/inversify.config';

@Component({
  components: {
    NotFoundPartial,
  },
})
export default class NotFound extends ClientSharedVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  public get reedsyUrl(): string {
    // TODO: Change to editor landing page when it is done
    return this.navigation.marketplaceUrl;
  }
}
</script>
