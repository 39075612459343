import {TRACK_CHANGES_KEYS} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {Range} from '@reedsy/quill/core/selection';

export default function clearTracking(range: Range, context: any): boolean {
  // Remove the tracking attributes from the context when hitting enter,
  // so that we don't transfer them to the next line. Helps with this
  // corner case:
  // 1. Start with a document like this:
  //
  //    # H1
  //    ## H2
  //
  // 2. Enable track changes
  // 3. Delete the H2 line, and then add a new paragraph after the deleted header
  // 4. The inserted paragraph should be at the end of the H2
  TRACK_CHANGES_KEYS.forEach((attribute) => delete context.format[attribute]);
  return true;
}
