import {VuexModule, Mutation} from '@reedsy/vuex-module-decorators';
import {IBookContent} from '@reedsy/reedsy-sharedb/lib/common/book-content/book-content';
import {clone} from '@reedsy/utils.clone';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {ContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {flat} from '@reedsy/reedsy-sharedb/lib/utils/book-content/flat';
import {findContentInfo} from '@reedsy/studio.isomorphic/utils/book-content/find-content-info';
import {findChapterNumber} from '@reedsy/studio.isomorphic/utils/book-content/find-chapter-number';
import {findPartNumber} from '@reedsy/studio.isomorphic/utils/book-content/find-part-number';
import {count, ICountOptions} from '@reedsy/studio.isomorphic/utils/book-content/count';
import {IApplyOpsMutation, IDataMutation} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

export class BookContentDataModule extends VuexModule {
  public data: IBookContent = null;

  public get flat(): ContentFragment[] {
    return flat(this.data);
  }

  public get contentInfo() {
    return (id: string): ContentFragment => findContentInfo(this.flat, id);
  }

  public get count() {
    return (options: ICountOptions): number => count(this.flat, options);
  }

  public get chapterNumber() {
    return (id: string): number => findChapterNumber(this.flat, id);
  }

  public get partNumber() {
    return (id: string): number => findPartNumber(this.flat, id);
  }

  public get firstChapterId(): string {
    const firstChapter = this.flat.find((content) => content.data.type === MatterType.Chapter);
    return firstChapter?.id;
  }

  @Mutation
  public DATA({data}: IDataMutation<'bookContents'>): void {
    // Clone the data so that Vue doesn't mutate the ShareDB Doc
    data = clone(data);
    this.data = data;
  }

  @Mutation
  public APPLY_OPS({ops}: IApplyOpsMutation<'bookContents'>): void {
    ops.forEach((op) => applyOp(this.data, op));
  }
}
