export enum BookViewerRouteName {
  App = 'bookViewerApp',
  BookViewer = 'bookViewer',
  Chapter = 'chapter',
  AboutTheAuthor = 'aboutTheAuthor',
  AlsoBy = 'alsoBy',
  Copyright = 'copyright',
  Dedication = 'dedication',
  Epigraph = 'epigraph',
  Part = 'part',
  NotFound = 'notFound',
}
