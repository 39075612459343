import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pull-loader-content-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenEdgeArrow = _resolveComponent("ScreenEdgeArrow")!
  const _component_VuiProgressCircular = _resolveComponent("VuiProgressCircular")!

  return (_openBlock(), _createElementBlock("rbe-pull-to-loader", {
    class: _normalizeClass(["pull-loader", {down: _ctx.down}])
  }, [
    (_ctx.down)
      ? (_openBlock(), _createBlock(_component_ScreenEdgeArrow, {
          key: 0,
          class: "down"
        }))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("rbe-pull-loader-content", null, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_VuiProgressCircular, {
          ref: "pullProgress",
          percentage: _ctx.percentage,
          class: "pull-progress"
        }, null, 8, ["percentage"]),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.text), 1)
      ], true)
    ]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    (!_ctx.down)
      ? (_openBlock(), _createBlock(_component_ScreenEdgeArrow, {
          key: 1,
          class: "up"
        }))
      : _createCommentVNode("", true)
  ], 2))
}