import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import Delta from '@reedsy/quill-delta';
import {KeyboardContext} from './keyboard.interface';

// This is a drastically simplified version of Quill's handler, which no longer
// attempts to preserve block formatting on backspace: that's handled by our own
// op transformer
export default function handleBackspace(quill: Quill, range: Range, context: KeyboardContext): void {
  // Check for astral symbols
  const length = /[\uD800-\uDBFF][\uDC00-\uDFFF]$/.test(context.prefix) ? 2 : 1;
  if (range.index === 0 || quill.getLength() <= 1) return;
  const delta = new Delta().retain(range.index - length).delete(length);
  quill.updateContents(delta, Quill.sources.USER);
  quill.focus();
}
