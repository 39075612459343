import Delta, {Op} from '@reedsy/quill-delta';
import {negativeLength, positiveLength} from '@reedsy/utils.ot-rich-text';

interface IOpContext {
  index: number;
  cursor: number;
  oldCursor: number;
}

interface IOpWithContext extends IOpContext {
  op: Op;
}

type OpCallback<Return> = (op: Op, context: IOpContext) => Return;

export function* eachOp(ops: ReadonlyArray<Op> | Delta): Generator<IOpWithContext> {
  if ('ops' in ops) ops = ops.ops;
  let cursor = 0;
  let oldCursor = 0;

  for (let index = 0; index < ops.length; index++) {
    const op = ops[index];
    yield {op, index, cursor, oldCursor};
    cursor += positiveLength(op);
    oldCursor += negativeLength(op);
  }
}

export function forEachOp(ops: ReadonlyArray<Op> | Delta, callback: OpCallback<void>): void {
  mapOps(ops, callback);
}

export function mapOps<T>(ops: ReadonlyArray<Op> | Delta, callback: OpCallback<T>): T[] {
  const results: T[] = [];
  for (const {op, ...context} of eachOp(ops)) {
    results.push(callback(op, context));
  }
  return results;
}
