<template>
  <PlainModal
    :id="id"
    :headless="true"
    class="subscription-modal accent-premium no-padding"
  >
    <rbe-columns>
      <rbe-column>
        <h1>
          <span>
            {{ title }}
          </span>
          <PremiumIcon size="sm" />
        </h1>

        <slot v-if="$slots.default" />
        <slot
          v-else
          name="left"
        />
      </rbe-column>

      <rbe-column
        v-if="$slots.right"
        class="right"
      >
        <slot name="right" />
      </rbe-column>
    </rbe-columns>

    <ModalCloseButton
      class="close-button default-color plain"
      @click="close"
    />
  </PlainModal>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';
import ModalCloseButton from '@reedsy/studio.shared/components/modals/components/modal-close-button.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';

@Component({
  components: {
    ModalCloseButton,
    PlainModal,
    PremiumIcon,
  },
})
export default class SubscriptionBaseModal extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({required: true})
  public title: string;
}
</script>

<style lang="scss" scoped>
.subscription-modal {
  --column-padding: #{$space-xl};
  --selection-background: var(--local-color-muted);

  // TODO: [subscriptions]: tablet/mobile designs
  @include screen-more-than(md) {
    width: 70rem;
  }

  rbe-columns {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 2fr 1fr;
  }

  rbe-column {
    padding: var(--column-padding);
    border-top-right-radius: $modal-border-radius;
    border-bottom-right-radius: $modal-border-radius;
    display: grid;
    gap: $space-sm;
  }

  h1 {
    @include font-family($controls, bold);

    display: flex;
    align-items: center;
    gap: $space-md;
    justify-content: left;
    font-size: $font-size-xxl;
  }

  .right {
    background: var(--selection-background);
  }

  .close-button {
    position: absolute;
    top: $space-base;
    right: $space-base;
    box-shadow: $box-shadow-sm;
  }

  // TODO: Get mobile/tablet designs
  @include screen-less-than(md) {
    h1 {
      flex-direction: row-reverse;
    }

    rbe-columns {
      grid-template-columns: 1fr;

      .right {
        display: none;
      }
    }
  }
}
</style>
