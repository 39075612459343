import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import Quill from '@reedsy/quill/core';
import {MentionEvent} from '@reedsy/studio.shared/services/quill/modules/mentions/events';

export default class MentionOption extends JsxComponent {
  public override $props: {
    id: string;
    title: string;
    quill: Quill;
    image?: string;
  };

  public onClick = (event: MouseEvent): void => {
    this.$props.quill.emitter.emit(MentionEvent.SuggestionClick, event.target);
  };

  public onMouseEnter = (event: MouseEvent): void => {
    this.$props.quill.emitter.emit(MentionEvent.SuggestionMouseEnter, event.target);
  };

  public render(): HTMLElement {
    return (
      <button
        onClick={this.onClick}
        onMouseenter={this.onMouseEnter}
        class="ql-mention-option button button-md reedsy-accented plain mention-button"
        type="button"
        data-id={this.$props.id}
      >
        {this.$props.image && <img
          src={this.$props.image}
          alt={this.$props.title}
        />}
        <span class="ellipsis">{this.$props.title}</span>
      </button>
    );
  }
}
