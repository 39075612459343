import IChangeRange from './i-change-range';

export default function combineRanges(ranges: IChangeRange[]): IChangeRange[] {
  const spaces: boolean[] = [];
  ranges.forEach((range) => {
    for (let i = range.start; i < range.end; i++) spaces[i] = true;
  });

  const combined: IChangeRange[] = [];
  let currentRange: IChangeRange;
  let previousIndex: number = null;
  spaces.forEach((_isSelected, index) => {
    if (previousIndex !== null && previousIndex !== index - 1) {
      currentRange.end = previousIndex + 1;
      combined.push(currentRange);
      currentRange = null;
    }

    if (!currentRange) currentRange = {start: index, end: null};
    previousIndex = index;
  });

  if (currentRange) {
    currentRange.end = spaces.length;
    combined.push(currentRange);
  }

  return combined;
}
