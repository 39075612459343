import Inline from '@reedsy/quill/blots/inline';
import isObject from '@reedsy/studio.shared/utils/is-object';
import Delta from '@reedsy/quill-delta';
import {deepEqual} from '@reedsy/utils.deep-equal';

/**
 * This class is for inline blots with complex objects as their format
 * values. Parchment only performs a shallow object comparison, which
 * then fails to merge complex formats, like store links or track
 * changes.
 * See: https://github.com/quilljs/parchment/blob/b62712c4a02b3b94e590ebf2afd921e30ec21f54/src/blot/inline.ts#L56-L60
 */
export default class ComplexInlineBlot extends Inline {
  public override optimize(context: any): void {
    super.optimize(context);
    if (!this.canMerge()) return;
    const next = this.next as ComplexInlineBlot;
    next.moveChildren(this);
    next.remove();
  }

  public override format(name: string, value: any): void {
    if (value && isObject(value) && name === this.statics.blotName) {
      value = Delta.AttributeMap.compose(this.formats()[name], value, false);
    }
    return super.format(name, value);
  }

  private canMerge(): boolean {
    return this.next instanceof ComplexInlineBlot &&
      this.next.prev === this &&
      deepEqual(this.formats(), this.next.formats());
  }
}
