<template>
  <div
    class="also-by-book"
  >
    <div
      v-if="hasCover"
      class="cover"
    >
      <img
        :src="book.cover"
        alt="Book cover"
      >
    </div>
    <h2
      v-if="title"
      class="section-title title-text paragraph-space"
    >
      {{ title }}
    </h2>
    <RichTextReader
      :rich-text-id="blurbRichTextId"
      :load-endnotes="false"
      class="blurb-text paragraph-space"
    >
      <template #empty-placeholder>
        <div />
      </template>
    </RichTextReader>
    <div
      v-if="hasLinks"
      class="store-links"
    >
      <a
        v-for="link in links"
        :key="link"
        :href="link"
        target="_blank"
        class="store-link"
      >
        {{ link }}
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import RichTextReader from '@reedsy/studio.viewer/components/reader/rich-text-reader.vue';
import {PropType} from 'vue';
import {IAlsoByBook} from '@reedsy/reedsy-sharedb/lib/common/book-content/also-by-book';
import {opsToString} from '@reedsy/studio.isomorphic/utils/rich-text/ops-to-string';

@Component({
  components: {
    RichTextReader,
  },
})
export default class AlsoByBook extends BookViewerVue {
  @Prop({type: Object as PropType<IAlsoByBook>, required: true})
  public book: IAlsoByBook;

  public get title(): string {
    return opsToString(this.book.title.ops).trim();
  }

  public get blurbRichTextId(): string {
    return this.book.blurbId;
  }

  public get links(): string[] {
    return Object.values(this.book.storeLinks)
      .filter((link) => !!link.trim());
  }

  public get hasLinks(): boolean {
    return !!this.links.length;
  }

  public get hasCover(): boolean {
    return !!this.book.cover;
  }
}
</script>

<style lang="scss" scoped>
.also-by-book {
  .cover {
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 40%;
    }
  }

  .paragraph-space {
    padding-bottom: $space-xl;
  }

  .title-text {
    color: var(--reedsy-plain-onDefault);
    width: 100%;
    text-align: left;
    font-size: $font-size-xxl;
    margin-top: 3rem;
    word-break: break-word;
  }

  .store-links {
    display: flex;
    flex-direction: column;

    .store-link {
      display: inline;
      text-decoration: underline;
      font-weight: normal;
    }
  }
}
</style>
