import QuillCursors from 'quill-cursors';
import ReedsyClipboard from './clipboard';
import DragAndDrop from './drag-and-drop';
import Endnotes from './endnotes';
import Figures from './figures';
import ReedsyKeyboard from './keyboard';
import Search from './search';
import ReedsySelection from './selection';
import FormInput from './form-input';
import SmartText from './smart-text';
import Spelling from './spelling';
import ReedsyToolbar from './toolbar';
import TrackChanges from '@reedsy/studio.shared/services/quill/modules/track-changes';
import ReedsyUploader from './uploader';
import SceneBreaks from './scene-breaks';
import History from './history';
import type Module from '@reedsy/quill/core/module';
import IQuillCursorsOptions from 'quill-cursors/dist/quill-cursors/i-quill-cursors-options';
import Mention from '@reedsy/studio.shared/services/quill/modules/mentions';
import Composition from './composition';
import Links from './links';
import Videos from './videos';

QuillCursors.DEFAULTS = {
  ...QuillCursors.DEFAULTS,
  hideDelayMs: 0,
  selectionChangeSource: null,
};

const MODULES = {
  clipboard: ReedsyClipboard,
  composition: Composition,
  cursors: QuillCursors as unknown as typeof Module<IQuillCursorsOptions>,
  'drag-and-drop': DragAndDrop,
  endnotes: Endnotes,
  figures: Figures,
  history: History,
  keyboard: ReedsyKeyboard,
  search: Search,
  'scene-breaks': SceneBreaks,
  selection: ReedsySelection,
  'form-input': FormInput,
  'smart-text': SmartText,
  spelling: Spelling,
  toolbar: ReedsyToolbar,
  'track-changes': TrackChanges,
  uploader: ReedsyUploader,
  mention: Mention,
  links: Links,
  videos: Videos,
} as const;

export type Modules = typeof MODULES;
export type ModuleName = keyof Modules;
export default Object.freeze(MODULES);
