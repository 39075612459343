import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotFoundPartial = _resolveComponent("NotFoundPartial")!

  return (_openBlock(), _createBlock(_component_NotFoundPartial, null, {
    "home-link": _withCtx(() => [
      _createElementVNode("a", {
        href: _ctx.reedsyUrl,
        class: "text-link"
      }, "Return to Reedsy", 8, _hoisted_1)
    ]),
    _: 1
  }))
}