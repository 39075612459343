import Blot from '@reedsy/studio.shared/services/quill/blots/blot';
import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import ImagePlaceholder from './placeholders/image-placeholder';
import {enforceRange} from '@reedsy/utils.primitive';
import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import {Constructor} from '@reedsy/utils.types';
import IDragAndDropOptions from './i-drag-and-drop-options';

const MAX_MOCK_PROGRESS = 80;
const MOCK_PROGRESS_INTERVAL_PERCENT = 5;
const MOCK_PROGRESS_INTERVAL_MILLIS = 1000;

export default class BlotPlaceholder {
  public static readonly classes = Object.freeze({
    PROGRESS: 'progress',
    WRAPPER: 'ql-placeholder',
  });

  public readonly element: HTMLElement;
  public readonly quill: Quill;
  private readonly progressElement: HTMLElement;
  private mockProgress = 0;

  public constructor(
    quill: Quill,
    blotType: string,
    shouldMockProgress = false,
  ) {
    this.quill = quill;
    const dragAndDropOptions = this.quill.options.modules['drag-and-drop'] as IDragAndDropOptions;
    const placeholderTemplates = dragAndDropOptions?.placeholderTemplates;
    this.element = document.createElement('div');
    this.element.classList.add(BlotPlaceholder.classes.WRAPPER);
    this.element.innerHTML = this.templateHtml(blotType, placeholderTemplates).innerHTML;
    this.progressElement = this.element.querySelector(`.${BlotPlaceholder.classes.PROGRESS}`);
    if (shouldMockProgress) this.updateMockProgress();
  }

  public remove(): void {
    this.element.remove();
  }

  public moveBefore(eventOrNode: Node | MouseEvent): void {
    let event: MouseEvent;
    let node: Node;

    if (eventOrNode instanceof MouseEvent) {
      event = eventOrNode;
      node = event.target as Node;
    } else {
      node = eventOrNode;
    }

    node = this.topLevelBlock(node);
    if (!this.quill.root.contains(node)) return;
    if (node.nextSibling && this.cursorIsInBottomHalf(node as HTMLElement, event)) node = node.nextSibling;
    node.parentElement.insertBefore(this.element, node);
    // Clear observer to prevent Quill trying to update its document model
    // due to our DOM manipulation
    this.quill.scroll.observer.takeRecords();
  }

  public range(): Range {
    if (!this.quill.root.contains(this.element)) return null;
    const blot = this.nextBlot();
    const index = blot ? this.quill.getIndex(blot) : this.quill.getLength();
    return {index, length: 0};
  }

  public progress(percentage: number): void {
    percentage = enforceRange(percentage, {min: 0, max: 100});
    this.progressElement.style.width = `${percentage}%`;
  }

  public attached(): boolean {
    return !!this.element.parentElement;
  }

  private templateHtml(blotType: string, templates: Record<string, Constructor<JsxComponent>> = {}): HTMLElement {
    const Placeholder = templates[blotType] || ImagePlaceholder;
    return new Placeholder().render();
  }

  private nextBlot(): Blot {
    let element = this.element.nextSibling;

    while (element) {
      const blot = this.quill.options.registry.find(element);
      if (blot) return blot as Blot;
      element = element.nextSibling;
    }

    return null;
  }

  private updateMockProgress(): void {
    if (this.mockProgress > MAX_MOCK_PROGRESS) return;
    this.progress(this.mockProgress);
    this.mockProgress += MOCK_PROGRESS_INTERVAL_PERCENT;
    setTimeout(() => this.updateMockProgress(), MOCK_PROGRESS_INTERVAL_MILLIS);
  }

  private topLevelBlock(node: Node): Node {
    if (node === this.quill.root) {
      return null;
    }

    while (node && node.parentElement !== this.quill.root) node = node.parentElement;
    return node;
  }

  private cursorIsInBottomHalf(block: HTMLElement, event: MouseEvent): boolean {
    if (!event) return false;
    const elementBounds = block.getBoundingClientRect();
    const elementMidLine = 0.5 * (elementBounds.top + elementBounds.bottom);
    return event.clientY > elementMidLine;
  }
}
