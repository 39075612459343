import {encodeObjectId, decodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';

const PREFIX = 'note-';

export function endnoteHashFromId(id: string): string {
  return id ? `${PREFIX}${encodeObjectId(id)}` : '';
}

export function endnoteIdFromHash(hash: string): string {
  hash = hash || '';
  const id = hash.split(PREFIX)[1] || '';
  return decodeObjectId(id);
}
