import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import Quill from '@reedsy/quill/core';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {ReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import IconOpen from './icons/open';

export default class OpenButton extends JsxComponent {
  @$lazyInject('Router')
  public _router: ReedsyRouter;

  public override $props: {
    url: string;
    quill: Quill;
  };

  public onClick = (event: Event): void => {
    event.preventDefault();
    this._router.push(this.$props.url);
  };

  public render(): HTMLElement {
    return (
      <a
        href={this.$props.url}
        onClick={this.onClick}
        class="button open-button"
      >
        Open
        <IconOpen />
      </a>
    );
  }
}
