import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';

export default class RegularLinkTemplate extends JsxComponent {
  public override $props: {
    links: ILinks;
    isEditing: boolean;
  };

  public render(): HTMLElement {
    const url = this.$props.links.regularUrl;

    return (
      <div class="regular-link-editor">
        <div class="link-row">
          {
            this.$props.isEditing ?
              <input
                is="url-input"
                name="regularUrl"
                placeholder="Type your link here"
                value={url}
              /> :
              <a
                class="regularUrl ellipsis"
                target="_blank"
                href={url}
                title={url}
              >
                {url}
              </a>
          }
        </div>
      </div>
    );
  }
}
