import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "first-page-text"
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 0,
  class: "last-page-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftSideBar = _resolveComponent("LeftSideBar")!
  const _component_PullLoader = _resolveComponent("PullLoader")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_PullTo = _resolveComponent("PullTo")!
  const _component_TopBar = _resolveComponent("TopBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["book-viewer-main-layout", { 'with-alert-bar': _ctx.alertBarIsOpen }])
  }, [
    _createVNode(_component_LeftSideBar),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createVNode(_component_PullTo, {
      id: "content-scroll",
      class: "content-wrapper",
      onPullTop: _ctx.goToPreviousPage,
      onPullBottom: _ctx.goToNextPage,
      onScrollPassive: _ctx.updateScrollLocation
    }, {
      "top-loader": _withCtx((slotProps) => [
        _createVNode(_component_PullLoader, {
          percentage: slotProps.activatePercentage,
          text: _ctx.topLoaderText(slotProps.activatePercentage)
        }, {
          default: _withCtx(() => [
            (!_ctx.hasPreviousPage)
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, "\n              You’re on the first page\n            "))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["percentage", "text"])
      ]),
      "bottom-loader": _withCtx((slotProps) => [
        _createVNode(_component_PullLoader, {
          percentage: slotProps.activatePercentage,
          text: _ctx.bottomLoaderText(slotProps.activatePercentage),
          down: ""
        }, {
          default: _withCtx(() => [
            (!_ctx.hasNextPage)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, "\n              You’ve reached the end\n            "))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["percentage", "text"])
      ]),
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createElementVNode("div", { id: _ctx.bottomDivId }, null, 8, _hoisted_3),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createVNode(_component_Footer),
        _cache[3] || (_cache[3] = _createTextVNode())
      ]),
      _: 3
    }, 8, ["onPullTop", "onPullBottom", "onScrollPassive"]),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createVNode(_component_TopBar)
  ], 2))
}