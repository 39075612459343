import {TextBlot} from 'parchment';
import Break from '@reedsy/quill/blots/break';
import Cursor from '@reedsy/quill/blots/cursor';
import HighlightBlot from '@reedsy/studio.shared/services/quill/blots/highlight';
import IgnoreSpelling from '@reedsy/studio.shared/services/quill/blots/ignore-spelling';
import TrackChange from '@reedsy/studio.shared/services/quill/blots/track-changes/track-change';
import TrackComment from '@reedsy/studio.shared/services/quill/blots/track-changes/track-comment';
import TrackDeletionBlockIndicator from '@reedsy/studio.shared/services/quill/blots/track-changes/track-deletion-block-indicator';

export const ALWAYS_ALLOWED_INLINES = Object.freeze([
  Break,
  Cursor,
  HighlightBlot,
  IgnoreSpelling,
  TextBlot,
  TrackChange,
  TrackComment,
  TrackDeletionBlockIndicator,
]);
