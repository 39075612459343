import {ILocalStorage} from '@reedsy/studio.shared/services/local-storage/i-local-storage';
import LocalStorageHelper from '@reedsy/studio.shared/services/local-storage/local-storage-helper';
import {injectable} from 'inversify';

@injectable()
export class BookViewerLocalStorageHelper extends LocalStorageHelper {
  public isInitialised = false;
  public constructor() {
    super('bookViewer');
  }

  public override getItem<
    T extends ILocalStorage,
    K extends keyof T,
    V extends T[K]>(
    obj: T,
    property: K & string,
  ): V {
    if (!this.isInitialised) {
      throw new Error('Local storage helper is not initialised');
    }
    return super.getItem(obj, property);
  }

  public override setItem<T extends ILocalStorage, K extends keyof T, V extends T[K]>(
    obj: T,
    property: K,
    value: V,
  ): void {
    if (!this.isInitialised) {
      throw new Error('Local storage helper is not initialised');
    }

    super.setItem(obj, property, value);
  }

  public initialise(shareableUrlShortId: string): void {
    if (!shareableUrlShortId) return;

    this.addNamespace(shareableUrlShortId);
    this.isInitialised = true;
  }
}
