import TooltipTemplate from './tooltip';
import {Bounds} from '@reedsy/studio.shared/services/quill/modules/bounds.interface';
import OpenButton from '@reedsy/studio.shared/services/quill/modules/mentions/actions-tooltip/open-button';
import ReedsyQuill from '@reedsy/quill';
import PinButton from '@reedsy/studio.shared/services/quill/modules/mentions/actions-tooltip/pin-button';
import {MentionEvent} from '@reedsy/studio.shared/services/quill/modules/mentions/events';
import {MentionsTooltip} from '@reedsy/studio.shared/services/quill/modules/mentions/mentions-tooltip';
import MentionBlot from '@reedsy/studio.shared/services/quill/blots/mentions';

const TOOLTIP_CLASS = 'ql-tooltip-actions';

export default class SuggestionsTooltip extends MentionsTooltip {
  public static override TEMPLATE = new TooltipTemplate().render().innerHTML;
  public static TOOLTIP_CLASS = TOOLTIP_CLASS;

  public override quill: ReedsyQuill;
  public buttonsContainer: HTMLElement;

  public constructor(
    quill: ReedsyQuill,
    bounds: HTMLElement,
  ) {
    super(quill, bounds);
    this.root.classList.add(TOOLTIP_CLASS);
    this.buttonsContainer = this.root.querySelector('.buttons-list');

    quill.emitter.on(MentionEvent.MentionPinClick, this.hide.bind(this));
  }

  protected get tooltipAnchorBounds(): Bounds {
    const range = this.quill.getSelection();
    if (!range) return;
    const [leaf] = this.quill.getLeaf(range.index);

    let mention: MentionBlot;
    if (leaf instanceof MentionBlot) mention = leaf;
    else if (leaf.next instanceof MentionBlot) mention = leaf.next;

    if (!mention) return;

    const index = this.quill.getIndex(mention);

    return this.quill.getBounds(index, mention.length());
  }

  public showTooltip(assetId: string, assetUrl: string, isPinned: boolean): void {
    const bounds = this.tooltipAnchorBounds;
    if (!bounds) return;

    this.updateButtons(assetId, assetUrl, isPinned);
    this.show();
    this.position(bounds);
  }

  private updateButtons(id: string, url: string, isPinned: boolean): void {
    this.buttonsContainer.innerHTML = '';

    const openButton = new OpenButton({url, quill: this.quill}).render();
    const pinButton = new PinButton({
      id,
      quill: this.quill,
      isPinned,
    }).render();
    this.buttonsContainer.appendChild(openButton);
    this.buttonsContainer.appendChild(pinButton);
  }
}
