import {Scope} from 'parchment';
import TrackBlockAttributor from './track-block-insert-attributor';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';

const {BLOCK} = TrackChangesKeys;

export const TrackInsertionBlock = new TrackBlockAttributor(
  BLOCK.INSERTION,
  BLOCK.INSERTION,
  {scope: Scope.BLOCK_ATTRIBUTE, indicatorClass: 'block-insertion-indicator'},
);
