import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "copyright-view theme-body" }
const _hoisted_2 = {
  key: 0,
  class: "publisher-name paragraph-space"
}
const _hoisted_3 = { class: "copyrights-clause paragraph-space" }
const _hoisted_4 = {
  key: 1,
  class: "custom-clause paragraph-space"
}
const _hoisted_5 = { class: "edition-text" }
const _hoisted_6 = {
  key: 2,
  class: "collaborators paragraph-space"
}
const _hoisted_7 = {
  key: 3,
  class: "publisher-logo-container"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.publisherName)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, "\n        First published by " + _toDisplayString(_ctx.publisherName) + " " + _toDisplayString(_ctx.publicationYear), 1))
          : _createCommentVNode("", true),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("p", _hoisted_3, "\n        Copyright © " + _toDisplayString(_ctx.publicationYear) + " by " + _toDisplayString(_ctx.authorName), 1),
        _cache[1] || (_cache[1] = _createTextVNode()),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.copyrightClauses, (clause) => {
          return (_openBlock(), _createElementBlock("p", {
            key: clause.key,
            class: "predefined-clause paragraph-space"
          }, _toDisplayString(clause.content), 1))
        }), 128)),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (_ctx.isCustomClauseEnabled && !_ctx.isCustomClauseEmpty)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_RichTextReader, {
                "rich-text-id": _ctx.customClauseRichTextId,
                class: "align-center"
              }, null, 8, ["rich-text-id"])
            ]))
          : _createCommentVNode("", true),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.currentEditionText), 1),
        _cache[4] || (_cache[4] = _createTextVNode()),
        (_ctx.hasCollaborators)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collaboratorsCredits, (collaborator, index) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: index,
                  class: "collaborator-row"
                }, _toDisplayString(collaborator), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _cache[5] || (_cache[5] = _createTextVNode()),
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "reedsy-info paragraph-space" }, [
          _createTextVNode("\n        This book was professionally typeset on Reedsy"),
          _createElementVNode("br"),
          _createTextVNode("\n        Find out more at "),
          _createElementVNode("a", {
            class: "reedsy-link",
            href: "https://reedsy.com",
            target: "_blank"
          }, "reedsy.com")
        ], -1)),
        _cache[7] || (_cache[7] = _createTextVNode()),
        (_ctx.hasPublisherLogo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("img", {
                src: _ctx.publisherLogoUrl,
                alt: "Publisher Logo",
                class: "publisher-logo"
              }, null, 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}