import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "book-viewer-footer" }
const _hoisted_2 = { class: "powered-by" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "author-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _cache[0] || (_cache[0] = _createTextVNode("\n      Powered by ")),
      _createElementVNode("a", {
        href: _ctx.writeABookUrl,
        class: "vui-no-animation"
      }, "\n        Reedsy\n      ", 8, _hoisted_3)
    ]),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("p", _hoisted_4, "\n      © " + _toDisplayString(_ctx.authorName), 1)
  ]))
}