import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "also-by-book" }
const _hoisted_2 = {
  key: 0,
  class: "cover"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "section-title title-text paragraph-space"
}
const _hoisted_5 = {
  key: 2,
  class: "store-links"
}
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RichTextReader = _resolveComponent("RichTextReader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasCover)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.book.cover,
            alt: "Book cover"
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createVNode(_component_RichTextReader, {
      "rich-text-id": _ctx.blurbRichTextId,
      "load-endnotes": false,
      class: "blurb-text paragraph-space"
    }, {
      "empty-placeholder": _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", null, null, -1)
      ])),
      _: 1
    }, 8, ["rich-text-id"]),
    _cache[3] || (_cache[3] = _createTextVNode()),
    (_ctx.hasLinks)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
            return (_openBlock(), _createElementBlock("a", {
              key: link,
              href: link,
              target: "_blank",
              class: "store-link"
            }, _toDisplayString(link), 9, _hoisted_6))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}