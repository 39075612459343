import Quill from '@reedsy/quill';
import {KeyboardHandler} from './keyboard.interface';
import {IBlockStringInsertOp} from '@reedsy/schemas.ot-rich-text';

type Align = IBlockStringInsertOp['attributes']['align'];

export const alignHandler = (align: Align): KeyboardHandler => {
  return function({index}, {format}) {
    if (format.align === align) align = false;
    this.quill.formatLine(index, 0, {align}, Quill.sources.USER);
  };
};
