import {Scope} from 'parchment';
import TrackReformatAttributor from './track-reformat-attributor';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';

const {INLINE} = TrackChangesKeys;

export const TrackReformatInline = new TrackReformatAttributor(
  INLINE.REFORMAT,
  INLINE.REFORMAT,
  {scope: Scope.INLINE_ATTRIBUTE},
);
