import {Registry} from 'parchment';
import Quill from '@reedsy/quill/core';
import {Range} from '@reedsy/quill/core/selection';
import TrackChangesStyles from '@reedsy/studio.shared/services/styles/track-changes';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import Delta from '@reedsy/quill-delta';
import {dig} from '@reedsy/utils.dig';
import {TrackCommentBlock} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-comment-block';
import {TrackInsertionInline} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-insertion-inline';
import {TrackInsertionBlock} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-insertion-block';
import {TrackDeletionInline} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-deletion-inline';
import {TrackDeletionBlock} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-deletion-block';
import {TrackDeletionBlockIndicatorAttributor} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-deletion-block-indicator';
import {TrackReformatInline} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-reformat-inline';
import {TrackReformatBlock} from '@reedsy/studio.shared/services/quill/blots/track-changes/attributors/track-reformat-block';
import TrackChange from '@reedsy/studio.shared/services/quill/blots/track-changes/track-change';
import TrackDeletionBlockIndicator from '@reedsy/studio.shared/services/quill/blots/track-changes/track-deletion-block-indicator';
import ChangeHighlighter from './change-highlighter';
import {Viewport} from '@reedsy/studio.shared/utils/viewport';
import {ObjectId} from '@reedsy/utils.object-id';
import isPrintable from '@reedsy/studio.shared/utils/keyboard/is-printable';
import browser from '@reedsy/studio.shared/utils/browser';
import SESSION_ID from '@reedsy/studio.shared/services/sharedb/session-id';
import Module from '@reedsy/quill/core/module';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {IReedsyOptionsMetadata} from '@reedsy/studio.shared/services/quill/i-reedsy-options-metadata';
import TrackComment from '@reedsy/studio.shared/services/quill/blots/track-changes/track-comment';

const {BLOCK, INLINE} = TrackChangesKeys;
const HIDE_DELETIONS_CLASS = 'hide-tracked-deletions';

export default class TrackChanges extends Module {
  public static HIDE_DELETIONS_CLASS = HIDE_DELETIONS_CLASS;
  public static events = Object.freeze({
    ADD_COMMENT: 'add-comment',
  });

  private static styles = new TrackChangesStyles();

  public static registerFormats(registry: Registry): void {
    [
      // Blots
      TrackChange,
      TrackDeletionBlockIndicator,
      TrackComment,
      // Attributors
      TrackCommentBlock,
      TrackInsertionInline,
      TrackInsertionBlock,
      TrackDeletionInline,
      TrackDeletionBlock,
      TrackDeletionBlockIndicatorAttributor,
      TrackReformatInline,
      TrackReformatBlock,
    ].forEach((format) => registry.register(format));
  }

  public override options: ITrackChangesOptions;

  private readonly userId: string;
  private readonly highlighter: ChangeHighlighter;
  private readonly functions: any = {};

  private keydownRange: Range = null;
  private keydownLength: number = null;
  private _isAddingComment = false;

  public constructor(quill: Quill, options: ITrackChangesOptions) {
    super(quill, options);
    TrackChanges.registerFormats(quill.options.registry);

    const metadata = (this.quill.options as any).metadata as IReedsyOptionsMetadata;
    const userId = metadata.userId || null;
    this.userId = userId && userId.toString();

    this.highlighter = new ChangeHighlighter(quill);

    this.functions = {
      deleteSelection: this.deleteSelection.bind(this),
      recordSelection: this.recordSelection.bind(this),
      updateSelection: this.updateSelection.bind(this),
      ensureOutsideHiddenDeletion: this.ensureOutsideHiddenDeletion.bind(this),
    };

    this.registerListeners();
    this.registerKeyboardShortcuts();
  }

  public get enabled(): boolean {
    return this.options.enabled();
  }

  public get deletionsAreHidden(): boolean {
    return this.quill.root.classList.contains(HIDE_DELETIONS_CLASS);
  }

  public get isAddingComment(): boolean {
    return this._isAddingComment;
  }

  public toggleHideDeletions(shouldHide: boolean): void {
    this.quill.root.classList.toggle(HIDE_DELETIONS_CLASS, shouldHide);
    this.highlighter.redraw();
  }

  public addUser(userId: string, color: string): void {
    TrackChanges.styles.addUser(userId, color);
  }

  public highlightChange(changeId: string): void {
    this.highlighter.select(changeId);
    const element = this.quill.root.querySelector(`[change-ids~="${changeId}"]`);
    if (!this.quill.hasFocus()) Viewport.scrollIntoView(element, {block: 'center'});
  }

  public addComment(range?: Range): void {
    range = range || this.quill.getSelection();
    if (!dig(range, 'length')) return;
    const changeId = new ObjectId().toHexString();
    const comment = {[changeId]: {userId: this.userId, changeId, pending: SESSION_ID}};
    this._isAddingComment = true;
    this.quill.emitter.emit(TrackChanges.events.ADD_COMMENT, changeId);
    this.quill.formatText(
      range.index,
      range.length,
      INLINE.COMMENT,
      comment,
      Quill.sources.USER,
    );
    this.quill.formatText(range.index, range.length, BLOCK.COMMENT, comment, Quill.sources.USER);
    this._isAddingComment = false;
  }

  private registerListeners(): void {
    this.quill.root.addEventListener('keyup', this.functions.updateSelection);
    this.quill.root.addEventListener('keydown', this.functions.deleteSelection);
    // Safari has a different event order for composition
    if (browser.is('safari')) this.quill.root.addEventListener('compositionupdate', this.functions.deleteSelection);
    // Attach a listener on CAPTURE. This means we record the current
    // selection before Quill has a chance to do anything
    this.quill.root.addEventListener('keydown', this.functions.recordSelection, true);
    document.addEventListener('selectionchange', this.functions.ensureOutsideHiddenDeletion);
  }

  // Quill sometimes tries to be overly clever with its ops. For example:
  //   1. Start with the text 'word'
  //   2. Highlight that text
  //   3. Type the word 'right'
  // In the above case, with track changes, you would expect to see 'word'
  // struck out, and 'right' inserted next to it. However, Quill spots that
  // the 'r' in 'right' matches part of the replaced text, and instead you
  // end up with 'wo' struck out, followed by 'ight' inserted, 'r' with no
  // tracking, and 'd' deleted.
  // In order to fix this behaviour, we listen out for the 'beforeinput'
  // event, which will fire when users add content to the editor. If they
  // have text selected, we manually delete this ourselves first so that
  // the deletion is kept conceptually separate from the insertion.
  private deleteSelection(event: KeyboardEvent): void {
    if (
      !this.enabled ||
      (event.key && !isPrintable(event)) ||
      // Android hides the keyboard if we handle this ourselves
      browser.is('android')
    ) return;
    const selection = this.quill.getSelection();
    if (!dig(selection, 'length')) return;

    const deletion = new Delta().retain(selection.index).delete(selection.length);
    this.quill.updateContents(deletion, Quill.sources.USER);
  }

  private recordSelection(): void {
    if (!this.enabled) return;
    this.keydownRange = this.quill.getSelection();
    this.keydownLength = this.quill.getLength();
  }

  // Pressing the delete key doesn't normally require a selection update,
  // but we need to advance the cursor when tracking changes, so let's do
  // that on keyup
  private updateSelection(event: KeyboardEvent): void {
    if (!this.shouldAdvanceDeleteCursor(event)) return;
    const length = this.keydownRange.length || 1;
    this.quill.setSelection(this.keydownRange.index + length);
  }

  private shouldAdvanceDeleteCursor(event: KeyboardEvent): boolean {
    return this.enabled &&
      !this.deletionsAreHidden &&
      event.key === Key.Delete &&
      this.keydownLength === this.quill.getLength();
  }

  private ensureOutsideHiddenDeletion(): void {
    if (!this.quill.root.isConnected) {
      return document.removeEventListener('selectionchange', this.functions.ensureOutsideHiddenDeletion);
    }

    const selection = window.getSelection();
    if (!this.deletionsAreHidden || !selection.rangeCount) return;
    const start = selection.getRangeAt(0).startContainer;
    if (!this.quill.root.contains(start)) return;
    const deletion = start.parentElement.closest(`[${INLINE.DELETION}]`);
    if (!deletion) return;

    const next = this.nextNonDeletion(deletion);
    if (next) return selection.setPosition(next, 0);

    const previous = this.lastTextNode(deletion.previousSibling);
    selection.setPosition(previous, previous.length);
  }

  private nextNonDeletion(node: Node): Node {
    if (!node) return null;
    if (node instanceof Text) return node;
    const element = node as HTMLElement;
    if (!element.hasAttribute(INLINE.DELETION)) return element;
    return this.nextNonDeletion(element.nextSibling);
  }

  private lastTextNode(node: Node): Text {
    if (node instanceof Text) return node;
    const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT);
    return walker.lastChild() as Text;
  }

  private registerKeyboardShortcuts(): void {
    // Need to use numeric key codes because on Mac, the Alt key changes event.key,
    // and Quill doesn't check key.code
    const A = 65;
    const M = 77;
    /* istanbul ignore next: Quill determines platform at page load, so hard to test */
    const key = browser.is('macOS') ? A : M;
    this.quill.keyboard.addBinding({
      key,
      shortKey: true,
      altKey: true,
      handler: (range) => this.addComment(range),
    });
  }
}

export interface ITrackChangesOptions {
  enabled: () => boolean;
}
