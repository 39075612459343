import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dedication" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_RichTextReader, {
          "rich-text-id": _ctx.richTextId,
          "empty-default-placeholder-text": "Dedication page is still a work in progress!",
          class: "align-center italic"
        }, null, 8, ["rich-text-id"])
      ])
    ]),
    _: 1
  }))
}