<template>
  <div class="paragraph-loader">
    <SkeletonLoader class="paragraph-length-5" />
    <SkeletonLoader class="paragraph-length-3" />
    <SkeletonLoader class="paragraph-length-4" />
    <SkeletonLoader class="paragraph-length-3" />
    <div class="section-with-image">
      <SkeletonLoader class="image-skeleton" />
      <div class="paragraph-column-skeleton">
        <SkeletonLoader />
        <SkeletonLoader class="paragraph-length-3" />
        <SkeletonLoader class="paragraph-length-4" />
        <SkeletonLoader class="paragraph-length-1" />
      </div>
    </div>
    <SkeletonLoader class="paragraph-length-4" />
    <SkeletonLoader class="paragraph-length-1" />
    <div class="title-skeleton">
      <SkeletonLoader class="paragraph-length-1" />
    </div>
    <SkeletonLoader />
    <SkeletonLoader class="paragraph-length-4" />
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class ParagraphLoader extends BookViewerVue {}
</script>

<style lang="scss" scoped>
.paragraph-loader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .skeleton-loader {
    margin-top: $space-sm;
  }

  @for $i from 1 through 4 {
    .paragraph-length-#{$i} {
      width: calc($i * 10% + 50%);
    }
  }

  .title-skeleton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: $space-lg 0;

    .skeleton-loader {
      height: 2rem;
    }
  }

  .section-with-image {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .image-skeleton {
      width: 30%;
      height: 7rem;
    }

    .paragraph-column-skeleton {
      display: flex;
      flex-direction: column;
      width: 65%;
    }
  }
}
</style>
