import {LinkTooltipMode} from '@reedsy/studio.shared/services/quill/modules/links';
import {LinkType} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/link-type.interface';
import {ILinks} from '@reedsy/reedsy-sharedb/lib/utils/rich-text/links.interface';
import BaseLink from './base-link';

export default class LinkWithStoreMode extends BaseLink {
  public static override create(value: any): HTMLLinkElement {
    const node = super.create(value);
    this.setValue(node, value);
    return node;
  }

  public static override formats(node: HTMLLinkElement): ILinks {
    let hasUrl = false;
    const formats = Object.values(LinkType).reduce((links, type) => {
      if (type === LinkType.Regular) links[type] = node.getAttribute('href') || '';
      else links[type] = node.dataset[type] || '';
      hasUrl = hasUrl || !!links[type];
      return links;
    }, {} as ILinks);

    return hasUrl ? formats : null;
  }

  private static setValue(node: HTMLLinkElement, value: any): void {
    if (typeof value === 'string') value = {[LinkType.Regular]: value};
    this.setStoreLinks(node, value);
  }

  private static setStoreLinks(node: HTMLLinkElement, links: ILinks): void {
    Object.values(LinkType).forEach((type) => {
      const shouldIgnoreValue = (links[LinkType.Regular] && type !== LinkType.Regular) ||
        (!links[LinkType.Regular] && type === LinkType.Regular);

      const value = shouldIgnoreValue ? '' : links[type];
      this.setLink(node, type, value);
    });
  }

  private static setLink(node: HTMLLinkElement, type: LinkType, value: string): void {
    value = this.sanitize(value);
    if (type === LinkType.Regular) node.href = value || '';
    else node.dataset[type] = value || '';
  }

  public get mode(): LinkTooltipMode {
    return this.links[LinkType.Regular] ? LinkTooltipMode.Link : LinkTooltipMode.StoreLink;
  }

  public get links(): ILinks {
    return this.formats().link;
  }
}
