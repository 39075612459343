
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import LeftSideBarBase from '@reedsy/studio.shared/components/left-sidebar/left-sidebar-base.vue';
import TableOfContents from '@reedsy/studio.viewer/components/table-of-contents/table-of-contents.vue';
import CloseLeftSidebarButton from './close-left-sidebar-button.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerLayoutModule} from '@reedsy/studio.viewer/store/modules/layout/book-viewer-layout';

@Component({
  components: {
    LeftSideBarBase,
    TableOfContents,
    CloseLeftSidebarButton,
  },
})
export default class LeftSidebar extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerLayout)
  public $layout: BookViewerLayoutModule;

  public get leftSidebarIsActive(): boolean {
    return this.$layout.leftSidebarIsOpen;
  }

  public toggleLeftSidebar(): void {
    this.$layout.toggleLeftSidebar();
  }
}
