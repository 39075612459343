
import {Component, Prop, Emit} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import ContentPane from './content-pane/content-pane.vue';

@Component({
  components: {
    Overlay,
    ContentPane,
  },
})
export default class LeftSidebarBase extends ClientSharedVue {
  @Prop({type: String, default: ''})
  public title: string;

  @Prop({type: Boolean, default: false})
  public readOnlyMode: boolean;

  @Prop({type: Boolean, default: false})
  public withOverlay: boolean;

  @Prop({type: Boolean, default: false})
  public open: boolean;

  @Emit('hide')
  public emitHide(): boolean {
    return true;
  }
}
