<template>
  <div
    class="font-settings-menu"
  >
    <SettingsField label="Theme">
      <VuiDropdown
        v-model="themeOption"
        class="sm theme-option-dropdown"
        :options="dropdownThemeOptions"
        avoid-auto-positioning
        underlined
      />
    </SettingsField>

    <SettingsField label="Font size">
      <Slider
        v-model="fontSize"
        :data="fontSizeData"
        class="font-size-slider"
      />
    </SettingsField>

    <SettingsField label="Line height">
      <Slider
        v-model="lineHeight"
        :data="lineHeightData"
        class="line-height-slider"
      />
    </SettingsField>

    <SettingsField label="Justify text">
      <VuiToggle
        v-model="isTextJustified"
        title="Justify text"
        class="justify-text-toggle label-left spread"
      />
    </SettingsField>
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {
  Theme,
  THEMES,
  FontSize,
  FontSizeName,
  FONT_SIZES,
  LINE_HEIGHTS,
  LineHeight,
  LineHeightName,
} from '@reedsy/studio.shared/models/settings';
import {IDropdownOption} from '@reedsy/studio.shared/components/forms/dropdown/i-option';
import Slider from '@reedsy/studio.shared/components/slider/slider.vue';
import SettingsField from './settings-field.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';

@Component({
  components: {
    Slider,
    SettingsField,
  },
})
export default class FontSettingsMenu extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerSettings)
  public $settings: BookViewerSettingsModule;

  public dropdownThemeOptions: IDropdownOption[] = Object.entries(THEMES)
    .map(([value, text]) => ({value, text}));

  public get themeOption(): Theme {
    return this.$settings.theme;
  }

  public set themeOption(option: Theme) {
    this.$settings.changeTheme(option);
  }

  public get fontSizeData(): FontSizeName[] {
    return Object.values(FONT_SIZES);
  }

  public get fontSize(): FontSizeName {
    return FONT_SIZES[this.$settings.fontSize];
  }

  public set fontSize(fontSize: FontSizeName) {
    this.$settings.changeFontSize(
      Object
        .keys(FONT_SIZES)
        .find((key: FontSize) => FONT_SIZES[key] === fontSize) as FontSize,
    );
  }

  public get lineHeightData(): LineHeightName[] {
    return Object.values(LINE_HEIGHTS);
  }

  public get lineHeight(): LineHeightName {
    return LINE_HEIGHTS[this.$settings.lineHeight];
  }

  public set lineHeight(lineHeight: LineHeightName) {
    this.$settings.changeLineHeight(
      Object
        .keys(LINE_HEIGHTS)
        .find((key: LineHeight) => LINE_HEIGHTS[key] === lineHeight) as LineHeight,
    );
  }

  public get isTextJustified(): boolean {
    return this.$settings.isTextJustified;
  }

  public set isTextJustified(isTextJustified: boolean) {
    this.$settings.toggleJustifyText(isTextJustified);
  }
}
</script>

<style lang="scss" scoped>
.font-settings-menu {
  padding: $space-md;
  width: 100%;
  box-sizing: border-box;
}
</style>
