import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {findContentInfo} from './find-content-info';
import {count} from './count';
import {has} from '@reedsy/utils.set';
import {bookContentStructure} from '@reedsy/reedsy-sharedb/lib/server/book-contents-structure';
import {topMatterType} from './top-matter-type';
import {dig} from '@reedsy/utils.dig';

const {numberedChapters} = bookContentStructure;

export function findChapterNumber(
  flatBookContent: IContentFragment[],
  id: string,
): number {
  const content = findContentInfo(flatBookContent, id);
  if (!has(numberedChapters, dig(content, 'data', 'type'))) return null;
  if (topMatterType(content) !== MatterType.Body) return null;
  if (content.data.enabled === false) return null;
  return count(flatBookContent, {
    types: [...bookContentStructure.numberedChapters],
    in: MatterType.Body,
    until: content.id,
  });
}
