
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PremiumIcon from '@reedsy/studio.shared/components/subscriptions/premium-icon.vue';
import ModalCloseButton from '@reedsy/studio.shared/components/modals/components/modal-close-button.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';

@Component({
  components: {
    ModalCloseButton,
    PlainModal,
    PremiumIcon,
  },
})
export default class SubscriptionBaseModal extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({required: true})
  public title: string;
}
