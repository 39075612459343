import {Registry} from 'parchment';
import Scroll from '@reedsy/quill/blots/scroll';
import IndentedBlockBlot from './indented-block-blot';
import {dig} from '@reedsy/utils.dig';
import {ContentEditable, CONTENT_EDITABLE} from '@reedsy/studio.shared/services/quill/helpers/content-editable';
import {isDraggable} from '@reedsy/studio.shared/services/quill/helpers/drag-and-drop';
import Emitter from '@reedsy/quill/core/emitter';

const TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export default class ReedsyScroll extends Scroll {
  private readonly dragGhost: HTMLImageElement;

  public constructor(registry: Registry, domNode: HTMLDivElement, options: {emitter: Emitter}) {
    super(registry, domNode, options);
    this.registerIndentationObserver();
    // Safari has a weird quirk, where if you try to construct a drag ghost in the dragstart
    // event, it just cancels the drag, so instead, let's initialise a ghost and reuse it
    this.dragGhost = document.createElement('img');
    this.dragGhost.src = TRANSPARENT_IMAGE;

    // There is a bug in quill that removes the text when the translation
    // in chrome is turned on this code disables translation in editable mode
    // and enable it in read only mode
    // The fix comes from this comment https://github.com/quilljs/quill/issues/2286#issuecomment-882705135
    this.domNode.parentElement.classList.toggle('notranslate', true);
  }

  public override handleDragStart(event: DragEvent): void {
    // This is a property that we must set on any dragstart events if we want to
    // allow the drag to happen. Otherwise we block all dragging events to
    // prevent eg dragging text, and other elements that shouldn't be dragged
    if (!isDraggable(event)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    this.disableDragGhost(event);
  }

  public override enable(shouldEnable: boolean | ContentEditable = true): void {
    this.domNode.contentEditable = `${shouldEnable}`;
  }

  public override isEnabled(): boolean {
    const contentEditable = this.domNode.contentEditable as ContentEditable;
    return !!CONTENT_EDITABLE[contentEditable];
  }

  // Paragraph indentation logic is reliant on knowing about the content immediately
  // preceding the paragraph. However, if a paragraph is deleted, image inserted,
  // etc., the following paragraph is not informed. So instead we set up an
  // observer, which will actively update the indentations either side of a
  // mutation.
  private registerIndentationObserver(): void {
    new MutationObserver(
      (mutations) => {
        mutations.forEach((mutation) => {
          this.updateIndentation(mutation.previousSibling);
          this.updateIndentation(mutation.nextSibling);
        });
      },
    ).observe(this.domNode, {childList: true});
  }

  private updateIndentation(domNode: Node): void {
    const blot = this.find(domNode);
    if (blot instanceof IndentedBlockBlot) blot.updateIndentation();
  }

  private disableDragGhost(event: DragEvent): void {
    if (dig(event, 'dataTransfer', 'setDragImage')) {
      event.dataTransfer.setDragImage(this.dragGhost, 0, 0);
    }
  }
}
