import Quill from '@reedsy/quill';
import Module from '@reedsy/quill/core/module';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import ReedsyKeyboard from '@reedsy/studio.shared/services/quill/modules/keyboard';

export default class FormInput extends Module {
  public constructor(quill: Quill, options?: any) {
    super(quill, options);

    this.addKeyboardBindings();
  }

  private addKeyboardBindings(): void {
    const keyboard = this.quill.keyboard as ReedsyKeyboard;
    keyboard.prependBinding({
      key: Key.Enter,
      handler: this.submitForm,
    });

    keyboard.prependBinding({
      key: Key.Enter,
      shiftKey: null,
      handler: this.submitForm,
    });
  }

  private submitForm(): void {
    const formElement = this.quill.container.closest('form');

    if (formElement) {
      const submitEvent = new SubmitEvent('submit', {
        bubbles: true,
        cancelable: true,
      });
      formElement.dispatchEvent(submitEvent);
    }
  }
}
