import Delta from '@reedsy/quill-delta';
import {splitOpsOnNewlines} from '@reedsy/utils.ot-rich-text';
import Video from '@reedsy/quill/formats/video';
import {youtubeLinkTransformer} from './youtube-link-transformer';

type Transformer = (input: string) => string;
const VIDEO_LINK_TRANSFORMERS: Transformer[] = [
  youtubeLinkTransformer,
];

function stringToEmbedVideoUrl(text: string): string {
  for (const linkTransformer of VIDEO_LINK_TRANSFORMERS) {
    const embedVideoUrl = linkTransformer(text);

    if (embedVideoUrl) return embedVideoUrl;
  }
}

export default function videoMatcher(node: HTMLElement, delta: Delta): Delta {
  delta = new Delta(splitOpsOnNewlines(delta.ops));

  delta.forEach((op) => {
    if (typeof op.insert === 'string') {
      const embedVideoUrl = stringToEmbedVideoUrl(op.insert);
      if (embedVideoUrl) {
        op.insert = {[Video.blotName]: embedVideoUrl};
      }
    }
  });

  return delta;
}
