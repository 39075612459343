import {StyleAttributor, Scope} from 'parchment';

class BoldStyleAttributor extends StyleAttributor {
  public override value(node: HTMLElement): any {
    const value = super.value(node);
    if (value === 'bold') return true;
    const weight = parseInt(value);
    if (Number.isInteger(weight)) return weight > 400;
    return false;
  }
}

const boldStyleAttributor = new BoldStyleAttributor('bold', 'font-weight', {
  scope: Scope.INLINE_ATTRIBUTE,
});

export default boldStyleAttributor;
