
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class Expander extends ClientSharedVue {
  @Prop({type: Boolean, required: true})
  public override modelValue: boolean;

  @Prop({type: String, required: false})
  public iconClass: string;

  @Prop({type: Boolean, required: false})
  public loading: boolean;

  public toggle(): void {
    if (this.loading) return;
    this.update();
  }

  @Emit('update:modelValue')
  private update(): boolean {
    return !this.modelValue;
  }
}
