import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {injectable} from 'inversify';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.viewer/types';
import {SharedModalContextMap} from '@reedsy/studio.shared/components/modals/modal-type-object-map';
import {SharedModals} from '@reedsy/studio.shared/store/modules/modals/base';
import {CANCELABLE_MODALS} from '@reedsy/studio.viewer/components/modals/cancelable-modals';
import IModal from '@reedsy/studio.shared/store/modules/modals/i-modal';

@injectable()
export class BookViewerModalsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('ModalsData')
    modalsData: Record<string, IModal>,
  ) {
    @Module({name: StoreName.BookViewerModals, store})
    class BookViewerModalsModule extends SharedModals<SharedModalContextMap> {
      public override readonly modalsById: {[id: string]: IModal} = modalsData;
      protected override readonly cancelableModals = CANCELABLE_MODALS;
    }

    this.Module = BookViewerModalsModule;
  }
}

export type BookViewerModalsModule = InstanceType<BookViewerModalsModuleFactory['Module']>;
