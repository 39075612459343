import Module from '@reedsy/quill/core/module';
import {Registry} from 'parchment';
import Quill from '@reedsy/quill';
import IgnoreSpelling from '@reedsy/studio.shared/services/quill/blots/ignore-spelling';
import Highlighter, {ACTIVE_CLASS} from '@reedsy/studio.shared/services/quill/helpers/highlighter/highlighter';
import SpellingTooltip from './spelling-tooltip';

const HIGHLIGHT_CLASS = 'ql-spelling';

export default class Spelling extends Module {
  public static readonly CLASSES = {
    HIGHLIGHT: HIGHLIGHT_CLASS,
    ACTIVE: ACTIVE_CLASS,
  };

  public static registerFormats(registry: Registry): void {
    registry.register(IgnoreSpelling);
  }

  public highlighter: Highlighter;
  public tooltip: SpellingTooltip;

  public constructor(quill: Quill, options?: any) {
    super(quill, options);
    Spelling.registerFormats(quill.options.registry);

    this.highlighter = new Highlighter(quill, HIGHLIGHT_CLASS);
    this.tooltip = new SpellingTooltip(
      quill,
      quill.root,
    );
  }
}
