import smartTextOp from '@reedsy/studio.shared/utils/smart-text/smart-text-op';
import {opStart} from '@reedsy/utils.ot-rich-text';
import Quill from '@reedsy/quill';
import Module from '@reedsy/quill/core/module';

export default class SmartText extends Module {
  public constructor(quill: Quill, options?: any) {
    super(quill, options);
    quill.on('text-change', (delta, oldContents, source) => {
      if (source !== Quill.sources.USER) return;
      const start = opStart(delta);
      const range = {index: start, length: delta.length() - start};
      const op = smartTextOp(quill.getContents(), range);
      quill.updateContents(op);
    });
  }
}
