
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({
  components: {},
})
export default class TitlePageHeader extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  public get authorName(): string {
    return this.$bookContent.authorName;
  }

  public get bookTitle(): string {
    return this.$bookContent.bookTitle;
  }

  public get bookSubtitle(): string {
    return this.$bookContent.bookSubtitle;
  }
}
