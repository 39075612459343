import {injectable, named} from 'inversify';
import {BookViewerRouterPlugin} from '@reedsy/studio.viewer/store/book-viewer-router-plugin';
import {$inject} from '@reedsy/studio.viewer/types';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';
import {decodeObjectId} from '@reedsy/studio.shared/router/helpers/encoders/object-id-encoder';
import {BookViewerRichTextModule} from '@reedsy/studio.viewer/store/modules/rich-text/book-viewer-rich-text';
import {BookViewerTableOfContentsModule} from '@reedsy/studio.viewer/store/modules/table-of-contents/table-of-contents';
import {IBookViewerRoute} from '@reedsy/studio.viewer/router/i-book-viewer-route';

@injectable()
export default class CurrentContentPluginFactory extends BookViewerRouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.BookViewerDisplayedData)
  public readonly _DisplayedData: BookViewerDisplayedDataModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerRichText)
  public readonly _RichText: BookViewerRichTextModule;

  @$inject('StoreModule')
  @named(StoreName.BookViewerTableOfContents)
  public readonly _TableOfContent: BookViewerTableOfContentsModule;

  public override async beforeEach(to: IBookViewerRoute, from: IBookViewerRoute): Promise<void> {
    const contentId = decodeObjectId(to.params.contentId);
    if (!contentId) return;

    if (from.path !== to.path) {
      this._DisplayedData.IS_SWITCHING_CONTENT(true);
    }
    this._DisplayedData.DISPLAYED_CONTENT_ID(contentId);

    // This shouldn't be awaited as we need to display the content as fast as possible
    this._RichText.loadContentReferences(contentId);

    if (this._TableOfContent.hasContentAfter(contentId)) {
      // Preload next chapter
      const nextContent = this._TableOfContent.nextContentFragment(contentId);

      this._RichText.loadContentReferences(nextContent.id);
    }
  }

  public override afterEach(): void {
    this._DisplayedData.IS_SWITCHING_CONTENT(false);
  }
}
