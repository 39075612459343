import Quill from '@reedsy/quill/core';

export default function findQuill(node: Node): Quill {
  while (node) {
    const quill = Quill.find(node);
    if (quill instanceof Quill) return quill;
    node = node.parentElement;
  }

  return null;
}
