<template>
  <div
    ref="footer"
    class="footer"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import {Component, Ref} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {timeout} from '@reedsy/utils.timeout';

export const LEFT_SIDEBAR_FOOTER_HEIGHT = '--left-sidebar-footer-height';

@Component({})
export default class Footer extends ClientSharedVue {
  @Ref
  private readonly footer: HTMLElement;

  private observer: ResizeObserver;

  public mounted(): void {
    this.updateCSSProperty();
    this.observer = new ResizeObserver(async () => {
      await timeout(); // Avoid loop by waiting for render
      this.updateCSSProperty();
    });
    this.observer.observe(this.footer);
  }

  public unmounted(): void {
    this.observer.disconnect();
  }

  private async updateCSSProperty(): Promise<void> {
    const height = this.footer?.getBoundingClientRect().height;
    if (!height) return;
    document.documentElement.style.setProperty(LEFT_SIDEBAR_FOOTER_HEIGHT, `${height}px`);
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
  display: flex;
  flex-direction: column;
  font-size: $font-size-sm;
  position: sticky;
  z-index: $z-index-absolute-blocks;
  justify-content: space-between;
}
</style>
