import {BlockEmbed} from '@reedsy/quill/blots/block';
import Scroll from '@reedsy/quill/blots/scroll';
import {TrackChangesKeys} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import {makeElementDraggable} from '@reedsy/studio.shared/services/quill/helpers/drag-and-drop';
import findQuill from '@reedsy/studio.shared/services/quill/helpers/find-quill';
import Quill from '@reedsy/quill';

const {BLOCK} = TrackChangesKeys;

export default class Video extends BlockEmbed {
  public static override readonly blotName = 'video';
  public static override readonly tagName = 'VIDEO-PLAYER';
  public static override readonly className = 'ql-video';

  public static override create(url: string): HTMLElement {
    const domNode = super.create(url) as HTMLElement;
    domNode.setAttribute('src', url);

    return domNode;
  }

  public static override value(domNode: HTMLElement): string {
    return domNode.getAttribute('src');
  }

  public override domNode: HTMLElement;
  private readonly quill: Quill;
  private readonly isReadOnly: boolean;

  public constructor(scroll: Scroll, domNode: HTMLElement) {
    super(scroll, domNode);
    this.quill = findQuill(scroll.domNode);
    this.isReadOnly = this.quill.options.readOnly;

    if (this.isReadOnly) {
      domNode.setAttribute('readonly', 'true');
      return;
    }

    makeElementDraggable(this.domNode, this.isTrackedDeletion.bind(this));
    domNode.draggable = true;
    domNode.contentEditable = 'false';
  }

  public isTrackedDeletion(): boolean {
    return !!this.attributes.values()[BLOCK.DELETION];
  }
}
