enum StoreName {
  BookViewerModals = 'bookViewer_modals',
  BookViewerSettings = 'bookViewer_settings',
  BookViewerReadingLocation = 'bookViewer_readingLocation',
  BookViewerLayout = 'bookViewer_layout',
  BookViewerSharableUrlDetails = 'bookViewer_sharableUrlDetails',
  BookViewerBookContent = 'bookViewer_bookContent',
  BookViewerTableOfContents = 'bookViewer_tableOfContents',
  BookViewerRichText = 'bookViewer_richText',
  BookViewerBookOwner = 'bookViewer_bookOwner',
  BookViewerDisplayedData = 'bookViewer_displayedData',
}

export default StoreName;
