import {ParentBlot} from 'parchment';
import Selection from '@reedsy/quill/core/selection';
import Quill from '@reedsy/quill';
import Delta from '@reedsy/quill-delta';
import Break from '@reedsy/quill/blots/break';
import browser from '@reedsy/studio.shared/utils/browser';
import {dig} from '@reedsy/utils.dig';
import Cursor from '@reedsy/quill/blots/cursor';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import Emitter, {EmitterSource} from '@reedsy/quill/core/emitter';

export default class ReedsySelection extends Selection {
  public constructor(quill: Quill, options: any) {
    super(quill, options);
    this.emitter.on(Quill.events.EDITOR_CHANGE, this.updateSavedRange.bind(this));
    this.emitter.on(Quill.events.EDITOR_CHANGE, this.avoidUiSpan.bind(this));
    this.emitter.on(Quill.events.TEXT_CHANGE, this.keepSelectionAwayFromEdgeOfScreen.bind(this));

    // Chromium browsers have a bug where page up and page down cause a bad
    // horizontal scroll inside contenteditable, so we sadly have to re-roll this
    // behaviour ourselves:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=890248
    if (browser.getEngineName() === 'Blink') {
      quill.root.addEventListener('keydown', this.handlePageUpDown.bind(this));
    }
  }

  // Override Quill's default. The default starts a "batch" when we receive 'compositionstart'
  // and doesn't end the batch until 'compositionend'. However, batches don't work well when
  // receiving remote ops (or tracked change ops), which flush the batch early, so we disable
  // that behaviour.
  public override handleComposition(): void {
    this.scroll.domNode.addEventListener('compositionstart', () => {
      this.composing = true;
      this.scroll.domNode.classList.remove('ql-blank');
    });
    this.scroll.domNode.addEventListener('compositionend', () => {
      this.composing = false;
      const nativeRange = this.getNativeRange();
      // Check if we're ending the composition in an empty cursor. If so, let's just ignore the
      // cursor.restore(). If we don't, then Android is tripped into an infinite loop
      if (
        dig(nativeRange, 'start', 'node') === this.cursor.textNode &&
        this.cursor.textNode.length === Cursor.CONTENTS.length
      ) return;
      // This unwraps the cursor <span> and finalises our IME changes.
      // If we don't call this,then tracked changes aren't applied correctly.
      const range = this.cursor.restore();
      if (!range) return;
      // Trying to restore the cursor position gets us an off-by-one positioning on Android,
      // but it seems to restore the cursor fine on its own, so let's early return
      if (browser.is('android')) return;
      setTimeout(() => {
        try {
          this.setNativeRange(
            range.startNode,
            range.startOffset,
            range.endNode,
            range.endOffset,
          );
        } catch {
          // Ignore error. Sometimes, when using IME at the end of a tracked change,
          // the cursor.restore() reports an incorrect range. The browsers seem to
          // handle this all right
        }
      }, 1);
    });

    // The "batch" behaviour that was in Quill was to solve a case where using an IME on an
    // empty line breaks:
    // https://github.com/quilljs/quill/commit/41a60fbf7cc9d23f4d87c4a88c42bb56157e3432
    // This is our own fix, which forces the Cursor blot onto the empty line on compositionstart by
    // removing a non-existent format. The cursor prevents the IME from being interrupted.
    this.scroll.domNode.addEventListener('compositionstart', () => {
      const selection = this.quill.getSelection();
      if (!selection) return;
      const [line] = this.quill.getLine(selection.index) as [ParentBlot, number];
      const isEmptyLine = dig(line, 'children', 'length') === 1 && line.children.head instanceof Break;
      if (!isEmptyLine) return;
      this.quill.format('', null, Quill.sources.SILENT);

      // On Android, the first keypress in an empty line is trapped inside the Cursor
      // we've just placed. The Cursor blot stops the browser from registering the
      // input, and the caret doesn't correctly update. Here, we forcibly set the
      // selection to the end of the cursor, which should correctly update.
      if (!browser.is('android')) return;
      this.emitter.once(Emitter.events.SCROLL_UPDATE, () => {
        const selection = document.getSelection();
        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0);
        range.setStart(this.cursor.textNode, this.cursor.textNode.data.length);
      });
    });
  }

  public override hasFocus(): boolean {
    if (super.hasFocus()) return true;
    if (this.quill.options.readOnly) return false;
    // Safari doesn't correctly handle the upstream hasFocus() check, because it
    // inaccurately reports document.activeElement. Let's add an extra check on the
    // document selection to catch this.
    return this.root.contains(this.getNativeRange()?.native?.startContainer);
  }

  public override setNativeRange(
    startNode: Node,
    startOffset?: number,
    endNode?: Node,
    endOffset?: number,
    force?: boolean,
  ): void {
    // Address an Android corner case:
    //  1. Have a document whose last (or only) block is a list item
    //  2. The list item's last blot must be an embed (eg an endnote)
    //  3. Put cursor after embed
    //  4. Backspace to remove embed
    //  5. Embed is removed, but keyboard is collapsed
    // This happens because - for some reason - the browser's selection
    // is moved inside the toolbar. This is technically "inside" Quill,
    // so Quill thinks it has focus.
    // When the nativeRange is moved back, the keyboard stays collapsed,
    // because we never blurred (so we never re-focus). This is a small
    // hack that checks if the focus is inside Quill, but *outside* the
    // contenteditable, and triggers a blur in this case, to force the
    // keyboard to reappear.
    const selection = document.getSelection();
    const range = selection.rangeCount && selection.getRangeAt(0);
    if (range && !this.scroll.domNode.contains(range.startContainer)) super.setNativeRange(null);

    super.setNativeRange(startNode, startOffset, endNode, endOffset, force);
  }

  // The default Selection class doesn't update its saved range when receiving changes,
  // which can cause it to drift when remote users edit a document
  private updateSavedRange(eventType: string, delta: Delta): void {
    if (eventType !== Quill.events.TEXT_CHANGE) return;
    const index = delta.transformPosition(this.savedRange.index, true);
    const end = delta.transformPosition(this.savedRange.index + this.savedRange.length, true);
    const length = end - index;
    this.savedRange = {index, length};
  }

  // Firefox can sometimes place the selection inside a .ql-ui element, which has
  // contenteditable="false" and leads to weird behaviour. Let's check if this has
  // happened, and just force the selection to reset
  private avoidUiSpan(): void {
    const range = this.getNativeRange();
    if (!range) return;
    const startNode = range.start.node;
    const startElement = startNode.nodeType === Node.ELEMENT_NODE ? startNode as HTMLElement : startNode.parentElement;
    const isInsideUi = !!startElement.closest('.ql-ui');
    if (isInsideUi) this.setRange(this.normalizedToRange(range));
  }

  private handlePageUpDown(event: KeyboardEvent): void {
    if (event.key !== Key.PageDown && event.key !== Key.PageUp) return;
    event.preventDefault();

    const quillBounds = this.quill.root.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const {left, right} = quillBounds;

    const highestBottom = Math.min(quillBounds.bottom, windowHeight);

    // PageDown means we want the bottom at the top
    let top = highestBottom;
    // quill.scrollRectIntoView() assumes "nearest" behaviour: it does the minimum work to get
    // the most of our rectangle into view, so set this to a very large number to force the
    // top of our rectangle to the top of the screen
    let bottom = Number.MAX_SAFE_INTEGER;

    if (event.key === Key.PageUp) {
      const lowestTop = Math.max(quillBounds.top, 0);
      // We need to guess how much to try and scroll by, which should be the height of the
      // scroll container. This is non-trivial, since the scrolling container could be any of
      // Quill, the window, or some element in between (eg a modal).
      // Let's just guess a "sensible" amount to scroll by as the height of the intersection of
      // Quill and the viewport: this is a lowest bound guess that shouldn't over-scroll.
      const height = highestBottom - lowestTop;
      const target = lowestTop - height;
      top = bottom = target;
    }

    this.quill.scrollRectIntoView({top, bottom, left, right});
  }

  private keepSelectionAwayFromEdgeOfScreen(delta: Delta, oldContents: Delta, source: EmitterSource): void {
    if (source !== Quill.sources.USER) return;
    const range = this.lastRange;
    const bounds = range && this.getBounds(range.index, range.length);
    if (!bounds) return;

    const intersection = this.quillWindowIntersection();

    let top = bounds.top;
    let bottom = bounds.bottom;
    // As with page up/down handling, we try to make a reasonable guess at the height of the
    // Quill scroll container by finding the intersection of Quill and the window.
    // We then check if the selection is too close to the edge of the window as a fraction of
    // this estimated scroll height.
    const topPadding = Math.max(
      0.1 * intersection.height,
      this.topBarHeightPx(),
    );
    const isTooCloseToTopOfScreen = bounds.top < topPadding;
    const isTooCloseToBottomOfScreen = bounds.bottom + 0.3 * intersection.height > window.innerHeight;
    if (isTooCloseToTopOfScreen) top -= topPadding;
    // If we're too close to the bottom, let's overscroll a little bit so that the scroll
    // doesn't jump *every* time we hit a new line, which can be a little distracting.
    if (isTooCloseToBottomOfScreen) bottom += 0.4 * intersection.height;

    this.quill.scrollRectIntoView({...bounds, top, bottom});
  }

  private topBarHeightPx(): number {
    const topBar = document.querySelector('.top-bar');
    return topBar ? topBar.getBoundingClientRect().height : 0;
  }

  private quillWindowIntersection(): ReturnType<Selection['getBounds']> {
    const quillBounds = this.quill.root.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const top = Math.max(quillBounds.top, 0);
    const bottom = Math.min(quillBounds.bottom, windowHeight);
    const left = Math.max(quillBounds.left, 0);
    const right = Math.min(quillBounds.right, windowWidth);

    return {
      top,
      bottom,
      left,
      right,
      height: bottom - top,
      width: right - left,
    };
  }
}
