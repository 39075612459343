
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import ContentNavigationButton from '@reedsy/studio.viewer/components/content-navigation-button/content-navigation-button.vue';
import TitlePageHeader from '@reedsy/studio.viewer/components/title-page-header/title-page-header.vue';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerDisplayedDataModule} from '@reedsy/studio.viewer/store/modules/displayed-data/book-viewer-displayed-data';

@Component({
  components: {
    ContentNavigationButton,
    TitlePageHeader,
  },
})
export default class PageContainer extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerDisplayedData)
  public $displayedData: BookViewerDisplayedDataModule;

  public get isFirstPage(): boolean {
    return this.$displayedData.isFirstPage;
  }
}
