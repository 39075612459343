import {ClassAttributor, Scope} from 'parchment';
import Block from '@reedsy/quill/blots/block';
import Callout from '@reedsy/studio.shared/services/quill/blots/callout';
import IndentedBlockquote from '@reedsy/studio.shared/services/quill/blots/indented-blockquote-blot';
import {blotTags} from '@reedsy/studio.shared/services/quill/helpers/blotTags';

const ALLOWED_BLOTS = Object.freeze([
  Block,
  Callout,
  IndentedBlockquote,
]);

const ALLOWED_TAGS = new Set(
  ALLOWED_BLOTS.reduce(
    (tags, blot) => tags.concat(blotTags(blot)),
    [] as string[],
  ),
);

class AlignAttributor extends ClassAttributor {
  public override canAdd(node: HTMLElement, value: string): boolean {
    return ALLOWED_TAGS.has(node.tagName) && super.canAdd(node, value);
  }
}

const ReedsyAlign = new AlignAttributor('align', 'ql-align', {
  scope: Scope.BLOCK,
  whitelist: ['right', 'center'],
});

export {ReedsyAlign as default, ALLOWED_BLOTS};
