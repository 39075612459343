import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "book-viewer" }
const _hoisted_2 = {
  key: 0,
  class: "initial-book-viewer-loader-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isNotFoundView && _ctx.isLayoutLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_VuiLoadingIndicator)
        ]))
      : (!_ctx.isSwitchingContent)
        ? (_openBlock(), _createBlock(_component_RouterView, {
            key: 1,
            class: "book-viewer-page"
          }))
        : _createCommentVNode("", true)
  ]))
}