import {dig} from '@reedsy/utils.dig';
import {addAttributes} from '@reedsy/utils.ot-rich-text';
import {ITrackedChanges, ITrackedChange} from '@reedsy/studio.shared/services/track-changes/i-tracked-changes';
import TrackAttributor from './track-attributor';
import SESSION_ID from '@reedsy/studio.shared/services/sharedb/session-id';
import TrackComment from '@reedsy/studio.shared/services/quill/blots/track-changes/track-comment';

const DELETED_ATTRIBUTES_ATTRIBUTE = 'deleted-attributes';

export default class TrackInsertAttributor extends TrackAttributor<ITrackedChange> {
  private allowedTags: Set<string>;

  public constructor(attrName: string, keyName: string, options?: any) {
    super(attrName, keyName, options);
    if (options.allowedTags) {
      const allowedTags = options.allowedTags.map((tag: string) => tag.toUpperCase());
      this.allowedTags = new Set(allowedTags);
    }
  }

  public value(node: HTMLElement): any {
    const changes = this.getUserChangeIds(node, this.keyName);
    if (!changes) return null;
    const deletedAttributes = this.getAttributeAsObject(node, DELETED_ATTRIBUTES_ATTRIBUTE);
    return changes.reduce((byId: ITrackedChanges, value: ITrackedChange) => {
      if (deletedAttributes) addAttributes(value, deletedAttributes);
      byId[value.changeId] = value;
      return byId;
    }, {});
  }

  public override canAdd(node: HTMLElement, values: ITrackedChange[]): boolean {
    return super.canAdd(node, values) && this.isAllowedTag(node);
  }

  protected override setChangeMeta(node: HTMLElement, values: ITrackedChange[]): void {
    const attributeValue = dig(values, 0, 'attributes');
    this.setAttribute(node, DELETED_ATTRIBUTES_ATTRIBUTE, attributeValue);
    if (this.isPending(values)) node.setAttribute(TrackComment.PENDING_ATTRIBUTE, 'true');
    else node.removeAttribute(TrackComment.PENDING_ATTRIBUTE);
  }

  private isAllowedTag(node: HTMLElement): boolean {
    return !this.allowedTags || this.allowedTags.has(node.tagName.toUpperCase());
  }

  private isPending(values: ITrackedChange[]): boolean {
    return values.every((value) => value.pending && value.pending !== SESSION_ID);
  }
}
