import {Range} from '@reedsy/quill/core/selection';
import BlotPlaceholder from '@reedsy/studio.shared/services/quill/modules/drag-and-drop/blot-placeholder';
import Quill from '@reedsy/quill/core';
import {FigureImage} from '@reedsy/studio.shared/services/quill/blots/figure';
import LiveRange from '@reedsy/studio.shared/services/quill/helpers/live-range';
import IFigure from '@reedsy/studio.shared/services/quill/modules/uploader/i-figure';
import Delta from '@reedsy/quill-delta';
import IUploaderOptions from './i-uploader-options';
import Module from '@reedsy/quill/core/module';
import Blot from '@reedsy/studio.shared/services/quill/blots/blot';

const EVENTS = {
  FILE_UPLOAD: 'file-upload',
};

export default class ReedsyUploader extends Module {
  public static readonly events = EVENTS;

  public static readonly MIME_TYPES = new Set([
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/svg+xml',
  ]);

  public override options: IUploaderOptions;

  public constructor(quill: Quill, options?: IUploaderOptions) {
    super(quill, options);
  }

  public async upload(range: Range, filesOrFigures: File[] | IFigure[]): Promise<void> {
    if (!this.options.enabled) return;
    range = range || this.quill.selection.savedRange;
    range = Object.assign({}, {index: 0}, range, {length: 0});
    const liveRange = new LiveRange(this.quill, range);
    filesOrFigures = this.filterMimeTypes(filesOrFigures);

    let shouldMockUploadProgress: boolean;
    let filesOrUrls: File[] | string[];
    if (this.areFiles(filesOrFigures)) {
      shouldMockUploadProgress = false;
      filesOrUrls = filesOrFigures;
    } else {
      // If the upload is an external URL, most of the heavy lifting happens
      // server-side, so let's just fake that progress is happening in the UI
      shouldMockUploadProgress = true;
      filesOrUrls = filesOrFigures.map((figure) => figure.url);
    }

    const placeholder = new BlotPlaceholder(this.quill, null, shouldMockUploadProgress);
    const block = this.nextBlock(range);
    placeholder.moveBefore(block);

    if (typeof this.options.upload !== 'function') throw new Error('No option is provided for the uploader module.');

    await this.options.upload(filesOrUrls, this.updateProgress.bind(this, placeholder))
      .then((urls: string[]): void => {
        const figures = urls.map((url, index) => {
          const figure: IFigure = {url};
          const fileOrFigure = filesOrFigures[index];
          if ('caption' in fileOrFigure) figure.caption = fileOrFigure.caption;
          return figure;
        });
        return this.addImagesToDocument(liveRange, figures);
      })
      .finally(() => {
        placeholder.remove();
      });
  }

  private addImagesToDocument(liveRange: LiveRange, uploads: IFigure[]): void {
    const range = liveRange.get();
    const startingDelta = new Delta().retain(range.index);
    const update = uploads.reduce((delta, upload) => {
      delta.insert({[FigureImage.blotName]: upload.url});
      if (!!upload.caption) {
        delta = delta.concat(upload.caption);
        delta.insert('\n', {caption: true});
      }
      return delta;
    }, startingDelta);
    this.quill.updateContents(update, Quill.sources.USER);
  }

  private filterMimeTypes(filesOrUrls: any[]): File[] | IFigure[] {
    return filesOrUrls.filter((file) => file && (!file.type || ReedsyUploader.MIME_TYPES.has(file.type)));
  }

  private nextBlock(range: Range): Node {
    const line = this.quill.getLine(range.index);
    let block: Blot = line[0];
    const offset = line[1];
    if (offset > 0 && block.next) {
      range.index += block.length() - offset;
      block = block.next;
    }

    return block.domNode;
  }

  private updateProgress(placeholder: BlotPlaceholder, progress: number): void {
    placeholder.progress(progress * 100);
  }

  private areFiles(filesOrFigures: File[] | IFigure[]): filesOrFigures is File[] {
    return filesOrFigures[0] instanceof File;
  }
}
