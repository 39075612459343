import {injectable} from 'inversify';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {AppTheme} from '@reedsy/studio.shared/store/modules/user-settings/app-theme';
import {watchEffect} from 'vue';
import {enumValues} from '@reedsy/utils.enum';
import {memoize} from '@reedsy/utils.decorator';

@injectable()
export abstract class AppThemeBasePluginFactory implements IStorePluginFactory {
  protected abstract get rootThemeClass(): AppTheme | null;

  private readonly themeClasses: AppTheme[] = enumValues(AppTheme);

  @memoize
  private get rootElement(): HTMLHtmlElement {
    return document.querySelector<HTMLHtmlElement>(':root');
  }

  public plugin(): void {
    watchEffect(this.updateTheme.bind(this));
  }

  private updateTheme(): void {
    this.themeClasses.forEach((className) => {
      this.rootElement.classList.remove(className);
    });

    // null appTheme means we wil not have any class
    // on the :root element and we will let the css
    // handle the color scheme utilizing prefers-color-scheme media query
    if (this.rootThemeClass !== null) {
      this.rootElement.classList.add(this.rootThemeClass);
    }
  }
}
