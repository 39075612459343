import {Registry} from 'parchment';
import Quill from '@reedsy/quill';
import Endnote from '@reedsy/studio.shared/services/quill/formats/endnote';
import {deepEqual} from '@reedsy/utils.deep-equal';
import {endnoteHashFromId} from '@reedsy/studio.shared/utils/endnote-hash';
import Module from '@reedsy/quill/core/module';
import IEndnotesOptions from './i-endnotes-options';
import {DEFAULT_ENDNOTE_PLACEHOLDER} from '@reedsy/studio.shared/mixins/endnotes';

export default class Endnotes extends Module {
  public static registerFormats(registry: Registry): void {
    registry.register(Endnote);
  }

  public override options: IEndnotesOptions;
  public readonly list: string[] = [];
  public startNumber: number = null;
  public shouldUsePlaceholder = false;
  public placeholder = DEFAULT_ENDNOTE_PLACEHOLDER;

  public constructor(quill: Quill, options?: IEndnotesOptions) {
    super(quill, options);
    Endnotes.registerFormats(quill.options.registry);
    this.quill.on(Quill.events.TEXT_CHANGE, () => this.update());
  }

  private get shouldRenderPlaceholder(): boolean {
    return this.shouldUsePlaceholder || !this.startNumber || isNaN(this.startNumber);
  }

  public update(): void {
    const updatedIds: string[] = [];
    const endnotes = this.quill.container.querySelectorAll(`.${Endnote.className}`);
    endnotes.forEach((endnote: HTMLElement, index: number) => {
      const endnoteLiteral = this.endnoteLiteral(index);
      const id = endnote.dataset.endnote;
      const blot = this.quill.options.registry.find(endnote) as Endnote;
      if (blot.text !== endnoteLiteral) {
        blot.text = endnoteLiteral;
        endnote.setAttribute('id', endnoteHashFromId(id));
      }
      updatedIds.push(id);
    });

    if (deepEqual(this.list, updatedIds)) return;
    this.list.splice(0, this.list.length, ...updatedIds);
  }

  private endnoteLiteral(index: number): string {
    if (this.shouldRenderPlaceholder) return this.placeholder;
    return `${this.startNumber + index}`;
  }
}
