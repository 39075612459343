<template>
  <div
    ref="scroll-container"
    class="pull-to"
    @scroll.passive="handleScroll"
    @touchstart.passive="handleTouchStart"
    @touchmove.passive="handleTouchMove"
    @touchend.passive="handleTouchEnd"
  >
    <div
      v-show="topShown"
      ref="top-loader"
      class="pull-to-top-loader pull-to-loader"
    >
      <slot
        name="top-loader"
        :activate-percentage="activatePercentage"
      >
        <PullLoader
          :percentage="activatePercentage"
        />
      </slot>
    </div>
    <slot />
    <div
      v-show="bottomShown"
      ref="bottom-loader"
      class="pull-to-bottom-loader pull-to-loader"
    >
      <slot
        name="bottom-loader"
        :activate-percentage="activatePercentage"
      >
        <PullLoader
          :percentage="activatePercentage"
          down
        />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Ref,
  Emit,
  Prop,
} from '@reedsy/studio.shared/utils/vue/decorators';
import PullLoader from './pull-loader.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

const PULL_DISTANCE_TO_SHOW_IN_PX = 0;
const PULL_DISTANCE_TO_ACTIVATE_DIV_RATIO = 0.42;

@Component({
  components: {
    PullLoader,
  },
})
export default class PullTo extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public disableTopLoader: boolean;

  @Prop({type: Boolean, default: false})
  public disableBottomLoader: boolean;

  @Ref('top-loader')
  public topLoader: HTMLDivElement;

  @Ref('scroll-container')
  public scrollContainer: HTMLDivElement;

  @Ref('bottom-loader')
  public bottomLoader: HTMLDivElement;

  public hasReachedBottom = false;
  public hasReachedTop = true;
  public startTouchLocation: number = null;
  public lastTouchLocation = 0;
  public bottomLoaderHeight = Number.POSITIVE_INFINITY;
  public topLoaderHeight = Number.POSITIVE_INFINITY;

  public get overScrollDelta(): number {
    if (this.startTouchLocation === null) return 0;

    return this.startTouchLocation - this.lastTouchLocation;
  }

  public get activatePercentage(): number {
    const height = this.scrollContainer?.clientHeight || Number.POSITIVE_INFINITY;
    const pixelsToActive = height * PULL_DISTANCE_TO_ACTIVATE_DIV_RATIO;

    const scrolledPercentage = Math.abs(this.overScrollDelta * 100 / pixelsToActive);

    return scrolledPercentage > 100 ? 100 : scrolledPercentage;
  }

  public get topShown(): boolean {
    if (this.disableTopLoader) return false;
    return this.hasReachedTop && -this.overScrollDelta > PULL_DISTANCE_TO_SHOW_IN_PX;
  }

  public get bottomShown(): boolean {
    if (this.disableBottomLoader) return false;
    return this.hasReachedBottom && this.overScrollDelta > PULL_DISTANCE_TO_SHOW_IN_PX;
  }

  public async mounted(): Promise<void> {
    this.checkScrollReachAnyEnd();
    await this.$nextTick();
    if (this.bottomLoader) this.bottomLoaderHeight = this.bottomLoader.getBoundingClientRect().height;
    if (this.topLoader) this.topLoaderHeight = this.topLoader.getBoundingClientRect().height;
  }

  public handleTouchStart(event: TouchEvent): void {
    this.updateLastTouchLocation(event);
    this.startTouchLocation = event.touches[0].clientY;
    this.checkScrollReachAnyEnd();
  }

  public handleTouchMove(event: TouchEvent): void {
    this.updateLastTouchLocation(event);
    this.checkScrollReachAnyEnd();
  }

  public handleTouchEnd(): void {
    if (this.activatePercentage >= 100) {
      this.emitPullEvent();
    }
    this.startTouchLocation = null;
  }

  public emitPullEvent(): void {
    if (this.bottomShown) {
      this.emitPullBottom();
    }

    if (this.topShown) {
      this.emitPullTop();
    }
  }

  public handleScroll(): void {
    this.checkScrollReachAnyEnd();
  }

  @Emit('pull-bottom')
  public emitPullBottom(): void {
    return;
  }

  @Emit('pull-top')
  public emitPullTop(): void {
    return;
  }

  private checkScrollReachAnyEnd(): void {
    this.checkHasReachedTop();
    this.checkHasReachedBottom();
  }

  private checkHasReachedTop(): void {
    this.hasReachedTop = Math.floor(this.scrollContainer.scrollTop) <= 0;
  }

  private checkHasReachedBottom(): void {
    this.hasReachedBottom = (
      this.scrollContainer.scrollHeight - Math.ceil(this.scrollContainer.scrollTop)
    ) <= this.scrollContainer.clientHeight;
  }

  private updateLastTouchLocation(event: TouchEvent): void {
    this.lastTouchLocation = event.touches[0].clientY;
  }
}
</script>

<style lang="scss" scoped>
.pull-to {
  .pull-to-loader {
    position: absolute;
    width: 100%;
  }

  .pull-to-top-loader {
    top: 0;
  }

  .pull-to-bottom-loader {
    bottom: 0;
  }
}
</style>
