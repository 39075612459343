import {TextEmbed} from '@reedsy/studio.shared/services/quill/blots/text-embed';
import {TRACK_CHANGES_KEYS} from '@reedsy/reedsy-sharedb/lib/utils/book-content/track-changes-attributes';
import findQuill from '@reedsy/studio.shared/services/quill/helpers/find-quill';
import Quill from '@reedsy/quill/core';
import {Scope} from 'parchment';
import {has} from '@reedsy/utils.set';

export default class UnformattableEmbed extends TextEmbed {
  public override format(name: string, value: string): void {
    if (!this.isTrackAttribute(name)) return;
    super.format(name, value);
  }

  public override optimize(context: any): void {
    super.optimize(context);
    this.removeExtraFormats();
  }

  private removeExtraFormats(): void {
    const quill = findQuill(this.scroll.domNode);
    const index = quill.getIndex(this);
    const length = this.length();
    const formats = quill.getFormat(index, length);
    for (const format of Object.keys(formats)) {
      if (this.isTrackAttribute(format) || this.isBlock(quill, format)) continue;
      this.scroll.formatAt(index, length, format, null);
    }
  }

  private isTrackAttribute(name: string): boolean {
    return has(TRACK_CHANGES_KEYS, name);
  }

  private isBlock(quill: Quill, name: string): boolean {
    return !!quill.options.registry.query(name, Scope.BLOCK);
  }
}
