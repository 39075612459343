import {uniqueId} from '@reedsy/studio.shared/utils/unique-id';

const TYPE_DELIMITER = '/';
const EVENT_DATA_TRANSFER_TYPE = 'reedsy-drag-item';
export const ATTRIBUTE_DRAG_ID = 'drag-id';

export function getDraggedElementId(event: DragEvent): string | null {
  const dragType = event.dataTransfer?.types.find(
    (type) => type.startsWith(EVENT_DATA_TRANSFER_TYPE),
  );
  return dragType ? dragType.split(TYPE_DELIMITER)[1] : null;
}

export function makeElementDraggable(element: HTMLElement, shouldPreventDrag = () => false): void {
  if (element.getAttribute(ATTRIBUTE_DRAG_ID)) return;

  element.setAttribute(ATTRIBUTE_DRAG_ID, uniqueId());
  element.addEventListener('dragstart', (event) => {
    if (shouldPreventDrag()) return;

    addElementToDragEvent(event, element);
  });
}

export function isDraggable(event: DragEvent): boolean {
  return !!getDraggedElementId(event);
}

export function addElementToDragEvent(event: DragEvent, element: HTMLElement): void {
  const dragId = element.getAttribute(ATTRIBUTE_DRAG_ID);

  if (!dragId) return;

  event.dataTransfer.setData(
    [EVENT_DATA_TRANSFER_TYPE, dragId].join(TYPE_DELIMITER),
    null,
  );
}
