import Api from '@reedsy/studio.shared/services/api/api';
import {config} from '@reedsy/studio.shared/config';
import {AxiosError} from 'axios';
import {ReedsyLocation} from '@reedsy/studio.shared/services/navigation/location';

export default class BookViewerApi extends Api {
  protected override async handleUnauthorized(error: AxiosError): Promise<void> {
    await super.handleUnauthorized(error);
    this.redirectToNotFoundPage();
  }

  protected override async handleForbidden(error: AxiosError): Promise<void> {
    await super.handleForbidden(error);
    this.redirectToNotFoundPage();
  }

  private redirectToNotFoundPage(): void {
    const notFoundUrl = `${window.location.origin}${config.app.bookViewer.basePath}/not-found`;

    ReedsyLocation.goToExternalUrl(notFoundUrl);
  }
}
