
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import MainLayout from '@reedsy/studio.viewer/components/layouts/main-layout.vue';
import EndnotePane from '@reedsy/studio.viewer/components/endnote-pane/endnote-pane.vue';
import {FontSize, LineHeight, Theme, TextJustified, TEXT_JUSTIFIED_CLASS} from '@reedsy/studio.shared/models/settings';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerSettingsModule} from '@reedsy/studio.viewer/store/modules/book-viewer-settings/book-viewer-settings';

type ISettingsClasses = Partial<{
  [className in FontSize | Theme | LineHeight | TextJustified]: boolean;
}>;

@Component({
  components: {
    MainLayout,
    EndnotePane,
  },
})
export default class BookViewer extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerSettings)
  public $settings: BookViewerSettingsModule;

  public get bookViewerSettingsClasses(): ISettingsClasses {
    const settingsClassesObject: ISettingsClasses = {
      [TEXT_JUSTIFIED_CLASS]: this.$settings.isTextJustified,
    };

    settingsClassesObject[this.$settings.theme] = true;
    settingsClassesObject[this.$settings.fontSize] = true;
    settingsClassesObject[this.$settings.lineHeight] = true;

    return settingsClassesObject;
  }
}
