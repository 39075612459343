import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "about-the-author" }
const _hoisted_2 = {
  key: 0,
  class: "author-photo"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "author-bio paragraph-space"
}
const _hoisted_5 = {
  key: 3,
  class: "links-wrapper paragraph-space"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 4,
  class: "newsletter-link-wrapper"
}
const _hoisted_8 = { class: "author-link" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_DefaultEmptyReaderPlaceholder = _resolveComponent("DefaultEmptyReaderPlaceholder")!
  const _component_RichTextReader = _resolveComponent("RichTextReader")!
  const _component_VuiIconEnvelope = _resolveComponent("VuiIconEnvelope")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.authorPhotoUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                src: _ctx.authorPhotoUrl,
                alt: "photo of the author"
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createVNode(_component_SectionTitle, { class: "chapter-title" }),
        _cache[7] || (_cache[7] = _createTextVNode()),
        (_ctx.isPageEmpty)
          ? (_openBlock(), _createBlock(_component_DefaultEmptyReaderPlaceholder, {
              key: 1,
              class: "empty-page-placeholder"
            }))
          : _createCommentVNode("", true),
        _cache[8] || (_cache[8] = _createTextVNode()),
        (_ctx.hasBio)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_RichTextReader, { "rich-text-id": _ctx.bioRichTextId }, null, 8, ["rich-text-id"])
            ]))
          : _createCommentVNode("", true),
        _cache[9] || (_cache[9] = _createTextVNode()),
        (_ctx.hasAnyUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "links-title theme-body" }, "\n          You can connect with me on:\n        ", -1)),
              _cache[2] || (_cache[2] = _createTextVNode()),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: link.url,
                  class: "author-link"
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon))),
                  _cache[0] || (_cache[0] = _createTextVNode()),
                  _createElementVNode("a", {
                    href: link.url,
                    target: "_blank"
                  }, _toDisplayString(link.url), 9, _hoisted_6)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _cache[10] || (_cache[10] = _createTextVNode()),
        (_ctx.newsletterUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "links-title theme-body" }, "\n          Subscribe to my newsletter:\n        ", -1)),
              _cache[5] || (_cache[5] = _createTextVNode()),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_VuiIconEnvelope),
                _cache[3] || (_cache[3] = _createTextVNode()),
                _createElementVNode("a", {
                  href: _ctx.newsletterUrl,
                  target: "_blank"
                }, _toDisplayString(_ctx.newsletterUrl), 9, _hoisted_9)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}