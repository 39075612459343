import SpellingTooltipTemplate from './tooltip';
import Quill from '@reedsy/quill/core';
import {Bounds} from '@reedsy/studio.shared/services/quill/modules/bounds.interface';
import {CollapsedSelectionTooltip} from '@reedsy/studio.shared/services/quill/theme/collapsed-selection-tooltip';

const TOOLTIP_CLASS = 'ql-tooltip-spelling';
export default class SpellingTooltip extends CollapsedSelectionTooltip {
  public static override TEMPLATE = new SpellingTooltipTemplate().render().innerHTML;
  public static TOOLTIP_CLASS = TOOLTIP_CLASS;
  public static readonly events = Object.freeze({
    SUGGESTION_CLICK: 'suggestion-click',
    OPTIONS_CLICK: 'options-click',
  });

  public constructor(
    quill: Quill,
    bounds: HTMLElement,
  ) {
    super(quill, bounds);
    this.suggestionButton.addEventListener('click', () => {
      quill.emitter.emit(SpellingTooltip.events.SUGGESTION_CLICK);
    });
    this.optionsButton.addEventListener('click', () => {
      quill.emitter.emit(SpellingTooltip.events.OPTIONS_CLICK);
    });
    this.root.classList.add(TOOLTIP_CLASS);
  }

  private get suggestionButton(): HTMLButtonElement {
    return this.root.querySelector('.suggestion-button');
  }

  private get optionsButton(): HTMLButtonElement {
    return this.root.querySelector('.options-button');
  }

  public setSuggestion(suggestion: string): void {
    this.suggestionButton.innerText = suggestion || 'No suggestions found';
    this.suggestionButton.disabled = !suggestion;
  }

  public override position(reference: Bounds): number {
    if (!reference) return;

    // This tooltip needs to align to the left side of the selected word. In order to do that
    // without doing a full rewrite of this method, we just pass a reference with an altered width.
    const newReference = {
      ...reference,
      // Set the fake width to be the same as the tooltip width, so the operation in this line:
      // https://github.com/quilljs/quill/blob/9cbf0041e0a96fb34f3cbbcf5b164ec892e2610e/ui/tooltip.js#L21
      // always results in the reference left value.
      width: this.root.offsetWidth,
    };
    let shift = super.position(newReference);

    // If the tooltip overflows on the right side of the container, align to the right end of the word
    // instead of the default, which is aligning to the right end of the container.
    if (shift < 0) {
      shift = this.root.offsetWidth - reference.width;
      this.root.style.left = `${reference.left - shift}px`;
    }

    return shift;
  }

  public setOptionsButtonVisibility(isVisible: boolean): void {
    this.optionsButton.classList.toggle('hidden', !isVisible);
  }
}
