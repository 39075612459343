import Embed from '@reedsy/quill/blots/embed';
import Scroll from '@reedsy/quill/blots/scroll';
import Emitter from '@reedsy/quill/core/emitter';
import {TextBlot} from 'parchment';
import Blot from './blot';
import {Range as QuillRange} from '@reedsy/quill/core/selection';

const DATA_KEY = 'embedContent';

export class TextEmbed extends Embed {
  public override domNode: HTMLElement;
  public override scroll: Scroll;

  public constructor(scroll: Scroll, node: Node) {
    super(scroll, node);
    // Don't allow complex DOM content in this Embed
    this.contentNode.innerHTML = '';
    this.contentNode.classList.add('ql-text-embed');

    const handler = (type: string, range: QuillRange): void => {
      if (!this.scroll.domNode.contains(this.domNode)) {
        scroll.emitter.off(Emitter.events.EDITOR_CHANGE, handler);
      }
      if (type !== Emitter.events.SELECTION_CHANGE || !range) return;
      this.moveSelectionOutOfEmbed(range);
    };
    scroll.emitter.on(Emitter.events.EDITOR_CHANGE, handler);
  }

  public get text(): string {
    return this.contentNode.dataset[DATA_KEY];
  }

  public set text(value: string) {
    this.contentNode.dataset[DATA_KEY] = value;
  }

  private get nodes(): ReadonlyArray<Node> {
    return [this.leftGuard, this.contentNode, this.rightGuard];
  }

  public override update(mutations: any, context: any): void {
    super.update(mutations, context);

    // Check for extra nodes added by the browser inserting content
    // inside the embed. If there is content, we create new blots for
    // it and move it outside the embed.
    let wantsAfter = false;
    this.domNode.childNodes.forEach((child) => {
      if (this.nodes.some((node) => node === child)) {
        return wantsAfter ||= child === this.contentNode;
      }
      const ref = wantsAfter ? this.nextConnected() : this;
      this.parent.insertBefore(this.scroll.create(child), ref);
    });
  }

  public override optimize(context: any): void {
    super.optimize(context);
    for (const guard of this.nodes) {
      if (!this.domNode.contains(guard)) {
        context.range = this.previousRange();
        return this.remove();
      }
    }
  }

  private nextConnected(): Blot {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let blot: Blot = this;
    while (blot = blot.next) {
      if (this.scroll.domNode.contains(blot.domNode)) return blot;
    }
  }

  private previousRange(): {startNode: Node; startOffset: number} {
    const index = this.offset(this.scroll);
    const [, offset] = this.scroll.line(index);

    const [previousLeaf] = offset ? this.scroll.leaf(index - 1) : [];
    if (previousLeaf instanceof TextBlot) {
      return {
        startNode: previousLeaf.domNode,
        startOffset: previousLeaf.domNode.textContent.length,
      };
    } else {
      return {
        startNode: this.parent.domNode,
        startOffset: Array.from(this.parent.domNode.children).indexOf(this.domNode),
      };
    }
  }

  private moveSelectionOutOfEmbed({index}: QuillRange): void {
    const [leaf, offset] = this.scroll.leaf(index);
    if (leaf !== this) return;
    const selection = document.getSelection();
    const range = selection.rangeCount && selection.getRangeAt(0);
    if (offset) range.setStartAfter(this.domNode);
    else range.setStartBefore(this.domNode);
  }
}
