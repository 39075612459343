<template>
  <div
    class="section-control"
    :class="{
      [`section-control-nest-level-${nestLevel}`]: true,
      'top-matter': isTopMatter,
      'part-fragment': isPart,
    }"
  >
    <Expander
      v-if="isCollapsible"
      v-model="expanded"
      icon-class="icon-xs expander"
    />

    <!--
      @click.native="closeLeftSidebar" is only being triggered on RouterLink
    -->
    <component
      :is="tocEntry.url ? 'RouterLink' : 'p'"
      :to="tocEntry.url"
      class="title ellipsis content-title"
      :class="{'has-children': isPart}"
      @click="closeLeftSidebar"
    >
      <span
        v-if="orderNumberLiteral"
        class="order-number"
        :class="{
          'part-number': isPart,
          'chapter-number': isChapter,
        }"
      >{{ orderNumberLiteral }}</span>
      {{ title }}
    </component>
  </div>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import Expander from '@reedsy/studio.shared/components/expander.vue';
import {ITableOfContentsEntry} from '@reedsy/studio.viewer/store/modules/table-of-contents/i-table-of-contents';
import {getOrderNumberLiteral} from '@reedsy/studio.viewer/utils/get-order-number-literal';
import {MatterType} from '@reedsy/reedsy-sharedb/lib/common/book-content/matter-type';
import {$lazyInjectStore} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerLayoutModule} from '@reedsy/studio.viewer/store/modules/layout/book-viewer-layout';

@Component({
  components: {
    Expander,
  },
})
export default class TOCContentFragmentTitle extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerLayout)
  public $layout: BookViewerLayoutModule;

  @Prop({type: Object, required: true})
  public tocEntry: ITableOfContentsEntry;

  @Prop({type: Boolean, required: true})
  public isCollapsible: boolean;

  @Model
  public expanded: boolean;

  @Prop({type: Number, required: true})
  public nestLevel: number;

  public get isTopMatter(): boolean {
    return this.nestLevel === 0;
  }

  public get isPart(): boolean {
    return this.tocEntry.matter?.type === MatterType.Part;
  }

  public get isChapter(): boolean {
    return this.tocEntry.matter.type === MatterType.Chapter;
  }

  public get title(): string {
    return this.tocEntry.title || 'Untitled chapter';
  }

  public get orderNumberLiteral(): string {
    if (!this.tocEntry.orderNumber) return '';
    return getOrderNumberLiteral(this.tocEntry.matter.type, this.tocEntry.orderNumber);
  }

  public closeLeftSidebar(): void {
    this.$layout.closeLeftSidebar();
  }
}
</script>

<style lang="scss" scoped>
.section-control {
  $nest-level-indent: $space-lg;
  $base-entry-indent: $space-md;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $space-base;
  height: $left-sidebar-content-item-height;
  font-size: $font-size-sm;
  border-bottom: $left-sidebar-separator;

  @include transition(background-color);

  &:hover {
    background: var(--studio-leftSidebar-headingBackground);
  }

  &.top-matter, &.part-fragment {
    background: var(--studio-leftSidebar-headingBackground)
  }

  & > :not(:last-child) {
    margin-right: $space-sm;
  }

  .content-title {
    flex: 1;
    height: $left-sidebar-content-item-height;
    line-height: $left-sidebar-content-item-height;
    display: block;
    align-items: center;
    margin: auto 0;

    &.has-children {
      text-transform: uppercase;
    }

    .order-number {
      display: inline-block;
    }

    .chapter-number {
      min-width: $space-lg;
      margin-right: $space-xs;
    }

    .part-number {
      margin-right: $space-sm;

      &::after {
        content: '.';
      }
    }

  }
}
</style>
