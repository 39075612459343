import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "paragraph-loader" }
const _hoisted_2 = { class: "section-with-image" }
const _hoisted_3 = { class: "paragraph-column-skeleton" }
const _hoisted_4 = { class: "title-skeleton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SkeletonLoader, { class: "paragraph-length-5" }),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "paragraph-length-3" }),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "paragraph-length-4" }),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "paragraph-length-3" }),
    _cache[7] || (_cache[7] = _createTextVNode()),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SkeletonLoader, { class: "image-skeleton" }),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SkeletonLoader),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createVNode(_component_SkeletonLoader, { class: "paragraph-length-3" }),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createVNode(_component_SkeletonLoader, { class: "paragraph-length-4" }),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createVNode(_component_SkeletonLoader, { class: "paragraph-length-1" })
      ])
    ]),
    _cache[8] || (_cache[8] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "paragraph-length-4" }),
    _cache[9] || (_cache[9] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "paragraph-length-1" }),
    _cache[10] || (_cache[10] = _createTextVNode()),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SkeletonLoader, { class: "paragraph-length-1" })
    ]),
    _cache[11] || (_cache[11] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader),
    _cache[12] || (_cache[12] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "paragraph-length-4" })
  ]))
}