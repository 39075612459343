import Block from '@reedsy/quill/blots/block';
import {ParentBlot} from 'parchment';

export default class RestrictedFormatBlock extends Block {
  public override enforceAllowedChildren(): void {
    super.enforceAllowedChildren();
    this.enforceAllowedDescendants();
  }

  private enforceAllowedDescendants(): void {
    this.unwrapInvalidBlots(this);
  }

  private unwrapInvalidBlots(parent: ParentBlot): void {
    parent.children.forEach((child) => {
      const isAllowed = this.statics.allowedChildren.some((BlotType: any) => child instanceof BlotType);

      if (isAllowed) {
        if (child instanceof ParentBlot) this.unwrapInvalidBlots(child);
        return;
      }

      if (child instanceof ParentBlot) {
        child.unwrap();
        return;
      }

      child.remove();
    });
  }
}
