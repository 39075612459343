import {IContentFragment} from '@reedsy/reedsy-sharedb/lib/utils/book-content/i-content-fragment.interface';
import {getContentFragmentAfter} from './get-content-fragment-after';

export function getContentFragmentBefore(
  flatBookContent: IContentFragment[],
  currentContentId: string,
): IContentFragment {
  const reverseFlatBookContent = flatBookContent.toReversed();
  return getContentFragmentAfter(reverseFlatBookContent, currentContentId);
}
