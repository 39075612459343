<template>
  <footer class="book-viewer-footer">
    <p class="powered-by">
      Powered by <a
        :href="writeABookUrl"
        class="vui-no-animation"
      >
        Reedsy
      </a>
    </p>
    <p
      class="author-name"
    >
      &copy; {{ authorName }}
    </p>
  </footer>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookViewerVue from '@reedsy/studio.viewer/book-viewer-vue';
import {$lazyInjectStore, $lazyInject} from '@reedsy/studio.viewer/inversify.config';
import StoreName from '@reedsy/studio.viewer/store/store-name';
import {BookViewerBookContentModule} from '@reedsy/studio.viewer/store/modules/book-content/book-viewer-book-content';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({})
export default class Footer extends BookViewerVue {
  @$lazyInjectStore(StoreName.BookViewerBookContent)
  public $bookContent: BookViewerBookContentModule;

  @$lazyInject('Navigation')
  public navigation: INavigation;

  public get writeABookUrl(): string {
    return this.navigation.writeABookUrl;
  }

  public get authorName(): string {
    return this.$bookContent.authorName;
  }
}
</script>

<style lang="scss" scoped>
.book-viewer-footer {
  min-height: $book-viewer-footer-min-height;
  border-top: $border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: $space-md $space-lg;
  width: 100%;
  flex-flow: row wrap;

  .powered-by {
    flex-shrink: 0;
    margin-right: $space-lg;

    a {
      text-decoration: underline;
      font-weight: normal;
    }
  }

  .author-name {
    flex-shrink: 1;
  }
}
</style>
